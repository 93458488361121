import React from 'react'
import CompetitionStep from '../steps/CompetitionStep'
import StepInfoPiece from '../steps/StepInfoPiece'
import Blockquote from '../blockquote/Blockquote'
import useTranslations from '../../../hooks/useTranslations'

const CompetitionStep3 = () => {
  const t = useTranslations()

  return (
    <CompetitionStep number={3} title="competition:step3-title">
      <p className="step-text">{t('competition:step3-text1')}</p>
      <StepInfoPiece
        label="competition:step3-label1"
        value="competition:step3-value1"
      />
      <StepInfoPiece
        label="competition:step3-label2"
        value="competition:step3-value2"
      />
      <Blockquote text="competition:step3-cite1" />
    </CompetitionStep>
  )
}

export default CompetitionStep3
