import React, { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import Button from '../ui/components/buttons/Button'
import Input from '../ui/components/inputs/Input'
import InputFile from '../ui/components/inputs/InputFile'
import Radio from '../ui/components/inputs/Radio'
import RadioGroup from '../ui/components/inputs/RadioGroup'
import ErrorCard from '../ui/components/cards/ErrorCard'
import Breadcrumbs from '../ui/components/breadcrumbs/Breadcrumbs'
import FormResult from '../ui/components/forms/FormResult'
import FormMatchData from '../ui/components/forms/FormMatchData'
import InnerWrapper from '../ui/components/wrappers/InnerWrapper'
import FormLayout from '../ui/layout/FormLayout'
import {
  matchResultOptions,
  quadrantOptions,
} from '../common/forms/submit-results'

import useSnackbar from '../hooks/useSnackbar'
import useBreadcrumbs from '../hooks/useBreadcrumbs'
import useTranslations from '../hooks/useTranslations'
import { openModal, showEphemeralSubmitResultsSnackbar } from '../redux/actions'
import { submitResultValidation, submitResults } from '../services/tournament'

import useModal from './modals/useModal'
import { MODAL_CONTACT } from './modals/constants'
import {
  validateResultSchema,
  createSubmitResultsSchema,
} from './validation/schemas'

import { ECOPA2021_SLUG } from './constants'
import * as routes from './routes'

const fieldTextError = (formik, field) =>
  formik.errors[field] && formik.touched[field] ? formik.errors[field] : ''

const handleChangeScreenshot = (formik, fieldName) => event => {
  if (!event.target.files) return

  formik.setFieldValue(fieldName, event.target.files[0])
}

const handleDeleteScreenshot = (formik, fieldName) => () => {
  formik.setFieldValue(fieldName, formik.initialValues[fieldName])
}

const getPlatformName = ({
  ps4: isPs4Player,
  psn_id: psnId,
  xbox: isXboxPlayer,
  gamer_tag: gamerTag,
} = {}) => {
  if (isPs4Player && psnId) return psnId
  if (isXboxPlayer && gamerTag) return gamerTag
  return null
}

const getWinner = ({ result, opponentPlatformName }, userPlatformName) =>
  result === matchResultOptions.valid.lost.value
    ? opponentPlatformName
    : userPlatformName

const ScrollHereWhenMounted = () => {
  const ref = useRef(null)
  useEffect(() => {
    ref.current.scrollIntoView({ behavior: 'smooth' })
  }, [])
  return <div ref={ref} style={{ scrollMarginTop: '5rem' }}></div>
}

const GoalsInput = ({ formik, fieldName, platformName }) => {
  return (
    <Input
      type="number"
      handleBlur={formik.handleBlur}
      handleChange={formik.handleChange}
      textError={fieldTextError(formik, fieldName)}
      textLabel="noun:goals-platformname"
      textLabelValues={{ platformName }}
      value={formik.values[fieldName]}
      name={fieldName}
    />
  )
}

const ValidationForm = ({ userPlatformName, setValues, isDisabled }) => {
  const [isValidating, setIsValidating] = useState(false)
  const [showValidationError, setShowValidationError] = useState(false)

  const validationFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      result: matchResultOptions.default,
      opponentPlatformName: '',
      roundNumber: '',
      quadrant: '',
    },
    validationSchema: Yup.object(validateResultSchema),
    onSubmit: async values => {
      setIsValidating(true)
      setShowValidationError(false)
      try {
        const { success } = await submitResultValidation(ECOPA2021_SLUG, {
          winner: getWinner(values, userPlatformName),
          adversary: values.opponentPlatformName,
          round: values.roundNumber,
          quadrant: values.quadrant,
        })
        if (success) {
          setValues(values)
        } else {
          setShowValidationError(true)
        }
      } catch (error) {
        const { data } = error.response
        console.error(data)
        setShowValidationError(true)
      }
      setIsValidating(false)
    },
  })

  return (
    <form className="formReportResult" onSubmit={validationFormik.handleSubmit}>
      <RadioGroup textError={fieldTextError(validationFormik, 'result')}>
        {Object.values(matchResultOptions.valid).map(option => (
          <Radio
            name="result"
            value={option.value}
            checked={validationFormik.values.result === option.value}
            handleBlur={validationFormik.handleBlur}
            handleChange={validationFormik.handleChange}
            textLabel={option.label}
            isDisabled={isDisabled}
            key={option.value}
          />
        ))}
      </RadioGroup>
      <FormMatchData
        opponentPlatformName={{
          handleBlur: validationFormik.handleBlur,
          handleChange: validationFormik.handleChange,
          isDisabled: isDisabled,
          error: fieldTextError(validationFormik, 'opponentPlatformName'),
          value: validationFormik.values.opponentPlatformName,
        }}
        roundNumber={{
          handleBlur: validationFormik.handleBlur,
          handleChange: validationFormik.handleChange,
          isDisabled: isDisabled,
          error: fieldTextError(validationFormik, 'roundNumber'),
          value: validationFormik.values.roundNumber,
        }}
        quadrant={{
          error: fieldTextError(validationFormik, 'quadrant'),
          isDisabled: isDisabled,
          top: {
            handleBlur: validationFormik.handleBlur,
            handleChange: validationFormik.handleChange,
            checked:
              validationFormik.values.quadrant ===
              quadrantOptions.valid.top.value,
          },
          bottom: {
            handleBlur: validationFormik.handleBlur,
            handleChange: validationFormik.handleChange,
            checked:
              validationFormik.values.quadrant ===
              quadrantOptions.valid.bottom.value,
          },
          value: validationFormik.values.quadrant,
        }}
        showError={showValidationError}
        validateButton={{
          type: 'submit',
          onClick: validationFormik.handleSubmit,
          isDisabled: !userPlatformName || isValidating || isDisabled,
          isLoading: !userPlatformName || isValidating,
          isSuccess: isDisabled,
        }}
        isDisabled={isDisabled}
      />
    </form>
  )
}

export default ({ inscription }) => {
  const [showReportError, setShowReportError] = useState(false)
  const [validation, setValidation] = useState(undefined)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const userPlatformName = getPlatformName(inscription)

  const t = useTranslations()
  const dispatch = useDispatch()
  const history = useHistory()

  const modal = useModal()
  const breadcrumbs = useBreadcrumbs()
  const snackbar = useSnackbar()

  const resultsFormik = useFormik({
    initialValues: {
      firstRoundPlayerOneGoals: '',
      firstRoundPlayerTwoGoals: '',
      firstRoundScreenshot: undefined,
      secondRoundPlayerOneGoals: '',
      secondRoundPlayerTwoGoals: '',
      secondRoundScreenshot: undefined,
      tieBreakerRoundPlayerOneGoals: '',
      tieBreakerRoundPlayerTwoGoals: '',
      tieBreakerRoundScreenshot: undefined,
    },
    validationSchema: Yup.object(createSubmitResultsSchema(validation)),
    onSubmit: async values => {
      setShowReportError(false)
      setIsSubmitting(true)
      try {
        const formData = new FormData()
        formData.append('result', validation.result)
        formData.append('round', validation.roundNumber)
        formData.append('quadrant', validation.quadrant)
        formData.append('winner', getWinner(validation, userPlatformName))
        formData.append('reporter', userPlatformName)
        formData.append(
          'firstResult',
          JSON.stringify({
            [userPlatformName]: values.firstRoundPlayerOneGoals,
            [validation.opponentPlatformName]: values.firstRoundPlayerTwoGoals,
          }),
        )
        formData.append('firstRoundImage', values.firstRoundScreenshot)
        formData.append(
          'secondResult',
          JSON.stringify({
            [userPlatformName]: values.secondRoundPlayerOneGoals,
            [validation.opponentPlatformName]: values.secondRoundPlayerTwoGoals,
          }),
        )
        formData.append('secondRoundImage', values.secondRoundScreenshot)
        formData.append(
          'tiebreak',
          JSON.stringify({
            [userPlatformName]: values.tieBreakerRoundPlayerOneGoals,
            [validation.opponentPlatformName]:
              values.tieBreakerRoundPlayerTwoGoals,
          }),
        )
        formData.append('tiebreakImage', values.tieBreakerRoundScreenshot)

        const { success } = await submitResults(ECOPA2021_SLUG, formData)
        if (success) {
          history.push(routes.ROOT.path)
          dispatch(showEphemeralSubmitResultsSnackbar())
        } else {
          setShowReportError(true)
        }
      } catch (error) {
        setShowReportError(true)
      }
      setIsSubmitting(false)
    },
  })

  const handleCancelClick = () => history.push(routes.ROOT.path)
  const handleClickContact = () => dispatch(openModal(MODAL_CONTACT))

  const barSlot = (
    <>
      <Breadcrumbs items={breadcrumbs} kind="light" />
      {snackbar}
    </>
  )

  return (
    <FormLayout
      modalSlot={modal}
      barSlot={barSlot}
      title="action:report-result"
      onCancel={handleCancelClick}
      onClickContact={handleClickContact}
    >
      <InnerWrapper size="md" className="reportResult">
        <ValidationForm
          userPlatformName={userPlatformName}
          setValues={setValidation}
          isDisabled={Boolean(validation)}
        />
        {validation ? (
          <form
            className="formReportResult"
            onSubmit={resultsFormik.handleSubmit}
            style={{ marginTop: '-5.5rem' }}
          >
            <ScrollHereWhenMounted />
            <FormResult
              title="noun:first-round-result"
              inputPlayerOne={
                <GoalsInput
                  formik={resultsFormik}
                  fieldName="firstRoundPlayerOneGoals"
                  platformName={userPlatformName}
                />
              }
              inputPlayerTwo={
                <GoalsInput
                  formik={resultsFormik}
                  fieldName="firstRoundPlayerTwoGoals"
                  platformName={validation.opponentPlatformName}
                />
              }
              inputScreenshot={
                <InputFile
                  name="add_screenshot"
                  textLabel="action:add-screenshot"
                  onChange={handleChangeScreenshot(
                    resultsFormik,
                    'firstRoundScreenshot',
                  )}
                  textError={fieldTextError(
                    resultsFormik,
                    'firstRoundScreenshot',
                  )}
                />
              }
              file={resultsFormik.values.firstRoundScreenshot}
              fileError={resultsFormik.errors['firstRoundScreenshot'] ?? ''}
              onDelete={handleDeleteScreenshot(
                resultsFormik,
                'firstRoundScreenshot',
              )}
            />
            <FormResult
              title="noun:second-round-result"
              inputPlayerOne={
                <GoalsInput
                  formik={resultsFormik}
                  fieldName="secondRoundPlayerOneGoals"
                  platformName={userPlatformName}
                />
              }
              inputPlayerTwo={
                <GoalsInput
                  formik={resultsFormik}
                  fieldName="secondRoundPlayerTwoGoals"
                  platformName={validation.opponentPlatformName}
                />
              }
              inputScreenshot={
                <InputFile
                  name="add_screenshot"
                  textLabel="action:add-screenshot"
                  onChange={handleChangeScreenshot(
                    resultsFormik,
                    'secondRoundScreenshot',
                  )}
                  textError={fieldTextError(
                    resultsFormik,
                    'secondRoundScreenshot',
                  )}
                />
              }
              file={resultsFormik.values.secondRoundScreenshot}
              fileError={resultsFormik.errors['secondRoundScreenshot'] ?? ''}
              onDelete={handleDeleteScreenshot(
                resultsFormik,
                'secondRoundScreenshot',
              )}
            />
            <FormResult
              isOptional
              title="noun:tie-breaker-round-result"
              inputPlayerOne={
                <GoalsInput
                  formik={resultsFormik}
                  fieldName="tieBreakerRoundPlayerOneGoals"
                  platformName={userPlatformName}
                />
              }
              inputPlayerTwo={
                <GoalsInput
                  formik={resultsFormik}
                  fieldName="tieBreakerRoundPlayerTwoGoals"
                  platformName={validation.opponentPlatformName}
                />
              }
              inputScreenshot={
                <InputFile
                  name="add_screenshot"
                  textLabel="action:add-screenshot"
                  onChange={handleChangeScreenshot(
                    resultsFormik,
                    'tieBreakerRoundScreenshot',
                  )}
                  textError={fieldTextError(
                    resultsFormik,
                    'tieBreakerRoundScreenshot',
                  )}
                />
              }
              file={resultsFormik.values.tieBreakerRoundScreenshot}
              fileError={
                resultsFormik.errors['tieBreakerRoundScreenshot'] ?? ''
              }
              onDelete={handleDeleteScreenshot(
                resultsFormik,
                'tieBreakerRoundScreenshot',
              )}
            />

            {showReportError && (
              <ErrorCard textTitle="error:result-report-failed-title">
                <p className="errorCard-description">
                  {t('error:result-report-failed-description')}
                </p>
              </ErrorCard>
            )}

            <div className="reportResult-actions">
              <Button
                as="button"
                type="submit"
                kind="primary"
                text="action:report-result"
                onClick={resultsFormik.handleSubmit}
                isLoading={isSubmitting}
                isDisabled={isSubmitting}
                isLight
              />
              <Button
                as="button"
                type="button"
                kind="tertiary"
                text="action:cancel"
                onClick={handleCancelClick}
                isLight
              />
            </div>
          </form>
        ) : null}
      </InnerWrapper>
    </FormLayout>
  )
}
