import React from 'react'
import PropTypes from 'prop-types'
import Icon from '../icons/Icon'
import ButtonIcon from '../buttons/ButtonIcon'
import { ReactComponent as IconDocument } from '../../../assets/icons/icon-document.svg'
import { ReactComponent as IconTrash } from '../../../assets/icons/icon-trash.svg'
import useTranslations from '../../../hooks/useTranslations'

const FileName = ({ name, size, textError, onDelete }) => {
  const t = useTranslations()
  return (
    <>
      <div className="fileName">
        <Icon className="fileName-icon" Component={IconDocument} />
        <div className="fileName-info">
          <p className="fileName-name">{name}</p>
          <p className="fileName-size">{size}</p>
        </div>
        <ButtonIcon
          IconComponent={IconTrash}
          aria="delete"
          onClick={onDelete}
          type="button"
        />
      </div>
      {textError ? (
        <span className="errorText fileName-error">{t(textError)}</span>
      ) : null}
    </>
  )
}

FileName.propTypes = {
  isDisabled: PropTypes.bool,
  textError: PropTypes.string,
  textLabel: PropTypes.string,
  name: PropTypes.string,
  onDelete: PropTypes.func,
}

export default FileName
