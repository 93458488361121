import React from 'react'
import PropTypes from 'prop-types'
import OslPropTypes from '../../common/osl-prop-types'
import EmptyHeader from '../components/utils/EmptyHeader'
import ArticleFeatured from '../components/articles/ArticleFeatured'
import ArticleThumbnail from '../components/articles/ArticleThumbnail'
import ArticleThumbnails from '../components/articles/ArticleThumbnails'
import ArticleCards from '../components/articles/ArticleCards'
import CtaNewsletter from '../components/ctas/CtaNewsletter'
//import SocialSection from '../components/sections/SocialSection'
import SponsorFull from '../components/sponsors/SponsorFull'

const NewsPage = ({
  articleFeatured,
  thumbnails,
  handleClickNewsletter,
  cards,
  onLoadMore,
  isLoading,
  firstSponsor,
}) => {
  return (
    <>
      <EmptyHeader withBar />
      <ArticleFeatured
        article={articleFeatured.content}
        cta={articleFeatured.cta}
      />
      {firstSponsor ? (
        <SponsorFull
          imageSm={firstSponsor.images?.banner_mobile.url}
          imageLg={firstSponsor.images?.banner_desktop.url}
          sponsor={firstSponsor.name}
          url={firstSponsor.url}
        />
      ) : null}
      <ArticleThumbnails thumbnails={thumbnails} />
      <CtaNewsletter
        title="message:find-out-everything"
        handleClick={handleClickNewsletter}
      />
      <ArticleCards
        cards={cards}
        onLoadMore={onLoadMore}
        isLoading={isLoading}
      />
    </>
  )
}

NewsPage.propTypes = {
  articleFeatured: ArticleFeatured.propTypes.article,
  thumbnails: PropTypes.arrayOf(ArticleThumbnail.propTypes.article),
  cards: PropTypes.node,
  handleClickNewsletter: PropTypes.func,
  onLoadMore: PropTypes.func,
  isLoading: PropTypes.bool,
  firstSponsor: OslPropTypes.sponsorProps,
}

export default NewsPage
