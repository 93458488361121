import React from 'react'
import PropTypes from 'prop-types'
import OslPropTypes from '../../../common/osl-prop-types'
import Sponsor from './Sponsor'
import useTranslations from '../../../hooks/useTranslations'
import SponsorSliderRow from './SponsorSliderRow'
import { CMS_URL } from '../../../constants'

const hostUrl = Boolean(process.env.STORYBOOK_RUNNING) ? '' : CMS_URL

// aux. components

const SponsorRowBasic = ({ title, items }) => (
  <div className="sponsorRow">
    {title ? <p className="sponsorRow-title">{title}</p> : null}
    {items.map(item => (
      <Sponsor
        key={item.name}
        alt={item.name}
        img={`${hostUrl}${item.images.cloud?.url}`}
        to={item.url}
      />
    ))}
  </div>
)

// main component

const SponsorRow = ({ title, items }) => {
  const t = useTranslations()

  if (items.length >= 6) {
    return <SponsorSliderRow title={title && t(title)} items={items} />
  } else {
    return <SponsorRowBasic title={title && t(title)} items={items} />
  }
}

SponsorRow.propTypes = {
  title: PropTypes.string,
  items: OslPropTypes.sponsorsProps,
}

export default SponsorRow
