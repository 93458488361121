import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import OslPropTypes from '../../common/osl-prop-types'
import EmptyHeader from '../components/utils/EmptyHeader'
import CompetitionHeader from '../components/competition/CompetitionHeader'
import Wysiwyg from '../components/wysiwyg/Wysiwyg'
import InnerWrapper from '../components/wrappers/InnerWrapper'
import SponsorFull from '../components/sponsors/SponsorFull'
import Button from '../components/buttons/Button'
import Brands from '../components/brands/Brands'

const CompetitionPage = ({
  detailHeader,
  detailBody,
  sponsor,
  summaryItems,
  isLogged,
  inscriptionLink,
  openEnrollment,
  handleGoToLogin,
  brands,
}) => {
  const linkRef = useRef(null)

  const handleExternalLink = e => {
    e.preventDefault()
    linkRef.current.click()
  }
  
  return (
    <>
      <EmptyHeader withBar />
      <InnerWrapper className="competitionPage" size="lg">
        <CompetitionHeader
          detailHeader={detailHeader}
          summaryItems={summaryItems}
        />
        {sponsor ? (
          <SponsorFull
            imageSm={sponsor.images?.banner_mobile.url}
            imageLg={sponsor.images?.banner_desktop.url}
            sponsor={sponsor.name}
            url={sponsor.url}
          />
        ) : null}
        <Wysiwyg>{detailBody}</Wysiwyg>
        {openEnrollment && (
          <div className="articleCards-actions">
            {isLogged ? (
              <>
                <Button
                  kind="primary"
                  as="button"
                  onClick={handleExternalLink}
                  text="action:enroll"
                  isLight
                />
                <a
                  href={inscriptionLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="invisible"
                  ref={linkRef}
                >
                  Enroll
                </a>
              </>
            ) : (
              <Button
                as="button"
                kind="primary"
                text="action:login"
                isLight
                onClick={handleGoToLogin}
              />
            )}
          </div>
        )}
      </InnerWrapper>
      {brands.length ? (
        <Brands brands={brands} />
      ): null}
    </>
  )
}

CompetitionPage.propTypes = {
  detailHeader: CompetitionHeader.propTypes.detailHeader,
  detailBody: PropTypes.node,
  sponsor: OslPropTypes.sponsorProps,
  summaryItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
      extra: PropTypes.object,
    }),
  ),
  brands: OslPropTypes.sponsorsProps,
  isLogged: PropTypes.bool,
  inscriptionLink: PropTypes.string,
  openEnrollment: PropTypes.bool,
  handleGoToLogin: PropTypes.func,
}

export default CompetitionPage
