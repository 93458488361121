import React from 'react'

import useTranslations from '../../../hooks/useTranslations'

const EditBirthDate = ({ children }) => {
    const t = useTranslations()
    return (
      <div className="dateInputs">
        <label className="dateInputs-label">{t('input-label:birth-date')}</label>
        <div className="dateInputs-inputs">{children}</div>
      </div>
    )
  }
  
export default EditBirthDate
  