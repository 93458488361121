import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

const Icon = ({ Component, className }) => {
  return (
    <div
      className={cx('iconWrapper', { [className]: className })}
      aria-hidden="true"
    >
      <Component />
    </div>
  )
}

Icon.propTypes = {
  Component: PropTypes.elementType.isRequired,
  className: PropTypes.string,
}

export default Icon
