import React, { useState } from 'react'
import { omit } from 'lodash'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { openModal, showEphemeralRegistrationSnackbar } from '../redux/actions'
import * as Auth from '../services/auth'
import FormLayout from '../ui/layout/FormLayout'
import Registration from '../ui/views/Registration'
import getTranslationKey from '../common/getTranslationKey'
import useModal from './modals/useModal'
import { MODAL_CONTACT, MODAL_CONFIRM_INSCRIPTION } from './modals/constants'
import { userRegistrationSchema } from './validation/schemas'
import * as routes from './routes'

const PRIVACY_CHECKS_FIELDS = ['oslComercial', 'oslPlaneta', 'oslPrivacyPolicy']
const FORM_INPUTS_FIELDS = ['email', 'emailConfirmation', 'username', 'password', 'passwordConfirmation']

const fieldTextError = (formik, field) => { 
  //Do not change the order of the following props - [field] must be the first
  const properties = { 
    [field]: [formik.touched[field], formik.errors[field]] ,
    emailConfirmation: [formik.touched.email, 'validation-error:email-mismatch'], 
    passwordConfirmation: [formik.touched.password, 'validation-error:password-mismatch']
  } 
  const [isTouched, error] = properties[field] 
  return formik.errors[field] && isTouched ? error : '' 
}

export default () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const modal = useModal()

  const [errors, setErrors] = useState()

  const handleSubmit = async formValues => {
    const valuesToSave = omit(formValues, ['emailConfirmation', 'passwordConfirmation'])
    await handleSignUp(valuesToSave)
  }

  const handleSignUp = async values => {
    try {
      await Auth.signup(values)
      dispatch(showEphemeralRegistrationSnackbar())
      history.push({
        pathname: routes.ROOT.path,
        state: { email: values.email, type: 'just-signup' },
      })
      dispatch(openModal(MODAL_CONFIRM_INSCRIPTION))
    } catch (error) {
      const { data } = error.response
      setErrors([getTranslationKey(data.error.id)])
    }
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: '',
      emailConfirmation: '',
      username: '',
      password: '',
      passwordConfirmation: '',
      oslComercial: false,
      oslPlaneta: false,
      oslPrivacyPolicy: false,
    },
    validationSchema: Yup.object(userRegistrationSchema),
    onSubmit: handleSubmit,
  })

  const handleClickContact = () => dispatch(openModal(MODAL_CONTACT))
  
  const createFormikFields = (section) => {
    let formikFields = {}
    section.map(fieldName => formikFields[fieldName] = {
        value: formik.values[fieldName],
        error: fieldTextError(formik, fieldName),
        handleChange: formik.handleChange,
        handleBlur: formik.handleBlur,
      }
    )
    return formikFields
  }

  const privacyChecks = createFormikFields(PRIVACY_CHECKS_FIELDS)
  const formInputs = createFormikFields(FORM_INPUTS_FIELDS)

  return (
    <FormLayout
      title="noun:registration"
      onCancel={() => history.goBack()}
      onClickContact={handleClickContact}
    >
      <Registration 
        bannerTitle="message:start-adventure"
        modalSlot={modal}
        errors={errors}
        handleSubmit={formik.handleSubmit}
        formInputs={formInputs}
        privacyChecks={privacyChecks}
      /> 
    </FormLayout>
  )
}
