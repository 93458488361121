import React from 'react'

import useTranslations from '../../../hooks/useTranslations'

import TournamentActions from '../integrations/TournamentActions'

export default ({ widgetUrl }) => {
  const t = useTranslations()

  return (
    <div className="brick-md">
      <h2 className="stepUp4-title">{t('message:final-phase')}</h2>
      <TournamentActions url={widgetUrl} />
    </div>
  )
}
