import React from 'react'
import PropTypes from 'prop-types'
import TournamentWidget from '../components/integrations/TournamentWidget'
import HeadingBanner from '../components/banners/HeadingBanner'
import EmptyHeader from '../components/utils/EmptyHeader'

const EcopaActiveBracketFull = ({ widgetUrl, title }) => {
  return (
    <>
      <EmptyHeader withBar />
      <HeadingBanner title={title} isBig />
      <TournamentWidget url={widgetUrl} />
    </>
  )
}

EcopaActiveBracketFull.propTypes = {
  title: PropTypes.func,
  widgetUrl: PropTypes.string,
}

export default EcopaActiveBracketFull
