import React from 'react'
import PropTypes from 'prop-types'
import useTranslations from '../../../hooks/useTranslations'
import cx from 'classnames'

const HeadingBanner = ({ title, subtitle, caption, isBig }) => {
  const t = useTranslations()

  return (
    <div className="headingBanner">
      <h1 className={cx('headingBanner-title', { 'is-big': isBig })}>
        {title(t)}
      </h1>
      {subtitle ? (
        <p className="headingBanner-subtitle">{t(subtitle)}</p>
      ) : null}
      {caption ? <p className="headingBanner-caption">{caption}</p> : null}
    </div>
  )
}

HeadingBanner.propTypes = {
  title: PropTypes.func,
  subtitle: PropTypes.string,
  caption: PropTypes.node,
  isBig: PropTypes.bool,
}

export default HeadingBanner
