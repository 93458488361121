import React, { useRef } from 'react'
import { NavLink } from 'react-router-dom'

const isHashLocationActive = pathWithHash => (_, { pathname, hash }) =>
  pathWithHash === pathname + hash

const NavbarLink = ({ toggleOpen, to, children }) => {
  const linkRef = useRef(null)
  const isExternal = !to.startsWith('/')

  const handleExternalLink = (e) => {
    e.preventDefault()
    linkRef.current.click()
  }
  
  return <>
    <NavLink
      className="mainNav-item"
      activeClassName="is-selected"
      isActive={isHashLocationActive(to)}
      to={isExternal ? '' : to}
      onClick={isExternal ? handleExternalLink : toggleOpen}
    >
      {children}
    </NavLink>
    { isExternal && <a href={to} target='_blank' rel='noopener noreferrer' className="invisible" ref={linkRef}>Open shop</a>}
  </>
}

export default NavbarLink
