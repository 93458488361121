import React from 'react'
import PropTypes from 'prop-types'
import useTranslations from '../../../hooks/useTranslations'
import cx from 'classnames'

const RadioButtonGroup = ({
  children,
  textLabel,
  textError,
  isDisabled,
  isOptional,
}) => {
  const t = useTranslations()

  return (
    <>
      <div className={cx('radioButtonGroup', { 'is-disabled': isDisabled })}>
        <span className="radioButtonGroup-label">
          {t(textLabel)}
          {isOptional ? (
            <span className="optional-label">{t('input-label:optional')}</span>
          ) : null}
        </span>
        {children}
      </div>
      {textError ? <span className="errorText">{t(textError)}</span> : null}
    </>
  )
}

RadioButtonGroup.propTypes = {
  children: PropTypes.node.isRequired,
  textLabel: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  textError: PropTypes.string,
  isOptional: PropTypes.bool,
}

export default RadioButtonGroup
