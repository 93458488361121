import React from 'react'
import PropTypes from 'prop-types'
import OslPropTypes from '../../../common/osl-prop-types'
import Slider from '../slider/Slider'
import Sponsor from './Sponsor'
import { SIZELG } from '../../common/ui-constants'
import MediaQuery from 'react-responsive'

const SponsorSliderRow = ({ items, title }) => {
  return (
    <div className="sponsorRow">
      {title ? <p className="sponsorRow-title">{title}</p> : null}
      <MediaQuery maxWidth={SIZELG - 1}>
        {items.map(item => (
          <Sponsor
            key={item.sponsor}
            alt={item.sponsor}
            img={item.img}
            to={item.to}
          />
        ))}
      </MediaQuery>
      <MediaQuery minWidth={SIZELG}>
        <Slider settings={{ slidesToShow: 5 }}>
          {items.map(item => (
            <Sponsor
              key={item.sponsor}
              alt={item.sponsor}
              img={item.img}
              to={item.to}
            />
          ))}
        </Slider>
      </MediaQuery>
    </div>
  )
}

SponsorSliderRow.propTypes = {
  title: PropTypes.string,
  items: OslPropTypes.sponsorsProps,
}

export default SponsorSliderRow
