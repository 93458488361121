import { ReactComponent as IconTwitter } from '../assets/icons/icon-twitter.svg'
import { ReactComponent as IconTwitch } from '../assets/icons/icon-twitch.svg'
import { ReactComponent as IconYoutube } from '../assets/icons/icon-youtube.svg'
import { ReactComponent as IconInstagram } from '../assets/icons/icon-instagram.svg'
import { ReactComponent as IconFacebook } from '../assets/icons/icon-facebook-bg.svg'
import { ReactComponent as IconSteam } from '../assets/icons/icon-steam.svg'
import { ReactComponent as IconPlaystation } from '../assets/icons/icon-playstation-bg.svg'
import { ReactComponent as IconXbox } from '../assets/icons/icon-xbox-bg.svg'
import { ReactComponent as IconOrigin } from '../assets/icons/icon-origin.svg'
import { ReactComponent as IconBattlenet } from '../assets/icons/icon-battle-net.svg'
import { ReactComponent as IconDiscord } from '../assets/icons/icon-discord-bg.svg'
import { ReactComponent as IconEpicGames } from '../assets/icons/icon-epic-games.svg'
import { ReactComponent as IconRiotgames } from '../assets/icons/icon-riot-games.svg'
import { ReactComponent as IconTiktok } from '../assets/icons/icon-tiktok.svg'
import { ReactComponent as IconUplay } from '../assets/icons/icon-uplay.svg'
import { SOCIAL, PLATFORM } from './constants'

const ICONS = {
  [SOCIAL.TWITTER]: IconTwitter,
  [SOCIAL.TWITCH]: IconTwitch,
  [SOCIAL.YOUTUBE]: IconYoutube,
  [SOCIAL.INSTAGRAM]: IconInstagram,
  [SOCIAL.TIKTOK]: IconTiktok,
  [SOCIAL.FACEBOOK]: IconFacebook,
  [PLATFORM.STEAM]: IconSteam,
  [PLATFORM.PLAYSTATION]: IconPlaystation,
  [PLATFORM.XBOX]: IconXbox,
  [PLATFORM.ORIGIN]: IconOrigin,
  [PLATFORM.BATTLENET]: IconBattlenet,
  [PLATFORM.DISCORD]: IconDiscord,
  [PLATFORM.EPICGAMES]: IconEpicGames,
  [PLATFORM.RIOTGAMES]: IconRiotgames,
  [PLATFORM.UPLAY]: IconUplay,
}

const DEFAULT_ICON = IconTwitter

const getSharedIcon = sharedIconName => ICONS[sharedIconName] || DEFAULT_ICON

export default getSharedIcon
