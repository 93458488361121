import React from 'react'
import PropTypes from 'prop-types'
import useTranslations from '../../../hooks/useTranslations'
import cx from 'classnames'

const InfoCard = ({ title, subtitle, date, description, actions, isLight }) => {
  const t = useTranslations()
  return (
    <div className={cx('infoCard', { 'is-light': isLight })}>
      <div className="infoCard-header">
        <h2 className="infoCard-title">{t(title)}</h2>
        {subtitle ? <p className="infoCard-subtitle">{t(subtitle)}</p> : null}
      </div>
      <div className="infoCard-body">
        <div className="infoCard-date">{t(date)}</div>
        <p className="infoCard-description">{t(description)}</p>
      </div>
      {actions ? <div className="infoCard-actions">{actions}</div> : null}
    </div>
  )
}

InfoCard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  date: PropTypes.string,
  description: PropTypes.string,
  actions: PropTypes.node,
  isLight: PropTypes.bool,
}

export default InfoCard
