import React from 'react'
import PropTypes from 'prop-types'
import SimpleBanner from './SimpleBanner'
import useTranslations from '../../../hooks/useTranslations'

const GenericBanner = ({ title }) => {
  const t = useTranslations()

  return (
    <SimpleBanner print="green" isGeneric className="has-top-space">
      <h2 className="simpleBanner-title">{t(title)}</h2>
    </SimpleBanner>
  )
}

GenericBanner.propTypes = {
  title: PropTypes.string,
}

export default GenericBanner
