import React from 'react'
import PropTypes from 'prop-types'

import Button from '../buttons/Button'

const PublicNavbarActions = ({
  showInscriptionButton,
  handleGoToLogin,
  handleGoToInscription,
  handleGoToRegister,
}) => {
  return (
    <div className="publicActions">
      {showInscriptionButton ? (
        <Button
          as="button"
          onClick={handleGoToInscription}
          kind="primary"
          text="action:inscribe-ecopa"
          isSmall
        />
      ) : (
        <Button
          as="button"
          onClick={handleGoToRegister}
          kind="primary"
          text="action:register-osl"
          isSmall
        />
      )}
      <Button
        as="button"
        kind="secondary"
        text="action:login"
        isSmall
        onClick={handleGoToLogin}
      />
    </div>
  )
}

PublicNavbarActions.propTypes = {
  showInscriptionButton: PropTypes.bool,
  handleGoToLogin: PropTypes.func,
  handleGoToInscription: PropTypes.func,
  handleGoToRegister: PropTypes.func,
}

export default PublicNavbarActions
