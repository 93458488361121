import React from 'react'
import PropTypes from 'prop-types'
import UserIntroEnrolled from '../components/sections/UserIntroEnrolled'
import UserIntroNotEnrolled from '../components/sections/UserIntroNotEnrolled'

const EcopaLayout = ({ isEnrolled, isNotEnrolled, children }) => {
  return (
    <>
      {isEnrolled ? <UserIntroEnrolled /> : null}
      {isNotEnrolled ? <UserIntroNotEnrolled /> : null}
      {children}
    </>
  )
}

EcopaLayout.propTypes = {
  isEnrolled: PropTypes.bool,
  isNotEnrolled: PropTypes.bool,
  children: PropTypes.node,
  hasFullSponsor: PropTypes.bool,
}

export default EcopaLayout
