import produce from 'immer'
import { combineReducers } from 'redux'

import {
  LOGIN_SUCCESSFUL,
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGOUT_SUCCESSFUL,
  CHECK_TOURNAMENT_SUCCESSFUL,
  CHECK_TOURNAMENT_FAILURE,
  CHECK_TOURNAMENT_REQUEST,
  GET_TOURNAMENT_INFO_SUCCESSFUL,
  GET_TOURNAMENT_INFO_FAILURE,
  GET_TOURNAMENT_WINNERS_SUCCESSFUL,
  TOURNAMENT_TOTAL_REGISTRANTS_SUCCESSFUL,
  TOURNAMENT_TOTAL_REGISTRANTS_FAILURE,
  GET_SUBTOURNAMENTS_LIST_SUCCESSFUL,
  GET_SUBTOURNAMENT_INFO_SUCCESSFUL,
  SNACKBAR_SHOW,
  SNACKBAR_HIDE,
  MODAL_OPEN,
  MODAL_CLOSE,
  AUTH_CHECKED
} from './types'

const userInitialState = {
  email: '',
  username: '',
  inscriptions: {},
}

const user = produce((draft, action) => {
  if (action.type === LOGIN_SUCCESSFUL) {
    draft.email = action.payload.email
    draft.username = action.payload.username
  }

  if (action.type === LOGIN_FAILURE) {
    draft.email = ''
    draft.username = ''
  }

  if (action.type === LOGIN_REQUEST) {
    draft.email = ''
    draft.username = ''
  }

  if (action.type === LOGOUT_SUCCESSFUL) {
    draft.email = ''
    draft.username = ''
    draft.inscriptions = {}
  }

  if (action.type === CHECK_TOURNAMENT_SUCCESSFUL) {
    const { tournamentSlug, inscription } = action.payload
    draft.inscriptions[tournamentSlug] = inscription
  }

  if (action.type === CHECK_TOURNAMENT_FAILURE) {
    delete draft.inscriptions[action.payload.tournamentSlug]
  }

  if (action.type === CHECK_TOURNAMENT_REQUEST) {
    delete draft.inscriptions[action.payload.tournamentSlug]
  }
}, userInitialState)

const tournamentsInitialState = {
  info: {},
  winners: [],
  registrants: {},
  subtournaments: undefined,
  subtournamentsInfo: [],
}

const parseTournamentInfo = (info = {}) => {
  const {
    init_inscription_date: inscriptionOpeningDate,
    end_inscription_date: inscriptionDeadline,
    init_tournament_date: tournamentStartingDate,
    end_tournament_date: tournamentFinishingDate,
    schedule_widget: scheduleWidget,
    stage_widget: stageWidget,
    group_widget: groupWidget,
    runner_up: runnerUp,
    ...otherProperties
  } = info

  return {
    inscriptionOpeningDate,
    inscriptionDeadline,
    tournamentStartingDate,
    tournamentFinishingDate,
    scheduleWidget,
    stageWidget,
    groupWidget,
    runnerUp,
    ...otherProperties,
  }
}

const parseSingleWinner = ({
  gamer_tag,
  psn_id,
  verified_fifa_player,
  id_global_series,
  ...rest
} = {}) => ({
  gamerTag: gamer_tag,
  psnId: psn_id,
  verifiedFifaPlayer: verified_fifa_player,
  idGlobalSeries: id_global_series,
  ...rest,
})
const parseTournamentWinners = (tournamentSlug, winners = {}) => {
  const { champion, runnerUp, divisions = [] } = winners
  return {
    tournamentSlug,
    champion: parseSingleWinner(champion),
    runnerUp: parseSingleWinner(runnerUp),
    divisions: divisions.map(parseSingleWinner),
  }
}

const parseSubtournamentListItem = tournamentSlug => subtournamentInfo => ({
  tournamentSlug,
  ...subtournamentInfo,
})

const parseSubtournamentInfo = (
  tournamentSlug,
  subtournamentId,
  {
    schedule_widget: scheduleWidget,
    stage_widget: stageWidget,
    group_widget: groupWidget,
    ...otherProperties
  },
) => ({
  tournamentSlug,
  id: subtournamentId,
  scheduleWidget,
  stageWidget,
  groupWidget,
  ...otherProperties,
})

const tournaments = produce((draft, action) => {
  if (action.type === GET_TOURNAMENT_INFO_SUCCESSFUL) {
    const { tournamentSlug, data } = action.payload
    draft.info[tournamentSlug] = parseTournamentInfo(data)
  }

  if (action.type === GET_TOURNAMENT_INFO_FAILURE) {
    const { tournamentSlug } = action.payload
    delete draft.info[tournamentSlug]
  }

  if (action.type === GET_TOURNAMENT_WINNERS_SUCCESSFUL) {
    const { tournamentSlug, data } = action.payload
    const otherWinners = draft.winners.filter(
      winner => winner.tournamentSlug !== tournamentSlug,
    )
    draft.winners = [
      ...otherWinners,
      parseTournamentWinners(tournamentSlug, data),
    ]
  }

  if (action.type === TOURNAMENT_TOTAL_REGISTRANTS_SUCCESSFUL) {
    const { tournamentSlug, total } = action.payload
    draft.registrants[tournamentSlug] = total
  }

  if (action.type === TOURNAMENT_TOTAL_REGISTRANTS_FAILURE) {
    const { tournamentSlug } = action.payload
    delete draft.registrants[tournamentSlug]
  }

  if (action.type === GET_SUBTOURNAMENTS_LIST_SUCCESSFUL) {
    const { tournamentSlug, data } = action.payload
    draft.subtournaments = data.map(parseSubtournamentListItem(tournamentSlug))
  }

  if (action.type === GET_SUBTOURNAMENT_INFO_SUCCESSFUL) {
    const { tournamentSlug, subtournamentId, data } = action.payload
    const otherSubtournaments = draft.subtournamentsInfo.filter(
      subtournament =>
        subtournament.tournamentSlug !== tournamentSlug ||
        subtournament.id !== data.id,
    )
    draft.subtournamentsInfo = [
      ...otherSubtournaments,
      parseSubtournamentInfo(tournamentSlug, subtournamentId, data),
    ]
  }
}, tournamentsInitialState)

const visualInitialState = {
  showSnackbar: false,
  snackbarText: undefined,
  activeModal: undefined,
}

const visual = produce((draft, action) => {
  if (action.type === SNACKBAR_SHOW) {
    const { text } = action.payload
    draft.showSnackbar = true
    draft.snackbarText = text
  }

  if (action.type === SNACKBAR_HIDE) {
    draft.showSnackbar = false
    draft.snackbarText = visualInitialState.snackbarText
  }

  if (action.type === MODAL_OPEN) {
    const { kind } = action.payload
    draft.activeModal = kind
  }

  if (action.type === MODAL_CLOSE) {
    draft.activeModal = visualInitialState.activeModal
  }
}, visualInitialState)

const flowInitialState = {
  authChecked: false
}

const flow = produce((draft, action) => {
  if (action.type === AUTH_CHECKED) {
    draft.authChecked = true
  }
}, flowInitialState)

export default combineReducers({ user, visual, tournaments, flow })
