import React from 'react'
import PropTypes from 'prop-types'
import useTranslations from '../../hooks/useTranslations'
import HeroSimple from '../components/hero/HeroSimple'
import print from '../../assets/images/print-gt.svg'
import gt from '../../assets/images/logo-gt.svg'
import VideoBlock from '../components/integrations/VideoBlock'
import CtaYoutube from '../components/ctas/CtaYoutube'
import WinnersGt from '../components/winners/WinnersGt'
import BrandsGt from '../components/brands/BrandsGt'

const GranPremioGt = ({ winners, videoGt, outstandingPost }) => {
  const t = useTranslations()
  return (
    <>
      <HeroSimple
        className="gt"
        print={print}
        alt="Gran Premio GT"
        text="message:gt-winners"
        logo={gt}
      />
      <VideoBlock
        title={t('message:relieve-best-moments', {
          competition: 'Gran Premio GT',
        })}
        video={videoGt}
      />
      <CtaYoutube />
      <WinnersGt winners={winners} />
      <BrandsGt />
    </>
  )
}

GranPremioGt.propTypes = {
  winners: WinnersGt.propTypes.winners,
  videoGt: PropTypes.node,
}

export default GranPremioGt
