import React from 'react'
import PropTypes from 'prop-types'
import OslPropTypes from '../../common/osl-prop-types'

import News from '../components/news/News'
import SocialSection from '../components/sections/SocialSection'

import Sponsors from '../components/sponsors/Sponsors'
import SponsorRow from '../components/sponsors/SponsorRow'

/* News no se debe mostrar en pagina de actualiad */
const PageLayout = ({ children, outstandingPost, sponsors }) => {
  return (
    <>
      {children}
      <SocialSection />
      {outstandingPost && <News post={outstandingPost} />}
      {sponsors.length ? (
        <Sponsors>
          <SponsorRow items={sponsors} />
        </Sponsors>
      ) : null}

    </>
  )
}

PageLayout.propTypes = {
  outstandingPost: PropTypes.object,
  sponsors: OslPropTypes.sponsorsProps,
}

export default PageLayout
