import React from 'react'

import useTranslations from '../../../hooks/useTranslations'

import Input from '../inputs/Input'
import HelperCard from '../cards/HelperCard'
import Button from '../buttons/Button'

const FormAccess = ({ formik, handleGoToLogin }) => {
  const t = useTranslations()
  return (
    <>
      <div className="formBase-row">
        <div className="formBase-cell">
          <label className="genericLabel">
            {t('general-label:form-account')}
          </label>
          <Button
            as="button"
            kind="primary"
            onClick={handleGoToLogin}
            text="action:login"
            type="button"
          />
        </div>
      </div>
      <div className="formBase-row">
        <div className="formBase-cell">
          <Input
            handleBlur={formik.handleBlur}
            handleChange={formik.handleChange}
            type="email"
            textError={
              formik.errors.email && formik.touched.email
                ? formik.errors.email
                : ''
            }
            textLabel="input-label:email"
            value={formik.values.email}
            name="email"
          />
        </div>
      </div>
      <div className="formBase-row">
        <div className="formBase-cell">
          <Input
            handleBlur={formik.handleBlur}
            handleChange={formik.handleChange}
            type="text"
            textError={
              formik.errors.username && formik.touched.username
                ? formik.errors.username
                : ''
            }
            textLabel="input-label:username"
            value={formik.values.username}
            name="username"
          />
        </div>
        <div className="formBase-cell">
          <HelperCard
            textTitle="helper-card:title"
            textDescription="helper-card:user"
          />
          <p className="helperText">{t('helper-text:username-tips')}</p>
        </div>
      </div>
      <div className="formBase-row">
        <div className="formBase-cell">
          <Input
            handleBlur={formik.handleBlur}
            handleChange={formik.handleChange}
            type="password"
            textError={
              formik.errors.password && formik.touched.password
                ? formik.errors.password
                : ''
            }
            textLabel="input-label:password"
            value={formik.values.password}
            name="password"
          />
        </div>
        <div className="formBase-cell">
          <p className="helperText">{t('helper-text-form:password')}</p>
        </div>
      </div>
    </>
  )
}

export default FormAccess
