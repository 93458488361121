import React from 'react'
import PropTypes from 'prop-types'
import useTranslations from '../../../hooks/useTranslations'
import Button from '../buttons/Button'

const Wrapper = ({ isForm, submitFn, children }) =>
  isForm ? (
    <form onSubmit={submitFn}>{children}</form>
  ) : (
    <React.Fragment>{children}</React.Fragment>
  )

const ModalLayout = ({
  title,
  children,
  subtitle,
  subtitleExtra = {},
  contactInfo,
  primaryButtonText,
  confirmationMessage,
  onClose,
  onSubmit,
  isBtnDisabled,
  isForm,
}) => {
  const t = useTranslations()

  return (
    <Wrapper isForm={isForm} submitFn={onSubmit}>
      <div className="modal-header">
        <h2 className="modal-title">{t(title)}</h2>
        {subtitle ? (
          <p className="modal-subtitle">{t(subtitle, subtitleExtra)}</p>
        ) : null}
      </div>
      <div className="modal-body">{children}</div>
      <div className="modal-actions">
        <Button
          as="button"
          type={isForm ? 'submit' : 'button'}
          kind="primary"
          onClick={onSubmit}
          text={primaryButtonText}
          isDisabled={isBtnDisabled}
        />
        {confirmationMessage ? (
          <p className="modal-subtitle">{t(confirmationMessage)}</p>
        ) : null}
        <Button
          as="button"
          type="button"
          kind="tertiary"
          onClick={onClose}
          text="action:cancel"
          isLight
        />
      </div>
      {contactInfo ? (
        <p className="modal-caption">
          {t('message:problems-password', {
            contact: (
              <a className="link" href="mailto:mailto:esports@deaplaneta.com">
                {t('action:contact-plural')}
              </a>
            ),
          })}
        </p>
      ) : null}
    </Wrapper>
  )
}

ModalLayout.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  subtitle: PropTypes.string,
  subtitleExtra: PropTypes.object,
  contactInfo: PropTypes.bool,
  primaryButtonText: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  confirmationMessage: PropTypes.string,
  isForm: PropTypes.bool,
}

export default ModalLayout
