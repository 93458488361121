import React from 'react'

import Modal from '../modals/Modal'
import Input from '../inputs/Input'
import Link from '../links/Link'

import ModalLayout from './ModalLayout'

const ModalLogin = ({
  onGoToRecover,
  onClose,
  onSubmit,
  handleBlur,
  handleChange,
  emailError,
  emailValue,
  passwordError,
  passwordValue,
  failedLoginComponent,
}) => {
  return (
    <Modal>
      <ModalLayout
        title="action:login-osl"
        primaryButtonText="action:login"
        onClose={onClose}
        onSubmit={onSubmit}
        isForm
      >
        <Input
          handleBlur={handleBlur}
          handleChange={handleChange}
          type="email"
          textError={emailError}
          textLabel={'input-label:email'}
          value={emailValue}
          name="email"
          autoComplete="username"
        />
        <Input
          handleBlur={handleBlur}
          handleChange={handleChange}
          type="password"
          textError={passwordError}
          textLabel={'input-label:password'}
          value={passwordValue}
          name="password"
          autoComplete="current-password"
        />

        <Link
          onClick={onGoToRecover}
          text="action:forgot-password"
          kind="primary"
          isLight
        />
        {failedLoginComponent}
      </ModalLayout>
    </Modal>
  )
}

export default ModalLogin
