import React from 'react'
import Cta from './Cta'

import { youtube } from '../../../data/osl'

const CtaYoutube = () => {
  return (
    <Cta
      className="cta-notCropped"
      buttonAs="a"
      buttonHref={youtube}
      target="_blank"
      rel="noopener noreferrer"
      print="pink3"
      title="message:all-videos-youtube"
      buttonKind="secondary"
      buttonText="action:visit-channel"
    />
  )
}

export default CtaYoutube
