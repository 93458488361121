// Form data

const federationOptions = {
  default: 'action:select-option',
  valid: [
    'federation:aragon',
    'federation:canarias',
    'federation:cantabria',
    'federation:castilla-leon',
    'federation:cataluna',
    'federation:castilla-mancha',
    'federation:ceuta',
    'federation:valencia',
    'federation:murcia',
    'federation:baleares',
    'federation:extremadura',
    'federation:navarra',
    'federation:rioja',
    'federation:pais-vasco',
    'federation:andalucia',
    'federation:asturias',
    'federation:madrid',
    'federation:galicia',
    'federation:melilla',
  ],
}

const federation = [federationOptions.default, ...federationOptions.valid]

const platformOptions = {
  default: '',
  valid: {
    xboxOne: { label: 'noun:console-xbox', value: 'xbox one' },
    playstation4: { label: 'noun:console-psn4', value: 'playstation' },
  },
}

const verifiedFifaPlayerOptions = {
  default: 'no',
  valid: {
    yes: {
      label: 'input-label:yes',
      value: 'yes',
    },
    no: { label: 'input-label:no', value: 'no' },
  },
}

export {
  federation,
  federationOptions,
  platformOptions,
  verifiedFifaPlayerOptions,
}
