import React from 'react'

import useTranslations from '../../hooks/useTranslations'

const getAge = (birth, today) => new Date(today - birth).getFullYear() - 1970

export const AgeAndLocation = ({ age, location }) => {
  const t = useTranslations()
  if (!age && !location) return null
  if (!age) return <p className="baseline-text">{location}</p>

  const years = t('profile:years', { age })

  if (!location) {
    return <p className="baseline-text">{years}</p>
  } else {
    return (
      <p className="baseline-text">
        {years} - {location}
      </p>
    )
  }
}

export const AgeAndLocationWithBirth = ({ birth, ...props }) => (
  <AgeAndLocation
    age={birth ? getAge(birth, Date.now()) : undefined}
    {...props}
  />
)

export default AgeAndLocation
