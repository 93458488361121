import React from 'react'

import Modal from '../../../../ui/components/modals/Modal'
import ModalLayout from '../../../../ui/components/modals/ModalLayout'
import RequestErrorCard from '../../../../ui/components/cards/RequestErrorCard'
import FormikInput from '../FormikInput'

const ModalAccount = ({
  title,
  formik,
  showError,
  onClose,
  children
}) => {

  return (
    <Modal>
      <ModalLayout
        title={title}
        primaryButtonText="action:add"
        onClose={onClose}
        onSubmit={(e)=> {
          e.preventDefault()
          formik.handleSubmit()
        }
      }
        isForm
      >
        {children}
        <FormikInput
          formik={formik}
          fieldName="handle"
          label="input-label:handler"
        />
        <FormikInput
          formik={formik}
          fieldName="link"
          label="input-label:profile-link"
        />
        {showError && <RequestErrorCard textTitle="error:profile-failed-title" />}
      </ModalLayout>
    </Modal>
  )
}

export default ModalAccount