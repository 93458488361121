import React from 'react'
import { useDispatch } from 'react-redux'

import ModalPasswordSent from '../../ui/components/modals/ModalPasswordSent'
import { closeModal } from '../../redux/actions'

export default props => {
  const dispatch = useDispatch()

  const handleClose = () => dispatch(closeModal())

  return <ModalPasswordSent onClose={handleClose} />
}
