import * as routes from './routes'

export const ecopaLandingNavItems = [
  { name: 'noun:ecopa', to: '#aboutecopa' },
  { name: 'noun:format', to: '#format' },
  { name: 'noun:calendar', to: '#calendar' },
  { name: 'action:contact', to: '#contact' },
]

export const appNavItems = [
  { name: 'noun:news', to: routes.NEWS_SECTION.path, hasSubmenu: false },
  { name: 'noun:ecopa', to: routes.ECOPA_SECTION.path, hasSubmenu: false },
  { name: 'noun:top-gamers-academy', to: routes.TGA.path, hasSubmenu: false },
  { name: 'noun:competitions', to: routes.COMPETITIONS.path, hasSubmenu: true},
  { name: 'noun:shop', to: routes.SHOP_PATH, hasSubmenu: false  },
]

export default {
  appNavItems,
  ecopaLandingNavItems,
}
