import React from 'react'
import { filter, includes } from 'lodash'

import * as Yup from 'yup'
import { useFormik } from 'formik'
import {  gamingPlatformsOptions } from '../../../../common/forms/profile'
import { addAccountSchema } from '../../../validation/schemas'
import { Select } from '../../../../ui/components/inputs/Select'
import ModalAccount from './ModalAccount'
import { MODAL_TYPE} from '../index'


const AddGamingPlatform = ({onClose, showError, formik}) => {
  const type = MODAL_TYPE.GAMING
  const savedAccounts = Object.keys(formik.values.gamingPlatforms)
  const availableOptions = filter(gamingPlatformsOptions.valid, (item)=> !(includes(savedAccounts, item.value)))

  const setNewAccount = ({ service, handle, link }) => {
    formik.setFieldValue(type, {
      ...formik.values[type],
      [service]: { handle, link },
    })
    formik.setFieldTouched(type)
    if (!internalFormik.errors.length){
      formik.handleSubmit()
    }
  }

  const configFormik =  { 
    enableReinitialize: true,
    initialValues: {
      service: gamingPlatformsOptions.valid[0].value,
      handle: '',
      link: '',
    },
    validationSchema: Yup.object(addAccountSchema),
    onSubmit: setNewAccount,
  }
  const internalFormik = useFormik(configFormik)

  return (<ModalAccount
    title='action:edit-platform'
    showError={showError}
    formik={internalFormik}
    onClose={onClose}
    >
    <Select
      options={availableOptions}
      handleBlur={internalFormik.handleBlur}
      handleChange={internalFormik.handleChange}
      textLabel='input-label:select-platform'
      name="service"
      value={internalFormik.values['service']}
      textError={
        internalFormik.errors['service'] && internalFormik.touched['service']
          ? internalFormik.errors['service']
          : ''
      }
    />
  </ModalAccount>)
}

export default AddGamingPlatform