import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'

import { IS_DEVELOPMENT } from '../constants'

import rootReducer from './reducers'

const store = createStore(
  rootReducer,
  compose(
    applyMiddleware(thunk),
    IS_DEVELOPMENT && window.__REDUX_DEVTOOLS_EXTENSION__ 
      ? window.__REDUX_DEVTOOLS_EXTENSION__()
      : f => f
  )
)

export default store