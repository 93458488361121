import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import Button from '../buttons/Button'
import { Link } from 'react-router-dom'
import useTranslations from '../../../hooks/useTranslations'

const StaticBanner = ({ button, banner }) => {
  const t = useTranslations()
  return (
    <div
      className={cx('staticBanner', {
        [banner.className]: banner.className,
      })}
    >
      <div className="staticBanner-wrapper">
        <div className="staticBanner-info">
          <h2 className="staticBanner-title">{t(banner.title)}</h2>
          <p className="staticBanner-text">{t(banner.text)}</p>
          {button ? (
            <Button
              as={Link}
              to={button.to}
              kind="primary"
              text={button.text}
            />
          ) : null}
        </div>
        <div className="staticBanner-image">
          <img className="image" src={banner.image} alt={banner.alt} />
        </div>
      </div>
    </div>
  )
}

StaticBanner.propTypes = {
  banner: PropTypes.shape({
    className: PropTypes.string,
    text: PropTypes.string,
    title: PropTypes.string,
    orderInverted: PropTypes.bool,
  }),
  button: PropTypes.shape({
    isInscription: PropTypes.bool,
    to: PropTypes.string,
    text: PropTypes.string,
  }),
}

export default StaticBanner
