export const matchResultOptions = {
  default: '',
  valid: {
    won: {
      value: 'won',
      label: 'message:i-won',
    },
    absentOponent: {
      value: 'absent-oponent',
      label: 'message:missing-opponent',
    },
    lost: {
      value: 'lost',
      label: 'message:i-lost',
    },
  },
}

export const quadrantOptions = {
  default: '',
  valid: {
    top: { value: 'top', label: '' },
    bottom: { value: 'bottom', label: '' },
  },
}
