import React from 'react'
import PropTypes from 'prop-types'
import SocialItem from '../social/SocialItem'
import frame from '../../../assets/images/frame.svg'
import frameBg from '../../../assets/images/frame-background.svg'
import useTranslations from '../../../hooks/useTranslations'
import cx from 'classnames'
import OslPropTypes from '../../../common/osl-prop-types'
import { Link } from 'react-router-dom'
import getSharedIcon from '../../../common/get-shared-icon'

const Portrait = ({
  title,
  isBig,
  playerImg,
  social,
  userName,
  consoleId,
  playerTo,
}) => {
  const t = useTranslations()
  return (
    <div className={cx('portrait', { 'is-big': isBig })}>
      <h2 className="portrait-title">{t(title)}</h2>
      <Link className="portrait-avatar" to={playerTo}>
        <img className="frame" src={frame} alt="frame" />
        <img className="frame-bg" src={frameBg} alt="frame" />
        <img className="player" src={playerImg} alt={title} />
        <p className="hover-text">{t('action:see-profile')}</p>
      </Link>
      <div className="portrait-info">
        <p className="portrait-userName">{userName}</p>
        <p className="portrait-consoleId">{consoleId}</p>
        <div className="portrait-social">
          {social.map(item => (
            <SocialItem
              key={item.sharedIconName}
              icon={getSharedIcon(item.sharedIconName)}
              href={item.href}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

Portrait.propTypes = {
  social: OslPropTypes.socialProps,
  title: PropTypes.string,
  playerImg: PropTypes.string,
  userName: PropTypes.string,
  consoleId: PropTypes.string,
  isBig: PropTypes.bool,
  playerTo: PropTypes.string,
}

export default Portrait
