import React from 'react'

import heroImage from '../../assets/images/copa-rfef.svg'
import Hero from '../../ui/components/hero/Hero'
import HeroContentP1 from '../../ui/components/hero/HeroContentP1'
import CtaLastEcopa from '../../ui/components/ctas/CtaLastEcopa'
import AboutEcopa from '../../ui/components/banners/AboutEcopa'
import CtaNewsletter from '../../ui/components/ctas/CtaNewsletter'
import CounterBanner from '../../ui/components/banners/CounterBanner'
import SummaryTiles from '../../ui/components/summary-tiles/SummaryTiles'

import Champions from '../../ui/components/champions/Champions'
import DivisionalChampions from '../../ui/components/champions/DivisionalChampions'

import useTournamentInfo from '../../hooks/useTournamentInfo'
import useTournamentWinners from '../../hooks/useTournamentWinners'

import * as routes from '../routes'

const finalBracketTo = routes.ECOPA_FINAL_BRACKET.path

const formatDateInterval = (
  tournamentStartingDate,
  tournamentFinishingDate,
) => {
  const formatOptions = {
    dateStyle: 'medium',
  }
  const start = new Date(tournamentStartingDate).toLocaleDateString(
    undefined,
    formatOptions,
  )
  const finish = new Date(tournamentFinishingDate).toLocaleDateString(
    undefined,
    formatOptions,
  )

  return `${start} - ${finish}`
}

const formatTitle = (isVerifiedPlayer, isPs4Player) => {
  const verifiedKey = isVerifiedPlayer
    ? 'noun:console-verify'
    : 'noun:console-amateur'
  const platformKey = isPs4Player ? 'noun:psn' : 'noun:xbox'

  return t => t(verifiedKey, { console: t(platformKey) })
}

const getWinnerItem = (winner = {}) => ({
  title: formatTitle(winner.verifiedFifaPlayer, winner.ps4),
  image: winner.pic,
  userName: winner.username,
  platformName: winner.ps4 ? winner.psnId : winner.gamerTag,
  to: winner.username
    ? routes.PROFILES_SUBSECTION.linkTo({ username: winner.username })
    : undefined,
  social: winner.socials
    ? Object.entries(winner.socials)
        .filter(([, values]) => values.link)
        .map(([platform, values]) => ({
          sharedIconName: platform,
          href: values.link,
        }))
    : [],
})

export default ({
  ecopaSlug,
  summary,
  totalRegistrants,
  showInscriptionButton,
  inscriptionButtonTo,
  onClickNewsletter,
}) => {
  const {
    status: ecopaStatus,
    tournamentStartingDate,
    tournamentFinishingDate,
  } = useTournamentInfo(ecopaSlug)

  const {
    champion,
    runnerUp,
    divisions: divisionalWinners,
  } = useTournamentWinners(ecopaSlug, ecopaStatus)

  return (
    <>
      <Hero hasRibbon>
        <HeroContentP1
          image={heroImage}
          imageAlt="ecopa rfef"
          titleHero="message:hero-title"
          subtitleHero="message:hero-text"
          captionHero={formatDateInterval(
            tournamentStartingDate,
            tournamentFinishingDate,
          )}
        />
      </Hero>
      <Champions
        champion={getWinnerItem(champion)}
        subchampion={getWinnerItem(runnerUp)}
      />
      {divisionalWinners && (
        <DivisionalChampions
          firstDivisionItems={divisionalWinners
            .filter(winner => winner.region === 'Suroeste')
            .map(getWinnerItem)}
          firstDivisionTitle={t => t('noun:division-sw')}
          secondDivisionItems={divisionalWinners
            .filter(winner => winner.region === 'Noreste')
            .map(getWinnerItem)}
          secondDivisionTitle={t => t('noun:division-ne')}
          buttonTo={finalBracketTo}
        />
      )}
      <CtaLastEcopa />
      <SummaryTiles items={summary} />
      <AboutEcopa
        isTimeless
        showInscriptionButton={showInscriptionButton}
        isFinishedEcopa
        inscriptionButtonTo={inscriptionButtonTo}
      />
      <CounterBanner
        title="message:people-inscribed"
        number={totalRegistrants}
      />
      <CtaNewsletter
        title="message:cta-newsletter-title"
        handleClick={onClickNewsletter}
      />
    </>
  )
}
