import React from 'react'
import PropTypes from 'prop-types'
import useTranslations from '../../../hooks/useTranslations'
import Input from '../inputs/Input'
import RadioButtonGroup from '../inputs/RadioButtonGroup'
import RadioButton from '../inputs/RadioButton'
import Button from '../buttons/Button'
import ErrorCard from '../cards/ErrorCard'
import OslPropTypes from '../../../common/osl-prop-types'
import cx from 'classnames'

const FormMatchData = ({
  opponentPlatformName,
  roundNumber,
  quadrant,
  validateButton,
  showError,
  isDisabled,
}) => {
  const t = useTranslations()
  return (
    <div className={cx('panel formPanel', { 'is-disabled': isDisabled })}>
      <h3 className="stepUp1-title">{t('noun:match-data')}</h3>
      <Input
        handleBlur={opponentPlatformName.handleBlur}
        handleChange={opponentPlatformName.handleChange}
        type="text"
        isDisabled={opponentPlatformName.isDisabled}
        textError={opponentPlatformName.error}
        textLabel="noun:opponent-gamer-tag"
        value={opponentPlatformName.value}
        name="opponentPlatformName"
      />
      <Input
        handleBlur={roundNumber.handleBlur}
        handleChange={roundNumber.handleChange}
        type="number"
        isDisabled={roundNumber.isDisabled}
        textError={roundNumber.error}
        textLabel="noun:round-number"
        value={roundNumber.value}
        name="roundNumber"
      />
      <RadioButtonGroup
        textLabel="noun:quadrant"
        textError={quadrant.error}
        isDisabled={quadrant.isDisabled}
        isOptional
      >
        <RadioButton
          handleChange={quadrant.top.handleChange}
          textLabel="noun:top"
          value="top"
          checked={quadrant.top.checked}
          name="quadrant"
        />
        <RadioButton
          handleChange={quadrant.bottom.handleChange}
          textLabel="noun:bottom"
          value="bottom"
          checked={quadrant.bottom.checked}
          name="quadrant"
        />
      </RadioButtonGroup>
      {showError && (
        <ErrorCard textTitle="error:validation-failed-title">
          <p className="errorCard-description">
            {t('error:validation-failed-description')}
          </p>
        </ErrorCard>
      )}
      <Button
        as="button"
        type={validateButton.type ?? 'button'}
        kind="primary"
        text={validateButton.isSuccess ? 'action:validated' : 'action:validate'}
        onClick={validateButton.onClick}
        isDisabled={validateButton.isDisabled}
        isLight
        isLoading={validateButton.isLoading}
        isSuccess={validateButton.isSuccess}
      />
    </div>
  )
}

const inputPropType = PropTypes.shape({
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  isDisabled: PropTypes.bool,
  error: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
})

const quadrantPropType = PropTypes.shape({
  error: PropTypes.string,
  isDisabled: PropTypes.bool,
  top: PropTypes.shape({
    handleChange: PropTypes.func,
    checked: PropTypes.bool,
  }),
  bottom: PropTypes.shape({
    handleChange: PropTypes.func,
    checked: PropTypes.bool,
  }),
})

FormMatchData.propTypes = {
  handleSubmit: PropTypes.func,
  roundNumber: inputPropType,
  opponentPlatformName: inputPropType,
  quadrant: quadrantPropType,
  buttonDisabled: PropTypes.bool,
  buttonOnClick: PropTypes.func,
  showError: PropTypes.bool,
  validateButton: OslPropTypes.buttonProps,
  isDisabled: PropTypes.bool,
}

export default FormMatchData
