import React from 'react'
import PropTypes from 'prop-types'
import Icon from '../icons/Icon'
import cx from 'classnames'

const ButtonIcon = ({
  isDisabled,
  onClick,
  type,
  IconComponent,
  className,
  aria,
}) => {
  return (
    <button
      type={type}
      className={cx('button-icon', { [className]: className })}
      onClick={onClick}
      disabled={isDisabled}
      aria-label={aria}
    >
      <Icon Component={IconComponent} />
    </button>
  )
}

ButtonIcon.propTypes = {
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func,
  IconComponent: PropTypes.elementType,
  type: PropTypes.string.isRequired,
  className: PropTypes.string,
  aria: PropTypes.string.isRequired,
}

export default ButtonIcon
