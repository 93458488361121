import React from 'react'
import UserIntro from './UserIntro'
import Button from '../buttons/Button'
import { Link } from 'react-router-dom'
import * as routes from '../../../app/routes'

const UserIntroNotEnrolled = () => {
  return (
    <UserIntro
      introText="message:welcome-osl"
      title="message:start-adventure"
      subtitle="message:release-date"
      dividerText="message:meanwhile"
    >
      <Button
        as={Link}
        to={routes.ECOPA_INSCRIPTION.path}
        kind="primary"
        text="action:inscribe-ecopa"
      />
    </UserIntro>
  )
}

export default UserIntroNotEnrolled
