import React from 'react'
import GenericBanner from '../components/banners/GenericBanner'
import Wysiwyg from '../components/wysiwyg/Wysiwyg'

const RawLink = ({ children }) => (
  <a href={children} target="_blank" rel="noopener noreferrer">
    {children}
  </a>
)

const RawMailto = ({ children }) => (
  <a href={`mailto:${children}`}>{children}</a>
)

const Privacy = () => {
  return (
    <>
      <GenericBanner title="views:privacy-policy-ecopa" />
      <Wysiwyg>
        <h3 className="wysiwyg-headline">
          1. ¿Quién es el Responsable del tratamiento de los datos?
        </h3>
        <p className="wysiwyg-paragraph">
          Las siguientes entidades tratarán tus datos personales en condición de
          Corresponsables del Tratamiento:
        </p>
        <ol className="wysiwyg-list">
          <li>
            DeAPlaneta, domiciliada en Avenida Diagonal 662-664, Barcelona,
            provista de CIF nº B-62490461 y correo electrónico de contacto{' '}
            <RawMailto>info@deaplaneta.com</RawMailto>. En adelante, referida
            individualmente como “DeAPlaneta”.
          </li>
          <li>
            REAL FEDERACION ESPAÑOLA DE FÚTBOL, con domicilio social en Ramón y
            Cajal, S/n, Rozas de Madrid (las) Madrid, con N.I.F. número
            Q2878017I, y correo electrónico de contacto{' '}
            <RawMailto>rfer@rfef.es</RawMailto>. En adelante, referida
            individualmente como “RFEF”.
          </li>
        </ol>
        <p className="wysiwyg-paragraph">
          Ambos corresponsables referidos, en adelante, como los
          “Corresponsables” o las “Entidades”. Podrá ponerse en contacto con el
          Delegado de Protección de Datos de cada corresponsable, mediante
          escrito dirigido a:
        </p>
        <ol className="wysiwyg-list">
          <li>
            DeAPlaneta: <RawMailto>dpo@planeta.es</RawMailto> o a Grupo Planeta,
            At.: Delegado de Protección de Datos, Avda. Diagonal 662-664, 08034
            Barcelona. Real
          </li>
          <li>
            Federación Española de Fútbol: <RawMailto>rfef@rfef.es</RawMailto> o
            a RFEF At.: Delegado de Protección de Datos, c/ Ramón y Cajal s/n,
            28230 Las Rozas (Madrid)
          </li>
        </ol>
        <h3 className="wysiwyg-headline">
          2. ¿Con qué finalidad y base legitimadora tratamos los datos
          personales?
        </h3>
        <p className="wysiwyg-paragraph">
          Los datos facilitados a través del formulario de “registro”, así como
          aquellos otros que pueda facilitarnos posteriormente a través de
          muestra web o los que se generen como consecuencia de su participación
          en los diferentes campeonatos o competiciones (todos ellos también
          denominados, en adelante, como “los datos”) serán tratados para las
          siguientes finalidades:
        </p>
        <div className="indent-sm">
          <p>
            2.1 DeAPlaneta y RFEF podrán tratar los citados datos para gestionar
            la participación en el Primer Campeonato de España de e-futbol como
            Country Qualifiers FIFA y en la competición final internacional de
            la FIFA. Este tratamiento implica el tratamiento de los datos
            personales para:
          </p>
          <ol className="wysiwyg-list-latin">
            <li>
              Gestionar el registro de los usuarios y crearles una cuenta
              personal en nuestra web;
            </li>
            <li>
              Posibilitar la participación de los usuarios registrado en el
              “Primer Campeonato de España de e-futbol como Country Qualifiers
              FIFA” (en adelante el “Campeonato”);
            </li>
            <li>
              Ceder a FIFA los datos personales de los usuarios con el fin de
              posibilitar su participación en la competición final internacional
              de la FIFA.
            </li>
            <li>
              Publicar en la web y difundir públicamente a través de diversos
              medios de comunicación los resultados de la clasificación
              derivados del Campeonato y de la competición final internacional
              de la FIFA;
            </li>
          </ol>
          <p>
            La base legitimadora de dichos tratamientos es la ejecución de la
            relación existente entre los corresponsables y los usuarios , la
            cual existirá desde el momento en que el usuario se inscriba en el
            Campeonato.
          </p>
          <p>
            Los datos solicitados en cada formulario bajo un asterisco son
            necesarios para poder participar en los diferentes campeonatos y
            competiciones, de modo que si no nos facilita dichos datos, no
            podremos gestionar dicha alta ni la citada participación.
            beneficiarse de los servicios prestados por los Corresponsables del
            Tratamiento.
          </p>
        </div>
        <div className="indent-sm">
          <p>
            2.2 Igualmente, tanto DeAPlaneta como RFEF podrán tratar los
            mencionados datos para remitir a los usuarios comunicaciones
            comerciales, por medios electrónicos, sobre productos, servicios o
            eventos de la RFEF y DeAPlaneta relacionados con el Campeonato. Los
            usuarios podrán, en cualquier momento, oponerse a la recepción de
            dichas comunicaciones, sin que ello afecte a su participación en el
            Campeonato. La base legitimadora es el interés legítimo de los
            corresponsables del tratamiento, en aplicación del art 21.2 de la
            Ley de Servicios de la Sociedad de la Información y Comercio
            Electrónico.
          </p>
        </div>
        <div className="indent-sm">
          <p>
            2.3 Por su parte, DeAPlaneta podrá tratar los citados datos para
            remitir comunicaciones comerciales propias de DeAPlaneta y de
            terceros que desarrollen su actividad en los sectores editorial,
            formación, cultura, ocio, coleccionismo, textil, seguros privados,
            gran consumo y joyería, o en los sectores de telecomunicaciones,
            bancario y financiero, viajes, tecnología y actividades jurídicas a
            aquellos usuarios que hayan dado su consentimiento expreso para
            ello, elaborando para ello un perfil comercial. La base legitimadora
            del tratamiento es el consentimiento, en su caso, prestado por los
            usuarios. Los usuarios podrán, en cualquier momento, oponerse a la
            recepción de dichas comunicaciones, sin que ello afecte a su
            participación en el Campeonato 2.4 La RFEF podrá tratar dichos datos
            para remitir comunicaciones comerciales propias de RFEF y de
            terceros. La base legitimadora de este tratamiento es el
            consentimiento, en su caso, prestado por los usuarios.
          </p>
        </div>
        <div className="indent-sm">
          <p>
            2.5 DeAPlaneta, podrá ceder los mencionados datos a sociedades de
            Grupo Planeta y terceras de aquellos usuarios que hayan dado su
            consentimiento para que les remitan comunicaciones comerciales
            propias y de terceros que desarrollen su actividad en los sectores
            editorial, formación, cultura, ocio, coleccionismo, textil, seguros
            privados, gran consumo y joyería, o en los sectores de
            telecomunicaciones, bancario y financiero, viajes, tecnología y
            actividades jurídicas, elaborando a su vez para ello su propio
            perfil comercial. La base legitimadora del tratamiento es el
            consentimiento, en caso, prestado por los usuarios.
          </p>
        </div>
        <h3 className="wysiwyg-headline">
          3. ¿A qué destinatarios se comunicarán los datos?
        </h3>
        <p className="wysiwyg-paragraph">
          Los Datos se cederán a la FIFA, con el fin de posibilitar la
          participación de los usuarios en la competición final internacional de
          la FIFA. La base legitimadora para dicha cesión será la ejecución de
          la relación existente entre los usuarios y los corresponsables.
        </p>
        <p className="wysiwyg-paragraph">
          Asimismo, como se ha indicado anteriormente, en base al
          consentimiento, en su caso, prestados por los usuarios, Planeta cederá
          los Datos a las empresas del Grupo Planeta, las cuales puede consultar
          en el siguiente link www.planeta.es y terceras que desarrollen su
          actividad en los sectores editorial, formación, cultura, ocio,
          coleccionismo, textil, seguros privados, gran consumo y joyería, o en
          los sectores de telecomunicaciones, bancario y financiero, viajes,
          tecnología y actividades jurídicas, para que éstas les remitan
          comunicaciones comerciales propias y de terceros que desarrollen su
          actividad en los sectores editorial, formación, cultura, ocio,
          coleccionismo, textil, seguros privados, gran consumo y joyería, o en
          los sectores de telecomunicaciones, bancario y financiero, viajes,
          tecnología y actividades jurídicas, elaborando a su vez para ello su
          propio perfil comercial.
        </p>
        <p className="wysiwyg-paragraph">
          Asimismo, los usuarios deben de tener en cuenta que la participación
          en el Campeonato conlleva el hecho de que las Entidades difundan
          públicamente los resultados del Campeonato, y con ello datos
          personales de los usuarios relativos a su Nickname, a través de la
          publicación en la propia página web, así como a través de medios de
          comunicación.
        </p>
        <p className="wysiwyg-paragraph">
          Las Entidades también pueden revelar los datos personales de los
          usuarios según lo requiera la ley, o cuando resulte necesario para
          proteger los derechos de las mismas, la seguridad del resto de
          usuarios o de terceros, investigar fraudes, o atender las solicitudes
          de las Administraciones, jueces y/o tribunales.
        </p>
        <h3 className="wysiwyg-headline">
          4. ¿Se llevarán a cabo transferencias internacionales de datos?
        </h3>
        <p className="wysiwyg-paragraph">
          En la medida que la RFEE cederá datos personales de los usuarios a la
          FIFA, se llevarán a cabo transferencias internacionales de los datos a
          Suiza (sede de la FIFA) y a Inglaterra (lugar en el que se celebrará
          la final internacional). La RFEF adoptará las medidas necesarias para
          regularizar las citadas transferencias de datos a efectos de cumplir
          con la normativa vigente en materia de protección de datos.
        </p>
        <h3 className="wysiwyg-headline">
          5. ¿Por cuánto tiempo conservaremos los datos?
        </h3>
        <ol className="wysiwyg-list">
          <li>
            Los datos tratados en base a la relación existente entre las
            entidades y los usuarios se conservarán mientras exista dicha
            relación. Una vez extinguida la relación entre las partes, los datos
            se conservarán bloqueados impidiendo su tratamiento, excepto para la
            puesta a disposición de los datos a los jueces y tribunales, el
            Ministerio Fiscal o las Administraciones Públicas competentes, en
            particular de las autoridades de protección de datos, para la
            exigencia de posibles responsabilidades derivadas del tratamiento y
            solo por el plazo de prescripción de las mismas.
          </li>
          <li>
            Los datos recabados sobre la base del consentimiento y el interés
            legítimo se conservarán mientras no se revoque el citado
            consentimiento o hasta que se solicite su supresión. Una vez
            revocado el consentimiento o solicitada la supresión de los datos,
            los datos se conservarán bloqueados impidiendo su tratamiento,
            excepto para la puesta a disposición de los datos a los jueces y
            tribunales, el Ministerio Fiscal o las Administraciones Públicas
            competentes, en particular de las autoridades de protección de
            datos, para la exigencia de posibles responsabilidades derivadas del
            tratamiento y solo por el plazo de prescripción de las mismas.
          </li>
        </ol>
        <h3 className="wysiwyg-headline">
          6. ¿Cuáles son sus derechos cuando nos facilita tus datos?
        </h3>
        <p className="wysiwyg-paragraph">
          Cualquier persona tiene derecho a obtener confirmación sobre si las
          Entidades están tratando datos personales que les conciernan, o no.
        </p>
        <p className="wysiwyg-paragraph">
          Asimismo, las personas interesadas tienen derecho a acceder a sus
          datos personales, así como a solicitar la rectificación de los datos
          inexactos o, en su caso, solicitar su supresión cuando, entre otros
          motivos, los datos ya no sean necesarios para los fines que fueron
          recogidos.
        </p>
        <p className="wysiwyg-paragraph">
          Igualmente, los interesados podrán solicitar la limitación del
          tratamiento de sus datos, en cuyo caso únicamente los conservaremos
          para el ejercicio o la defensa de reclamaciones. Los interesados
          podrán oponerse al tratamiento de sus datos. Las Entidades dejarán de
          tratar los datos, salvo por motivos legítimos imperiosos, o el
          ejercicio o la defensa de posibles reclamaciones. Del mismo modo, los
          interesados podrán revocar el consentimiento prestado para el
          tratamiento de sus datos (incluido el consentimiento prestado para la
          elaboración de perfiles).
        </p>
        <p className="wysiwyg-paragraph">
          Asimismo, los interesados tienen derecho a recibir, en un formato
          estructurado, de uso común y lectura mecánica, los datos personales
          que le incumban que nos hayan facilitado, y a transmitirlos a otro
          responsable.
        </p>
        <p className="wysiwyg-paragraph">
          Los interesados, podrán ejercer dichos derechos y la revocación en
          cualquier momento de los consentimientos en su caso prestados,
          mediante carta dirigida a:
        </p>
        <ol className="wysiwyg-list">
          <li>
            DeAPlaneta: Apartado de Correos 221 de Barcelona o remitiendo un
            email <RawMailto>lpd@deaplaneta.com</RawMailto>.
          </li>
          <li>
            RFEF: c/ Ramón y Cajal s/n, 28230 Las Rozas (Madrid) Responsable de
            Seguridad, Área de Tecnología e Infraestructuras.
          </li>
        </ol>
        <p className="wysiwyg-paragraph">
          En este sentido, le informamos que tiene a su disposición modelos de
          ejercicio de los citados derechos en la página web de la Agencia
          Española de Protección de Datos (
          <RawLink>
            http://www.agpd.es/portalwebAGPD/CanalDelCiudadano/derechos/principales_derechos
          </RawLink>
          ).
        </p>
        <p className="wysiwyg-paragraph">
          Cuando el interesado lo considere oportuno podrá presentar una
          reclamación ante la Agencia Española de Protección de Datos,
          especialmente cuando aquél considere que no hemos satisfecho
          debidamente el ejercicio de sus derechos. Dicha reclamación podrá
          presentarla ante la citada autoridad mediante las diferentes opciones
          que la sede electrónica de la Agencia Española de Protección de Datos
          ofrece.
        </p>
      </Wysiwyg>
    </>
  )
}

export default Privacy
