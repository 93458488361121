import axios from 'axios'
import { pick } from 'ramda'
import { CMS_URL } from '../constants'

export const fetchNewsContent = async () => {
  const { data } = await axios.get(`${CMS_URL}/actual`)
  return data
}

export const fetchPosts = async (start = 0, limit = -1, filter) => {
  const { data } = await axios.get(
    `${CMS_URL}/posts?_start=${start}&_limit=${limit}&_sort=created_at:desc${
      filter ? `&${filter}` : ''
    }`,
  )
  return data
}

export const fetchOutstandingPost = async () => {
  const { data } = await axios.get(`${CMS_URL}/posts?outstanding=true`)
  return data.length ? data[0] : undefined
}

export const fetchPostByTitle = async title => {
  const { data } = await axios.get(`${CMS_URL}/posts?title=${title}`)
  return data.length ? data[0] : undefined
}

export const fetchCompetitions = async () => {
  let { data } = await axios.get(`${CMS_URL}/competitions`)
  data = data.map(comp => (pick(['id', 'title'], comp)))
  return data 
}

export const fetchCompetitionByTitle = async title => {
  const { data } = await axios.get(`${CMS_URL}/competitions?title=${title}`)
  return data.length ? data[0] : undefined
}

export const fetchHome = async () => {
  const { data } = await axios.get(`${CMS_URL}/home`)
  return data
}

export const fetchCommonData = async () => {
  const { data } = await axios.get(`${CMS_URL}/common`)
  return data
}

export const countFingerprint = async idPost => {
  const { data } = await axios.get(
    `${CMS_URL}/fingerprints/count?post=${idPost}`,
  )
  return data
}

export const incrementFingerprint = async idPost => {
  const { data } = await axios.post(
    `${CMS_URL}/fingerprints`,
    { post: idPost }
  )
  return data
}
