import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router'
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'
import { useDispatch } from 'react-redux'

import useLocalStorage from '../hooks/useLocalStorage'
import {
  fetchPostByTitle,
  incrementFingerprint,
  countFingerprint,
  fetchPosts,
} from '../services/cms'
import { addSocialMediaShares, loadTwitterScript } from '../common/social-media'
import PostPage from '../ui/views/PostPage'

const Post = () => {
  const dispatch = useDispatch()
  const [data, setData] = useState()
  const [lastPosts, setLastPosts] = useState()
  const [fingerprints, setFingerprints] = useState(0)
  const [localFingerprints, setLocalFingerprints] = useLocalStorage(
    'fingerprints',
    [],
  )
  const { slug } = useParams()
  const history = useHistory()

  // TODO Duplicated method
  const addLinkToPosts = posts =>
    posts.map(post => ({
      ...post,
      to: `/news/${post.title}`,
    }))

  const handleClickCounter = async id => {
    try {
      await incrementFingerprint(id)
      setLocalFingerprints([...localFingerprints, id])
      const _fingerprints = await countFingerprint(id)
      setFingerprints(_fingerprints)
    } catch (e) {
      //TODO: Redirect and show snackbar
    }
  }

  useEffect(() => {
    async function fetchData() {
      try {
        let data = await fetchPostByTitle(slug)
        if (!data) history.replace('/news')
        else {
          const _lastPosts = await fetchPosts(0, 3, `id_ne=${data.id}`)
          setLastPosts(addLinkToPosts(_lastPosts))
          const _fingerprints = await countFingerprint(data.id)
          setFingerprints(_fingerprints)
          data = addSocialMediaShares(data, dispatch)
          setData(data)
          loadTwitterScript()
        }
      } catch (e) {
        //TODO: Redirect and show snackbar
      }
    }
    fetchData()
  }, [slug, history, dispatch])

  return data ? (
    <PostPage
      detailHeader={data}
      detailBody={<ReactMarkdown plugins={[gfm]} source={data.content} allowDangerousHtml={true} />}
      thumbnails={lastPosts}
      likeCounter={fingerprints}
      hasLike={localFingerprints.includes(data.id)}
      onClickCounter={() => handleClickCounter(data.id)}
    />
  ) : null
}

export default Post
