import React, { useState, useEffect } from 'react'
import { useHistory, Link } from 'react-router-dom'

import EmptyHeader from '../../ui/components/utils/EmptyHeader'
import InnerWrapper from '../../ui/components/wrappers/InnerWrapper'
import DetailPlayer from '../../ui/components/detail/DetailPlayer'
import Button from '../../ui/components/buttons/Button'

import EcopaLayout from '../../ui/layout/EcopaLayout'

import { fetchPrivateProfile } from '../../services/user'

import * as routes from '../routes'

import Username from './Username'
import { AgeAndLocationWithBirth } from './AgeAndLocation'
import Accounts from './Accounts'
import Email from './Email'
import BirthDate from './BirthDate'

const editProfileTo = routes.USER_PROFILE_EDIT.path

export default () => {
  const [profile, setProfile] = useState()

  const history = useHistory()

  useEffect(() => {
    const fetchPrivateProfileData = async () => {
      const { success, data } = await fetchPrivateProfile()
      if (success) {
        setProfile(data)
      } else {
        history.push(routes.NOT_FOUND_ERROR.path)
      }
    }

    fetchPrivateProfileData()
  }, [history])

  return (
    <EcopaLayout>
      <EmptyHeader withBar />
      {profile ? (
        <InnerWrapper size="sm" className="profile">
          <DetailPlayer
            avatar={profile.profile_pic}
            title={profile.username}
            content={
              <>
                <Username name={profile.name} surname={profile.surname} />
                <Email email={profile.email} />
                <BirthDate birthDate={profile.birth} />
                <AgeAndLocationWithBirth
                  birth={profile.birth}
                  location={profile.location}
                />
              </>
            }
            actions={
              <Button
                kind="primary"
                as={Link}
                text="action:edit-profile"
                to={editProfileTo}
                isLight
              />
            }
          />
          <Accounts label="noun:social-media" accounts={profile.socials} />
          <Accounts label="noun:game-platforms" accounts={profile.platforms} />
        </InnerWrapper>
      ) : null}
    </EcopaLayout>
  )
}
