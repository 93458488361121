import React from 'react'
import PropTypes from 'prop-types'
import ErrorBanner from '../components/banners/ErrorBanner'
import useTranslations from '../../hooks/useTranslations'
import Button from '../components/buttons/Button'
import { Link } from 'react-router-dom'
import * as routes from '../../app/routes'

const Error404 = ({ notification }) => {
  const t = useTranslations()

  return (
    <ErrorBanner title="views:404-title" print="orange">
      <h3 className="stepUp3-text">{t('views:404-text')}</h3>
      <h3 className="error-subtext">{t('views:404-subtext')}</h3>
      <Button
        as={Link}
        to={routes.ROOT.path}
        kind="primary"
        text="action:go-to-home"
      />
    </ErrorBanner>
  )
}

Error404.propTypes = {
  notification: PropTypes.node,
}

export default Error404
