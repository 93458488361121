import React from 'react'
import PropTypes from 'prop-types'
import useTranslations from '../../../hooks/useTranslations'
import cx from 'classnames'

const CountdownItem = ({ value, label, isSingle }) => {
  const t = useTranslations()

  return (
    <div className={cx('countdown-item', { 'is-single': isSingle })}>
      <p className="value">{value}</p>
      {!isSingle ? <p className="label">{t(label)}</p> : null}
    </div>
  )
}

CountdownItem.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  isSingle: PropTypes.bool,
}

export default CountdownItem
