import React, { useState, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import OslPropTypes from '../../../common/osl-prop-types'
import useTranslations from '../../../hooks/useTranslations'
import PortraitCardSquared from '../portraits/PortraitCardSquared'
import Button from '../buttons/Button'

const ParticipantsTga = ({ participants, title }) => {
  const t = useTranslations()
  const participantsRef = useRef(null)

  const [isOpen, setOpen] = useState(false)
  const toggleOpen = useCallback(() => {
    participantsRef.current.scrollIntoView()
    setOpen(isOpen => !isOpen)
  }, [setOpen])
  const items = isOpen ? participants : participants.slice(0, 6)

  return (
    <div className="participants-tga" ref={participantsRef}>
      <div className="wrapper">
        <h2 className="stepUp4-title">{t(title)}</h2>
        <div className="participants">
          {items.map(participant => (
            <PortraitCardSquared
              key={participant.name}
              name={participant.name}
              to={participant.to}
              playerImg={participant.image}
              nickname={participant.nickname}
              social={participant.social}
            />
          ))}
        </div>
        <div className="participants-actions">
          <Button
            kind="secondary"
            as="button"
            onClick={toggleOpen}
            text={
              isOpen
                ? 'action:see-less-participants'
                : 'action:see-all-participants'
            }
          />
        </div>
      </div>
    </div>
  )
}

ParticipantsTga.propTypes = {
  title: PropTypes.string,
  participants: PropTypes.arrayOf(
    PropTypes.shape({
      social: OslPropTypes.socialProps,
      name: PropTypes.string,
      playerImg: PropTypes.string,
      nickname: PropTypes.string,
    }),
  ),
}

export default ParticipantsTga
