import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export default function ScrollToTop({ withHash = true }) {
  const { pathname, hash } = useLocation()

  useEffect(() => {
    if (withHash || !hash) {
      window.scrollTo(0, 0)
    }
  }, [pathname, hash, withHash])

  return null
}
