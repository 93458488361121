import React from 'react'
import { useParams } from 'react-router'

import EcopaActiveBracketFull from '../../ui/views/EcopaActiveBracketFull'
import useSubtournamentInfo from '../../hooks/useSubtournamentInfo'

import { ECOPA2021_SLUG } from '../constants'

export default ({ onClickContact }) => {
  const { subtournamentId } = useParams()

  const { stageWidget, division } = useSubtournamentInfo(
    ECOPA2021_SLUG,
    subtournamentId,
  )

  return (
    <EcopaActiveBracketFull
      title={t => (division ? `${t('noun:round')} ${division}` : null)}
      widgetUrl={stageWidget}
      onClickContact={onClickContact}
    />
  )
}
