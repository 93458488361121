import React from 'react'
import useOutstandingPost from '../hooks/useOutstandingPost'
import { video, winners } from '../data/grandPrix'
import GranPremioGt from '../ui/views/GranPremioGt'

const Iframe = ({ video }) => {
  return (
    <div>
      <iframe src={video} height={'100%'} width={'100%'} title="Promo video of GrandPrix"/>
    </div>
  )
}

const GrandPrix = () => {
  const outstandingPost = useOutstandingPost()
  return (
    <GranPremioGt
      videoGt={<Iframe video={video} />}
      winners={winners}
      outstandingPost={outstandingPost}
    />
  )
}

export default GrandPrix
