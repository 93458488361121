import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'

import useOutstandingPost from '../../hooks/useOutstandingPost'

import EmptyHeader from '../../ui/components/utils/EmptyHeader'
import InnerWrapper from '../../ui/components/wrappers/InnerWrapper'
import DetailPlayer from '../../ui/components/detail/DetailPlayer'

import EcopaLayout from '../../ui/layout/EcopaLayout'

import { fetchProfile } from '../../services/user'
import * as routes from '../routes'

import Username from './Username'
import { AgeAndLocation } from './AgeAndLocation'
import Accounts from './Accounts'

export default () => {
  const [profile, setProfile] = useState()

  const { username } = useParams()
  const history = useHistory()
  const outstandingPost = useOutstandingPost()

  useEffect(() => {
    let didCancel = false
    const fetchProfileData = async (username) => {
      const { success, data } = await fetchProfile(username)
      if (success && !didCancel) {
        setProfile(data)
      } else if (!didCancel){
        history.push(routes.NOT_FOUND_ERROR.path)
      }
    } 

    if (username) {
      fetchProfileData(username)
    } else {
      history.goBack()
    }
    return () => {
      didCancel = true
    }
  }, [username, history])

  return (
    <EcopaLayout outstandingPost={outstandingPost}>
      <EmptyHeader withBar />
      {profile ? (
        <InnerWrapper size="sm" className="profile">
          <DetailPlayer
            avatar={profile.profile_pic}
            title={profile.username}
            content={
              <>
                <Username name={profile.name} surname={profile.surname} />
                <AgeAndLocation age={profile.age} location={profile.location} />
              </>
            }
          />
          <Accounts label="noun:social-media" accounts={profile.socials} />
          <Accounts label="noun:game-platforms" accounts={profile.platforms} />
        </InnerWrapper>
      ) : null}
    </EcopaLayout>
  )
}
