import React from 'react'
import PropTypes from 'prop-types'
import Button from '../buttons/Button'

const ArticleCards = ({ cards, onLoadMore, isLoading }) => {
  return (
    <div className="articleCards">
      {cards}
      <div className="articleCards-actions">
        <Button
          kind="primary"
          as="button"
          onClick={onLoadMore}
          text="action:see-more-news"
          isLoading={isLoading}
          isDisabled={isLoading}
          isLight
        />
      </div>
    </div>
  )
}

ArticleCards.propTypes = {
  cards: PropTypes.node,
  onLoadMore: PropTypes.func,
  isLoading: PropTypes.bool,
}

export default ArticleCards
