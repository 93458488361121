import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import * as Auth from '../../services/auth'
import useTranslations from '../../hooks/useTranslations'
import ModalPasswordNew from '../../ui/components/modals/ModalPasswordNew'
import { openModal, closeModal } from '../../redux/actions'

import * as routes from '../routes'

import { MODAL_PASSWORD_CHANGED } from './constants'

export default () => {
  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)

  const token = searchParams.get('token')
  const email = searchParams.get('email')

  const [redirect, setRedirect] = useState(false)
  const [resetFailed, setResetFailed] = useState(!email || !token)
  const dispatch = useDispatch()
  const history = useHistory()
  const t = useTranslations()
  const formik = useFormik({
    initialValues: {
      password: '',
      token,
      email,
    },
    validationSchema: Yup.object({
      password: Yup.string().required(t('validation-error:field-required')),
    }),
    onSubmit: async values => {
      try {
        await Auth.resetPassword(values)
        setRedirect(true)
      } catch (err) {
        setResetFailed(true)
        console.info('error => ', err)
      }
    },
  })

  useEffect(() => {
    if (redirect) {
      dispatch(openModal(MODAL_PASSWORD_CHANGED))
      history.push(routes.ECOPA_SECTION.path)
    }
  }, [redirect, history, dispatch])

  const handleClose = () => {
    history.push(routes.ROOT.path)
    dispatch(closeModal())
  }

  return (
    <ModalPasswordNew
      onClose={handleClose}
      handleSubmit={formik.handleSubmit}
      handleChange={formik.handleChange}
      handleBlur={formik.handleBlur}
      textError={
        formik.errors.password && formik.touched.password
          ? formik.errors.password
          : ''
      }
      value={formik.values.password}
      showFailedReset={resetFailed}
    />
  )
}
