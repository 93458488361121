import React from 'react'
import ButtonLink from '../links/ButtonLink'
import PropTypes from 'prop-types'
import OslPropTypes from '../../../common/osl-prop-types'
import cx from 'classnames'

const MobileSubmenu = ({ title, dropItems }) => (
  <div className="mobileSubmenu">
    <p className="mobileSubmenu-title">{title}</p>
    <div className="mobileSubmenu-list">
      {dropItems?.map(item => (
        <div
          key={item.name}
          className={cx('mobileSubmenu-itemWrapper', {
            [item.className]: item.className,
          })}
        >
          <ButtonLink
            className="mobileSubmenu-item"
            as={item.as}
            to={item.to}
            key={item.name}
            text={item.name}
            onClick={item.onClick}
            icon={item.icon}
            noTranslation
          />
        </div>
      ))}
    </div>
  </div>
)


MobileSubmenu.propTypes = {
  title: PropTypes.string.isRequired,
  dropItems: OslPropTypes.navDropItems,
}

export default MobileSubmenu
