import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import useTranslations from '../../../hooks/useTranslations'

const Textarea = ({
  handleBlur,
  handleChange,
  isDisabled,
  textError,
  textLabel,
  name,
  value,
}) => {
  const classes = cx('textarea form-field', {
    'has-error': textError,
    'is-disabled': isDisabled,
    'has-value': value,
  })

  const t = useTranslations()

  return (
    <div className={classes}>
      <textarea
        className="textarea-input"
        onBlur={handleBlur}
        onChange={handleChange}
        name={name}
        value={value}
      />
      <label className="textarea-label">{t(textLabel)}</label>
      {textError ? <span className="errorText">{t(textError)}</span> : null}
    </div>
  )
}

Textarea.propTypes = {
  isDisabled: PropTypes.bool,
  textError: PropTypes.string,
  textLabel: PropTypes.string.isRequired,
  name: PropTypes.string,
  value: PropTypes.string,
  handleChange: PropTypes.func,
}

export default Textarea
