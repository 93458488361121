import React from 'react'
import PropTypes from 'prop-types'
import { useMediaQuery } from 'react-responsive'
import useTranslations from '../../../hooks/useTranslations'
import { SIZEXL } from '../../common/ui-constants'
import federationMobile from '../../../assets/images/rfef-mobile.png'
import federationDesktop from '../../../assets/images/rfef-desktop.png'

const PoweredBy = ({ title }) => {
  const t = useTranslations()

  const isTabletOrMobile = useMediaQuery({
    query: `(max-width: ${SIZEXL - 1}px)`,
  })

  return (
    <div className="brick-sm is-dark">
      <div className="wrapper">
        <h1 className="stepUp3-title">{t(title)}</h1>
        <a
          href="https://www.rfef.es/"
          className="brandLogo"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="image"
            src={isTabletOrMobile ? federationMobile : federationDesktop}
            alt="Federación Española de Fútbol"
          />
        </a>
      </div>
    </div>
  )
}

PoweredBy.propTypes = {
  title: PropTypes.string,
}

export default PoweredBy
