import React from 'react'
import PropTypes from 'prop-types'
import useTranslations from '../../../hooks/useTranslations'

const Blockquote = ({ text }) => {
  const t = useTranslations()
  return (
    <blockquote className="blockquote">
      <p className="blockquote-text">{t(text)}</p>
    </blockquote>
  )
}

Blockquote.propTypes = {
  text: PropTypes.string,
}

export default Blockquote
