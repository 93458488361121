import * as routes from './routes'

export const ECOPA2021_SUBPATH = 'ecopa-rfef-2021'
export const ECOPA2021_SLUG = ECOPA2021_SUBPATH.replaceAll('-', '')

export const HOME_BUTTONS = {
  inscription: {
    to: routes.ECOPA_INSCRIPTION.path,
    text: 'action:enroll',
  },
  eCopa: {
    to: routes.ECOPA_SECTION.path,
    text: 'action:know-more',
  },
  tga: {
    to: routes.TGA.path,
    text: 'action:know-more',
  },
  gt: {
    to: routes.GT_GRAND_PRIX.path,
    text: 'action:know-more',
  },
}
