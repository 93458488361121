import React from 'react'
import PropTypes from 'prop-types'
import Loader from '../loaders/Loader'
import cx from 'classnames'

const TournamentWidget = ({ url, isSmall }) => {
  return (
    <div className={cx('tournament-widget', { 'is-small': isSmall })}>
      <div className="wrapper">
        <div className="loader-panel">
          <Loader color="#964bff" size="100px" />
        </div>
        <iframe
          title="tournament-widget"
          width="100%"
          height="100%"
          src={url}
          allowFullScreen={true}
          frameBorder={0}
        />
      </div>
    </div>
  )
}
TournamentWidget.propTypes = {
  url: PropTypes.string,
  isSmall: PropTypes.bool,
}

export default TournamentWidget
