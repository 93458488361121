import React from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'

import ModalPasswordSent from '../../ui/components/modals/ModalPasswordSent'
import { closeModal } from '../../redux/actions'

import useTournamentInfo from '../../hooks/useTournamentInfo'
import { ECOPA2021_SLUG } from '../constants'
import { resendEmail } from '../../services/user'

export default props => {
  const dispatch = useDispatch()
  const { state } = useLocation()
  const { name: tournament } = useTournamentInfo(ECOPA2021_SLUG)

  const handleClose = () => dispatch(closeModal())
  const handleSubmit = async () => {
    await resendEmail({
      emailType: state.type,
      email: state.email,
      tournament,
    })
  }

  return (
    <ModalPasswordSent
      isInscription
      onClose={handleClose}
      onSubmit={handleSubmit}
    />
  )
}
