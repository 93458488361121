import React from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import ModalPasswordChanged from '../../ui/components/modals/ModalPasswordChanged'
import { closeModal } from '../../redux/actions'

import * as routes from '../routes'

export default props => {
  const dispatch = useDispatch()
  const history = useHistory()

  const handleClose = () => dispatch(closeModal())
  const handleLogin = () => {
    dispatch(closeModal())
    history.push(routes.ECOPA_SECTION.path)
  }

  return <ModalPasswordChanged onClose={handleClose} onLogin={handleLogin} />
}
