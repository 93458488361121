import React from 'react'
import PropTypes from 'prop-types'
import useTranslations from '../../../hooks/useTranslations'
import cx from 'classnames'

const HeroSimple = ({ text, logo, alt, print, className }) => {
  const t = useTranslations()
  return (
    <div className={cx('hero heroSimple', { [className]: className })}>
      <img className="print" src={print} alt="Huella OSL" />
      <div className="wrapper">
        <img className="heroSimple-logo" src={logo} alt={alt} />
        <p className="heroSimple-text">{t(text)}</p>
      </div>
    </div>
  )
}

HeroSimple.propTypes = {
  print: PropTypes.string,
  text: PropTypes.string,
  logo: PropTypes.string,
  alt: PropTypes.string,
  className: PropTypes.string,
}

export default HeroSimple
