import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { ECOPA2021_SLUG } from '../app/constants'
import * as routes from '../app/routes'

export const getBreadcrumbsFromPathname = (pathname, subtournamentsInfo) => {
  const modalRoutes = [routes.LOGIN.path, routes.PASSWORD_NEW.path]
  return pathname
    .filter(el => !modalRoutes.includes(`/${el}`))
    .map((el, i, arr) => {
      const to = `/${arr.slice(0, i + 1).join('/')}`
      const isLiteral = /\s/.test(el) || !isNaN(el) || arr[i - 1] === 'profiles'
      const item = {
        notCurrent: arr.length - 1 !== i,
        to,
        name: isLiteral ? el : `noun:${el.toLowerCase()}`,
        literal: isLiteral,
      }

      if (arr[i - 1] === 'division' && !isNaN(el)) {
        const subtournament = subtournamentsInfo.find(
          subtournament =>
            subtournament.id === Number(el) &&
            subtournament.tournamentSlug === ECOPA2021_SLUG,
        )
        return {
          ...item,
          name: subtournament?.division,
          literal: true,
        }
      }
      return item
    })
}

const useBreadcrumbs = () => {
  const [breadcrumbs, setBreadcrumbs] = useState([])
  const location = useLocation()
  const subtournamentsInfo = useSelector(
    state => state.tournaments.subtournamentsInfo,
  )

  useEffect(() => {
    const pathname = location.pathname.split('/').filter(e => e.length)
    setBreadcrumbs([
      {
        notCurrent: true,
        to: '/',
        name: 'OSL',
        literal: true,
      },
      ...getBreadcrumbsFromPathname(pathname, subtournamentsInfo),
    ])
  }, [location.pathname, subtournamentsInfo])
  return breadcrumbs
}

export default useBreadcrumbs
