import React from 'react'
import PropTypes from 'prop-types'
import Sticky from '../components/utils/Sticky'
import Footer from '../components/footer/Footer'
import Button from '../components/buttons/Button'
import SimpleNavigation from '../components/navigation/SimpleNavigation'

const FormLayout = ({
  children,
  title,
  modalSlot,
  barSlot,
  onCancel,
  onClickContact,
}) => {
  return (
    <div className="formLayout">
      <Sticky offsetTop={20}>
        <SimpleNavigation
          title={title}
          leftAction={
            <Button
              as="button"
              to="#"
              kind="tertiary"
              isLight
              text="action:cancel"
              onClick={onCancel}
            />
          }
          rightAction={
            <Button
              as="button"
              kind="tertiary"
              isLight
              text="action:help"
              onClick={onClickContact}
            />
          }
        />
      </Sticky>
      {barSlot ? <div className="barSlot">{barSlot}</div> : null}
      <div className="mainContent">{children}</div>
      {modalSlot}
      <Footer onClickContact={onClickContact} />
    </div>
  )
}

FormLayout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  barSlot: PropTypes.node,
  modalSlot: PropTypes.node,
  onCancel: PropTypes.func,
  onClickContact: PropTypes.func,
}

export default FormLayout
