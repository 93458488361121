import React from 'react'
import PropTypes from 'prop-types'
import useTranslations from '../../../hooks/useTranslations'

const RadioGroup = ({ children, textLabel, textError }) => {
  const t = useTranslations()

  return (
    <>
      <div className="radioGroup">
        {textLabel ? (
          <span className="radioGroup-label">{t(textLabel)}</span>
        ) : null}
        {children}
      </div>
      {textError ? <span className="errorText">{t(textError)}</span> : null}
    </>
  )
}

RadioGroup.propTypes = {
  children: PropTypes.node.isRequired,
  textLabel: PropTypes.string,
  textError: PropTypes.string,
}

export default RadioGroup
