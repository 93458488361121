import React from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as Print } from '../../../assets/icons/icon-print.svg'
import { ReactComponent as PrintColor } from '../../../assets/icons/icon-print-color.svg'

import useTranslations from '../../../hooks/useTranslations'
import cx from 'classnames'

const LikeCounter = ({ counter, hasLike, onClick }) => {
  const t = useTranslations()
  const classes = cx('likeCounter', { 'is-active': hasLike })
  return (
    <div className={classes} onClick={() => !hasLike && onClick()}>
      <div className="likeCounter-printWrapper">
        <div className="likeCounter-bubble">+1</div>
        <div className="likeCounter-print">
          <div className="likeCounter-iconBw">
            <Print className="likeCounter-icon" />
          </div>
          <div className="likeCounter-iconColor">
            <PrintColor className="likeCounter-icon" />
          </div>
        </div>
        <span className="likeCounter-counter" title={counter}>
          {counter}
        </span>
      </div>
      <p className="likeCounter-text">{t('message:liked-article')}</p>
    </div>
  )
}

LikeCounter.propTypes = {
  counter: PropTypes.number,
  hasLike: PropTypes.bool,
  onClick: PropTypes.func,
}

export default LikeCounter
