import React from 'react'
import Slider from './Slider'
import CompetitionStep1 from '../steps/CompetitionStep1'
import CompetitionStep2 from '../steps/CompetitionStep2'
import CompetitionStep3 from '../steps/CompetitionStep3'
import CompetitionStep4 from '../steps/CompetitionStep4'

const CompetitionSlider = () => {
  return (
    <Slider>
      <CompetitionStep1 />
      <CompetitionStep2 />
      <CompetitionStep3 />
      <CompetitionStep4 />
    </Slider>
  )
}

export default CompetitionSlider
