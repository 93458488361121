import React from 'react'
import PropTypes from 'prop-types'
import useTranslations from '../../../hooks/useTranslations'
import ChartBar from './ChartBar'

const ChartsBannerDivisionalP1 = ({ verified, amateurs }) => {
  const t = useTranslations()
  return (
    <div className="chartsBanner">
      <div className="wrapper">
        <h2 className="chartsBanner-title">{t('message:divisional-phase')}</h2>
        <div className="chartPanels only-bars">
          <div className="chartPanel">
            <h2 className="stepUp1-title">{t('noun:verified')}</h2>
            {verified.map(item => (
              <ChartBar
                key={item.label}
                to={item.to}
                literal
                label={item.label}
              />
            ))}
          </div>
          <div className="chartPanel">
            <h2 className="stepUp1-title">{t('noun:amateurs')}</h2>
            {amateurs.map(item => (
              <ChartBar
                key={item.label}
                to={item.to}
                literal
                label={item.label}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

const barPropType = PropTypes.shape({
  to: PropTypes.string,
  label: PropTypes.string,
  percent: PropTypes.number,
})

ChartsBannerDivisionalP1.propTypes = {
  verified: PropTypes.arrayOf(barPropType),
  amateurs: PropTypes.arrayOf(barPropType),
}

export default ChartsBannerDivisionalP1
