import React from 'react'
import PropTypes from 'prop-types'
import SimpleBanner from './SimpleBanner'
import useTranslations from '../../../hooks/useTranslations'

const IntroBanner = ({ name, title }) => {
  const t = useTranslations()

  return (
    <SimpleBanner print="purple">
      <h2 className="simpleBanner-title">
        {t(title, { name: <span>{name}</span> })}
      </h2>
    </SimpleBanner>
  )
}

IntroBanner.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
}

export default IntroBanner
