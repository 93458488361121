import React from 'react'
import PropTypes from 'prop-types'
import useTranslations from '../../../hooks/useTranslations'
import Button from '../buttons/Button'
import OslPropTypes from '../../../common/osl-prop-types'

const HeroContentP1 = ({
  image,
  imageAlt,
  buttonText,
  buttonAs,
  buttonTo,
  buttonKind,
  captionHero,
  titleHero,
  subtitleHero,
  helperText,
}) => {
  const t = useTranslations()
  return (
    <>
      {image ? <img className="hero-logo" src={image} alt={imageAlt} /> : null}
      <h1 className="stepUp5-title">{t(titleHero)}</h1>
      {helperText ? <p className="hero-helperText">{helperText}</p> : null}
      <p className="stepUp3-text">{t(subtitleHero)}</p>
      {buttonText ? (
        <Button
          as={buttonAs}
          to={buttonTo}
          kind={buttonKind}
          text={buttonText}
        />
      ) : null}
      {captionHero ? <p className="baseline-text">{captionHero}</p> : null}
    </>
  )
}

HeroContentP1.defaultProps = {
  buttonKind: 'primary',
}

HeroContentP1.propTypes = {
  image: PropTypes.string,
  imageAlt: PropTypes.string,
  buttonText: PropTypes.string,
  buttonTo: PropTypes.string,
  buttonKind: PropTypes.string,
  buttonAs: OslPropTypes.buttonAs,
  captionHero: PropTypes.node,
  titleHero: PropTypes.string.isRequired,
  subtitleHero: PropTypes.string,
  helperText: PropTypes.string,
}

export default HeroContentP1
