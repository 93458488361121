import React from 'react'
import PropTypes from 'prop-types'
import Modal from './Modal'
import ModalLayout from './ModalLayout'
import useTranslations from '../../../hooks/useTranslations'

const ModalPasswordSent = ({
  onClose,
  isInscription,
  confirmationMessage,
  onSubmit,
}) => {
  const t = useTranslations()

  return (
    <Modal>
      <ModalLayout
        title={isInscription ? 'action:enrolled' : 'action:restore-password'}
        subtitle={
          isInscription ? 'message:first-login' : 'message:password-sent-long'
        }
        subtitleExtra={{ extra: <span>{t('message:spam-folder')}</span> }}
        contactInfo={!isInscription}
        confirmationMessage={
          confirmationMessage ? 'message:sent-message' : null
        }
        primaryButtonText="action:resend-link"
        onClose={onClose}
        onSubmit={onSubmit}
      >
        <span className="bulletDivider"></span>
        <p className="modal-subtitle">{t('message:link-received')}</p>
      </ModalLayout>
    </Modal>
  )
}

ModalPasswordSent.propTypes = {
  onClose: PropTypes.func,
  isInscription: PropTypes.bool,
  confirmationMessage: PropTypes.bool,
  onSubmit: PropTypes.func,
}

export default ModalPasswordSent
