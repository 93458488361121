import React from 'react'
import TwitterTimeline from '../integrations/TwitterTimeline'
import { ReactComponent as IconTwitter } from '../../../assets/icons/icon-twitter.svg'
import SocialItem from '../social/SocialItem'
import { ReactComponent as IconInstagram } from '../../../assets/icons/icon-instagram.svg'
import { ReactComponent as IconYoutube } from '../../../assets/icons/icon-youtube.svg'
import { ReactComponent as IconTwitch } from '../../../assets/icons/icon-twitch.svg'
import MediaQuery from 'react-responsive'
import { SIZEMD } from '../../common/ui-constants'
import useTranslations from '../../../hooks/useTranslations'
import { twitter, instagram, youtube, twitch } from '../../../data/osl'

const SocialSection = () => {
  const t = useTranslations()
  return (
    <div className="socialSection">
      <div className="wrapper">
        <TwitterTimeline />
        <div className="socialSection-info">
          <h2 className="socialSection-title">{t('action:follow-us')}</h2>
          <p className="baseline-text">{t('social-banner:description')}</p>
          <MediaQuery minWidth={SIZEMD}>
            <div className="socialSection-list">
              <SocialItem icon={IconTwitter} href={twitter} name="Twitter" />
              <SocialItem
                icon={IconInstagram}
                href={instagram}
                name="Instagram"
              />
              <SocialItem icon={IconYoutube} href={youtube} name="Youtube" />
              <SocialItem icon={IconTwitch} href={twitch} name="Twitch" />
            </div>
          </MediaQuery>
        </div>
      </div>
    </div>
  )
}

export default SocialSection
