import React from 'react'
import useTranslations from '../../../hooks/useTranslations'

const CookiesTable = () => {
  const t = useTranslations()

  return (
    <div className="table-wrapper">
      <table className="table">
        <thead>
          <tr className="table-header">
            <th>{t('cookies:finality')}</th>
            <th>{t('cookies:entity')}</th>
            <th>{t('cookies:cookie')}</th>
            <th>{t('cookies:purpose')}</th>
            <th>{t('cookies:expiration')}</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <th rowSpan="4" className="bold">
              {t('cookies:technical')}
            </th>
            <th>{t('cookies:own')}</th>
            <th></th>
            <th></th>
            <th></th>
          </tr>

          <tr>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
          </tr>

          <tr>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
          </tr>

          <tr>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
          </tr>

          <tr>
            <th rowSpan="4" className="bold">
              {t('cookies:analysis')}
            </th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
          </tr>

          <tr>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
          </tr>

          <tr>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
          </tr>

          <tr>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default CookiesTable
