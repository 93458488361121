import React from 'react'
import PropTypes from 'prop-types'
import Modal from './Modal'
import ModalLayout from './ModalLayout'
import Input from '../inputs/Input'
import useTranslations from '../../../hooks/useTranslations'

const ModalPasswordNew = ({
  onClose,
  handleSubmit,
  handleBlur,
  handleChange,
  textError,
  value,
  showFailedReset,
}) => {
  const t = useTranslations()
  return (
    <Modal>
      <ModalLayout
        title="action:restore-password"
        contactInfo
        onSubmit={handleSubmit}
        primaryButtonText="action:confirm-new-password"
        onClose={onClose}
      >
        <Input
          handleBlur={handleBlur}
          handleChange={handleChange}
          type="password"
          textError={textError}
          textLabel={'input-label:new-password'}
          value={value}
          name="password"
        />
        <p className="modal-caption">{t('message:password-patterns')}</p>
        {showFailedReset && (
          <div className="errorCard">
            <p className="errorCard-title">{t('error:reset-failed-title')}</p>
            <p className="errorCard-description">
              {t('error:reset-failed-description')}
            </p>
          </div>
        )}
      </ModalLayout>
    </Modal>
  )
}

ModalPasswordNew.propTypes = {
  onClose: PropTypes.func,
  handleSubmit: PropTypes.func,
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  textError: PropTypes.string,
  value: PropTypes.string,
  showFailedReset: PropTypes.bool,
}

export default ModalPasswordNew
