import React from 'react'
import PropTypes from 'prop-types'
import OslPropTypes from '../../../common/osl-prop-types'
import useTranslations from '../../../hooks/useTranslations'
import PortraitCard from '../portraits/PortraitCard'
import Button from '../buttons/Button'
import { Link } from 'react-router-dom'

const DivisionalChampions = ({
  firstDivisionItems,
  firstDivisionTitle,
  secondDivisionItems,
  secondDivisionTitle,
  buttonTo,
}) => {
  const t = useTranslations()
  return (
    <div className="divisionalChampions">
      <div className="wrapper">
        <h2 className="divisionalChampions-title">
          {t('noun:divisional-champions')}
        </h2>
        <div className="divisionalChampions-lists">
          <div className="divisionalChampions-list">
            <h3 className="divisionalChampions-subtitle">
              {firstDivisionTitle(t)}
            </h3>
            {firstDivisionItems.map(item => (
              <PortraitCard
                key={item.userName}
                title={item.title}
                to={item.to}
                playerImg={item.image}
                userName={item.userName}
                platformName={item.platformName}
                social={item.social}
              />
            ))}
          </div>
          <div className="divisionalChampions-list">
            <h3 className="divisionalChampions-subtitle">
              {secondDivisionTitle(t)}
            </h3>
            {secondDivisionItems.map(item => (
              <PortraitCard
                key={item.userName}
                title={item.title}
                to={item.to}
                playerImg={item.image}
                userName={item.userName}
                platformName={item.platformName}
                social={item.social}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="divisionalChampions-action">
        <Button
          as={Link}
          to={buttonTo}
          text="action:check-all-results"
          kind="secondary"
          isLight
        />
      </div>
    </div>
  )
}

DivisionalChampions.propTypes = {
  firstDivisionItems: PropTypes.arrayOf(OslPropTypes.finalistProps),
  secondDivisionItems: PropTypes.arrayOf(OslPropTypes.finalistProps),
  firstDivisionTitle: PropTypes.func,
  secondDivisionTitle: PropTypes.func,
  buttonTo: PropTypes.string,
}

export default DivisionalChampions
