import React from 'react'
import PropTypes from 'prop-types'
import Cta from './Cta'

const CtaNewsletter = ({ handleClick, title, print }) => {
  return (
    <Cta
      buttonAs="button"
      id="newsletter"
      print={print ? 'pink2' : null}
      title={title}
      buttonKind="secondary"
      onClick={handleClick}
      buttonText="action:subscribe-newsletter"
    />
  )
}

CtaNewsletter.propTypes = {
  handleClick: PropTypes.func,
  title: PropTypes.string,
  print: PropTypes.bool,
}

CtaNewsletter.defaultProps = {
  print: true,
}

export default CtaNewsletter
