import React from 'react'
import PropTypes from 'prop-types'
import defaultAvatar from '../../../assets/images/avatar-mistery-man-big.svg'
import InputFile from '../inputs/InputFile'
import useTranslations from '../../../hooks/useTranslations'

const AvatarUploader = ({
  avatar,
  fieldName = 'upload-avatar',
  handleChange,
  textError,
}) => {
  const t = useTranslations()
  return (
    <div className="avatarUploader">
      <div className="avatarUploader-image">
        {avatar ? (
          <img className="avatar" alt="avatar" src={avatar} />
        ) : (
          <img
            className="default-avatar"
            src={defaultAvatar}
            alt="Mistery man"
          />
        )}
      </div>
      <div className="avatarUploader-content">
        <InputFile
          textLabel="action:add-image"
          name={fieldName}
          onChange={handleChange}
        />
        {textError ? <span className="errorText">{t(textError)}</span> : null}
        <p className="helperText">
          {t('message:image-instructions', { size: '2Mb' })}
        </p>
        <p className="helperText">{t('message:image-tips')}</p>
      </div>
    </div>
  )
}

AvatarUploader.propTypes = {
  avatar: PropTypes.string,
  fieldName: PropTypes.string,
  handleChange: PropTypes.func,
  textError: PropTypes.string,
}

export default AvatarUploader
