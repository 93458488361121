import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchSubtournamentInfo } from '../redux/actions'

export default (tournamentSlug, rawSubtournamentId) => {
  const subtournamentId = Number(rawSubtournamentId)
  const dispatch = useDispatch()

  const subtournamentInfo = useSelector(state =>
    state.tournaments.subtournamentsInfo.find(
      subtournament =>
        subtournament.id === subtournamentId &&
        subtournament.tournamentSlug === tournamentSlug,
    ),
  )

  useEffect(() => {
    if (!subtournamentInfo)
      dispatch(fetchSubtournamentInfo(tournamentSlug, subtournamentId))
  }, [tournamentSlug, subtournamentId, subtournamentInfo, dispatch])

  return subtournamentInfo || {}
}
