import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchSubtournaments } from '../redux/actions'

const VERIFIED_REGEX = /[Vv]erificado/

export default tournamentSlug => {
  const dispatch = useDispatch()

  const subtournaments = useSelector(
    state => state.tournaments.subtournaments,
  )

  useEffect(() => {
    if (subtournaments === undefined) {
      dispatch(fetchSubtournaments(tournamentSlug))
    }
  }, [tournamentSlug, subtournaments, dispatch])

  return !subtournaments ? [] : subtournaments.map(subtournament => ({
    isVerified: VERIFIED_REGEX.test(subtournament.division),
    ...subtournament,
  }))
}
