import React from 'react'

import EditAccountsUI from './EditAccountsUI'
import { MODAL_TYPE, INNER_MODAL } from './index'

const createHandleClickDeleteAccount = (formik, fieldName) => slug => () => {
  const newAccounts = { ...formik.values[fieldName] }
  delete newAccounts[slug]
  formik.setFieldValue(fieldName, newAccounts)
  formik.setFieldTouched(fieldName)
  formik.handleSubmit()
}

const EditAccounts = ({
  type = MODAL_TYPE.SOCIAL,
  formik,
  setInnerModal,
  setModalType,
  setEditingAccount,
}) => {

  const onClickNew = () => {
    setInnerModal(INNER_MODAL.ADD_ACCOUNT)
    setModalType(type)

  }
  const onClickEdit = (slug) => {
    setInnerModal(INNER_MODAL.EDIT_ACCOUNT)
    setModalType(type)
    setEditingAccount(slug)
  }
  return (
    <EditAccountsUI
      type={type}
      accounts={formik.values[type]}
      handleClickNew={onClickNew}
      createHandleClickEdit={slug => () => onClickEdit(slug)}
      createHandleClickDelete={createHandleClickDeleteAccount(
        formik,
        type,
      )}
    />
  )
}

export default EditAccounts

