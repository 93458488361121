import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { fetchTournamentInfo } from '../redux/actions'

export const STATUS_FUTURE = 'future'
export const STATUS_INSCRIPTIONS_OPEN = 'inscriptions'
export const STATUS_TO_BE_STARTED = 'waiting'
export const STATUS_ACTIVE = 'active'
export const STATUS_FINISHED = 'finished'
export const STATUS_CANCELED = 'canceled'

const getStatus = (tournamentInfo = {}, now) => {
  const {
    status,
    inscriptionOpeningDate,
    inscriptionDeadline,
    tournamentStartingDate,
  } = tournamentInfo

  if (status === 'canceled') {
    return STATUS_CANCELED
  }
  if (status === 'finished') {
    return STATUS_FINISHED
  }
  if (tournamentStartingDate < now) {
    return STATUS_ACTIVE
  }
  if (inscriptionDeadline < now) {
    return STATUS_TO_BE_STARTED
  }
  if (inscriptionOpeningDate < now) {
    return STATUS_INSCRIPTIONS_OPEN
  }
  return STATUS_FUTURE
}

export default tournamentSlug => {
  const dispatch = useDispatch()

  const tournamentInfo = useSelector(
    state => state.tournaments.info[tournamentSlug],
  )

  useEffect(() => {
    if (!tournamentInfo) dispatch(fetchTournamentInfo(tournamentSlug))
  }, [tournamentSlug, tournamentInfo, dispatch])

  const status = getStatus(tournamentInfo, Date.now())

  const {
    name,
    stage,
    inscriptionOpeningDate,
    inscriptionDeadline,
    tournamentStartingDate,
    tournamentFinishingDate,
    stageWidget,
  } = tournamentInfo ? tournamentInfo : {}

  return {
    status,
    name,
    stage,
    inscriptionOpeningDate,
    inscriptionDeadline,
    tournamentStartingDate,
    tournamentFinishingDate,
    stageWidget,
  }
}
