import React from 'react'
import PropTypes from 'prop-types'
import TournamentWidget from './TournamentWidget'

const TournamentActions = ({ url, actions }) => {
  return (
    <div className="tournament-wrapper">
      <TournamentWidget url={url} />
      <div className="actions">{actions}</div>
    </div>
  )
}
TournamentActions.propTypes = {
  url: PropTypes.string,
  actions: PropTypes.node,
}

export default TournamentActions
