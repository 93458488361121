import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import cx from 'classnames'

const Modal = ({ children, isDark }) => {
  const [container] = useState(document.createElement('div'))
  container.id = 'modal-root'

  useEffect(() => {
    document.body.appendChild(container)
    return () => document.body.removeChild(container)
  }, [container])

  return ReactDOM.createPortal(
    <div className="modal-overlay">
      <div className={cx('modal', { 'is-dark': isDark })}>{children}</div>
    </div>,
    container,
  )
}

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  isDark: PropTypes.bool,
}

export default Modal
