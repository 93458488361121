import React from 'react'
import PropTypes from 'prop-types'
import IconListItemEdit from './IconListItemEdit'
import useTranslations from '../../../hooks/useTranslations'
import getSharedIcon from '../../../common/get-shared-icon'
import Button from '../buttons/Button'

const IconListEdit = ({ title, items, addButton, emptyText }) => {
  const t = useTranslations()
  return (
    <div className="iconList-wrapper iconList-edit">
      <h2 className="stepDown1-title iconList-title">{t(title)}</h2>
      {emptyText ? (
        <p className="iconList-emptyText">{t(emptyText)}</p>
      ) : (
        <>
          <div className="iconList-heading">
            <span className="iconList-headingLabel is-long">
              {t('input-label:name')}
            </span>
            <span className="iconList-headingLabel is-short">
              {t('action:edit')}
            </span>
            <span className="iconList-headingLabel is-short">
              {t('action:delete')}
            </span>
          </div>
          <ul className="iconList">
            {items.map(item => (
              <IconListItemEdit
                key={item.name}
                name={item.name}
                onClickEdit={item.onClickEdit}
                onClickDelete={item.onClickDelete}
                icon={getSharedIcon(item.sharedIconName)}
              />
            ))}
          </ul>
        </>
      )}
      <Button
        as="button"
        type="button"
        onClick={addButton.onClick}
        text={addButton.text}
        kind="secondary"
        isLight
      />
    </div>
  )
}

IconListEdit.propTypes = {
  emptyText: PropTypes.string,
  title: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      onClickDelete: PropTypes.func,
      onClickEdit: PropTypes.func,
      icon: PropTypes.elementType,
    }),
  ),
  addButton: PropTypes.shape({
    onClick: PropTypes.func,
    text: PropTypes.string,
  }),
}

export default IconListEdit
