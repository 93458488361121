import React, { useState, useCallback} from 'react'
import PropTypes from 'prop-types'
import OslPropTypes from '../../../common/osl-prop-types'
import cx from 'classnames'

import ButtonLink from '../links/ButtonLink'

const DesktopSubmenu = ({ title, dropItems }) => {
  const [isOpen, setOpen] = useState(false)
  const toggleOpenDropdown = useCallback(() => setOpen(!isOpen), [isOpen])

  return(
    <div className={cx('dropdown', { open: isOpen })} >
      <button
        type="button"
        className="dropdown-trigger"        
        onClick={toggleOpenDropdown}
      >
        <p className="mainNav-item"> {title}</p>
      </button>
      <div className="dropdown-list dropdown-list-competitions">
        {dropItems ? (dropItems.map(item => (
          <ButtonLink
            className="dropdown-item"
            as={item.as}
            key={item.name}
            text={item.name}
            onClick={item.onClick}
            icon={item.icon}
            noTranslation
          />)
        )) : null}
      </div>

    </div>
  )
}

DesktopSubmenu.propTypes = {
  title: PropTypes.string.isRequired,
  dropItems: OslPropTypes.navDropItems,
}

export default DesktopSubmenu
