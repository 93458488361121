import React from 'react'
import PropTypes from 'prop-types'
import Modal from '../modals/Modal'
import ModalLayout from './ModalLayout'
import Input from '../inputs/Input'
import RequestErrorCard from '../cards/RequestErrorCard'

import OslPropTypes from '../../../common/osl-prop-types'

const ModalEditPassword = ({
  onClose,
  newPassword,
  confirmNewPassword,
  showError,
  isBtnDisabled,
}) => {
  return (
    <Modal>
      <ModalLayout
        title="action:edit-password"
        primaryButtonText="action:save"
        onClose={onClose}
        isBtnDisabled={isBtnDisabled}
        isForm
      >
        <Input
          handleBlur={newPassword.handleBlur}
          handleChange={newPassword.handleChange}
          type="password"
          textError={newPassword.error}
          textLabel="input-label:new-password"
          value={newPassword.value}
          name={newPassword.name}
        />
        <Input
          handleBlur={confirmNewPassword.handleBlur}
          handleChange={confirmNewPassword.handleChange}
          type="password"
          textError={confirmNewPassword.error}
          textLabel="input-label:confirm-new-password"
          value={confirmNewPassword.value}
          name={confirmNewPassword.name}
        />
       {showError && <RequestErrorCard textTitle="error:profile-failed-title" />}
      </ModalLayout>
    </Modal>
  )
}

ModalEditPassword.propTypes = {
  newPassword: OslPropTypes.inputProps,
  confirmNewPassword: OslPropTypes.inputProps,
  showError: PropTypes.bool,
}

export default ModalEditPassword
