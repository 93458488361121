import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import useTranslations from '../../../hooks/useTranslations'

const Radio = ({
  handleChange,
  handleBlur,
  textError,
  textHelper,
  textLabel,
  name,
  value,
  checked,
  isDisabled,
}) => {
  const classes = cx('radio', {
    'has-error': textError,
    'is-disabled': isDisabled,
  })
  const t = useTranslations()

  return (
    <div className={classes}>
      <input
        className="radio-input"
        type="radio"
        id={textLabel}
        name={name}
        value={value}
        onBlur={handleBlur}
        onChange={handleChange}
        checked={checked}
        disabled={isDisabled}
      />
      <div className="radio-text">
        <label className="radio-label" htmlFor={textLabel}>
          <span className="radio-labelText">{t(textLabel)}</span>
          {textHelper ? (
            <span className="radio-labelHelper">{t(textHelper)}</span>
          ) : null}
        </label>
      </div>
      {textError ? <span className="errorText">{t(textError)}</span> : null}
    </div>
  )
}

Radio.propTypes = {
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  textError: PropTypes.string,
  textHelper: PropTypes.string,
  textLabel: PropTypes.string.isRequired,
  value: PropTypes.string,
  name: PropTypes.string,
  isDisabled: PropTypes.bool,
}

export default Radio
