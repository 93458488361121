import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { fetchHome } from '../services/cms'
import { openModal } from '../redux/actions'
import { MODAL_NEWSLETTER } from './modals/constants'

import HomeView from '../ui/views/Home'
import EcopaLayout from '../ui/layout/EcopaLayout'

import useTournamentInfo, {
  STATUS_INSCRIPTIONS_OPEN,
} from '../hooks/useTournamentInfo'
import { HOME_BUTTONS as BUTTONS, ECOPA2021_SLUG } from './constants'

const addLinkToPosts = posts =>
posts.map(post => ({
  ...post,
  to: `news/${post.title}`,
}))


const Home = () => {
  const [articleFeatured, setArticleFeatured] = useState()
  const [posts, setPosts] = useState([])
  const [streaming, setStreaming] = useState({})
  const [sponsors, setSponsors] = useState({})
  const dispatch = useDispatch()

  const { status: ecopa2021Status } = useTournamentInfo(ECOPA2021_SLUG)
  const isInscriptionOpen = ecopa2021Status === STATUS_INSCRIPTIONS_OPEN

  const handleClickNewsletter = () => dispatch(openModal(MODAL_NEWSLETTER))

  useEffect(() => {
    async function fetchData() {
      const data = await fetchHome()
      data.post.outstanding.to = `news/${data.post.outstanding.title}`
      setArticleFeatured({ content: data.post.outstanding, cta: data.post.cta })
      setStreaming(data.streaming || {})
      data.posts.length && setPosts( addLinkToPosts(data.posts))
      setSponsors(data.sponsors_blocks)
    }
    fetchData()
  }, [])

  return (
    <EcopaLayout>
      <HomeView
        articleFeatured={articleFeatured}
        thumbnails={posts}
        videoTitle={streaming.title}
        videoContent={
          <div dangerouslySetInnerHTML={{ __html: streaming.htmlEmbed }}></div>
        }
        videoCta={streaming.cta}
        showVideo={streaming.show}
        ecopaButton={isInscriptionOpen ? BUTTONS.inscription : BUTTONS.eCopa}
        tgaButton={BUTTONS.tga}
        gtButton={BUTTONS.gt}
        firstSponsor={sponsors ? sponsors.first : null}
        secondSponsor={sponsors ? sponsors.second: null}
        onClickNewsletter={handleClickNewsletter}
      />
    </EcopaLayout>
  )
}

export default Home
