import React from 'react'
import PropTypes from 'prop-types'
import Icon from '../icons/Icon'
import ButtonIcon from '../buttons/ButtonIcon'

import { ReactComponent as IconEdit } from '../../../assets/icons/icon-edit.svg'
import { ReactComponent as IconDelete } from '../../../assets/icons/icon-trash.svg'

const IconListItemEdit = ({ name, onClickEdit, onClickDelete, icon }) => {
  return (
    <li className="iconList-item">
      <div className="iconList-content">
        <Icon Component={icon} />
        <p className="iconList-name" title={name}>
          {name}
        </p>
        <div className="iconList-actions">
          <ButtonIcon
            IconComponent={IconEdit}
            onClick={onClickEdit}
            aria="edit"
            type="button"
          />
          <ButtonIcon
            IconComponent={IconDelete}
            onClick={onClickDelete}
            aria="delete"
            type="button"
          />
        </div>
      </div>
    </li>
  )
}

IconListItemEdit.propTypes = {
  hasLink: PropTypes.bool,
  to: PropTypes.string,
  icon: PropTypes.elementType,
  name: PropTypes.string,
  onClickEdit: PropTypes.func,
  onClickDelete: PropTypes.func,
}

export default IconListItemEdit
