import React from 'react'
import useTranslations from '../../../hooks/useTranslations'
import UserIntro from './UserIntro'
import SocialGroup from '../social/SocialGroup'
import Button from '../buttons/Button'
import { ReactComponent as IconDiscord } from '../../../assets/icons/icon-discord.svg'
import { joinDiscord } from '../../../data/ecopa'

const UserIntroEnrolled = () => {
  const t = useTranslations()
  return (
    <UserIntro
      introText="message:ecopa-path-started"
      title="message:reach-victory"
      dividerText="message:following-steps"
    >
      <div className="cards">
        <div className="card">
          <SocialGroup title="action:follow-media" />
        </div>
        <div className="card">
          <p className="genericLabel">{t('action:join-server')}</p>
          <Button
            as="a"
            target="_blank"
            href={joinDiscord}
            kind="primary"
            text="action:go-to-discord"
            IconComponent={IconDiscord}
          />
        </div>
      </div>
    </UserIntro>
  )
}

export default UserIntroEnrolled
