import React from 'react'
import { setupI18n } from '../../libs/i18n'

const formatOptions = {
  dateStyle: 'long',
}

export default ({ birthDate }) => {
  if (!birthDate) return null

  const { locale } = setupI18n()
  const birth = new Date(birthDate).toLocaleDateString(locale, formatOptions)

  return <p className="baseline-text">{birth}</p>
}
