import React from 'react'
import PropTypes from 'prop-types'
import frame from '../../../assets/images/frame-tiny.svg'

const PortraitTiny = ({ playerImg }) => {
  return (
    <div className="portrait is-tiny">
      <div className="portrait-avatar">
        <img className="frame" src={frame} alt="frame" />
        <img className="player" src={playerImg} alt="player" />
      </div>
    </div>
  )
}

PortraitTiny.propTypes = {
  playerImg: PropTypes.string,
}

export default PortraitTiny
