import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { ReactComponent as Arrow } from '../../../assets/icons/icon-chevron-down.svg'
import useTranslations from '../../../hooks/useTranslations'

const convertToOption = key => ({ value: key, label: t => t(key) })

const Select = ({
  options,
  isDisabled,
  handleBlur,
  handleChange,
  textLabel,
  textError,
  value,
  name,
}) => {
  const classes = cx('select', { 'is-disabled': isDisabled })

  const t = useTranslations()
  return (
    <div className={classes}>
      <select
        className="select-select"
        id={textLabel}
        onBlur={handleBlur}
        onChange={handleChange}
        name={name}
        value={value}
      >
        {options.map(option => (
          <option value={option.value} key={option.value}>
            {option.label(t)}
          </option>
        ))}
      </select>
      <Arrow className="select-arrow" />
      <label className="select-label">{t(textLabel)}</label>
      {textError ? <span className="errorText">{t(textError)}</span> : null}
    </div>
  )
}

Select.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.func,
    }),
  ),
  isDisabled: PropTypes.bool,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  textLabel: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  textError: PropTypes.string,
}

export { Select, convertToOption }
