import React from 'react'
import { Link } from 'react-router-dom'

import useTranslations from '../../../hooks/useTranslations'
import { ReactComponent as IconClose } from '../../../assets/icons/icon-close.svg'
import image from '../../../assets/images/check.svg'

import ButtonIcon from '../buttons/ButtonIcon'
import Button from '../buttons/Button'

import Modal from './Modal'

const ModalPasswordChanged = ({ onClose, onLogin }) => {
  const t = useTranslations()

  return (
    <Modal isDark>
      <ButtonIcon
        onClick={onClose}
        IconComponent={IconClose}
        className="button-close"
        aria="close"
      />
      <div className="modal-confirmation">
        <div className="rounded-image">
          <img className="modal-image" alt="password changed" src={image} />
        </div>
        <p className="modal-text">{t('message:password-changed')}</p>
        <Button
          as={Link}
          kind="primary"
          onClick={onLogin}
          text="action:login-osl"
        />
      </div>
    </Modal>
  )
}

export default ModalPasswordChanged
