import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

const EmptyHeader = ({ withBar }) => {
  return (
    <div
      className={cx('emptyHeader', {
        'with-bar': withBar,
      })}
    ></div>
  )
}

EmptyHeader.propTypes = {
  withBar: PropTypes.bool,
}

export default EmptyHeader
