import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import cx from 'classnames'

import { CMS_URL } from '../../../constants'

const hostUrl = Boolean(process.env.STORYBOOK_RUNNING) ? '' : CMS_URL

const ArticleThumbnail = ({ article, isSmall }) => {
  return (
    <Link
      className={cx('article articleThumbnail', { 'is-small': isSmall })}
      to={article.to}
    >
      <div className="articleThumbnail-image">
        <div className="skeleton"></div>
        <img
          src={`${hostUrl}${article.image.formats.medium.url}`}
          srcSet={
            `${hostUrl}${article.image.formats.thumbnail.url} 245w,
            ${hostUrl}${article.image.formats.medium.url} 750w`
          }
          sizes="(max-width: 245px) 245px, (max-width: 750px) 750px"
          alt={article.title}
        />
      </div>
      <div className="articleThumbnail-info">
        <div className="wrapper">
          <h1 className="articleThumbnail-title">{article.title}</h1>
          {article.description ? (
            <p className="articleThumbnail-text">{article.description}</p>
          ) : null}
        </div>
      </div>
    </Link>
  )
}

ArticleThumbnail.propTypes = {
  isSmall: PropTypes.bool,
  article: PropTypes.shape({
    to: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.shape({
      formats: PropTypes.shape({
        thumbnail: PropTypes.shape({
          url: PropTypes.string
        }),
        medium: PropTypes.shape({
          url: PropTypes.string
        }),
        large: PropTypes.shape({
          url: PropTypes.string
        })
      })
    })
  }),
}

export default ArticleThumbnail
