import React from 'react'
import PropTypes from 'prop-types'
import useTranslations from '../../../hooks/useTranslations'
import SocialItem from './SocialItem'
import { ReactComponent as IconTwitter } from '../../../assets/icons/icon-twitter.svg'
import { ReactComponent as IconInstagram } from '../../../assets/icons/icon-instagram.svg'
import { ReactComponent as IconYoutube } from '../../../assets/icons/icon-youtube.svg'
import { ReactComponent as IconTwitch } from '../../../assets/icons/icon-twitch.svg'
import { twitter, instagram, youtube, twitch } from '../../../data/osl'

const SocialGroup = ({ title }) => {
  const t = useTranslations()

  return (
    <div className="socialGroup">
      <p className="genericLabel">{t(title)}</p>
      <div className="socialItems">
        <SocialItem icon={IconTwitter} href={twitter} />
        <SocialItem icon={IconInstagram} href={instagram} />
        <SocialItem icon={IconYoutube} href={youtube} />
        <SocialItem icon={IconTwitch} href={twitch} />
      </div>
    </div>
  )
}

SocialGroup.propTypes = {
  title: PropTypes.string,
}

export default SocialGroup
