import React from 'react'
import PropTypes from 'prop-types'
import Icon from '../icons/Icon'
import { ReactComponent as IconLogin } from '../../../assets/icons/icon-login.svg'
import MediaQuery from 'react-responsive'
import { SIZEXL } from '../../common/ui-constants'

import UserDropdown from '../dropdown/UserDropdown'
import UserPrivateArea from './UserPrivateArea'
import OslPropTypes from '../../../common/osl-prop-types'

const User = ({ name, avatar, dropItems }) => {
  return (
    <>
      <MediaQuery maxWidth={SIZEXL - 1}>
        <UserPrivateArea dropItems={dropItems} />
      </MediaQuery>
      <MediaQuery minWidth={SIZEXL}>
        <div className="user">
          <div className="user-name">
            <div className="user-avatar">
              {avatar ? (
                <img src={avatar} alt={name} />
              ) : (
                <Icon Component={IconLogin} />
              )}
            </div>
            <UserDropdown name={name} dropItems={dropItems} />
          </div>
        </div>
      </MediaQuery>
    </>
  )
}

User.propTypes = {
  name: PropTypes.string.isRequired,
  avatar: PropTypes.string,
  dropItems: OslPropTypes.userDropItems,
}

export default User
