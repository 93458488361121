import React from 'react'
import useTranslations from '../../../hooks/useTranslations'
import SocialGroup from '../social/SocialGroup'
import FooterPartner from './FooterPartner'
import deaplaneta from '../../../assets/images/deaplaneta.svg'
import neox from '../../../assets/images/neox.svg'
import { Link } from 'react-router-dom'
// import { HashLink } from 'react-router-hash-link'
import smoothscroll from 'smoothscroll-polyfill'
import * as routes from '../../../app/routes'

// import { STICKY_HEADER_HEIGHT } from '../../common/ui-constants'

smoothscroll.polyfill()

const Footer = ({ onClickContact }) => {
  const t = useTranslations()
  return (
    <footer className="footer">
      <div className="wrapper">
        <div className="footer-main">
          <div className="footer-links">
            {/*
            <HashLink
              to="/#aboutosl"
              className="footer-link"
              smooth
              scroll={el => {
                // modify the scroll function to account for the offset
                // introduced by the sticky header
                const targetTop =
                  el.getBoundingClientRect().top + window.scrollY
                window.scrollTo({
                  top: targetTop - STICKY_HEADER_HEIGHT,
                  behavior: 'smooth',
                })
              }}
            >
              {t('noun:about')}
            </HashLink>
            */}
            <button onClick={onClickContact} className="footer-link">
              {t('action:contact')}
            </button>
          </div>
          <SocialGroup title="action:follow-us" />
          <div className="footer-partners">
            <FooterPartner
              image={deaplaneta}
              alt="DeAplaneta"
              href="https://www.deaplaneta.com/"
            />
            <FooterPartner
              image={neox}
              alt="Neox Games"
              href="https://neox.atresmedia.com/games/"
              className="neox"
            />
          </div>
        </div>
        <div className="footer-extra">
          <Link to={routes.PRIVACY.path} className="footer-extraLink">
            {t('views:privacy-policy')}
          </Link>
          <Link to={routes.CONDITIONS.path} className="footer-extraLink">
            {t('views:conditions')}
          </Link>
          <Link to={routes.COOKIES.path} className="footer-extraLink">
            {t('views:cookies-policy')}
          </Link>
          <p className="footer-copy">{t('footer:copy')}</p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
