import React from 'react'
import PropTypes from 'prop-types'
import Modal from './Modal'
import ModalLayout from './ModalLayout'
import Input from '../inputs/Input'

const ModalPasswordRecover = ({
  onClose,
  handleSubmit,
  handleBlur,
  handleChange,
  value,
  textError,
}) => {
  return (
    <Modal>
      <ModalLayout
        title="action:restore-password"
        subtitle="message:password-recover"
        contactInfo
        onSubmit={handleSubmit}
        primaryButtonText="action:send-link"
        onClose={onClose}
      >
        <Input
          handleBlur={handleBlur}
          handleChange={handleChange}
          type="email"
          textError={textError}
          textLabel={'input-label:email'}
          value={value}
          name="email"
        />
      </ModalLayout>
    </Modal>
  )
}

ModalPasswordRecover.propTypes = {
  onClose: PropTypes.func,
  handleSubmit: PropTypes.func,
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  value: PropTypes.string,
  textError: PropTypes.string,
}

export default ModalPasswordRecover
