import React from 'react'
import PropTypes from 'prop-types'
import useTranslations from '../../../hooks/useTranslations'

const UserIntro = ({ introText, title, subtitle, dividerText, children }) => {
  const t = useTranslations()
  return (
    <div className="userIntro">
      <div className="wrapper">
        <div className="userIntro-header">
          <p className="stepUp2-text">{t(introText)}</p>
          <h1 className="stepUp4-title">{t(title)}</h1>
          {subtitle ? <p className="userIntro-subtitle">{t(subtitle)}</p> : null}
          <p className="dividerText">{t(dividerText)}</p>
        </div>
        <div className="userIntro-content">
          {children}
        </div>
        <div className="userIntro-footer">
          <h3 className="stepUp2-title">{t('message:train-hard')}</h3>
          <p className="caption">{t('message:have-doubts')} <a className="link" href="mailto:esports@deaplaneta.com">{t('action:contact-plural')}</a></p>
        </div>
      </div>
    </div>
  )
}

UserIntro.propTypes = {
  introText: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  dividerText: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default UserIntro
