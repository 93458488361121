import React from 'react'
import PropTypes from 'prop-types'
import useTranslations from '../../../hooks/useTranslations'

const InfoPiece = ({ image, alt, title, text }) => {
  const t = useTranslations()
  return (
    <div className="infoPiece">
      <div className="rounded-image">
        <img className="infoPiece-image" src={image} alt={alt} />
      </div>
      <p className="infoPiece-title">{t(title)}</p>
      <p className="infoPiece-description">{t(text)}</p>
    </div>
  )
}

InfoPiece.propTypes = {
  image: PropTypes.string,
  alt: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
}

export default InfoPiece
