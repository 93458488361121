import React from 'react'
import PropTypes from 'prop-types'
import Banner from './Banner'
import smEcopa from '../../../assets/images/ecopa-min.png'
import lgEcopa from '../../../assets/images/ecopa@2x-min.png'
import useTranslations from '../../../hooks/useTranslations'
import Button from '../buttons/Button'
import { Link } from 'react-router-dom'

const AboutEcopa = ({
  showInscriptionButton,
  inscriptionButtonTo,
  isFinishedEcopa,
  isTimeless,
}) => {
  const t = useTranslations()

  return (
    <Banner
      className="banner--ecopa"
      smImg={smEcopa}
      lgImg={lgEcopa}
      smWidth="863w"
      lgWidth="1766w"
      alt="ecopa"
      hasRightImage
      id="aboutecopa"
    >
      <h2 className="stepUp5-title">{t('message:about-ecopa-title')}</h2>
      <p className="stepUp2-text">
        {isTimeless
          ? t('message:about-ecopa-timeless', {
              strong: text => <strong>{text}</strong>,
            })
          : t('message:about-ecopa-text', {
              strong: text => <strong>{text}</strong>,
              compete: isFinishedEcopa
                ? t('noun:compete-past')
                : t('noun:compete'),
              fight: isFinishedEcopa ? t('noun:fight-past') : t('noun:fight'),
            })}
      </p>
      {showInscriptionButton ? (
        <Button
          as={Link}
          to={inscriptionButtonTo}
          kind="primary"
          text="action:enroll"
        />
      ) : null}
    </Banner>
  )
}

AboutEcopa.propTypes = {
  showInscriptionButton: PropTypes.bool,
  inscriptionButtonTo: PropTypes.string,
  isFinishedEcopa: PropTypes.bool,
  isTimeless: PropTypes.bool,
}

export default AboutEcopa
