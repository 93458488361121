import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import {
  loginSuccess,
  loginFail,
  closeModal,
  openModal,
} from '../../redux/actions'
import * as Auth from '../../services/auth'
import { resendEmail } from '../../services/user'

import ModalLogin from '../../ui/components/modals/ModalLogin'
import ErrorCard from '../../ui/components/cards/ErrorCard'
import ButtonLink from '../../ui/components/links/ButtonLink'

import { loginSchema } from '../validation/schemas'
import * as routes from '../routes'
import { MODAL_RECOVER } from './constants'
import useTranslations from '../../hooks/useTranslations'

const LoginErrorBuilder = ({email, status = 500}) => {
  const t = useTranslations()
  const history = useHistory()
  const handleResendEmail = async () => {
    await resendEmail({
      emailType: 'just-signup',
      email: email
    })
    history.push(routes.LOGIN.path)
  }
  const GenericError = () => (
    <ErrorCard textTitle="error:login-failed-title">
      <p className="errorCard-description">
        {t('error:login-failed-description')}
      </p>
    </ErrorCard>
  )
  const SignupConfirmationError = () => (
    <ErrorCard textTitle="error:login-failed-title">
      <p className="errorCard-description">
        {t('error:account-not-confirmed')}
      </p>
      <p>
        <ButtonLink as={'a'} className="errorCard-cta" text={'action:resend-link'} onClick={handleResendEmail} />
      </p>
    </ErrorCard>
  )
  const errorsMap = {
    500: <GenericError/>,
    404: <SignupConfirmationError/>,
    403: <SignupConfirmationError/>,
    401: <GenericError/>
  }
  return errorsMap[status] || null
}

export default props => {
  const [loginStatus, setLoginStatus] = useState(null)
  const { email } = useSelector(state => state.user)
  const history = useHistory()
  const dispatch = useDispatch()

  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)
  const token = searchParams.get('token')
  const isInscription = searchParams.get('is-inscription')

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object(loginSchema),
    onSubmit: async values => {
      try {
        const response = await Auth.login({ ...values, token })
        dispatch(loginSuccess(response))
      } catch (err) {
        setLoginStatus(err.response.status)
        dispatch(loginFail())
      }
    },
  })

  useEffect(() => {
    if (email) {
      if (token && !isInscription) {
        history.push(routes.USER_PROFILE_EDIT.path)
      }
      dispatch(closeModal())
    }
  }, [dispatch, email, history, isInscription, token])

  const handleClose = () => dispatch(closeModal())
  const handleGoToRecover = () => {
    dispatch(openModal(MODAL_RECOVER))
  }

  return (
    <ModalLogin
      onClose={handleClose}
      onSubmit={formik.handleSubmit}
      onGoToRecover={handleGoToRecover}
      handleBlur={formik.handleBlur}
      handleChange={formik.handleChange}
      emailError={
        formik.errors.email && formik.touched.email ? formik.errors.email : ''
      }
      emailValue={formik.values.email}
      passwordError={
        formik.errors.password && formik.touched.password
          ? formik.errors.password
          : ''
      }
      passwordValue={formik.values.password}
      failedLoginComponent={<LoginErrorBuilder email={formik.values.email} status={loginStatus}/>}
      {...props}
    />
  )
}
