import React from 'react'
import PropTypes from 'prop-types'
import useTranslations from '../../../hooks/useTranslations'

const SimpleNavigation = ({ title, leftAction, rightAction }) => {
  const t = useTranslations()

  return (
    <div className="simpleNav">
      <div className="wrapper">
        {leftAction}
        {title ? <h2 className="simpleNav-title">{t(title)}</h2> : null}
        {rightAction}
      </div>
    </div>
  )
}

SimpleNavigation.propTypes = {
  title: PropTypes.string.isRequired,
  leftAction: PropTypes.node,
  rightAction: PropTypes.node,
}

export default SimpleNavigation
