import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { fetchNewsContent, fetchPosts } from '../services/cms'
import { openModal } from '../redux/actions'
import { MODAL_NEWSLETTER } from './modals/constants'

import NewsPageView from '../ui/views/NewsPage'
import ArticleCard from '../ui/components/articles/ArticleCard'
import CtaYoutube from '../ui/components/ctas/CtaYoutube'
import CtaNewsletter from '../ui/components/ctas/CtaNewsletter'

const News = () => {
  const [data, setData] = useState()
  const [cards, setCards] = useState([])
  const [offsetPost, setOffsetPost] = useState(6)
  const [sponsors, setSponsors] = useState({})
  const dispatch = useDispatch()

  const handleClickNewsletter = () => dispatch(openModal(MODAL_NEWSLETTER))

  const addLinkToPosts = posts =>
    posts.map(post => ({
      ...post,
      to: `news/${post.title}`,
    }))

  useEffect(() => {
    async function fetchData() {
      const data = await fetchNewsContent()
      data.outstanding.to = `news/${data.outstanding.title}`
      data.posts = addLinkToPosts(data.posts)

      setData(data)
      setCards(data.posts.slice(3, 6))
      setSponsors(data.sponsors_blocks)
    }
    fetchData()
  }, [])

  const loadMorePosts = async () => {
    const newCards = await fetchPosts(offsetPost + 1, 6)
    setOffsetPost(offsetPost + newCards.length)
    setCards([...cards, ...addLinkToPosts(newCards)])
  }

  const renderCards = () => {
    return cards.map((card, i) => {
      const mod = (i + 1) % 6
      const divMod = ((i + 1) / 6) % 2
      if (mod) {
        return <ArticleCard article={card} />
      }
      return (
        <>
          <ArticleCard article={card} />
          {divMod ? (
            <CtaYoutube />
          ) : (
            <CtaNewsletter
              title="message:find-out-everything"
              handleClick={handleClickNewsletter}
            />
          )}
        </>
      )
    })
  }

  return data ? (
    <NewsPageView
      articleFeatured={{ content: data.outstanding }}
      thumbnails={data.posts.slice(0, 3)}
      handleClickNewsletter={handleClickNewsletter}
      cards={renderCards()}
      onLoadMore={loadMorePosts}
      isLoading={false}
      firstSponsor={sponsors ? sponsors.first : null}
    />
  ) : null
}

export default News
