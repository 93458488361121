import React from 'react'
import PropTypes from 'prop-types'
import HelperCard from '../cards/HelperCard'
import Input from '../inputs/Input'
import useTranslations from '../../../hooks/useTranslations'
import OslPropTypes from '../../../common/osl-prop-types'

const FormRegistrationFiels = ({formInputs}) => {
  const { email, emailConfirmation, username, password, passwordConfirmation } = formInputs
  const t = useTranslations()
  return (
    <>
      <div className="formBase-row">
        <div className="formBase-cell">
          <Input
            handleBlur={email.handleBlur}
            handleChange={email.handleChange}
            type="email"
            textError={email.error}
            textLabel="input-label:email"
            value={email.value}
            name="email"
          />
        </div>
      </div>
      <div className="formBase-row">
        <div className="formBase-cell">
          <Input
            handleBlur={emailConfirmation.handleBlur}
            handleChange={emailConfirmation.handleChange}
            type="email"
            textError={emailConfirmation.error}
            textLabel="input-label:email-confirmation"
            value={emailConfirmation.value}
            name="emailConfirmation"
          />
        </div>
      </div>
      <div className="formBase-row">
        <div className="formBase-cell">
          <Input
            handleBlur={username.handleBlur}
            handleChange={username.handleChange}
            type="text"
            textError={username.error}
            textLabel="input-label:username"
            value={username.value}
            name="username"
            autoComplete="username"
          />
        </div>
        <div className="formBase-cell">
          <HelperCard
            textTitle="helper-card:title"
            textDescription="helper-card:user"
          />
          <p className="helperText">{t('helper-text:username-tips')}</p>
        </div>
      </div>
      <div className="formBase-row">
        <div className="formBase-cell">
          <Input
            handleBlur={password.handleBlur}
            handleChange={password.handleChange}
            type="password"
            textError={password.error}
            textLabel="input-label:password"
            value={password.value}
            name="password"
            autoComplete="new-password"
          />
        </div>
      </div>
      <div className="formBase-row">
        <div className="formBase-cell">
          <Input
            handleBlur={passwordConfirmation.handleBlur}
            handleChange={passwordConfirmation.handleChange}
            type="password"
            textError={passwordConfirmation.error}
            textLabel="input-label:password-confirmation"
            value={passwordConfirmation.value}
            name="passwordConfirmation"
            autoComplete="new-password"
          />
        </div>
        <div className="formBase-cell">
          <p className="helperText">{t('helper-text:password-tips')}</p>
        </div>
      </div>
      <div className="formBase-row">
        <div className="formBase-cell">
          <p className="helperText">
            {t('registration:paragraph01', { b: text => <b>{text}</b> })}
          </p>
          <p className="helperText">{t('registration:paragraph02')}</p>
        </div>
      </div>
    </>
  )
}

FormRegistrationFiels.propTypes = {
  formInputs: PropTypes.shape({
    email: OslPropTypes.inputProps,
    emailConfirmation: OslPropTypes.inputProps,
    username: OslPropTypes.inputProps,
    password: OslPropTypes.inputProps,
    passwordConfirmation: OslPropTypes.inputProps
  }),
}

export default FormRegistrationFiels
