import React from 'react'

import EcopaActiveBracketFull from '../../ui/views/EcopaActiveBracketFull'
import useTournamentInfo from '../../hooks/useTournamentInfo'

import { ECOPA2021_SLUG } from '../constants'

const generateTitle = tournamentName => t =>
  `${t('message:final-phase')} ${tournamentName}`

export default () => {
  const { name, stageWidget } = useTournamentInfo(ECOPA2021_SLUG)

  return (
    <EcopaActiveBracketFull
      title={generateTitle(name)}
      widgetUrl={stageWidget}
    />
  )
}
