import React from 'react'
import PropTypes from 'prop-types'
import useTranslations from '../../../hooks/useTranslations'

const RadioButton = ({ handleChange, textLabel, value, name, checked }) => {
  const t = useTranslations()
  return (
    <div className="radioButton">
      <input
        className="radioButton-input"
        type="radio"
        id={value}
        name={name}
        value={value}
        checked={checked}
        onChange={handleChange}
      />
      <label className="radioButton-label" htmlFor={value}>
        <span className="radioButton-labelText">{t(textLabel)}</span>
      </label>
    </div>
  )
}

RadioButton.propTypes = {
  handleChange: PropTypes.func.isRequired,
  textLabel: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
}

export default RadioButton
