import React from 'react'
import PropTypes from 'prop-types'
import Icon from '../icons/Icon'

const SocialItemButton = ({ onClick, icon, name }) => {
  return (
    <button onClick={onClick} className="socialItem">
      <Icon Component={icon} />
      {name ? <span className="socialItem-name">{name}</span> : null}
    </button>
  )
}

SocialItemButton.propTypes = {
  onClick: PropTypes.func,
  icon: PropTypes.elementType.isRequired,
  name: PropTypes.string,
}

export default SocialItemButton
