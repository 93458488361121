const ENVIRONMENT = process.env.REACT_APP_STAGE || 'development'

export const IS_DEVELOPMENT = ENVIRONMENT === 'development'

export const IS_TEST = ENVIRONMENT === 'test'

export const IS_STAGING = ENVIRONMENT === 'staging'

export const IS_PRODUCTION = ENVIRONMENT === 'production'

const DEV_URL = IS_DEVELOPMENT && 'http://localhost:3000/api'
const STAGING_URL = IS_STAGING && 'https://pre.osldeaplaneta.com/api'
const PRODUCTION_URL = IS_PRODUCTION && 'https://osldeaplaneta.com/api' // definitive
// const PRODUCTION_URL = IS_PRODUCTION && 'https://pro.osldeaplaneta.com/api' // temporal test

const DEV_CMS_URL = IS_DEVELOPMENT && 'http://localhost:1337'
const STAGING_CMS_URL = IS_STAGING && 'https://pre.osldeaplaneta.com/cms'
const PRODUCTION_CMS_URL = IS_PRODUCTION && 'https://osldeaplaneta.com/cms' // definitive
// const PRODUCTION_CMS_URL = IS_PRODUCTION && 'https://pro.osldeaplaneta.com/cms' // temporal test

export const BASE_URL =
  PRODUCTION_URL || STAGING_URL || DEV_URL || 'http://localhost:3000/api'

export const CMS_URL =
  PRODUCTION_CMS_URL ||
  STAGING_CMS_URL ||
  DEV_CMS_URL ||
  'http://localhost:1337'
