import React from 'react'
import PropTypes from 'prop-types'
import Icon from '../icons/Icon'

const SocialItem = ({ href, icon, name }) => {
  return (
    <a
      href={href}
      className="socialItem"
      target="_blank"
      rel="noopener noreferrer"
    >
      <Icon Component={icon} />
      {name ? <span className="socialItem-name">{name}</span> : null}
    </a>
  )
}

SocialItem.propTypes = {
  href: PropTypes.string.isRequired,
  icon: PropTypes.elementType.isRequired,
  name: PropTypes.string,
}

export default SocialItem
