import PropTypes from 'prop-types'
import { REGIONS } from './constants'

const buttonAs = PropTypes.oneOfType([
  PropTypes.object,
  PropTypes.oneOf(['a', 'button']),
])

const navActions = PropTypes.node

const highlightRegions = PropTypes.oneOf(Object.values(REGIONS))

// For react-router (e.g. Link and Redirect components)
const link = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string,
    hash: PropTypes.string,
    state: PropTypes.object,
  }),
  PropTypes.func,
])

const commonPlayerProps = {
  to: PropTypes.string,
  avatar: PropTypes.string,
  platformName: PropTypes.string,
  userName: PropTypes.string,
}

const chipProps = {
  chipName: PropTypes.string,
  chipKind: PropTypes.oneOf(['fail', 'success']),
}

const playerStatusProps = {
  console: PropTypes.string,
  level: PropTypes.string,
  division: PropTypes.string,
  federation: PropTypes.string,
}

const playerTopItems = PropTypes.arrayOf(
  PropTypes.shape({
    ...commonPlayerProps,
    value: PropTypes.string,
  }),
)

const playerResultItems = PropTypes.arrayOf(
  PropTypes.shape({
    ...commonPlayerProps,
    ...chipProps,
    result: PropTypes.string,
  }),
)

const playerStatusItems = PropTypes.arrayOf(
  PropTypes.shape({
    ...commonPlayerProps,
    ...playerStatusProps,
    ...chipProps,
  }),
)

const userDropItems = PropTypes.arrayOf(
  PropTypes.shape({
    name: PropTypes.string,
    to: PropTypes.string,
    as: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.oneOf(['a', 'button']),
    ]),
    className: PropTypes.string,
    onClick: PropTypes.func,
    icon: PropTypes.elementType,
  }),
)

const navDropItems = PropTypes.arrayOf(
  PropTypes.shape({
    name: PropTypes.string,
    as: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.oneOf(['a', 'button']),
    ]),
    onClick: PropTypes.func,
  }),
)

const inputProps = PropTypes.shape({
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  isDisabled: PropTypes.bool,
  error: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
})

const selectProps = PropTypes.shape({
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.func,
    }),
  ),
  isDisabled: PropTypes.bool,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  textLabel: PropTypes.string,
  value: PropTypes.string,
  textError: PropTypes.string,
})

const checkboxProps = PropTypes.shape({
  handleChange: PropTypes.func,
  checked: PropTypes.bool,
  name: PropTypes.string,
  isDisabled: PropTypes.bool,
  error: PropTypes.string,
})

const resultRoundProps = PropTypes.shape({
  goalsGamer1: { ...inputProps },
  goalsGamer2: { ...inputProps },
  showError: PropTypes.bool,
  onClick: PropTypes.func,
  isDisabled: PropTypes.bool,
})

const buttonProps = PropTypes.shape({
  onClick: PropTypes.func,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  isSuccess: PropTypes.bool,
})

const socialProps = PropTypes.arrayOf(
  PropTypes.shape({
    sharedIconName: PropTypes.elementType,
    icon: PropTypes.elementType,
    href: PropTypes.string,
  }),
)

const finalistProps = PropTypes.shape({
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  image: PropTypes.string,
  to: PropTypes.string,
  userName: PropTypes.string,
  platformName: PropTypes.string,
  consoleId: PropTypes.string,
  social: { ...socialProps },
})

const participantProps = PropTypes.shape({
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  textError: PropTypes.string,
})

const errorsType = PropTypes.arrayOf(PropTypes.string)

const socialListProps = PropTypes.arrayOf(
  PropTypes.shape({
    hasLink: PropTypes.bool,
    to: PropTypes.string,
    icon: PropTypes.elementType,
    username: PropTypes.string,
  }),
)

const profileProps = PropTypes.shape({
  avatar: PropTypes.string,
  title: PropTypes.string,
  name: PropTypes.string,
  subtitle: PropTypes.string,
  birthDate: PropTypes.string,
  age: PropTypes.string,
  region: PropTypes.string,
  federation: PropTypes.string,
})

const sponsorProps = PropTypes.shape({
  created_at: PropTypes.string,
  id: PropTypes.number,
  images: PropTypes.shape({
    banner_desktop: PropTypes.object,
    banner_mobile: PropTypes.object,
    cloud: PropTypes.object,
  }),
  name: PropTypes.string,
  published_at: PropTypes.string,
  updated_at: PropTypes.string,
})

const sponsorsProps = PropTypes.arrayOf(sponsorProps)

const OslPropTypes = {
  buttonAs,
  navActions,
  link,
  highlightRegions,
  playerTopItems,
  playerResultItems,
  playerStatusItems,
  commonPlayerProps,
  chipProps,
  playerStatusProps,
  userDropItems,
  navDropItems,
  inputProps,
  resultRoundProps,
  buttonProps,
  socialProps,
  finalistProps,
  participantProps,
  errorsType,
  socialListProps,
  checkboxProps,
  profileProps,
  selectProps,
  sponsorProps,
  sponsorsProps,
}

export default OslPropTypes
