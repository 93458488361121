import React from 'react'
import PropTypes from 'prop-types'
import useTranslations from '../../../hooks/useTranslations'
import board from '../../../assets/images/board.svg'
import playoff from '../../../assets/images/playoff.svg'
import trophy from '../../../assets/images/trophy.svg'
import InfoPiece from '../info-piece/InfoPiece'
import Button from '../buttons/Button'
import CompetitionSteps from '../steps/CompetitionSteps'
import { ReactComponent as IconDownload } from '../../../assets/icons/icon-download.svg'
import regulation from '../../../assets/docs/regamento-ecopa-rfef-2021.pdf'
import cx from 'classnames'

const CompetitionFormat = ({ isDark, id, hasSteps }) => {
  const t = useTranslations()
  return (
    <div className={cx('brick', { 'is-dark': isDark })} id={id}>
      <h1 className="stepUp5-title">{t('message:competition-format-title')}</h1>
      {hasSteps ? <CompetitionSteps /> : null}
      <div className="infoPiece-group">
        <InfoPiece
          image={board}
          alt="board"
          title="message:team-title"
          text="message:team-text"
        />
        <InfoPiece
          image={playoff}
          alt="playoff"
          title="message:playoff-title"
          text="message:playoff-text"
        />
        <InfoPiece
          image={trophy}
          alt="trophy"
          title="noun:champion-title"
          text="message:champion-text"
        />
      </div>
      <div className="brick-actions">
        <Button
          as="a"
          href={regulation}
          kind="secondary"
          text="action:regulation-download"
          IconComponent={IconDownload}
          download
          isLight={!isDark}
        />
      </div>
    </div>
  )
}

CompetitionFormat.propTypes = {
  isDark: PropTypes.bool,
  id: PropTypes.string,
  hasSteps: PropTypes.bool,
}

export default CompetitionFormat
