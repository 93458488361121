import React from 'react'
import PropTypes from 'prop-types'
import print from '../../../assets/images/print-yellow.svg'
import smImg from '../../../assets/images/players-sm-min.png'
import mdImg from '../../../assets/images/players-min.png'
import lgImg from '../../../assets/images/players@2x-min.png'
import cx from 'classnames'
import useTranslations from '../../../hooks/useTranslations'

const Hero = ({ children, className, hasRibbon }) => {
  const t = useTranslations()
  return (
    <div className={cx('hero', { [className]: className })}>
      <img className="print" src={print} alt="Huella OSL" />
      {hasRibbon ? (
        <div className="hero-ribbon">
          <p className="hero-ribbonText">{t('noun:finished-competition')}</p>
        </div>
      ) : null}
      <div className="wrapper">
        <div className="hero-image">
          <img
            className="image"
            src={smImg}
            srcSet={`${smImg} 500w, ${mdImg} 588w, ${lgImg} 1176w`}
            alt="players"
          />
        </div>
        <div className="hero-info">{children}</div>
      </div>
    </div>
  )
}

Hero.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  hasRibbon: PropTypes.bool,
}

export default Hero
