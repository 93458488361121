import React from 'react'
import PropTypes from 'prop-types'
import Button from '../buttons/Button'
import User from '../user/User'
import { Link } from 'react-router-dom'
import * as routes from '../../../app/routes'

const UserNavbarActions = ({
  showInscriptionButton,
  name,
  avatar,
  dropItems,
}) => {
  return (
    <>
      {showInscriptionButton ? (
        <Button
          as={Link}
          to={routes.ECOPA_INSCRIPTION.path}
          kind="primary"
          text="action:inscribe-ecopa"
          isSmall
        />
      ) : null}
      <User name={name} avatar={avatar} dropItems={dropItems} />
    </>
  )
}

UserNavbarActions.propTypes = {
  showInscriptionButton: PropTypes.bool,
  name: PropTypes.string,
  avatar: PropTypes.string,
  dropItems: User.propTypes.dropItems,
  closeSession: PropTypes.func,
}

export default UserNavbarActions
