import React from 'react'
import PropTypes from 'prop-types'
import useTranslations from '../../../hooks/useTranslations'
import Button from '../buttons/Button'
import InfoCard from '../cards/InfoCard'
import { Link } from 'react-router-dom'

const CompetitionCalendar = ({
  showInscriptionButton,
  inscriptionButtonTo,
}) => {
  const t = useTranslations()
  return (
    <div className="brick is-dark" id="calendar">
      <div className="wrapper">
        <h1 className="stepUp5-title">
          {t('message:competition-calendar-title')}
        </h1>
        <div className="infoCard-group">
          <InfoCard
            title="calendar:phase-1-title"
            subtitle="calendar:phase-1-subtitle"
            date="calendar:phase-1-date"
            description="calendar:phase-1-text"
          />
          <InfoCard
            title="calendar:phase-2-title"
            subtitle="calendar:phase-2-subtitle"
            date="calendar:phase-2-date"
            description="calendar:phase-2-text"
          />
        </div>
        {showInscriptionButton ? (
          <div className="brick-actions">
            <Button
              as={Link}
              to={inscriptionButtonTo}
              kind="primary"
              text="action:enroll"
            />
          </div>
        ) : null}
      </div>
    </div>
  )
}

CompetitionCalendar.propTypes = {
  showInscriptionButton: PropTypes.bool,
}

export default CompetitionCalendar
