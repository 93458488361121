import manuel from '../assets/images/gt/manuel-rodriguez.png'
import fernando from '../assets/images/gt/fernando-perpi-n.png'
import carlos from '../assets/images/gt/carlos-dembilio.png'
import { SOCIAL } from '../common/constants'

const video = 'https://www.youtube.com/embed/XNTXhUVMmTk'

const winners = [
  {
    image: manuel,
    name: 'Manuel Rodriguez',
    position: 'noun:first-classified',
    social: [
      {
        sharedIconName: SOCIAL.TWITTER,
        href: 'https://twitter.com/ManuRodry94',
      },
      {
        sharedIconName: SOCIAL.INSTAGRAM,
        href: 'https://www.instagram.com/manurodry94/',
      },
    ],
  },
  {
    image: fernando,
    name: 'Fernando Perpiñán',
    position: 'noun:second-classified',
    social: [
      {
        sharedIconName: SOCIAL.TWITTER,
        href: 'https://twitter.com/_PERPI',
      },
      {
        sharedIconName: SOCIAL.INSTAGRAM,
        href: 'https://www.instagram.com/perpisubiie__/',
      },
    ],
  },
  {
    image: carlos,
    name: 'Carlos Dembilio',
    position: 'noun:third-classified',
    social: [
      {
        sharedIconName: SOCIAL.TWITTER,
        href: 'https://twitter.com/cdembi23',
      },
      {
        sharedIconName: SOCIAL.INSTAGRAM,
        href: 'https://www.instagram.com/carlosdembilio23/',
      },
    ],
  },
]

export { video, winners }