import React from 'react'
import PropTypes from 'prop-types'
import AboutEcopa from '../components/banners/AboutEcopa'
import CompetitionFormat from '../components/sections/CompetitionFormat'
import CompetitionCalendar from '../components/sections/CompetitionCalendar'
import CtaContact from '../components/ctas/CtaContact'
import EmptyHeader from '../components/utils/EmptyHeader'

const EcopaKnowMore = ({
  showInscriptionButton,
  inscriptionButtonTo,
  handleClickContact,
}) => {
  return (
    <>
      <EmptyHeader withBar />
      <AboutEcopa
        isTimeless
        showInscriptionButton={showInscriptionButton}
        isFinishedEcopa={false}
        inscriptionButtonTo={inscriptionButtonTo}
      />
      <CompetitionFormat id="format" hasSteps />
      <CompetitionCalendar showInscriptionButton={showInscriptionButton} />
      <CtaContact handleClick={handleClickContact} />
    </>
  )
}

EcopaKnowMore.propTypes = {
  showInscriptionButton: PropTypes.bool,
  children: PropTypes.node,
  inscriptionButtonTo: PropTypes.string,
  handleClickContact: PropTypes.func,
}

export default EcopaKnowMore
