import React from 'react'
import CompetitionStep from '../steps/CompetitionStep'
import StepInfoPiece from '../steps/StepInfoPiece'
import Blockquote from '../blockquote/Blockquote'
import useTranslations from '../../../hooks/useTranslations'

const CompetitionStep1 = () => {
  const t = useTranslations()

  return (
    <CompetitionStep number={1} title="competition:step1-title">
      <p className="step-text">{t('competition:step1-text1')}</p>
      <StepInfoPiece
        label="competition:step1-label1"
        value="competition:step1-value1"
      />
      <StepInfoPiece
        label="competition:step1-label2"
        value="competition:step1-value2"
      />
      <StepInfoPiece
        label="competition:step1-label3"
        value="competition:step1-value3"
      />
      <StepInfoPiece
        label="competition:step1-label4"
        value="competition:step1-value4"
      />
      <StepInfoPiece
        label="competition:step1-label5"
        value="competition:step1-value5"
      />
      <Blockquote text="competition:step1-cite1" />
    </CompetitionStep>
  )
}

export default CompetitionStep1
