import axios from 'axios'

import { BASE_URL } from '../constants'

const PATHPART = '/user'

const errorHandler = error => {
  console.log('Error: ', error.toJSON())
  return error.response
}

const userApi = axios.create()

userApi.interceptors.response.use(
  response => response.data,
  error => errorHandler(error),
)

export const fetchProfile = async username =>
  await userApi.get(`${BASE_URL}${PATHPART}/${username}`, {
    withCredentials: false,
  })

export const fetchPrivateProfile = async () =>
  await userApi.get(`${BASE_URL}${PATHPART}/me`, {
    withCredentials: false,
  })

export const submitUpdateProfile = async formData =>
  await userApi.put(`${BASE_URL}${PATHPART}/me`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    withCredentials: false,
  })

export const changePassword = async formData =>
  await userApi.put(`${BASE_URL}${PATHPART}/me/password`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    withCredentials: false,
  })

export const submitSubscription = async subscription =>
  await userApi.post(`${BASE_URL}${PATHPART}/subscribe`, subscription, {
    withCredentials: false,
  })

export const submitContact = async contact =>
  await userApi.post(`${BASE_URL}${PATHPART}/contact`, contact, {
    withCredentials: false,
  })

export const resendEmail = async ({ email, emailType, tournament }) => {
  return await axios.post(`${BASE_URL}${PATHPART}/resend-email`, {
    emailType,
    email,
    tournament,
  })
}
