import React from 'react'
import PropTypes from 'prop-types'
import MediaQuery from 'react-responsive'

import useTranslations from '../../../hooks/useTranslations'
import { SIZEXL } from '../../common/ui-constants'
import MobileSubmenu from './MobileSubmenu'
import DesktopSubmenu from './DesktopSubmenu'

const NavbarLinkWithSubmenu = ({ item }) => {
  const t = useTranslations()
  const title = t(item.name)

  return(
  <>
    <MediaQuery maxWidth={SIZEXL - 1}>
      <MobileSubmenu title={title} dropItems={item.dropItems} />
    </MediaQuery>

    <MediaQuery minWidth={SIZEXL}>
      <DesktopSubmenu title={title} dropItems={item.dropItems} />
    </MediaQuery>
  </>
)}

NavbarLinkWithSubmenu.propTypes = {
  item: PropTypes.object.isRequired,
}

export default NavbarLinkWithSubmenu


