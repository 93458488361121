import React from 'react'
import OslPropTypes from '../../../common/osl-prop-types'
import Portrait from '../portraits/Portrait'
import leftPrint from '../../../assets/images/print-champions-left.svg'
import rightPrint from '../../../assets/images/print-champions-right.svg'

const Champions = ({ champion, subchampion }) => {
  return (
    <div className="champions">
      <img className="left-print" src={leftPrint} alt="print OSL Deaplaneta" />
      <div className="wrapper">
        <div className="champions-row">
          <Portrait
            isBig
            title="noun:champion"
            playerTo={champion.to}
            playerImg={champion.image}
            userName={champion.userName}
            consoleId={champion.platformName}
            social={champion.social}
          />
        </div>
        <div className="champions-row">
          <Portrait
            title="noun:subchampion"
            playerTo={subchampion.to}
            playerImg={subchampion.image}
            userName={subchampion.userName}
            consoleId={subchampion.platformName}
            social={subchampion.social}
          />
        </div>
      </div>
      <img
        className="right-print"
        src={rightPrint}
        alt="print OSL Deaplaneta"
      />
    </div>
  )
}

Champions.propTypes = {
  champion: OslPropTypes.finalistProps,
  subchampion: OslPropTypes.finalistProps,
}

export default Champions
