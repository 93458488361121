import React from 'react'
import PropTypes from 'prop-types'
import PrintYellow from '../../../assets/images/print-yellow.svg'
import PrintBlue from '../../../assets/images/print-blue.svg'
import PrintBlue2 from '../../../assets/images/print-blue2.svg'
import PrintGreen from '../../../assets/images/print-green.svg'
import PrintPink from '../../../assets/images/print-pink.svg'
import PrintPink2 from '../../../assets/images/print-pink2.svg'
import PrintPink3 from '../../../assets/images/print-pink-single-right.svg'
import useTranslations from '../../../hooks/useTranslations'
import Button from '../buttons/Button'
import cx from 'classnames'
import OslPropTypes from '../../../common/osl-prop-types'

const Cta = ({
  className,
  print,
  buttonAs,
  buttonTo,
  buttonHref,
  buttonKind,
  buttonText,
  title,
  imgAlt,
  id,
  smImg,
  lgImg,
  lgWidth,
  smWidth,
  onClick,
  message,
}) => {
  const t = useTranslations()

  const classes = cx('cta', {
    [className]: className,
  })

  const selectPrint = propValue => {
    switch (propValue) {
      case 'yellow':
        return PrintYellow
      case 'blue':
        return PrintBlue
      case 'blue2':
        return PrintBlue2
      case 'pink':
        return PrintPink
      case 'pink2':
        return PrintPink2
      case 'pink3':
        return PrintPink3
      default:
        return PrintGreen
    }
  }

  return (
    <div className={classes} id={id}>
      {print ? (
        <img className="print" src={selectPrint(print)} alt="Huella OSL" />
      ) : null}
      {smImg ? (
        <div className="cta-bg">
          <img
            className="bg-image"
            src={smImg}
            srcSet={`${smImg} ${smWidth}, ${lgImg} ${lgWidth}`}
            alt={imgAlt}
          />
        </div>
      ) : null}
      <div className="wrapper">
        <h2 className="stepUp5-title">{t(title)}</h2>
        <Button
          as={buttonAs}
          href={buttonHref}
          to={buttonTo}
          kind={buttonKind}
          text={buttonText}
          onClick={onClick}
          target="_blank"
          rel="noopener noreferrer"
        />
        {message ? <p className="cta-message">{t(message)}</p> : null}
      </div>
    </div>
  )
}

Cta.propTypes = {
  className: PropTypes.string,
  print: PropTypes.oneOf([
    'yellow',
    'blue',
    'blue2',
    'green',
    'pink',
    'pink2',
    'pink3',
  ]),
  buttonText: Button.propTypes.text,
  buttonKind: Button.propTypes.kind,
  buttonHref: PropTypes.string,
  buttonAs: OslPropTypes.buttonAs,
  buttonTo: PropTypes.string,
  title: PropTypes.string,
  imgAlt: PropTypes.string,
  is: PropTypes.string,
  smImg: PropTypes.string,
  lgImg: PropTypes.string,
  smWidth: PropTypes.string,
  lgWidth: PropTypes.string,
  onClick: PropTypes.func,
  message: PropTypes.string,
}

export default Cta
