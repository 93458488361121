import React, { useEffect, useState } from 'react'
import Countdown from '../../ui/components/countdown/Countdown'
import CountdownItem from '../../ui/components/countdown/CountdownItem'

const MILLIS_PER_SECOND = 1000
const SECONDS_PER_MINUTE = 60
const MINUTES_PER_HOUR = 60
const HOURS_PER_DAY = 24
const MILLIS_PER_MINUTE = MILLIS_PER_SECOND * SECONDS_PER_MINUTE
const MILLIS_PER_HOUR = MILLIS_PER_MINUTE * MINUTES_PER_HOUR
const MILLIS_PER_DAY = MILLIS_PER_HOUR * HOURS_PER_DAY

const CountdownSection = ({ deadline }) => {
  const [currentTime, setCurrentTime] = useState(new Date())

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date())
    }, MILLIS_PER_MINUTE)
    return () => clearTimeout(timer)
  }, [setCurrentTime])

  const deltaTime = deadline - currentTime

  const daysLeft = Math.floor(deltaTime / MILLIS_PER_DAY)
  const hoursLeft = Math.floor((deltaTime / MILLIS_PER_HOUR) % HOURS_PER_DAY)
  const minsLeft = Math.floor(
    (deltaTime / MILLIS_PER_MINUTE) % MINUTES_PER_HOUR,
  )

  return (
    <div className="countdown-wrapper">
      <Countdown
        topLabel="message:remaining-hours"
        bottomLabel="message:ecopa-start"
      >
        <CountdownItem label="countdown:days" value={daysLeft} />
        <CountdownItem label="countdown:hours" value={hoursLeft} />
        <CountdownItem label="countdown:mins" value={minsLeft} />
      </Countdown>
    </div>
  )
}

export default CountdownSection
