import React from 'react'
import CompetitionStep from '../steps/CompetitionStep'
import useTranslations from '../../../hooks/useTranslations'

const CompetitionStep2 = () => {
  const t = useTranslations()

  return (
    <CompetitionStep number={2} title="competition:step2-title">
      <p className="step-text">{t('competition:step2-text1')}</p>
      <p className="step-text">{t('competition:step2-text2')}</p>
    </CompetitionStep>
  )
}

export default CompetitionStep2
