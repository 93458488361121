import React from 'react'
import PropTypes from 'prop-types'
import useTranslations from '../../../hooks/useTranslations'
import ErrorCard from './ErrorCard'

const RequestErrorCard = ({ textTitle }) => {
  const t = useTranslations()

  return (
    <ErrorCard textTitle={textTitle}>
      <p className="errorCard-description">
        {t('error:api-call-failed-description', {
          contact: (
            <a className="link" href="mailto:mailto:esports@deaplaneta.com">
              {t('action:contact-plural')}
            </a>
          ),
        })}
      </p>
    </ErrorCard>
  )
}

RequestErrorCard.propTypes = {
  textTitle: PropTypes.string.isRequired,
}

export default RequestErrorCard
