import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import heroImage from '../../assets/images/copa-rfef.svg'
import Hero from '../../ui/components/hero/Hero'
import HeroContentP1 from '../../ui/components/hero/HeroContentP1'
import CtaLastEcopa from '../../ui/components/ctas/CtaLastEcopa'
import AboutEcopa from '../../ui/components/banners/AboutEcopa'
import CtaContact from '../../ui/components/ctas/CtaContact'
import CtaNewsletter from '../../ui/components/ctas/CtaNewsletter'
import CompetitionFormat from '../../ui/components/sections/CompetitionFormat'
import CompetitionCalendar from '../../ui/components/sections/CompetitionCalendar'
import FinalPhase from '../../ui/components/sections/FinalPhase'
import CounterBanner from '../../ui/components/banners/CounterBanner'
import SummaryTiles from '../../ui/components/summary-tiles/SummaryTiles'
import ChartsBannerDivisionalP1 from '../../ui/components/charts/ChartsBannerDivisionalP1'
import EcopaLayout from '../../ui/layout/EcopaLayout'

import { setupI18n } from '../../libs/i18n'
import { fetchTournamentTotalRegistrants } from '../../redux/actions'
import useOutstandingPost from '../../hooks/useOutstandingPost'
import useTranslations from '../../hooks/useTranslations'
import useSubtournamentsList from '../../hooks/useSubtournamentsList'
import useTournamentInfo, {
  STATUS_FUTURE,
  STATUS_INSCRIPTIONS_OPEN,
  STATUS_TO_BE_STARTED,
  STATUS_ACTIVE,
  STATUS_FINISHED,
} from '../../hooks/useTournamentInfo'
import { SUMMARY_TILES_ECOPA } from '../../common/constants'
import getFormattedDeadline from '../../common/get-formatted-deadline'
import { ECOPA2021_SLUG } from '../constants'
import * as routes from '../routes'
import CountdownSection from './CountdownSection'
import EcopaFinishedView from './EcopaFinishedView'

const STAGE_FINAL = 'final'

const formatCaptionHero = (t, message, date) => {
  const formatOptions = {
    dateStyle: 'long',
  }
  const localeDate = new Date(date).toLocaleDateString(undefined, formatOptions)

  return t(message, {
    date: <strong>{localeDate}</strong>,
  })
}

const generateCaptionHero = (
  t,
  inscriptionOpeningDate,
  inscriptionDeadline,
  now,
) => {
  if (now > inscriptionDeadline) return t('message:hero-finished-time')
  if (now > inscriptionOpeningDate)
    return formatCaptionHero(
      t,
      'message:hero-remaining-time',
      inscriptionDeadline,
    )
  return formatCaptionHero(
    t,
    'message:hero-opening-time',
    inscriptionOpeningDate,
  )
}

const getSummaryItems = (deadline, locale, t) => {
  const INSCRIPTION_DEADLINE_INDEX = 5
  let summaryItems = SUMMARY_TILES_ECOPA
  summaryItems[INSCRIPTION_DEADLINE_INDEX].description = getFormattedDeadline(
    deadline,
    locale,
    t,
  )
  return summaryItems
}

const subtournamentToDivisionEntry = subtournament => ({
  label: subtournament.division,
  to: routes.ECOPA_DIVISION_SUBSECTION.linkTo({
    subtournamentId: subtournament.id,
  }),
})

const inscriptionButtonTo = routes.ECOPA_INSCRIPTION.path
const knowmoreButtonTo = routes.ECOPA_KNOWMORE.path

export default ({
  isEnrolled,
  isLogged,
  onClickNewsletter,
  onClickContact,
}) => {
  const t = useTranslations()
  const { locale } = setupI18n()
  const dispatch = useDispatch()
  const outstandingPost = useOutstandingPost()

  const {
    status: ecopaStatus,
    stage: ecopaStage,
    inscriptionOpeningDate,
    inscriptionDeadline,
    tournamentStartingDate,
    stageWidget: finalWidgetUrl,
  } = useTournamentInfo(ECOPA2021_SLUG)

  const totalRegistrants = useSelector(
    state => state.tournaments.registrants[ECOPA2021_SLUG],
  )
  useEffect(() => {
    if (totalRegistrants === undefined)
      dispatch(fetchTournamentTotalRegistrants(ECOPA2021_SLUG))
  }, [totalRegistrants, dispatch])

  const subtournamentsList = useSubtournamentsList(ECOPA2021_SLUG)

  const amateurDivisions = subtournamentsList
    .filter(({ isVerified }) => !isVerified)
    .map(subtournamentToDivisionEntry)
  const verifiedDivisions = subtournamentsList
    .filter(({ isVerified }) => isVerified)
    .map(subtournamentToDivisionEntry)

  const showInscriptionButton =
    ecopaStatus === STATUS_INSCRIPTIONS_OPEN && !isEnrolled
  const showEnrolledIntro =
    isLogged && isEnrolled && ecopaStatus === STATUS_INSCRIPTIONS_OPEN
  const showNotEnrolledIntro = false

  return (
    <EcopaLayout
      hasFullSponsor
      isEnrolled={showEnrolledIntro}
      isNotEnrolled={showNotEnrolledIntro}
      outstandingPost={outstandingPost}
    >
      {[STATUS_FUTURE, STATUS_INSCRIPTIONS_OPEN, STATUS_TO_BE_STARTED].includes(
        ecopaStatus,
      ) && (
        <>
          <Hero className="hero-extraInfo">
            <HeroContentP1
              image={heroImage}
              imageAlt="ecopa rfef"
              captionHero={generateCaptionHero(
                t,
                inscriptionOpeningDate,
                inscriptionDeadline,
                Date.now(),
              )}
              buttonText={showInscriptionButton ? 'action:enroll' : null}
              buttonTo={inscriptionButtonTo}
              buttonAs={Link}
              titleHero="message:hero-title"
              subtitleHero="message:hero-text"
            />
          </Hero>
          {tournamentStartingDate && (
            <CountdownSection deadline={tournamentStartingDate} />
          )}
          <CtaLastEcopa />
          <SummaryTiles
            items={getSummaryItems(inscriptionDeadline, locale, t)}
          />
          <AboutEcopa
            showInscriptionButton={showInscriptionButton}
            inscriptionButtonTo={inscriptionButtonTo}
          />
          <CompetitionFormat id="format" hasSteps />
          <CtaNewsletter
            title="message:cta-newsletter-title"
            handleClick={onClickNewsletter}
          />
          {ecopaStatus === STATUS_INSCRIPTIONS_OPEN && totalRegistrants > 0 && (
            <CounterBanner
              title="message:people-inscribed-and-uploading"
              number={totalRegistrants}
            />
          )}
          {ecopaStatus === STATUS_TO_BE_STARTED && (
            <CounterBanner
              title="message:people-inscribed"
              number={totalRegistrants}
            />
          )}
          <CompetitionCalendar
            showInscriptionButton={showInscriptionButton}
            inscriptionButtonTo={inscriptionButtonTo}
          />
          <CtaContact handleClick={onClickContact} />
        </>
      )}
      {ecopaStatus === STATUS_ACTIVE && (
        <>
          <Hero className="hero-extraInfo">
            <HeroContentP1
              image={heroImage}
              imageAlt="ecopa rfef"
              buttonText="action:know-more"
              buttonTo={knowmoreButtonTo}
              buttonAs={Link}
              titleHero="message:hero-title"
              subtitleHero="message:ecopa-explanation"
            />
          </Hero>
          <SummaryTiles
            items={getSummaryItems(inscriptionDeadline, locale, t)}
          />
          {ecopaStage === STAGE_FINAL && (
            <FinalPhase widgetUrl={finalWidgetUrl} />
          )}
          <ChartsBannerDivisionalP1
            verified={verifiedDivisions}
            amateurs={amateurDivisions}
          />
          <CtaNewsletter
            title="message:cta-newsletter-title"
            handleClick={onClickNewsletter}
          />
        </>
      )}
      {ecopaStatus === STATUS_FINISHED && (
        <EcopaFinishedView
          ecopaSlug={ECOPA2021_SLUG}
          summary={getSummaryItems(inscriptionDeadline, locale, t)}
          totalRegistrants={totalRegistrants}
          showInscriptionButton={showInscriptionButton}
          inscriptionButtonTo={inscriptionButtonTo}
          onClickNewsletter={onClickNewsletter}
        />
      )}
    </EcopaLayout>
  )
}
