import React from 'react'
import PropTypes from 'prop-types'
import useTranslations from '../../../hooks/useTranslations'
import PrintOrange from '../../../assets/images/print-orange.svg'
import PrintGreen from '../../../assets/images/print-green2.svg'

const ErrorBanner = ({ print, children, title }) => {
  const t = useTranslations()

  const selectPrint = propValue => {
    switch (propValue) {
      case 'orange':
        return PrintOrange
      case 'green':
        return PrintGreen
      default:
        return PrintOrange
    }
  }

  return (
    <div className="errorBanner">
      <img className="print" src={selectPrint(print)} alt="Huella OSL" />
      <div className="wrapper">
        <h1 className="stepUp6-title">{t(title)}</h1>
        {children}
      </div>
    </div>
  )
}

ErrorBanner.propTypes = {
  title: PropTypes.string,
  print: PropTypes.oneOf(['orange', 'green']),
  children: PropTypes.node,
}

export default ErrorBanner
