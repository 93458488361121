import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { Link as RouterLink } from 'react-router-dom'
import useTranslations from '../../../hooks/useTranslations'

const Link = ({
  isLight,
  text,
  to,
  isExternal,
  kind,
  isDisabled,
  isAnchor,
  ...restProps
}) => {
  const linkClasses = cx(`link link--${kind}`, {
    'is-light': isLight,
    'is-disabled': isDisabled,
  })

  const t = useTranslations()

  return (
    <>
      {isAnchor ? (
        <a
          href={to}
          className={linkClasses}
          target={isExternal ? '_blank' : undefined}
          rel="noopener noreferrer"
          aria-label={t(text)}
        >
          <span className="link-text">{t(text)}</span>
        </a>
      ) : (
        <RouterLink
          to={to}
          className={linkClasses}
          target={isExternal ? '_blank' : undefined}
          aria-label={t(text)}
          {...restProps}>
          <span className="link-text">{t(text)}</span>
        </RouterLink>
      )}
    </>
  )
}

Link.propTypes = {
  isLight: PropTypes.bool,
  isExternal: PropTypes.bool,
  isAnchor: PropTypes.bool,
  isDisabled: PropTypes.bool,
  text: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  kind: PropTypes.oneOf(['primary', 'secondary']).isRequired,
}

export default Link
