import React from 'react'
import PropTypes from 'prop-types'
import useTranslations from '../../../hooks/useTranslations'

const StepInfoPiece = ({ label, value }) => {
  const t = useTranslations()
  return (
    <div className="step-infoPiece">
      <p className="label">{t(label)}</p>
      <p className="value">{t(value)}</p>
    </div>
  )
}

StepInfoPiece.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
}

export default StepInfoPiece
