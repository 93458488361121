import React from 'react'
import PropTypes from 'prop-types'
import useTranslations from '../../../hooks/useTranslations'

const Sponsors = ({ children }) => {
  const t = useTranslations()
  return (
    <div className="sponsors brick is-dark">
      <div className="wrapper">
        <h1 className="stepUp5-title">{t('message:thanks-sponsors')}</h1>
        {children}
      </div>
    </div>
  )
}

Sponsors.propTypes = {
  children: PropTypes.node,
}

export default Sponsors
