import axios from 'axios'

import { BASE_URL } from '../constants'

const PATHPART = '/auth'

export const login = async ({ email, password, token }) => {
  const { data: { user } } = await axios.post(
    `${BASE_URL}${PATHPART}/login`,
    { email, password, token },
    { withCredentials: true },
  )

  return user
}

export const remember = async () => {
  const { data: { user } } = await axios.post(
    `${BASE_URL}${PATHPART}/refresh`,
    {},
    { withCredentials: true },
  )

  return user
}

export const signup = async payload => {
  const { data } = await axios.post(`${BASE_URL}${PATHPART}/signup`, payload, {
    withCredentials: false,
  })

  return data
}

export const logout = async () => {
  return await axios.post(
    `${BASE_URL}${PATHPART}/logout`,
    {},
    { withCredentials: true },
  )
}

export const resetPasswordRequest = async ({ email }) => {
  return await axios.post(
    `${BASE_URL}${PATHPART}/password-reset/request`,
    { email },
    { withCredentials: true },
  )
}

export const resetPassword = async ({ email, token, password }) => {
  return await axios.post(
    `${BASE_URL}${PATHPART}/password-reset`,
    { email, token, password },
    { withCredentials: true },
  )
}
