import React from 'react'
import PropTypes from 'prop-types'
import Cta from './Cta'

const CtaContact = ({ handleClick }) => {
  return (
    <Cta
      buttonAs="button"
      id="contact"
      print="pink"
      title="message:cta-title"
      buttonKind="secondary"
      buttonText="action:contact-plural"
      onClick={handleClick}
    />
  )
}

CtaContact.propTypes = {
  handleClick: PropTypes.func,
}

export default CtaContact
