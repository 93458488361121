import React from 'react'
import { useParams } from 'react-router'

import EcopaActiveBracketP1 from '../../ui/views/EcopaActiveBracketP1'
import useSubtournamentInfo from '../../hooks/useSubtournamentInfo'

import { ECOPA2021_SLUG } from '../constants'
import * as routes from '../routes'

export default ({ onClickNewsletter, onClickContact }) => {
  const { subtournamentId } = useParams()

  const subtournamentInfo = useSubtournamentInfo(
    ECOPA2021_SLUG,
    subtournamentId,
  )

  const { scheduleWidget, division } = subtournamentInfo
  const fullBracketButtonTo = routes.ECOPA_DIVISION_BRACKET.linkTo({
    subtournamentId,
  })

  const players = []

  return (
    <EcopaActiveBracketP1
      title={t => division}
      widgetUrl={scheduleWidget}
      playerTop={players}
      handleClickNewsletter={onClickNewsletter}
      fullBracketButtonTo={fullBracketButtonTo}
    />
  )
}
