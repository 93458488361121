import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { fetchPrivateProfile } from '../services/user'
import * as routes from '../app/routes'

export default function useProfile () {
  const history = useHistory()

  const [profile, setProfile] = useState()

  useEffect(() => {
    let didCancel = false
    const fetchPrivateProfileData = async () => {
      const { success, data } = await fetchPrivateProfile()
      if (success && !didCancel) {
        setProfile(data)
      } else if (!didCancel) {
        history.push(routes.NOT_FOUND_ERROR.path)
      }
    }
    fetchPrivateProfileData()
    return () => {
      didCancel = true
    }
  }, [history])

  return  { profile, setProfile } 
}
