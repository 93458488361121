import React from 'react'
import PropTypes from 'prop-types'
import HeroSimple from '../components/hero/HeroSimple'
import StreamingBlockTga from '../components/integrations/StreamingBlockTga'
import TeamsTga from '../components/teams/TeamsTga'
import ParticipantsTga from '../components/participants/ParticipantsTga'
import CompetitionStepsTga from '../components/steps/CompetitionStepsTga'
import PrizeTga from '../components/banners/PrizeTga'
import BroadcastsTga from '../components/sections/BroadcastsTga'
import print from '../../assets/images/print-tga.svg'
import tga from '../../assets/images/logo-tga.svg'

const Tga = ({ teams, participants, videoTga, outstandingPost }) => {
  return (
    <>
      <HeroSimple
        print={print}
        alt="TGA"
        text="message:turn-into-content-creator"
        logo={tga}
      />
      <StreamingBlockTga title="message:follow-streaming" videoTga={videoTga} />
      <TeamsTga title="message:teams-title" teams={teams} />
      <ParticipantsTga
        title="message:participants-title"
        participants={participants}
      />
      <CompetitionStepsTga />
      <PrizeTga />
      <BroadcastsTga />
    </>
  )
}

Tga.propTypes = {
  youtube: PropTypes.bool,
  teams: TeamsTga.propTypes.teams,
  participants: ParticipantsTga.propTypes.participants,
  videoTga: PropTypes.node,
}

export default Tga
