import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import FormLayout from '../../ui/layout/FormLayout'
import Inscription from '../../ui/views/Inscription'
import * as Tournament from '../../services/tournament'
import { platformOptions } from '../../common/forms/inscription'
import getTranslationKey from '../../common/getTranslationKey'
import { openModal, fetchTournamentInscription } from '../../redux/actions'

import { ECOPA2021_SLUG } from '../constants'
import * as routes from '../routes'

import {
  userInscriptionSchema,
  guestInscriptionSchema,
} from '../validation/schemas'
import { sharedInitialInscriptionValues } from '../validation/initialValues'
import {
  MODAL_LOGIN,
  MODAL_CONFIRM_INSCRIPTION,
  MODAL_CONTACT,
} from '../modals/constants'
import useModal from '../modals/useModal'

export default props => {
  const { isLogged } = props

  const dispatch = useDispatch()
  const modal = useModal()
  const history = useHistory()
  const [errors, setErrors] = useState()

  const { email, username, inscriptions } = useSelector(state => state.user)
  const isEnrolled = Boolean(inscriptions[ECOPA2021_SLUG])

  useEffect(() => {
    if (isEnrolled) {
      history.push(routes.ROOT.path)
    }
  }, [isEnrolled, history])

  const initialValues = isLogged
    ? {
        ...sharedInitialInscriptionValues,
        email,
        username,
      }
    : {
        ...sharedInitialInscriptionValues,
        email,
        username,
        password: '',
      }
  const validationSchema = isLogged
    ? userInscriptionSchema
    : guestInscriptionSchema

  const handleSubmit = async values => {
    const isXboxPlayer = values.platform === platformOptions.valid.xboxOne.value
    const isPS4Player =
      values.platform === platformOptions.valid.playstation4.value

    const inscription = {
      email: values.email,
      username: values.username,
      federation: values.federation.replace('federation:', ''),
      xbox: isXboxPlayer,
      gamerTag: isXboxPlayer ? values.tag : '',
      ps4: isPS4Player,
      psnId: isPS4Player ? values.tag : '',
      verifiedFifaPlayer: values.verifiedFifaPlayer === 'yes',
      idGlobalSeries: values.idGlobalSeries,
      newsletterPlaneta: values.newsletterPlaneta,
      newsletterRFEF: values.newsletterRFEF,
      newsletterFNF: false,
      thirdParties: values.thirdParties,
      privacyPolicy: values.privacyPolicy,
    }

    const registration = {
      password: values.password,
      oslComercial: values.oslComercial,
      oslPlaneta: values.oslPlaneta,
      oslPrivacyPolicy: values.oslPrivacyPolicy,
    }

    try {
      if (isLogged) {
        await Tournament.submitInscription(inscription)
      } else {
        await Tournament.submitRegistrationAndInscription(
          registration,
          inscription,
        )
      }
      dispatch(fetchTournamentInscription(ECOPA2021_SLUG))
      dispatch(openModal(MODAL_CONFIRM_INSCRIPTION))
      history.push({
        pathname: routes.ECOPA_SECTION.path,
        state: { email: values.email, type: 'enroll' },
      })
    } catch (error) {
      const { data } = error.response
      setErrors([getTranslationKey(data.error.id)])
    }
  }

  const handleGoToLogin = () => dispatch(openModal(MODAL_LOGIN))
  const handleClickCancel = () => history.push(routes.ECOPA_SECTION.path)
  const handleClickContact = () => dispatch(openModal(MODAL_CONTACT))

  const inscriptionBannerTitle = !isLogged
    ? 'message:inscription-title'
    : 'message:inscription-logged-title'

  return (
    <FormLayout
      title="noun:inscription"
      onCancel={handleClickCancel}
      onClickContact={handleClickContact}
    >
      <Inscription
        bannerTitle={inscriptionBannerTitle}
        handleGoToLogin={handleGoToLogin}
        handleSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
        errors={errors}
        modalSlot={modal}
        cancelRoute={routes.ECOPA_SECTION.path}
        {...props}
      />
    </FormLayout>
  )
}
