import React, { useCallback, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import logo from '../../../assets/images/logo-osl.svg'
import { Link } from 'react-router-dom'
import NavbarLink from './NavbarLink'
import NavbarLinkWithSubmenu from './NavbarLinkWithSubmenu'
import MediaQuery, { useMediaQuery } from 'react-responsive'
import ButtonIcon from '../buttons/ButtonIcon'
import { ReactComponent as IconMenu } from '../../../assets/icons/icon-menu.svg'
import { ReactComponent as IconClose } from '../../../assets/icons/icon-close.svg'
import cx from 'classnames'
import useTranslations from '../../../hooks/useTranslations'
import useOnClickOutside from '../../../hooks/useOnClickOutside'
import smoothscroll from 'smoothscroll-polyfill'
import { SIZEXL } from '../../common/ui-constants'
import * as routes from '../../../app/routes'

smoothscroll.polyfill()

const MainNavigation = ({ items, children }) => {
  const t = useTranslations()

  const isTabletOrMobile = useMediaQuery({
    query: `(max-width: ${SIZEXL - 1}px)`,
  })

  const ref = useRef()
  const [isOpen, setIsOpen] = useState(false)
  const toggleOpen = useCallback(() => setIsOpen(isOpen => !isOpen), [
    setIsOpen,
  ])
  useOnClickOutside(ref, () => setIsOpen(false))
  const navClasses = cx('mainNav', {
    'is-menu-open': isOpen,
    'is-animated': isTabletOrMobile,
  })

  // NOTE: This is a provisional solution to close the modal when
  // a button is clicked. We should refactor: either manage
  // the isOpen state or include menu actions in this component
  // to avoid this quick solution
  const handleActionClick = e => {
    if (e.target.localName !== 'div') {
      setIsOpen(false)
    }
  }

  return (
    <div className={navClasses}>
      <div className="wrapper">
        <Link
          to={routes.ROOT.path}
          className="logo"
          onClick={() => {
            setIsOpen(false)
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: 'smooth',
            })
          }}
        >
          <img className="logoImage" src={logo} alt="Logo OSL" />
        </Link>
        <div className="mainNav-menu" ref={ref}>
          <MediaQuery maxWidth={SIZEXL - 1}>
            <ButtonIcon
              type="button"
              className="button-close"
              onClick={toggleOpen}
              IconComponent={IconClose}
              aria="close"
            />
          </MediaQuery>
          <nav className="mainNav-list">
            {items.map(item => item.hasSubmenu ? (
              <div className="mainNav-itemWrapper" key={item.name}>
                <NavbarLinkWithSubmenu item={item} />
              </div>
            ) : (
              <div className="mainNav-itemWrapper" key={item.name}>
                <NavbarLink to={item.to ? item.to : ''} toggleOpen={toggleOpen}>
                  {t(item.name)}
                </NavbarLink>
              </div>
            ))}
          </nav>
          <div className="mainNav-actions" onClick={handleActionClick}>
            {children}
          </div>
        </div>
        <MediaQuery maxWidth={SIZEXL - 1}>
          <ButtonIcon
            type="button"
            className="button-menu"
            onClick={toggleOpen}
            IconComponent={IconMenu}
            aria="menu"
          />
        </MediaQuery>
      </div>
    </div>
  )
}

MainNavigation.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string,
      name: PropTypes.string,
      isSelected: PropTypes.bool,
    }),
  ),
  children: PropTypes.node,
}

export default MainNavigation
