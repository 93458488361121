import React from 'react'
import useOutstandingPost from '../hooks/useOutstandingPost'
import Tga from '../ui/views/Tga'
import { teams, participants } from '../data/tga'

const TGA = () => {
  const outstandingPost = useOutstandingPost()
  return (
    <Tga
      teams={teams}
      participants={participants}
      videoTga={
        <iframe
          title="Tga"
          src={`https://player.twitch.tv/?channel=topgamersacademy&parent=${window.location.hostname}`}
          frameBorder="0"
          allowFullScreen={true}
          scrolling="no"
          height="100%"
          width="100%"
        ></iframe>
      }
      outstandingPost={outstandingPost}
    />
  )
}

export default TGA
