import React from 'react'
import PropTypes from 'prop-types'

import * as routes from '../../app/routes'
import IntroBanner from '../components/banners/IntroBanner'
import FormRegistration from '../components/forms/FormRegistration'
import OslPropTypes from '../../common/osl-prop-types'

const Registration = ({
  bannerTitle,
  modalSlot,
  errors,
  handleSubmit,
  formInputs,
  privacyChecks
}) => (
  <>
    <IntroBanner title={bannerTitle} />
    <FormRegistration
      errors={errors}
      handleSubmit={handleSubmit}
      formInputs={formInputs}
      privacyChecks={privacyChecks}
      cancelRoute={routes.ROOT.path}
    />
    {modalSlot}
  </>
)

Registration.propTypes = {
  bannerTitle: PropTypes.string.isRequired,
  modalSlot: PropTypes.node,
  errors: OslPropTypes.errorsType,
  handleSubmit: PropTypes.func,
  formInputs: PropTypes.shape({
    email: OslPropTypes.inputProps,
    emailConfirmation: OslPropTypes.inputProps,
    username: OslPropTypes.inputProps,
    password: OslPropTypes.inputProps,
    passwordConfirmation: OslPropTypes.inputProps
  }),
  privacyChecks: FormRegistration.propTypes.privacyChecks,
}

export default Registration
