import React from 'react'
import PropTypes from 'prop-types'
import VideoWrapper from './VideoWrapper'
import useTranslations from '../../../hooks/useTranslations'

const StreamingBlockTga = ({ title, videoTga }) => {
  const t = useTranslations()
  return (
    <div className="streaming-block">
      <div className="video-wrapper">
        <h3 className="streaming-title">{t(title)}</h3>
        <VideoWrapper video={videoTga} />
        <div className="streaming-info">
          <h2 className="stepUp4-title">{t('message:what-is-tga-title')}</h2>
          <p className="streaming-text">{t('message:what-is-tga-text')}</p>
        </div>
      </div>
    </div>
  )
}

StreamingBlockTga.propTypes = {
  title: PropTypes.string,
  videoTga: PropTypes.node,
}

export default StreamingBlockTga
