import React from 'react'
import PropTypes from 'prop-types'
import Loader from '../loaders/Loader'

const VideoWrapper = ({ video }) => {
  return (
    <div className="iframe-wrapper">
      <div className="loader-panel">
        <Loader color="#964bff" size="100px" />
      </div>
      {video}
    </div>
  )
}

VideoWrapper.propTypes = {
  iframe: PropTypes.node,
}

export default VideoWrapper
