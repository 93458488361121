import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import useTranslations from '../../hooks/useTranslations'
import * as Auth from '../../services/auth'
import ModalPasswordRecover from '../../ui/components/modals/ModalPasswordRecover'
import { closeModal, openModal } from '../../redux/actions'

import { MODAL_RECOVER_SENT } from './constants'

export default props => {
  const [redirect, setRedirect] = useState(false)
  const dispatch = useDispatch()
  const t = useTranslations()
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email(t('validation-error:email-format'))
        .required(t('validation-error:field-required')),
    }),
    onSubmit: async value => {
      try {
        await Auth.resetPasswordRequest(value)
        setRedirect(true)
      } catch (err) {
        console.info('error => ', err)
      }
    },
  })

  useEffect(() => {
    if (redirect) {
      dispatch(openModal(MODAL_RECOVER_SENT))
    }
  }, [redirect, dispatch])

  const handleClose = () => dispatch(closeModal())

  return (
    <ModalPasswordRecover
      onClose={handleClose}
      handleSubmit={formik.handleSubmit}
      handleBlur={formik.handleBlur}
      handleChange={formik.handleChange}
      value={formik.values.email}
      textError={
        formik.errors.email && formik.touched.email ? formik.errors.email : ''
      }
      {...props}
    />
  )
}
