import { SOCIAL, PLATFORM, FULL_SERVICE_NAME } from '../constants'

export const socialMediaOptions = {
  valid: Object.values(SOCIAL).map(slug => ({
    value: slug,
    label: () => FULL_SERVICE_NAME[slug],
  })),
}

export const gamingPlatformsOptions = {
  valid: Object.values(PLATFORM).map(slug => ({
    value: slug,
    label: () => FULL_SERVICE_NAME[slug],
  })),
}

export const requiredLinkServices = [
  SOCIAL.FACEBOOK,
  SOCIAL.YOUTUBE,
  PLATFORM.STEAM,
]

export const requiredHandleServices = [
  SOCIAL.INSTAGRAM,
  SOCIAL.TWITTER,
  SOCIAL.TWITCH,
  SOCIAL.DISCORD,
  SOCIAL.TIKTOK,
  PLATFORM.PLAYSTATION,
  PLATFORM.XBOX,
  PLATFORM.ORIGIN,
  PLATFORM.RIOTGAMES,
  PLATFORM.EPICGAMES,
  PLATFORM.BATTLENET,
  PLATFORM.UPLAY,
]

export const monthOptions = {
  valid: [
    'month:january',
    'month:february',
    'month:march',
    'month:april',
    'month:may',
    'month:june',
    'month:july',
    'month:august',
    'month:september',
    'month:october',
    'month:november',
    'month:december',
  ].map((label, index) => ({
    value: index,
    label: t => t(label),
  })),
}
