import React from 'react'
import PropTypes from 'prop-types'
import PrintYellow from '../../../assets/images/print-yellow.svg'
import PrintBlue from '../../../assets/images/print-blue.svg'
import PrintPurple from '../../../assets/images/print-purple.svg'
import PrintGreen from '../../../assets/images/print-green3.svg'
import PrintPink from '../../../assets/images/print-pink.svg'
import cx from 'classnames'


const SimpleBanner = ({ print, children, className, isGeneric }) => {

  const selectPrint = (propValue) => {
    switch(propValue) {
      case 'yellow': return PrintYellow
      case 'blue': return PrintBlue
      case 'pink': return PrintPink
      case 'purple': return PrintPurple
      default: return PrintGreen
    }
  }

  const bannerClasses = cx('simpleBanner', {
    [className]: className,
    'is-generic': isGeneric
  })

  return (
    <div className={bannerClasses}>
      { print ? <img className="print" src={selectPrint(print)} alt="Huella OSL" /> : null }
      <div className="wrapper">
        <div className="simpleBanner-info">
          {children}
        </div>
      </div>
    </div>
  );
};

SimpleBanner.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  print: PropTypes.oneOf(['yellow', 'blue', 'green', 'pink', 'purple']),
  isGeneric: PropTypes.bool
}

export default SimpleBanner
