import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import ModalNewsletter from '../../ui/components/modals/ModalNewsletter'
import {
  closeModal,
  showEphemeralNewsletterSnackbar,
} from '../../redux/actions'
import { submitSubscription } from '../../services/user'

import { newsleterSubcriptionSchema } from '../validation/schemas'

export default props => {
  const dispatch = useDispatch()

  const [showError, setShowError] = useState(false)

  const { email } = useSelector(state => state.user)

  const handleSubmitSubscription = async (email, newsletter) => {
    setShowError(false)
    const {success} = await submitSubscription({ email, newsletter })
    if (success) {
      dispatch(closeModal())
      dispatch(showEphemeralNewsletterSnackbar())
    } else {
      setShowError(true)
    }
  }

  const formik = useFormik({
    initialValues: {
      email: email,
      privacyPolicy: false,
    },
    validationSchema: Yup.object(newsleterSubcriptionSchema),
    onSubmit: values => handleSubmitSubscription(values.email, values.privacyPolicy)
  })

  const handleClose = () => dispatch(closeModal())

  const emailField = {
    handleBlur: formik.handleBlur,
    handleChange: formik.handleChange,
    error:
      formik.errors.email && formik.touched.email ? formik.errors.email : '',
    value: formik.values.email,
  }

  const privacyCheckField = {
    name: 'privacyPolicy',
    handleBlur: formik.handleBlur,
    handleChange: formik.handleChange,
    error:
      formik.errors.privacyPolicy && formik.touched.privacyPolicy
        ? formik.errors.privacyPolicy
        : '',
    value: formik.values.privacyPolicy,
  }

  return (
    <ModalNewsletter
      onClose={handleClose}
      onSubmit={formik.handleSubmit}
      privacyCheck={privacyCheckField}
      email={emailField}
      showError={showError}
      {...props}
    />
  )
}
