import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { ReactComponent as Check } from '../../../assets/icons/icon-check.svg'
import useTranslations from '../../../hooks/useTranslations'

const Checkbox = ({
  hasDivider,
  handleChange,
  children,
  textError,
  textHelper,
  textLabel,
  name,
  checked,
  isDisabled,
}) => {
  const classes = cx('checkbox', {
    'has-divider': hasDivider,
    'has-error': textError,
    'is-disabled': isDisabled,
  })
  const t = useTranslations()

  return (
    <div className={classes}>
      <div className="checkbox-item">
        <input
          className="checkbox-input"
          type="checkbox"
          id={textLabel}
          name={name}
          onChange={handleChange}
          checked={checked}
        />
        <Check className="checkbox-icon" />
        <div className="checkbox-text">
          <label className="checkbox-label" htmlFor={textLabel}>
            <span className="checkbox-labelText">{t(textLabel)}</span>
            {textHelper ? (
              <span className="checkbox-labelHelper">{t(textHelper)}</span>
            ) : null}
          </label>
        </div>
        {React.Children.count(children) ? children : null}
        {textError ? <span className="errorText">{t(textError)}</span> : null}
      </div>
    </div>
  )
}

Checkbox.propTypes = {
  hasDivider: PropTypes.bool,
  handleChange: PropTypes.func,
  children: PropTypes.node,
  textError: PropTypes.string,
  textHelper: PropTypes.string,
  textLabel: PropTypes.string.isRequired,
  value: PropTypes.string,
  isDisabled: PropTypes.bool,
}

export default Checkbox
