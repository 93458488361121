import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import Icon from '../icons/Icon'
import useTranslations from '../../../hooks/useTranslations'
import OslPropTypes from '../../../common/osl-prop-types'
import Loader from '../loaders/Loader'
import { ReactComponent as IconSuccessCircle } from '../../../assets/icons/icon-success-circle.svg'

const Button = ({
  isDisabled,
  text,
  IconComponent,
  kind,
  isLight,
  isSmall,
  as,
  isLoading,
  isSuccess,
  literal,
  ...restProps
}) => {
  const buttonClasses = cx(`button button--${kind}`, {
    'is-small': isSmall,
    'is-light': isLight,
    'is-success': isSuccess,
    'is-loading': isLoading,
  })
  const Component = as
  const t = useTranslations()
  return (
    <Component
      className={buttonClasses}
      disabled={isDisabled}
      {...restProps}
      aria-label={literal ? text : t(text)}
    >
      <span className="button-text">{literal ? text : t(text)}</span>
      {IconComponent && <Icon Component={IconComponent} />}
      {isLoading ? (
        <Loader size="24" className={cx(kind, { isLight: isLight })} />
      ) : null}
      {isSuccess ? <Icon Component={IconSuccessCircle} /> : null}
    </Component>
  )
}

Button.propTypes = {
  as: OslPropTypes.buttonAs.isRequired,
  literal: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isLight: PropTypes.bool,
  isSmall: PropTypes.bool,
  text: PropTypes.string,
  IconComponent: PropTypes.elementType,
  kind: PropTypes.oneOf(['primary', 'secondary', 'tertiary']).isRequired,
  isLoading: PropTypes.bool,
  isSuccess: PropTypes.bool,
}

export default Button
