import React from 'react'
import useTranslations from '../../../hooks/useTranslations'
import Input from '../inputs/Input'
import HelperCard from '../cards/HelperCard'
import { Select, convertToOption } from '../inputs/Select'
import RadioButtonGroup from '../inputs/RadioButtonGroup'
import RadioButton from '../inputs/RadioButton'
import {
  federation,
  platformOptions,
  verifiedFifaPlayerOptions,
} from '../../../common/forms/inscription'

const FormSharedItems = ({ formik }) => {
  const t = useTranslations()
  return (
    <>
      <div className="formBase-row">
        <div className="formBase-cell">
          <Select
            options={federation.map(convertToOption)}
            handleBlur={formik.handleBlur}
            handleChange={formik.handleChange}
            textLabel="input-label:federation"
            textError={
              formik.errors.federation && formik.touched.federation
                ? formik.errors.federation
                : ''
            }
            value={formik.values.federation.value}
            name="federation"
          />
        </div>
        <div className="formBase-cell">
          <p className="helperText top-space">
            {t('helper-text-form:federation')}
          </p>
        </div>
      </div>
      <div className="formBase-row">
        <div className="formBase-cell">
          <RadioButtonGroup
            textLabel="input-label:platform"
            textError={
              formik.errors.platform && formik.touched.platform
                ? formik.errors.platform
                : ''
            }
          >
            {Object.values(platformOptions.valid).map(option => (
              <RadioButton
                key={option.value}
                handleChange={formik.handleChange}
                textLabel={option.label}
                value={option.value}
                checked={formik.values.platform === option.value}
                name="platform"
              />
            ))}
          </RadioButtonGroup>
        </div>
      </div>
      <div className="formBase-row">
        <div className="formBase-cell">
          <Input
            handleBlur={formik.handleBlur}
            handleChange={formik.handleChange}
            type="text"
            textError={
              formik.errors.tag && formik.touched.tag ? formik.errors.tag : ''
            }
            textLabel={
              formik.values.platform ===
              platformOptions.valid.playstation4.value
                ? 'input-label:tag-ps'
                : 'input-label:tag-xbox'
            }
            value={formik.values.tag}
            name="tag"
          />
        </div>
        <div className="formBase-cell">
          <p className="helperText">{t('helper-text-form:tag')}</p>
        </div>
      </div>
      <div className="formBase-row">
        <div className="formBase-cell">
          <RadioButtonGroup
            textLabel="input-label:verified"
            textError={
              formik.errors.verifiedFifaPlayer &&
              formik.touched.verifiedFifaPlayer
                ? formik.errors.verifiedFifaPlayer
                : ''
            }
          >
            {Object.values(verifiedFifaPlayerOptions.valid).map(option => (
              <RadioButton
                key={option.value}
                handleChange={formik.handleChange}
                textLabel={option.label}
                value={option.value}
                checked={formik.values.verifiedFifaPlayer === option.value}
                name="verifiedFifaPlayer"
              />
            ))}
          </RadioButtonGroup>
        </div>
        <div className="formBase-cell">
          <HelperCard
            textTitle="helper-card:title"
            textDescription="helper-card:verified"
          />
        </div>
      </div>
      <div className="formBase-row">
        <div className="formBase-cell">
          <Input
            handleBlur={formik.handleBlur}
            handleChange={formik.handleChange}
            type="text"
            textError={
              formik.errors.idGlobalSeries && formik.touched.idGlobalSeries
                ? formik.errors.idGlobalSeries
                : ''
            }
            textLabel="input-label:id"
            value={formik.values.idGlobalSeries}
            name="idGlobalSeries"
          />
        </div>
        <div className="formBase-cell">
          <p className="helperText">{t('helper-text-form:verified')}</p>
        </div>
      </div>
    </>
  )
}

export default FormSharedItems
