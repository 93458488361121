import React from 'react'
import PropTypes from 'prop-types'
import useTranslations from '../../../hooks/useTranslations'

const SummaryTile = ({ title, description, image }) => {
  const t = useTranslations()
  return (
    <div
      className="summaryTile
    "
    >
      <img className="image" src={image} alt={t(title)} />
      <div className="info">
        <p className="title">{t(title)}</p>
        {description ? <p className="description">{description}</p> : null}
      </div>
    </div>
  )
}

SummaryTile.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  extra: PropTypes.object,
}

export default SummaryTile
