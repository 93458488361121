import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import useTranslations from '../../../hooks/useTranslations'

const Input = ({
  isDisabled,
  handleBlur,
  handleChange,
  type,
  textError,
  textLabel,
  textLabelValues,
  name,
  value,
  autoComplete,
}) => {
  const classes = cx('input form-field', {
    'has-error': textError,
    'has-value': value === 0 || value,
    'is-disabled': isDisabled,
  })

  const t = useTranslations()

  return (
    <div className={classes}>
      <input
        className="input-input"
        type={type}
        id={textLabel}
        onBlur={handleBlur}
        onChange={handleChange}
        name={name}
        value={value}
        autoComplete={autoComplete}
      />
      <label className="input-label">{t(textLabel, textLabelValues)}</label>
      {textError ? <span className="errorText">{t(textError)}</span> : null}
    </div>
  )
}

Input.propTypes = {
  isDisabled: PropTypes.bool,
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  type: PropTypes.oneOf(['email', 'text', 'password', 'number']).isRequired,
  textError: PropTypes.string,
  textLabel: PropTypes.string.isRequired,
  textLabelValues: PropTypes.object,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  autoComplete: PropTypes.string,
}

export default Input
