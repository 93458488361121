import React from 'react'
import PropTypes from 'prop-types'
import SocialItem from '../social/SocialItem'
import frame from '../../../assets/images/frame-squared-small.svg'
import getSharedIcon from '../../../common/get-shared-icon'
import OslPropTypes from '../../../common/osl-prop-types'

const PortraitCardSquared = ({ playerImg, social, nickname, name }) => {
  return (
    <div className="portraitCard is-squared">
      <div className="portraitCard-avatar">
        <img className="frame" src={frame} alt="frame" />
        <img className="player" src={playerImg} alt={name} />
      </div>
      <div className="portraitCard-info">
        <p className="portraitCard-nickname">{nickname}</p>
        <p className="portraitCard-name">{name}</p>
        {social ? (
          <div className="portraitCard-social">
            {social.map(item => (
              <SocialItem
                key={item.sharedIconName}
                icon={getSharedIcon(item.sharedIconName)}
                href={item.href}
              />
            ))}
          </div>
        ) : null}
      </div>
    </div>
  )
}

PortraitCardSquared.propTypes = {
  social: OslPropTypes.socialProps,
  name: PropTypes.string,
  playerImg: PropTypes.string,
  nickname: PropTypes.string,
}

export default PortraitCardSquared
