import React from 'react'
import PropTypes from 'prop-types'
import useTranslations from '../../../hooks/useTranslations'
import Input from '../inputs/Input'
import HelperCard from '../cards/HelperCard'

const FormAccessLogged = ({ formik }) => {
  const t = useTranslations()
  return (
    <div className="formBase-row">
      <div className="formBase-cell">
        <Input
          handleBlur={formik.handleBlur}
          handleChange={formik.handleChange}
          type="text"
          textError={
            formik.errors.username && formik.touched.username
              ? formik.errors.username
              : ''
          }
          textLabel="input-label:username"
          value={formik.values.username}
          name="username"
        />
      </div>
      <div className="formBase-cell">
        <HelperCard
          textTitle="helper-card:username"
          textDescription="helper-card:username-description"
        />
        <p className="helperText">{t('helper-text:username-tips')}</p>
      </div>
    </div>
  )
}

FormAccessLogged.propTypes = {
  userName: PropTypes.string,
}

export default FormAccessLogged
