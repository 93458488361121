import React from 'react'
import PropTypes from 'prop-types'
import useTranslations from '../../../hooks/useTranslations'
import Icon from '../icons/Icon'
import OslPropTypes from '../../../common/osl-prop-types'

const ButtonLink = ({ className, as, text, icon, noTranslation, ...restProps }) => {
  const Component = as
  const t = useTranslations()
  const content = noTranslation ? text : t(text)
  return (
    <Component className={className} {...restProps} aria-label={content}>
      {content}
      {icon ? <Icon Component={icon} /> : null}
    </Component>
  )
}

ButtonLink.propTypes = {
  className: PropTypes.string,
  as: OslPropTypes.buttonAs.isRequired,
  text: PropTypes.string,
  icon: PropTypes.elementType,
  noTranslation: PropTypes.bool,
}

export default ButtonLink
