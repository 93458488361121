import React from 'react'

import EcopaKnowMore from '../../ui/views/EcopaKnowMore'
import * as routes from '../routes'

export default ({ isInscriptionOpen, isEnrolled, onClickContact }) => {
  const showInscriptionButton = isInscriptionOpen && !isEnrolled

  return (
    <EcopaKnowMore
      showInscriptionButton={showInscriptionButton}
      inscriptionButtonTo={routes.ECOPA_INSCRIPTION.path}
      handleClickContact={onClickContact}
    />
  )
}
