import React from 'react'
import PropTypes from 'prop-types'
import Button from '../buttons/Button'
import { Link } from 'react-router-dom'

const FormControls = ({ mainActionText, cancelRoute }) => {
  return (
    <div className="formBase-controls">
      <Button
        as={Link}
        to={cancelRoute}
        isLight
        kind="tertiary"
        text="action:cancel"
        type="button"
      />
      <Button as="button" kind="primary" text={mainActionText} type="submit" />
    </div>
  )
}

FormControls.propTypes = {
  mainActionText: PropTypes.string,
  cancelRoute: PropTypes.string,
}

export default FormControls
