import React from 'react'
import PropTypes from 'prop-types'
import IconListItem from './IconListItem'
import useTranslations from '../../../hooks/useTranslations'
import getSharedIcon from '../../../common/get-shared-icon'

const IconList = ({ title, items }) => {
  const t = useTranslations()
  return (
    <div className="iconList-wrapper">
      <h2 className="stepDown1-title iconList-title">{t(title)}</h2>
      <ul className="iconList">
        {items.map(item => (
          <IconListItem
            key={item.username}
            hasLink={item.hasLink}
            to={item.to}
            icon={getSharedIcon(item.sharedIconName)}
            username={item.username}
          />
        ))}
      </ul>
    </div>
  )
}

IconList.propTypes = {
  title: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      username: PropTypes.string,
      hasLink: PropTypes.bool,
      to: PropTypes.string,
      icon: PropTypes.elementType,
    }),
  ),
}

export default IconList
