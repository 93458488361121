import React from 'react'
import PropTypes from 'prop-types'
import OslPropTypes from '../../../common/osl-prop-types'
import FormRegistrationPolicy from './formRegistrationPolicy'
import FormControls from './FormControls'
import FormRegistrationFiels from './FormRegistrationFields'
import FormErrors from './FormErrors'

const FormRegistration = ({
  formInputs,
  handleSubmit,
  privacyChecks,
  errors,
  cancelRoute,
}) => {
  return (
    <form className="formBase wrapper" onSubmit={handleSubmit}>
      <FormRegistrationFiels formInputs={formInputs} />
      <FormRegistrationPolicy privacyChecks={privacyChecks} />
      {errors ? <FormErrors errors={errors} /> : null}
      <FormControls 
        mainActionText="action:registration" 
        cancelRoute={cancelRoute}
      />
    </form>
  )
}

FormRegistration.propTypes = {
  email: OslPropTypes.inputProps,
  errors: OslPropTypes.errorsType,
  handleSubmit: PropTypes.func,
  privacyChecks: FormRegistrationPolicy.propTypes.privacyChecks,
  formInputs: PropTypes.shape({
    email: OslPropTypes.inputProps,
    emailConfirmation: OslPropTypes.inputProps,
    username: OslPropTypes.inputProps,
    password: OslPropTypes.inputProps,
    passwordConfirmation: OslPropTypes.inputProps
  }),
  cancelRoute: PropTypes.string,
}

export default FormRegistration
