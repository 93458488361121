import rubius from '../assets/images/tga/team-rubius.png'
import mangel from '../assets/images/tga/team-mangel.png'
import grefg from '../assets/images/tga/team-grefg.png'
import ampeter from '../assets/images/tga/team-ampeter.png'
import willyrex from '../assets/images/tga/team-willyrex.png'
import fargan from '../assets/images/tga/team-fargan.png'
import albavicius from '../assets/images/tga/participant-alba-vicius.png'
import aryacroft from '../assets/images/tga/participant-aryacroft-7.png'
import brucewayneiv from '../assets/images/tga/participant-brucewayneiv.png'
import chucaa from '../assets/images/tga/participant-chucaa-966.png'
import eka from '../assets/images/tga/participant-eka-2310.png'
import elskipler from '../assets/images/tga/participant-elskipler.png'
import igestark from '../assets/images/tga/participant-igestark.png'
import irenefields from '../assets/images/tga/participant-irenefields.png'
import lilwicca from '../assets/images/tga/participant-lilwicca-7.png'
import mapxmen from '../assets/images/tga/participant-mapxmen.png'
import olivliv from '../assets/images/tga/participant-olivliv-12.png'
import pepelu from '../assets/images/tga/participant-pepelu-lav.png'
import perrymcqueen from '../assets/images/tga/participant-perrymcqueen.png'
import rubysky from '../assets/images/tga/participant-rubysky-9.png'
import tgaonlycopycat from '../assets/images/tga/participant-tgaonlycopycat.png'
import nik from '../assets/images/tga/participant-nik.png'
import tolosaa from '../assets/images/tga/participant-tolosaa-ttv.png'
import townicortes from '../assets/images/tga/participant-townicortes.png'
import zeepos from '../assets/images/tga/participant-zeepos.png'
import zunksoyyo from '../assets/images/tga/participant-zunksoyyo.png'
import silsicons from '../assets/images/tga/participant-silsicons.png'
import { SOCIAL } from '../common/constants'

const teams = [
  {
    title: 'Team Rubius',
    members: [
      {
        image: rubius,
        name: 'Rubén Doblas Gundersen',
        position: 'noun:team-owner',
        social: [
          {
            sharedIconName: SOCIAL.TWITCH,
            href: 'https://www.twitch.tv/rubius',
          },
          {
            sharedIconName: SOCIAL.TWITTER,
            href: 'https://twitter.com/Rubiu5',
          },
          {
            sharedIconName: SOCIAL.YOUTUBE,
            href: 'https://www.youtube.com/channel/UCXazgXDIYyWH-yXLAkcrFxw',
          },
          {
            sharedIconName: SOCIAL.INSTAGRAM,
            href: 'https://www.instagram.com/elrubiuswtf/',
          },
        ],
      },
      {
        image: mangel,
        name: 'Miguel Ángel Rogel',
        position: 'noun:team-manager',
        social: [
          {
            sharedIconName: SOCIAL.TWITCH,
            href: 'https://www.twitch.tv/mangel',
          },
          {
            sharedIconName: SOCIAL.TWITTER,
            href: 'https://twitter.com/mangelrogel',
          },
          {
            sharedIconName: SOCIAL.YOUTUBE,
            href: 'https://www.youtube.com/user/mangelrogel',
          },
          {
            sharedIconName: SOCIAL.INSTAGRAM,
            href: 'https://www.instagram.com/mangelrogel/?hl=es',
          },
        ],
      },
    ],
  },
  {
    title: 'Team Grefg',
    members: [
      {
        image: grefg,
        name: 'David Cánovas Martínez',
        position: 'noun:team-owner',
        social: [
          {
            sharedIconName: SOCIAL.TWITCH,
            href: 'https://www.twitch.tv/thegrefg',
          },
          {
            sharedIconName: SOCIAL.TWITTER,
            href: 'https://twitter.com/TheGrefg',
          },
          {
            sharedIconName: SOCIAL.YOUTUBE,
            href: 'https://www.youtube.com/user/TheGrefg',
          },
          {
            sharedIconName: SOCIAL.INSTAGRAM,
            href: 'https://www.instagram.com/grefg_official/?hl=es',
          },
        ],
      },
      {
        image: ampeter,
        name: 'Andrés López',
        position: 'noun:team-manager',
        social: [
          {
            sharedIconName: SOCIAL.TWITCH,
            href: 'https://www.twitch.tv/ampeterby7',
          },
          {
            sharedIconName: SOCIAL.TWITTER,
            href: 'https://twitter.com/Ampeterby7',
          },
          {
            sharedIconName: SOCIAL.YOUTUBE,
            href: 'https://www.youtube.com/user/Ampeterby7',
          },
          {
            sharedIconName: SOCIAL.INSTAGRAM,
            href: 'https://www.instagram.com/ampeterby7_',
          },
        ],
      },
    ],
  },

  {
    title: 'Team Willyrex',
    members: [
      {
        image: willyrex,
        name: 'Guillermo Díaz Ibañez',
        position: 'noun:team-owner',
        social: [
          {
            sharedIconName: SOCIAL.TWITCH,
            href: 'https://www.twitch.tv/willyrex',
          },
          {
            sharedIconName: SOCIAL.TWITTER,
            href: 'https://twitter.com/WillyrexYT',
          },
          {
            sharedIconName: SOCIAL.YOUTUBE,
            href: 'https://www.youtube.com/user/Willyrex',
          },
          {
            sharedIconName: SOCIAL.INSTAGRAM,
            href: 'https://www.instagram.com/willyrex/',
          },
        ],
      },
      {
        image: fargan,
        name: 'David Alonso Romero',
        position: 'noun:team-manager',
        social: [
          {
            sharedIconName: SOCIAL.TWITTER,
            href: 'https://twitter.com/xFaRgAnx',
          },
          {
            sharedIconName: SOCIAL.YOUTUBE,
            href: 'https://www.youtube.com/c/xFaRgAnxYTube/videos',
          },
          {
            sharedIconName: SOCIAL.INSTAGRAM,
            href: 'https://www.instagram.com/xfarganxig/',
          },
          {
            sharedIconName: SOCIAL.FACEBOOK,
            href: 'https://www.facebook.com/gaming/farganfb',
          },
        ],
      },
    ],
  },
]

const participants = [
  {
    nickname: 'Zunk',
    name: 'Alejandro López',
    image: zunksoyyo,
    social: [
      {
        platform: 'instagram',
        sharedIconName: SOCIAL.INSTAGRAM,
        href: 'https://www.instagram.com/zunk_tga',
      },
      {
        platform: 'twitch',
        sharedIconName: SOCIAL.TWITCH,
        href: 'https://www.twitch.tv/zunk_tga',
      },
    ],
  },
  {
    nickname: 'ElSkipler',
    name: 'Anas',
    image: elskipler,
    social: [
      {
        platform: 'instagram',
        sharedIconName: SOCIAL.INSTAGRAM,
        href: 'https://www.instagram.com/elskipler_tga',
      },
      {
        platform: 'twitch',
        sharedIconName: SOCIAL.TWITCH,
        href: 'https://www.twitch.tv/elskipler_tga',
      },
    ],
  },
  {
    nickname: 'Chuca',
    name: 'Angie Fernández',
    image: chucaa,
    social: [
      {
        platform: 'instagram',
        sharedIconName: SOCIAL.INSTAGRAM,
        href: 'https://www.instagram.com/chuca_tga',
      },
      {
        platform: 'twitch',
        sharedIconName: SOCIAL.TWITCH,
        href: 'https://www.twitch.tv/chuca_tga',
      },
    ],
  },
  {
    nickname: 'Onlycopycat',
    name: 'Blanca Yugueros',
    image: tgaonlycopycat,
    social: [
      {
        platform: 'instagram',
        sharedIconName: SOCIAL.INSTAGRAM,
        href: 'https://www.instagram.com/onlycopycap_tga',
      },
      {
        platform: 'twitch',
        sharedIconName: SOCIAL.TWITCH,
        href: 'https://www.twitch.tv/onlycopycat_tga',
      },
    ],
  },
  {
    nickname: 'Zeepos',
    name: 'Carlos García',
    image: zeepos,
    social: [
      {
        platform: 'instagram',
        sharedIconName: SOCIAL.INSTAGRAM,
        href: 'https://www.instagram.com/zeepos_tga',
      },
      {
        platform: 'twitch',
        sharedIconName: SOCIAL.TWITCH,
        href: 'https://www.twitch.tv/zeepos_tga',
      },
    ],
  },
  {
    nickname: 'Eka_2310',
    name: 'Ekaitz Viscarolasaga',
    image: eka,
    social: [
      {
        platform: 'instagram',
        sharedIconName: SOCIAL.INSTAGRAM,
        href: 'https://www.instagram.com/eka_2310_tga',
      },
      {
        platform: 'twitch',
        sharedIconName: SOCIAL.TWITCH,
        href: 'https://www.twitch.tv/eka_2310_tga',
      },
    ],
  },
  {
    nickname: 'StarK',
    name: 'Gerard Pous',
    image: igestark,
    social: [
      {
        platform: 'instagram',
        sharedIconName: SOCIAL.INSTAGRAM,
        href: 'https://www.instagram.com/stark_tga',
      },
      {
        platform: 'twitch',
        sharedIconName: SOCIAL.TWITCH,
        href: 'https://www.twitch.tv/stark_tga',
      },
    ],
  },
  {
    nickname: 'Irene Fields',
    name: 'Irene Bailón',
    image: irenefields,
    social: [
      {
        platform: 'instagram',
        sharedIconName: SOCIAL.INSTAGRAM,
        href: 'https://www.instagram.com/irenefields_tga',
      },
      {
        platform: 'twitch',
        sharedIconName: SOCIAL.TWITCH,
        href: 'https://www.twitch.tv/irenefields_tga',
      },
    ],
  },
  {
    nickname: 'Bruce Wayneiv',
    name: 'Iván Molina',
    image: brucewayneiv,
    social: [
      {
        platform: 'instagram',
        sharedIconName: SOCIAL.INSTAGRAM,
        href: 'https://www.instagram.com/brucewayneiv_tga',
      },
      {
        platform: 'twitch',
        sharedIconName: SOCIAL.TWITCH,
        href: 'https://www.twitch.tv/brucewayneiv_tga',
      },
    ],
  },
  {
    nickname: 'Pepelu_lav',
    name: 'José Luis Lavilla',
    image: pepelu,
    social: [
      {
        platform: 'instagram',
        sharedIconName: SOCIAL.INSTAGRAM,
        href: 'https://www.instagram.com/pepelu_lav_tga',
      },
      {
        platform: 'twitch',
        sharedIconName: SOCIAL.TWITCH,
        href: 'https://www.twitch.tv/pepelu_lav_tga',
      },
    ],
  },
  {
    nickname: 'MapXMEN',
    name: 'Marc Anduix',
    image: mapxmen,
    social: [
      {
        platform: 'instagram',
        sharedIconName: SOCIAL.INSTAGRAM,
        href: 'https://www.instagram.com/_mapxmen_tga',
      },
      {
        platform: 'twitch',
        sharedIconName: SOCIAL.TWITCH,
        href: 'https://www.twitch.tv/mapxmen_tga',
      },
    ],
  },
  {
    nickname: 'OlivLiv',
    name: 'Olivia',
    image: olivliv,
    social: [
      {
        platform: 'instagram',
        sharedIconName: SOCIAL.INSTAGRAM,
        href: 'https://www.instagram.com/olivliv_tga',
      },
      {
        platform: 'twitch',
        sharedIconName: SOCIAL.TWITCH,
        href: 'https://www.twitch.tv/olivliv_tga',
      },
    ],
  },
  {
    nickname: 'Tolosa23',
    name: 'Pol Tolosa',
    image: tolosaa,
    social: [
      {
        platform: 'instagram',
        sharedIconName: SOCIAL.INSTAGRAM,
        href: 'https://www.instagram.com/tolosa23_tga',
      },
      {
        platform: 'twitch',
        sharedIconName: SOCIAL.TWITCH,
        href: 'https://www.twitch.tv/tolosa23_tga',
      },
    ],
  },
  {
    nickname: 'PerryMcQueen',
    name: 'Raquel J. Sánchez',
    image: perrymcqueen,
    social: [
      {
        platform: 'instagram',
        sharedIconName: SOCIAL.INSTAGRAM,
        href: 'https://www.instagram.com/perrymcqueen_tga',
      },
      {
        platform: 'twitch',
        sharedIconName: SOCIAL.TWITCH,
        href: 'https://www.twitch.tv/perrymcqueen_tga',
      },
    ],
  },
  {
    nickname: 'RubySky9',
    name: 'Sheila López',
    image: rubysky,
    social: [
      {
        platform: 'instagram',
        sharedIconName: SOCIAL.INSTAGRAM,
        href: 'https://www.instagram.com/rubysky9_tga',
      },
      {
        platform: 'twitch',
        sharedIconName: SOCIAL.TWITCH,
        href: 'https://www.twitch.tv/rubysky9_tga',
      },
    ],
  },
  {
    nickname: 'SilSil',
    name: 'Silvia Tomey',
    image: silsicons,
    social: [
      {
        platform: 'instagram',
        sharedIconName: SOCIAL.INSTAGRAM,
        href: 'https://www.instagram.com/silsil_tga',
      },
      {
        platform: 'twitch',
        sharedIconName: SOCIAL.TWITCH,
        href: 'https://www.twitch.tv/silsil_tga',
      },
    ],
  },
  {
    nickname: 'ToniCortes',
    name: 'Toni Cortés',
    image: townicortes,
    social: [
      {
        platform: 'instagram',
        sharedIconName: SOCIAL.INSTAGRAM,
        href: 'https://www.instagram.com/tonicortes_tga',
      },
      {
        platform: 'twitch',
        sharedIconName: SOCIAL.TWITCH,
        href: 'https://www.twitch.tv/tonicortes_tga',
      },
    ],
  },
  {
    nickname: 'Aryacroft',
    name: 'Verónica',
    image: aryacroft,
    social: [
      {
        platform: 'instagram',
        sharedIconName: SOCIAL.INSTAGRAM,
        href: 'https://www.instagram.com/aryacroft_tga',
      },
      {
        platform: 'twitch',
        sharedIconName: SOCIAL.TWITCH,
        href: 'https://www.twitch.tv/aryacroft_tga',
      },
    ],
  },
  {
    nickname: 'Albavicius',
    name: 'Alba',
    image: albavicius,
    social: [
      {
        platform: 'instagram',
        sharedIconName: SOCIAL.INSTAGRAM,
        href: 'https://www.instagram.com/albavicius_tga',
      },
      {
        platform: 'twitch',
        sharedIconName: SOCIAL.TWITCH,
        href: 'https://www.twitch.tv/albavicius_tga',
      },
    ],
  },
  {
    nickname: 'Nik',
    name: 'Pedro Sanchez',
    image: nik,
    social: [
      {
        platform: 'instagram',
        sharedIconName: SOCIAL.INSTAGRAM,
        href: 'https://www.instagram.com/nik_tga',
      },
      {
        platform: 'twitch',
        sharedIconName: SOCIAL.TWITCH,
        href: 'https://www.twitch.tv/nik_tga',
      },
    ],
  },
  {
    nickname: 'LilWicca7',
    name: 'Melody',
    image: lilwicca,
    social: [
      {
        platform: 'instagram',
        sharedIconName: SOCIAL.INSTAGRAM,
        href: 'https://www.instagram.com/lilwicca7_tga',
      },
      {
        platform: 'twitch',
        sharedIconName: SOCIAL.TWITCH,
        href: 'https://www.twitch.tv/lilwicca7_tga',
      },
    ],
  },
]

export { teams, participants }
