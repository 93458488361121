import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Button from '../buttons/Button'

import { CMS_URL } from '../../../constants'

const hostUrl = Boolean(process.env.STORYBOOK_RUNNING) ? '' : CMS_URL

const ArticleFeatured = ({ article, cta }) => {
  const handleClickCTA = e => e.stopPropagation()

  return (
    <div className="article articleFeatured">
      <Link className="articleFeatured-link" to={article.to} />
      <div className="articleFeatured-image">
        <div className="skeleton"></div>
        <Link to={article.to}>
          <img
            src={`${hostUrl}${article.image.formats.large.url}`}
            srcSet={`${hostUrl}${article.image.formats.thumbnail.url} 245w,
              ${hostUrl}${article.image.formats.medium.url} 750w,
              ${hostUrl}${article.image.formats.large.url} 1000w`}
            sizes="(max-width: 245px) 245px, (max-width: 750px) 750px, (min-width: 1000px) 1000px"
            alt={article.title}
          />
        </Link>
      </div>

      <div className="articleFeatured-info">
        <div className="wrapper">
          <h1 className="articleFeatured-title">{article.title}</h1>
          <p className="articleFeatured-text">{article.description}</p>
          {cta && cta.show ? (
            <Button
              as="a"
              kind="primary"
              href={cta.url}
              literal
              text={cta.copy}
              onClick={handleClickCTA}
            />
          ) : null}
        </div>
      </div>
    </div>
  )
}

ArticleFeatured.propTypes = {
  article: PropTypes.shape({
    to: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.shape({
      formats: PropTypes.shape({
        thumbnail: PropTypes.shape({
          url: PropTypes.string,
        }),
        medium: PropTypes.shape({
          url: PropTypes.string,
        }),
        large: PropTypes.shape({
          url: PropTypes.string,
        }),
      }),
    }),
  }),
}

export default ArticleFeatured
