import React from 'react'
import PropTypes from 'prop-types'
import Modal from '../modals/Modal'
import ModalLayout from './ModalLayout'
import Input from '../inputs/Input'
import Textarea from '../inputs/Textarea'
import Checkbox from '../inputs/Checkbox'
import RequestErrorCard from '../cards/RequestErrorCard'
import Link from '../links/Link'
import useTranslations from '../../../hooks/useTranslations'
import OslPropTypes from '../../../common/osl-prop-types'

const ModalContact = ({
  onClose,
  onSubmit,
  privacyCheck,
  email,
  subject,
  comment,
  showError,
}) => {
  const t = useTranslations()
  return (
    <Modal>
      <ModalLayout
        title="action:send-questions-suggestions"
        primaryButtonText="action:send"
        onClose={onClose}
        onSubmit={onSubmit}
        isForm
      >
        <Input
          handleBlur={email.handleBlur}
          handleChange={email.handleChange}
          type="email"
          textError={email.error}
          textLabel="input-label:email"
          value={email.value}
          name="email"
        />
        <Input
          handleBlur={subject.handleBlur}
          handleChange={subject.handleChange}
          type="text"
          textError={subject.error}
          textLabel="input-label:subject"
          value={subject.value}
          name="subject"
        />
        <Textarea
          handleBlur={comment.handleBlur}
          handleChange={comment.handleChange}
          textError={comment.error}
          textLabel="input-label:write-your-text"
          value={comment.value}
          name="comment"
        />
        <p className="helperText">
          {t('contact:privacy-policy', {
            b: text => <strong>{text}</strong>,
          })}
        </p>
        <Checkbox
          handleChange={privacyCheck.handleChange}
          textLabel="form-policy:policy"
          checked={privacyCheck.checked}
          name={privacyCheck.name}
          textError={privacyCheck.error}
        >
          <Link
            isExternal
            kind="primary"
            text="form-policy:check05-link"
            to="/Privacy"
          />
        </Checkbox>
        {showError && (
          <RequestErrorCard textTitle="error:contact-failed-title"/>
        )}
      </ModalLayout>
    </Modal>
  )
}

ModalContact.propTypes = {
  email: OslPropTypes.inputProps,
  subject: OslPropTypes.inputProps,
  comment: PropTypes.shape({
    error: PropTypes.string,
    value: PropTypes.string,
    handleChange: PropTypes.func,
  }),
  privacyCheck: OslPropTypes.checkboxProps,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  showError: PropTypes.bool,
}

export default ModalContact
