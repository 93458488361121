import React from 'react'

import { errorsType } from '../../../customPropTypes'

import ErrorCard from '../cards/ErrorCard'

const FormErrors = ({ errors }) => {
  return (
    <div className="formBase-row">
      <div className="formBase-cell">
        <ErrorCard textTitle="card-error:title" errors={errors} />
      </div>
    </div>
  )
}

FormErrors.propTypes = {
  errors: errorsType.isRequired,
}

export default FormErrors
