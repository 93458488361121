import React from 'react'
import PropTypes from 'prop-types'
import OslPropTypes from '../../common/osl-prop-types'
import EmptyHeader from '../components/utils/EmptyHeader'
import ArticleFeatured from '../components/articles/ArticleFeatured'
import ArticleThumbnails from '../components/articles/ArticleThumbnails'
import CtaLastEcopa from '../components/ctas/CtaLastEcopa'
import CtaNewsletter from '../components/ctas/CtaNewsletter'
import AboutOsl from '../components/banners/AboutOsl'
import PoweredBy from '../components/sections/PoweredBy'
import CtaYoutube from '../components/ctas/CtaYoutube'
import VideoBlock from '../components/integrations/VideoBlock'
import StaticBanners from '../components/banners/StaticBanners'
import SponsorFull from '../components/sponsors/SponsorFull'

const Home = ({
  articleFeatured,
  thumbnails,
  onClickNewsletter,
  videoContent,
  videoTitle,
  videoCta,
  showVideo,
  ecopaButton,
  tgaButton,
  gtButton,
  firstSponsor,
  secondSponsor,
}) => {
  return (
    <>
      <EmptyHeader />
      {articleFeatured ? (
        <ArticleFeatured
          article={articleFeatured.content}
          cta={articleFeatured.cta}
        />
      ) : null}
      {thumbnails && <ArticleThumbnails thumbnails={thumbnails} />}
      {firstSponsor ? (
        <SponsorFull
          imageSm={firstSponsor.images?.banner_mobile.url}
          imageLg={firstSponsor.images?.banner_desktop.url}
          sponsor={firstSponsor.name}
          url={firstSponsor.url}
        />
      ) : null}
      {showVideo && videoContent ? (
        <>
          <VideoBlock
            literal
            title={videoTitle}
            video={videoContent}
            videoCta={videoCta}
          />
        </>
      ) : null}
      <StaticBanners
        ecopaButton={ecopaButton}
        tgaButton={tgaButton}
        gtButton={gtButton}
      />
      {secondSponsor ? (
        <SponsorFull
          imageSm={secondSponsor.images?.banner_mobile.url}
          imageLg={secondSponsor.images?.banner_desktop.url}
          sponsor={secondSponsor.name}
          url={secondSponsor.url}
        />
      ) : null}
      <CtaLastEcopa />
      <CtaNewsletter
        title="message:cta-newsletter-title"
        handleClick={onClickNewsletter}
        print={false}
      />
      <AboutOsl />
      <PoweredBy title="message:partner-rfef" />
      <CtaYoutube />
    </>
  )
}

Home.propTypes = {
  articleFeatured: ArticleFeatured.propTypes.article,
  onClickNewsletter: PropTypes.func,
  videoHome: PropTypes.node,
  videoTitle: PropTypes.string,
  videoContent: PropTypes.node,
  videoCta: VideoBlock.propTypes.videoCta,
  showVideo: PropTypes.bool,
  ecopaButton: StaticBanners.propTypes.ecopaButton,
  tgaButton: StaticBanners.propTypes.tgaButton,
  gtButton: StaticBanners.propTypes.gtButton,
  firstSponsor: OslPropTypes.sponsorProps,
  secondSponsor: OslPropTypes.sponsorProps,
}

export default Home
