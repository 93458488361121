import React from 'react'

import IconList from '../../ui/components/lists/IconList'

export default ({ label, accounts }) => {
  if (!accounts) return null
  if (Object.keys(accounts).length <= 0) return null

  const items = Object.entries(accounts).map(([platform, values]) => ({
    username: values.handle,
    hasLink: Boolean(values.link),
    to: values.link,
    sharedIconName: platform,
  }))
  return <IconList title={label} items={items} />
}
