import React from 'react'
import PropTypes from 'prop-types'
import VideoWrapper from './VideoWrapper'
import useTranslations from '../../../hooks/useTranslations'
import Button from '../buttons/Button'

const VideoBlock = ({ title, video, videoCta, literal }) => {
  const t = useTranslations()
  return (
    <div className="videoBlock">
      <div className="video-wrapper">
        <h2 className="stepUp4-title">{literal ? title : t(title)}</h2>
        <VideoWrapper video={video} />
      </div>
      {videoCta && videoCta.show ? (
        <Button
          as="a"
          kind="primary"
          href={videoCta.url}
          literal={literal}
          text={videoCta.copy}
        />
      ) : null}
    </div>
  )
}

VideoBlock.propTypes = {
  title: PropTypes.string,
  video: PropTypes.node,
  literal: PropTypes.bool,
  videoCta: PropTypes.shape({
    url: PropTypes.string,
    copy: PropTypes.string,
    show: PropTypes.bool,
  }),
}

export default VideoBlock
