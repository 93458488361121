import React from 'react'
import ButtonLink from '../links/ButtonLink'
import useTranslations from '../../../hooks/useTranslations'
import OslPropTypes from '../../../common/osl-prop-types'
import cx from 'classnames'

const UserPrivateArea = ({ dropItems }) => {
  const t = useTranslations()
  return (
    <div className="privateArea">
      <p className="privateArea-title">{t('noun:private-area')}</p>
      <div className="privateArea-list">
        {dropItems.map(item => (
          <div
            key={item.name}
            className={cx('privateArea-itemWrapper', {
              [item.className]: item.className,
            })}
          >
            <ButtonLink
              className="privateArea-item"
              as={item.as}
              to={item.to}
              key={item.name}
              text={item.name}
              onClick={item.onClick}
              icon={item.icon}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

UserPrivateArea.propTypes = {
  dropItems: OslPropTypes.userDropItems,
}

export default UserPrivateArea
