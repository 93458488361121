const {
  federationOptions,
  platformOptions,
  verifiedFifaPlayerOptions,
} = require('../../common/forms/inscription')

const sharedInitialInscriptionValues = {
  federation: federationOptions.default,
  platform: platformOptions.default,
  tag: '',
  verifiedFifaPlayer: verifiedFifaPlayerOptions.default,
  idGlobalSeries: '',
  newsletterPlaneta: false,
  newsletterRFEF: false,
  thirdParties: false,
  privacyPolicy: false,
  oslComercial: false,
  oslPlaneta: false,
  oslPrivacyPolicy: false,
}

export { sharedInitialInscriptionValues }
