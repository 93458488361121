import React from 'react'
import PropTypes from 'prop-types'
import ArticleThumbnail from '../articles/ArticleThumbnail'

const ArticleThumbnails = ({ thumbnails }) => {
  return (
    <div className="articleThumbnails">
      {thumbnails.map(item => (
        <ArticleThumbnail key={item.title} article={item} />
      ))}
    </div>
  )
}

ArticleThumbnails.propTypes = {
  thumbnails: PropTypes.arrayOf(ArticleThumbnail.propTypes.article),
}

export default ArticleThumbnails
