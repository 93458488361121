import axios from 'axios'

import { BASE_URL } from '../constants'

const PATHPART = '/tournament'

export const fetchInfo = async tournamentSlug => {
  const { data } = await axios.get(`${BASE_URL}${PATHPART}/${tournamentSlug}`, {
    withCredentials: false,
  })
  return data
}

export const fetchWinners = async tournamentSlug => {
  const { data } = await axios.get(
    `${BASE_URL}${PATHPART}/${tournamentSlug}/results`,
    {
      withCredentials: false,
    },
  )
  return data
}

export const fetchTotalRegistrants = async tournamentSlug => {
  const {
    data,
  } = await axios.get(
    `${BASE_URL}${PATHPART}/inscriptions/${tournamentSlug}/total`,
    { withCredentials: false },
  )
  return data
}

export const fetchLoggedUserInscription = async tournamentSlug => {
  const { data } = await axios.get(
    `${BASE_URL}${PATHPART}/inscriptions/${tournamentSlug}`,
    {
      withCredentials: true,
    },
  )

  return data
}

export const fetchSubtournaments = async tournamentSlug => {
  const {
    data,
  } = await axios.get(
    `${BASE_URL}${PATHPART}/${tournamentSlug}/subtournaments`,
    { withCredentials: false },
  )
  return data
}

export const fetchSubtournamentInfo = async (
  tournamentSlug,
  subtournamentId,
) => {
  const {
    data,
  } = await axios.get(
    `${BASE_URL}${PATHPART}/${tournamentSlug}/subtournaments/${subtournamentId}`,
    { withCredentials: false },
  )
  return data
}

export const submitRegistrationAndInscription = async (
  registration,
  inscription,
) => {
  const { data } = await axios.post(
    `${BASE_URL}${PATHPART}/inscription-full`,
    {
      tournament: 'eCopa RFEF 2021',
      ...inscription,
      ...registration,
    },
    { withCredentials: true },
  )
  return data
}

export const submitInscription = async inscription => {
  const { data } = await axios.post(
    `${BASE_URL}${PATHPART}/inscription`,
    {
      tournament: 'eCopa RFEF 2021',
      ...inscription,
    },
    { withCredentials: true },
  )
  return data
}

export const submitResultValidation = async (tournamentSlug, validation) => {
  const {
    data,
  } = await axios.post(
    `${BASE_URL}${PATHPART}/${tournamentSlug}/results/validate`,
    validation,
    { withCredentials: true },
  )
  return data
}

export const submitResults = async (tournamentSlug, formData) => {
  const { data } = await axios.post(
    `${BASE_URL}${PATHPART}/${tournamentSlug}/results`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      withCredentials: true,
    },
  )
  return data
}
