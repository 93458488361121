import React from 'react'
import { Link } from 'react-router-dom'
import Button from '../buttons/Button'
import * as routes from '../../../app/routes'

import { CMS_URL } from '../../../constants'

const hostUrl = Boolean(process.env.STORYBOOK_RUNNING) ? '' : CMS_URL

const News = ({ post }) => {
  return (
    <div className="news">
      <div className="news-image">
        <img
          className="image"
          src={`${hostUrl}${post.image.formats.large.url}`}
          alt="football"
        />
      </div>
      <div className="wrapper">
        <div className="news-info">
          <h3 className="news-title">{post.title}</h3>
          <p className="news-description">{post.description}</p>
          <Button
            as={Link}
            to={routes.NEWS_SECTION.path}
            kind="primary"
            text="action:consult-news"
          />
        </div>
      </div>
    </div>
  )
}

export default News
