import React from 'react'
import OslPropTypes from '../../../common/osl-prop-types'
import useTranslations from '../../../hooks/useTranslations'
import { CMS_URL } from '../../../constants'

const hostUrl = Boolean(process.env.STORYBOOK_RUNNING) ? '' : CMS_URL

const Brands = ({brands}) => {
  const t = useTranslations()

  return (
    <div className="brick is-dark">
      <h2 className="stepUp4-title">{t('message:with-the-collaboration')}</h2>
      <div className="brands">
        {brands.map(brand => (
          <React.Fragment key={brand.name} >
            {brand.url ? (
              <a href={brand.url} target="_blank" rel="noopener noreferrer" className="brand" >
                <img src={hostUrl+brand.images.cloud.url} alt={brand.name}/>
              </a>
              ) : (
              <img src={hostUrl+brand.images.cloud.url} alt={brand.name} className="brand"/>
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  )
}

Brands.propTypes = {
  brands: OslPropTypes.sponsorsProps,
}

export default Brands
