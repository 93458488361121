import React from 'react'
import CompetitionStep from './CompetitionStep'
import useTranslations from '../../../hooks/useTranslations'
import { ReactComponent as IconCompetitions } from '../../../assets/icons/icon-competitions.svg'
import { ReactComponent as IconRanking } from '../../../assets/icons/icon-ranking.svg'
import { ReactComponent as IconNominations } from '../../../assets/icons/icon-nominations.svg'
import { ReactComponent as IconInitialPhase } from '../../../assets/icons/icon-initial-phase.svg'
import { ReactComponent as IconFinalPhase } from '../../../assets/icons/icon-final-phase.svg'

const CompetitionStepsTga = () => {
  const t = useTranslations()

  return (
    <div className="competitionSteps-tga">
      <div className="wrapper">
        <h2 className="stepUp4-title">
          {t('message:competition-format-title')}
        </h2>
        <div className="steps">
          <CompetitionStep icon={IconCompetitions} title="noun:competitions">
            <p className="step-text">{t('competition-tga:text-1-1')}</p>
            <p className="step-text">{t('competition-tga:text-1-2')}</p>
          </CompetitionStep>
          <CompetitionStep icon={IconRanking} title="noun:popularity-ranking">
            <p className="step-text">{t('competition-tga:text-2-1')}</p>
          </CompetitionStep>
          <CompetitionStep icon={IconNominations} title="noun:nominations">
            <p className="step-text">{t('competition-tga:text-3-1')}</p>
            <p className="step-text">{t('competition-tga:text-3-2')}</p>
            <p className="step-text">{t('competition-tga:text-3-3')}</p>
            <p className="step-text">{t('competition-tga:text-3-4')}</p>
          </CompetitionStep>
          <CompetitionStep icon={IconInitialPhase} title="noun:initial-phase">
            <p className="step-text">{t('competition-tga:text-4-1')}</p>
            <p className="step-text">{t('competition-tga:text-4-2')}</p>
          </CompetitionStep>
          <CompetitionStep icon={IconFinalPhase} title="noun:final-phase">
            <p className="step-text">{t('competition-tga:text-5-1')}</p>
            <p className="step-text">{t('competition-tga:text-5-2')}</p>
          </CompetitionStep>
        </div>
      </div>
    </div>
  )
}

export default CompetitionStepsTga
