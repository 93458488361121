import React from 'react'
import PropTypes from 'prop-types'
import imageEcopa from '../../../assets/images/ecopa-transparent.png'
import imageTga from '../../../assets/images/gamers-tga.png'
import imageGt from '../../../assets/images/gt-car.png'

import StaticBanner from './StaticBanner'
import useTranslations from '../../../hooks/useTranslations'

const ecopaBanner = {
  title: 'message:about-ecopa-2021-title',
  text: 'message:about-ecopa-2021-text',
  image: imageEcopa,
  alt: 'Ecopa',
  className: 'banner-ecopa',
}

const tgaBanner = {
  title: 'message:about-tga-title',
  text: 'message:about-tga-text',
  image: imageTga,
  alt: 'Tga',
  className: 'banner-tga',
}

const gtBanner = {
  title: 'message:about-gt-title',
  text: 'message:about-gt-text',
  image: imageGt,
  alt: 'Gran Premio Gt',
  className: 'banner-gt',
}

const StaticBanners = ({ ecopaButton, tgaButton, gtButton }) => {
  const t = useTranslations()
  return (
    <div className="staticBanners">
      <div className="wrapper">
        <h1 className="staticBanners-title">
          {t('message:new-way-enjoy-esports')}
        </h1>
        <p className="staticBanners-subtitle">
          {t('message:bring-esports-closer')}
        </p>
      </div>
      <>
        <StaticBanner banner={ecopaBanner} button={ecopaButton} />
        <StaticBanner banner={tgaBanner} button={tgaButton} />
        <StaticBanner banner={gtBanner} button={gtButton} />
      </>
    </div>
  )
}

const buttonPropType = PropTypes.shape({
  to: PropTypes.string,
  text: PropTypes.string,
})

StaticBanners.propTypes = {
  ecopaButton: buttonPropType,
  tgaButton: buttonPropType,
  gtButton: buttonPropType,
}

export default StaticBanners
