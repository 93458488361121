import React from 'react'
import PropTypes from 'prop-types'
import useTranslations from '../../../hooks/useTranslations'

const HelperCard = ({ textDescription, textTitle }) => {
  const t = useTranslations()

  return (
    <div className="helperCard">
      <p className="helperCard-title">{t(textTitle)}</p>
      <p className="helperCard-description">{t(textDescription)}</p>
    </div>
  )
}

HelperCard.propTypes = {
  textDescription: PropTypes.string.isRequired,
  textTitle: PropTypes.string.isRequired,
}

export default HelperCard
