import React from 'react'
import PropTypes from 'prop-types'
import SocialItem from '../social/SocialItem'
import frame from '../../../assets/images/frame-squared.svg'
import getSharedIcon from '../../../common/get-shared-icon'
import OslPropTypes from '../../../common/osl-prop-types'
import useTranslations from '../../../hooks/useTranslations'

const PortraitSquared = ({ playerImg, social, position, name }) => {
  const t = useTranslations()
  return (
    <div className="portrait is-squared">
      <div className="portrait-avatar">
        <img className="frame" src={frame} alt="frame" />
        <img className="player" src={playerImg} alt={name} />
      </div>
      <div className="portrait-info">
        <p className="portrait-name">{name}</p>
        <p className="portrait-position">{t(position)}</p>
        <div className="portrait-social">
          {social.map(item => (
            <SocialItem
              key={item.sharedIconName}
              icon={getSharedIcon(item.sharedIconName)}
              href={item.href}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

PortraitSquared.propTypes = {
  social: OslPropTypes.socialProps,
  name: PropTypes.string,
  playerImg: PropTypes.string,
  position: PropTypes.string,
}

export default PortraitSquared
