import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

const FooterPartner = ({ href, image, alt, className}) => {

  return (
    <a href={href} className={cx("footer-partner", {[className]: className})} target="_blank" rel="noopener noreferrer">
      <img className="image" src={image} alt={alt} />
    </a>
  );
};

FooterPartner.propTypes = {
  href: PropTypes.string.isRequired,
  image: PropTypes.elementType.isRequired,
  alt: PropTypes.string.isRequired,
  clasName: PropTypes.string
}

export default FooterPartner
