import React from 'react'
import useTranslations from '../../../hooks/useTranslations'
import Checkbox from '../inputs/Checkbox'
import Link from '../links/Link'
import * as routes from '../../../app/routes'

const FormPolicy = ({ formik, isRegistration }) => {
  const t = useTranslations()
  return (
    <>
      <div className="formBase-row">
        <div className="formBase-cell">
          <p className="helperText">
            {t(
              isRegistration
                ? 'registration:paragraph01'
                : 'form-policy:paragraph01',
              {
                b: text => <b>{text}</b>,
              },
            )}
          </p>
          <p className="helperText">
            {t(
              isRegistration
                ? 'registration:paragraph02'
                : 'form-policy:paragraph02',
            )}
          </p>
        </div>
      </div>
      <div className="formBase-row">
        <div className="formBase-cell">
          <Checkbox
            handleChange={formik.handleChange}
            textLabel={
              isRegistration ? 'registration:commercial' : 'form-policy:check01'
            }
            textHelper="input-label:optional"
            checked={
              isRegistration
                ? formik.values.oslComercial
                : formik.values.newsletterPlaneta
            }
            name={isRegistration ? 'oslComercial' : 'newsletterPlaneta'}
            textError={
              isRegistration
                ? formik.errors.oslComercial && formik.touched.oslComercial
                  ? formik.errors.oslComercial
                  : ''
                : formik.errors.newsletterPlaneta &&
                  formik.touched.newsletterPlaneta
                ? formik.errors.newsletterPlaneta
                : ''
            }
          />
          <Checkbox
            handleChange={formik.handleChange}
            textLabel={
              isRegistration ? 'registration:planeta' : 'form-policy:check02'
            }
            textHelper="input-label:optional"
            checked={
              isRegistration
                ? formik.values.oslPlaneta
                : formik.values.newsletterRFEF
            }
            name={isRegistration ? 'oslPlaneta' : 'newsletterRFEF'}
            textError={
              isRegistration
                ? formik.errors.oslPlaneta && formik.touched.oslPlaneta
                  ? formik.errors.oslPlaneta
                  : ''
                : formik.errors.newsletterRFEF && formik.touched.newsletterRFEF
                ? formik.errors.newsletterRFEF
                : ''
            }
          />
          {!isRegistration && (
            <Checkbox
              handleChange={formik.handleChange}
              textLabel="form-policy:check03"
              textHelper="input-label:optional"
              checked={formik.values.thirdParties}
              name="thirdParties"
              textError={
                formik.errors.thirdParties && formik.touched.thirdParties
                  ? formik.errors.thirdParties
                  : ''
              }
            />
          )}
          <Checkbox
            hasDivider
            handleChange={formik.handleChange}
            textLabel={
              isRegistration ? 'registration:privacy' : 'form-policy:check04'
            }
            textHelper={!isRegistration ? 'form-policy:check04-caption' : ''}
            checked={
              isRegistration
                ? formik.values.oslPrivacyPolicy
                : formik.values.privacyPolicy
            }
            name={isRegistration ? 'oslPrivacyPolicy' : 'privacyPolicy'}
            textError={
              isRegistration
                ? formik.errors.oslPrivacyPolicy &&
                  formik.touched.oslPrivacyPolicy
                  ? formik.errors.oslPrivacyPolicy
                  : ''
                : formik.errors.privacyPolicy && formik.touched.privacyPolicy
                ? formik.errors.privacyPolicy
                : ''
            }
          >
            <Link
              isExternal
              kind="primary"
              text="form-policy:check05-link"
              to={
                isRegistration ? routes.PRIVACY.path : routes.ECOPA_PRIVACY.path
              }
            />
            <Link
              isAnchor
              isExternal
              kind="primary"
              text={
                isRegistration
                  ? 'form-policy:check-conditions-link'
                  : 'form-policy:check04-link'
              }
              to={
                isRegistration
                  ? routes.CONDITIONS.path
                  : routes.ECOPA_REGULATIONS_PATH
              }
            />
          </Checkbox>
        </div>
      </div>
    </>
  )
}

export default FormPolicy
