import React, { useState, useEffect } from 'react'

import AvatarUploader from '../../../ui/components/avatar-uploader/AvatarUploader'

const validateAndSetAvatar = (formik, fieldName, file) => {
  if (file.type !== 'image/png' && file.type !== 'image/jpeg') {
    formik.setFieldValue(fieldName, formik.initialValues[fieldName], false)
    formik.setFieldError(fieldName, 'validation-error:avatar-not-an-image')
  } else if (file.size > 2 * 1024 * 1024) {
    formik.setFieldValue(fieldName, formik.initialValues[fieldName], false)
    formik.setFieldError(fieldName, 'validation-error:avatar-greater-than-1mb')
  } else {
    formik.setFieldValue(fieldName, file)
    formik.setFieldTouched(fieldName)
  }
}

const Avatar = ({ formik, fieldName, currentAvatar }) => {
  const [uriAvatar, setUriAvatar] = useState()
  const newAvatar = formik.values[fieldName]

  useEffect(() => {
    if (newAvatar instanceof File) {
      const reader = new FileReader()
      reader.onload = event => {
        setUriAvatar(event.target.result)
      }
      reader.readAsDataURL(newAvatar)
    } else {
      setUriAvatar(currentAvatar)
    }
  }, [newAvatar, currentAvatar])

  const handleChange = event => {
    if (!event.target.files) return
    validateAndSetAvatar(formik, fieldName, event.target.files[0])
  }

  return (
    <AvatarUploader
      fieldName={fieldName}
      handleChange={handleChange}
      avatar={uriAvatar}
      textError={formik.errors[fieldName]}
    />
  )
}

export default Avatar
