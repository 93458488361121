import React from 'react'
import MediaQuery from 'react-responsive'
import CompetitionSlider from '../slider/CompetitionSlider'
import CompetitionStep1 from '../steps/CompetitionStep1'
import CompetitionStep2 from '../steps/CompetitionStep2'
import CompetitionStep3 from '../steps/CompetitionStep3'
import CompetitionStep4 from '../steps/CompetitionStep4'
import { SIZEXL } from '../../common/ui-constants'

const CompetitionSteps = () => {
  return (
    <div className="competitionSteps">
      <MediaQuery maxWidth={SIZEXL - 1}>
        <CompetitionSlider />
      </MediaQuery>
      <MediaQuery minWidth={SIZEXL}>
        <CompetitionStep1 />
        <CompetitionStep2 />
        <CompetitionStep3 />
        <CompetitionStep4 />
      </MediaQuery>
    </div>
  )
}

export default CompetitionSteps
