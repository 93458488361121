import React from 'react'
import PropTypes from 'prop-types'
import Icon from '../icons/Icon'
import useTranslations from '../../../hooks/useTranslations'
import ButtonIcon from '../buttons/ButtonIcon'
import { ReactComponent as IconClose } from '../../../assets/icons/icon-close.svg'
import cx from 'classnames'

const Snackbar = ({
  IconComponent,
  text,
  hasCloseAction,
  handleClose,
  status,
  isAdaptative,
}) => {
  const t = useTranslations()

  return (
    <div
      className={cx(`snackBar snackBar-${status}`, {
        'is-adaptative': isAdaptative,
      })}
    >
      <div className="wrapper">
        {IconComponent && <Icon Component={IconComponent} />}
        <p className="snackBar-text">{t(text)}</p>
        {hasCloseAction ? (
          <ButtonIcon
            className="button-close"
            IconComponent={IconClose}
            aria="close"
            type="button"
            onClick={handleClose}
          />
        ) : null}
      </div>
    </div>
  )
}

Snackbar.propTypes = {
  IconComponent: PropTypes.elementType,
  text: PropTypes.string.isRequired,
  hasCloseAction: PropTypes.bool,
  handleClose: PropTypes.func,
  status: PropTypes.oneOf(['success', 'default']),
  isAdaptative: PropTypes.bool,
}

Snackbar.defaultProps = {
  status: 'default',
}

export default Snackbar
