import React, {
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
  useCallback,
} from 'react'
import PropTypes from 'prop-types'

const Sticky = ({ offsetTop = 0, containerClass = null, children }) => {
  const [isSticky, setSticky] = useState(false)
  const [height, setHeight] = useState(undefined)
  const ref = useRef(null)

  const handleScroll = useCallback(() => {
    if (!ref.current) return
    setSticky(ref.current.getBoundingClientRect().top < -offsetTop)
  }, [offsetTop])

  useEffect(() => {
    let wrapper
    if (containerClass) {
      wrapper = document.querySelector(`.${containerClass}`)
    } else {
      wrapper = window
    }
    wrapper.addEventListener('scroll', handleScroll)

    return () => {
      wrapper.removeEventListener('scroll', handleScroll)
    }
  }, [containerClass, handleScroll, ref])

  useLayoutEffect(() => {
    setHeight(ref.current.getBoundingClientRect().height)
    handleScroll()
  }, [setHeight, handleScroll])

  return (
    <div
      className={isSticky ? 'sticky' : ''}
      style={height ? { height: `${height}px` } : {}}
      ref={ref}
    >
      {children}
    </div>
  )
}

Sticky.propTypes = {
  offsetTop: PropTypes.number,
  containerClass: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

export default Sticky
