import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

const Loader = ({ color, size, className }) => {
  return (
    <div className={cx('loader', className)}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        style={{
          margin: 'auto',
          background: 'transparent',
          display: 'block',
        }}
        width={size}
        height={size}
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
      >
        <defs>
          <linearGradient id="linear">
            <stop
              className="gradient-stop"
              offset="0%"
              stopColor={color}
              stopOpacity="1"
            />
            <stop
              className="gradient-stop"
              offset="100%"
              stopColor={color}
              stopOpacity="0"
            />
          </linearGradient>
        </defs>
        <circle
          cx="50"
          cy="50"
          fill="none"
          stroke="url(#linear)"
          strokeWidth="8"
          r="30"
          strokeDasharray="100%"
        >
          <animateTransform
            attributeName="transform"
            type="rotate"
            repeatCount="indefinite"
            dur="1.2s"
            values="0 50 50;360 50 50"
            keyTimes="0;1"
          ></animateTransform>
        </circle>
      </svg>
    </div>
  )
}

Loader.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
}

Loader.defatultProps = {
  color: '#964bff',
  size: '100px',
}

export default Loader
