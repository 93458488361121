import React from 'react'
import PropTypes from 'prop-types'
import useTranslations from '../../../hooks/useTranslations'
import Icon from '../icons/Icon'
import cx from 'classnames'

const CompetitionStep = ({ number, icon, title, children }) => {
  const t = useTranslations()
  return (
    <div className="competitionStep">
      <div className={cx('step', { 'has-icon': icon })}>
        {icon ? (
          <Icon Component={icon} />
        ) : (
          <span className="number">{number}</span>
        )}
      </div>
      <p className="competitionStep-title">{t(title)}</p>
      {children}
    </div>
  )
}

CompetitionStep.propTypes = {
  number: PropTypes.number,
  icon: PropTypes.elementType,
  children: PropTypes.node,
  title: PropTypes.string,
}

export default CompetitionStep
