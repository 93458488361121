import React, {useState} from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Avatar from './Avatar'
import EditPersonalInfo from './EditPersonalInfo'
import * as routes from '../../../app/routes'
import { personalInformationSchema } from '../../../app/validation/schemas'
import { submitUpdateProfile } from '../../../services/user'
import { showEphemeralEditProfileSnackbar } from '../../../redux/actions'
import Button from '../../../ui/components/buttons/Button'
import RequestErrorCard from '../../../ui/components/cards/RequestErrorCard'

const formatDate = (year, month, day) => {
  const yyyy = String(year).padStart(4, '0')
  const mm = String(1 + Number(month)).padStart(2, '0')
  const dd = String(day).padStart(2, '0')

  return new Date(`${yyyy}-${mm}-${dd}`).toUTCString()
}

const formatProfile = profile => {
  const formData = new FormData()
  formData.append('profilePic', profile.newAvatar)
  formData.append('name', profile.name)
  formData.append('surname', profile.surname)
  formData.append('birth', formatDate(profile.year, profile.month, profile.day))
  formData.append('location', profile.location)

  return formData
}

const PersonalInformation = ({profile}) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [showValidationError, setShowValidationError] = useState(false)

  const birthDate = profile ? new Date(profile.birth) : undefined

  const initialValues = {
    newAvatar: '',
    name: profile?.name ?? '',
    surname: profile?.surname ?? '',
    day: birthDate?.getDate(),
    month: birthDate?.getMonth(),
    year: birthDate?.getFullYear(),
    location: profile?.location ?? '',
  }

  const submitUpdateProfileData = async formData => {
    const { success } = await submitUpdateProfile(formData)
    if (success) {
      dispatch(showEphemeralEditProfileSnackbar())
      history.push(routes.USER_PROFILE.path)
    } else {
      setShowValidationError(true)
    }
  }

  const handleOnSubmit = async values => {
    setIsSubmitting(true)
    setShowValidationError(false)
    const formData = formatProfile(values)
    submitUpdateProfileData(formData)
    setIsSubmitting(false)
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object(personalInformationSchema),
    onSubmit: handleOnSubmit,
  })

  return (
    <form className="personalInfo-form" onSubmit={formik.handleSubmit}>
      <Avatar
        formik={formik}
        fieldName="newAvatar"
        currentAvatar={profile.profile_pic}
      />
      <EditPersonalInfo formik={formik} />
      {showValidationError && <RequestErrorCard textTitle="error:profile-failed-title" />}
      <div className="submitBtn">
        <Button
          as="button"
          type="submit"
          onClick={formik.handleSubmit}
          text="action:save-changes"
          kind="primary"
          isDisabled={Object.keys(formik.touched).length === 0}
          isLoading={isSubmitting}
          isLight
        />
      </div>
    </form>
  )
}

export default PersonalInformation
