import React from 'react'
import PropTypes from 'prop-types'
import useTranslations from '../../../hooks/useTranslations'
import ball from '../../../assets/images/ball.svg'

const CounterBanner = ({ number, title }) => {
  const t = useTranslations()

  return (
    <div className="counterBanner">
      <div className="wrapper">
        <img className="counterBanner-ball" src={ball} alt="football" />
        <div className="counterBanner-content">
          <p className="counterBanner-number">{number}</p>
          <h3 className="stepUp2-title">{t(title)}</h3>
        </div>
        <img className="counterBanner-ball" src={ball} alt="football" />
      </div>
    </div>
  )
}

CounterBanner.propTypes = {
  title: PropTypes.string,
  number: PropTypes.number,
}

export default CounterBanner
