import React from 'react'
import PropTypes from 'prop-types'
import GenericBanner from '../components/banners/GenericBanner'
import Wysiwyg from '../components/wysiwyg/Wysiwyg'

const RawLink = ({ children }) => {
  const content = children.replace('https://', '').replace('http://', '')
  return (
  <a href={children} target="_blank" rel="noopener noreferrer">
    {content}
  </a>
)}

const RawMailto = ({ children }) => (
  <a href={`mailto:${children}`}>{children}</a>
)

const Privacy = ({ title }) => {
  return (
    <>
      <GenericBanner title={title} />
      <Wysiwyg>
        <h3 className="wysiwyg-headline">
          1. ¿Quién es el Responsable del tratamiento de sus datos?
        </h3>
        <p className="wysiwyg-paragraph">
          DeAPlaneta, con CIF B62490461, será la responsable del tratamiento de
          sus datos personales. Dicha compañía se encuentra ubicada en Avda.
          Diagonal 662-664, 08034 Barcelona, pudiendo ponerse en contacto con la
          misma a través de la dirección electrónica{' '}
          <RawMailto>lpd@deaplaneta.com</RawMailto>.
        </p>
        <p className="wysiwyg-paragraph">
          Igualmente, podrá ponerse en contacto con nuestro Delegado de
          Protección de Datos mediante escrito dirigido a{' '}
          <RawMailto>dpo@planeta.es</RawMailto> o a Grupo Planeta, At.: Delegado
          de Protección de Datos, Avda. Diagonal 662-664, 08034 Barcelona.
        </p>
        <h3 className="wysiwyg-headline">
          2. ¿Con qué finalidad y base legitimadora tratamos sus datos
          personales?
        </h3>
        <p className="wysiwyg-paragraph">
          Los datos recabados a través del formulario de “registro”, así como
          aquellos otros posteriormente facilitados a través de esta web o los
          que se generen como consecuencia de su participación en los diferentes
          campeonatos o competiciones de eSports (todos ellos también
          denominados, en adelante, como “los datos”) serán tratados para las
          finalidades descritas a continuación.
        </p>
        <div className="indent-sm">
          <p>
            2.1. Para gestionar su registro y alta como usuario de esta página
            web, a los efectos de posibilitar su inscripción en campeonatos y
            competiciones de e-Sports. Lo anterior implica el tratamiento de los
            datos personales para:
          </p>
          <ol className="wysiwyg-list-latin">
            <li>
              Gestionar su registro como usuarios y crearle una cuenta en la
              página web;
            </li>
            <li>
              Posibilitar su inscripción en las competiciones y campeonatos de
              eSports organizados por DeAplaneta.
            </li>
            <li>
              Publicar en la web y difundir públicamente a través de diversos
              medios de comunicación los resultados y clasificaciones derivados
              de los campeonatos y competiciones en los que participe, así como
              sus datos identificativos (nombre de usuario).
            </li>
          </ol>
          <p>
            La base legitimadora de dichos tratamientos es la ejecución de la
            relación existente entre DeAplaneta y Usted, la cual existirá desde
            el momento en que acepte los términos y condiciones de la página web
            para la participación en las competiciones de eSports.
          </p>
          <p>
            Los datos solicitados en el momento de su registro marcados en un
            asterisco (*) resultan necesarios para poder gestionar su alta como
            usuario y posterior inscripción en los diferentes campeonatos y
            competiciones, de modo que, si no nos facilita dichos datos, no
            podremos gestionar dicha alta ni la citada inscripción.
          </p>
          <p>
            2.2. Para la creación de un perfil personal de usuario. Dicho perfil
            estará formado por el “nombre de usuario” que usted haya
            seleccionado, así como por el resto de los datos (incluidas
            fotografías) que Usted decida, voluntariamente, incluir en el mismo.
            Su perfil personal de usuario, así como la información contenida en
            el mismo, incluidos los datos que voluntariamente Usted haya
            incluido podrá ser visualizado por parte de todas las personas que
            visiten la página web.
          </p>
          <p>
            2.3. La base legitimadora para el tratamiento de dichos datos es la
            ejecución de la relación existente entre DeAplaneta y Usted, la cual
            existirá desde el momento en que acepte los términos y condiciones
            de la página web para la participación en las competiciones de
            eSports. Usted podrá, en cualquier momento, oponerse al tratamiento
            de los datos que voluntariamente haya incluido en su perfil, lo que
            implicará la desaparición de los mismos del citado perfil, a
            excepción de su “nombre de usuario” , que se mantendrá visible para
            todas las personas que visiten la página web en tanto en cuanto
            Usted no cancele su registro en la misma. En el caso de que Usted se
            haya registrado en esta página web, DeAplaneta podrá tratar sus
            datos para remitirle comunicaciones comerciales, por cualquier
            medio, sobre productos, servicios o eventos de DeAplaneta
            relacionados con el mundo de los eSports. Usted podrá, en cualquier
            momento, oponerse a la recepción de dichas comunicaciones
            comerciales, sin que ello afecte a su condición de usuario
            registrado. La base legitimadora es el interés legítimo del
            responsable del tratamiento, en aplicación del art 21.2 de la Ley de
            Servicios de la Sociedad de la Información y Comercio Electrónico.
          </p>
          <p>
            2.4. Si nos facilita sus datos a través del formulario web “Soporte
            y Asistencia”, trataremos sus datos con el fin de atender su
            solicitud, contactar con usted por cualquier medio a los efectos de
            prestarle la asistencia requerida y proporcionarle la información
            correspondiente para solventar cualquier incidencia, duda o
            cuestiones que pueda tener. La base legitimadora del tratamiento de
            sus datos es el consentimiento prestado por Usted al dirigirnos
            libre y voluntariamente la solicitud correspondiente.
          </p>
          <p>
            2.5. En el supuesto de que se suscriba a nuestra Newsletter, a
            través del formulario ubicado en la presente página web, sus datos
            serán tratados para informarle de eventos, concursos, noticias,
            ofertas, descuentos y novedades relativos a eSports. La base
            legitimadora de este tratamiento es el consentimiento prestado por
            Usted al suscribirse libre y voluntariamente a nuestra Newsletter.
            Usted podrá retirar su consentimiento, darse de baja u oponerse a la
            recepción de dichas comunicaciones en cualquier momento, a través de
            la opción facilitada en cada correo electrónico o bien ejercitando
            sus derechos a través de las direcciones facilitadas en el apartado
            6 de esta Política de Privacidad.
          </p>
          <div>
            <p>
              2.6. Con el fin de poder ofrecerle productos y servicios de
              DeAPlaneta, o de terceras empresas adecuados a sus intereses, así
              como para mejorar su experiencia de usuario de esta página Web,
              elaboraremos un “perfil comercial” en base tanto a la información
              facilitada por Usted como a aquella otra derivada de sus hábitos
              de consumo y navegación y la obtenida de redes sociales.
            </p>
            <p>
              Adicionalmente, sus datos personales y perfil comercial serán
              cedidos al resto de empresas del Grupo Planeta para que éstas le
              remitan comunicaciones comerciales sobre sus propios productos y
              servicios o de terceras empresas pertenecientes o ajenas al Grupo
              Planeta, elaborando para ello, a su vez, su propio perfil
              comercial con dichos datos y perfiles, así como con los datos y
              perfiles del usuario que ya tuviera u obtenga, todo ello, para que
              dichas comunicaciones sean de su interés por adecuarse a sus
              gustos, hábitos de consumo y navegación y preferencias
              comerciales. Este tratamiento implicará, por tanto, el cruce de
              bases de datos de las distintas empresas del Grupo Planeta y
              enriquecimiento de las mismas.
            </p>
            <p>
              Puede consultarse el listado de empresas del Grupo Planeta en el
              siguiente link <RawLink> https://www.planeta.es</RawLink>. Dichas empresas
              desarrollan su actividad en los sectores editorial, formación,
              cultura, ocio, coleccionismo, textil, seguros privados, gran
              consumo y joyería.
            </p>
            <p>
              En cualquier caso, las terceras empresas ajenas a nuestro grupo de
              las que, en su caso, le enviaremos comunicaciones comerciales
              serán aquellas que desarrollen su actividad en los mismos sectores
              que las empresas del Grupo Planeta y que han sido indicados
              anteriormente, o en los sectores de Telecomunicaciones, bancario y
              financiero, viajes, tecnología y actividades jurídicas.
            </p>
            <p>
              La base legitimadora de los indicados tratamientos de datos es el
              consentimiento, en su caso, presado por el Usted, el cual podrá
              retirar en cualquier momento.
            </p>
          </div>
        </div>

        <h3 className="wysiwyg-headline">
          3. ¿A qué destinatarios se comunicarán los datos?
        </h3>
        <p className="wysiwyg-paragraph">
          En el caso de que nos dé su consentimiento para ello, sus datos
          personales y, en su caso, su perfil comercial, serán comunicados al
          resto de empresas del Grupo Planeta con las finalidades indicadas en
          el punto “¿Con qué finalidad y base legitimadora tratamos sus datos
          personales?” de la presente Política de Privacidad. Puede consultar el
          listado de empresas del Grupo Planeta en el siguiente link 
          <RawLink> https://www.planeta.es</RawLink>.
        </p>
        <p className="wysiwyg-paragraph">
          Asimismo, en el caso que Usted preste su consentimiento para la
          elaboración de un perfil personal de usuario, los datos incluidos en
          dicho perfil podrán ser visualizados por parte de todas las personas
          que visiten esta página web.
        </p>
        <p className="wysiwyg-paragraph">
          Adicionalmente a lo anterior, ha de tenerse en cuenta que el registro
          en la presente página web y la participación en competiciones o
          campeonatos conlleva el hecho de que se difundan los resultados de
          dichas competiciones y, con ello, datos personales de los
          participantes relativos a su “Nombre de Usuario” a través de la
          publicación en la propia página web, así como a través de medios de
          comunicación.
        </p>
        <p className="wysiwyg-paragraph">
          Finalmente, DeAplaneta también puede revelar los datos personales de
          los usuarios según lo requiera la ley, o cuando resulte necesario para
          proteger los derechos de las mismas, la seguridad del resto de
          usuarios o de terceros , investigar fraudes, o atender las solicitudes
          de las Administraciones, jueces y/o tribunales.
        </p>
        <h3 className="wysiwyg-headline">
          4. ¿Se llevarán a cabo transferencias internacionales de datos?
        </h3>
        <p className="wysiwyg-paragraph">
          DeAplaneta no llevará a cabo transferencias internacionales de datos.
        </p>

        <h3 className="wysiwyg-headline">
          5. ¿Por cuánto tiempo conservaremos los datos?
        </h3>
        <p className="wysiwyg-paragraph">
          Los datos tratados en base a la relación existente entre DeAplaneta y
          Usted se conservarán mientras exista dicha relación. Una vez
          extinguida la relación, los datos se conservarán bloqueados impidiendo
          su tratamiento, excepto para la puesta a disposición de los datos a
          los jueces y tribunales, el Ministerio Fiscal o las Administraciones
          Públicas competentes, en particular de las autoridades de protección
          de datos, para la exigencia de posibles responsabilidades derivadas
          del tratamiento y solo por su plazo de prescripción .
        </p>
        <p className="wysiwyg-paragraph">
          Los datos recabados sobre la base del consentimiento y el interés
          legítimo se conservarán mientras no se revoque el citado
          consentimiento o hasta que se solicite su supresión. Una vez revocado
          el consentimiento o solicitada la supresión de los datos, los datos se
          conservarán bloqueados impidiendo su tratamiento, excepto para la
          puesta a disposición de los datos a los jueces y tribunales, el
          Ministerio Fiscal o las Administraciones Públicas competentes, en
          particular de las autoridades de protección de datos, para la
          exigencia de posibles responsabilidades derivadas del tratamiento y
          solo por su plazo de prescripción.
        </p>

        <h3 className="wysiwyg-headline">
          6. ¿Cuáles son sus derechos cuando nos facilita sus datos?
        </h3>
        <p className="wysiwyg-paragraph">
          Cualquier persona tiene derecho a obtener confirmación sobre si las
          DeAplaneta está tratando datos personales que les conciernan, o no.
        </p>
        <p className="wysiwyg-paragraph">
          Asimismo, las personas interesadas tienen derecho a acceder a sus
          datos personales, así como a solicitar la rectificación de los datos
          inexactos o, en su caso, solicitar su supresión cuando, entre otros
          motivos, los datos ya no sean necesarios para los fines que fueron
          recogidos.
        </p>
        <p className="wysiwyg-paragraph">
          Igualmente, los interesados podrán solicitar la limitación del
          tratamiento de sus datos, en cuyo caso únicamente los conservaremos
          para el ejercicio o la defensa de reclamaciones.
        </p>
        <p className="wysiwyg-paragraph">
          Los interesados podrán oponerse al tratamiento de sus datos.
          DeAplaneta dejará de tratar los datos, salvo por motivos legítimos
          imperiosos, o el ejercicio o la defensa de posibles reclamaciones. Del
          mismo modo, los interesados podrán revocar el consentimiento prestado
          para el tratamiento de sus datos (incluido el consentimiento prestado
          para la elaboración de perfiles).
        </p>
        <p className="wysiwyg-paragraph">
          Los interesados tienen derecho a recibir, en un formato estructurado,
          de uso común y lectura mecánica, los datos personales que le incumban
          que nos hayan facilitado, y a transmitirlos a otro responsable.
        </p>
        <p className="wysiwyg-paragraph">
          Los interesados, podrán ejercer dichos derechos y la revocación en
          cualquier momento de los consentimientos en su caso prestados,
          mediante carta dirigida DeAPlaneta, Apartado de Correos 221 de
          Barcelona o remitiendo un email a{' '}
          <RawMailto>lpd@deaplaneta.com</RawMailto>. En este sentido, le
          informamos que tiene a su disposición modelos de ejercicio de los
          citados derechos en la página web de la Agencia Española de Protección
          de Datos (
          <RawLink>
            https://www.aepd.es/es/derechos-y-deberes/conoce-tus-derechos
          </RawLink>
          ).
        </p>
        <p className="wysiwyg-paragraph">
          Cuando el interesado lo considere oportuno podrá presentar una
          reclamación ante la Agencia Española de Protección de Datos,
          especialmente cuando aquél considere que no hemos satisfecho
          debidamente el ejercicio de sus derechos. Dicha reclamación podrá
          presentarla ante la citada autoridad mediante las diferentes opciones
          que la sede electrónica de la Agencia Española de Protección de Datos
          ofrece.
        </p>
      </Wysiwyg>
    </>
  )
}

Privacy.propTypes = {
  title: PropTypes.string,
}

export default Privacy
