import React from 'react'
import PropTypes from 'prop-types'
import EmptyHeader from '../components/utils/EmptyHeader'
import SocialSection from '../components/sections/SocialSection'
import PostHeader from '../components/post/PostHeader'
import Wysiwyg from '../components/wysiwyg/Wysiwyg'
import ArticleThumbnail from '../components/articles/ArticleThumbnail'
import PostThumbnails from '../components/post/PostThumbnails'
import LikeCounter from '../components/like-counter/LikeCounter'
import InnerWrapper from '../components/wrappers/InnerWrapper'

const PostPage = ({ detailHeader, detailBody, thumbnails, likeCounter, onClickCounter, hasLike }) => {
  return (
    <>
      <EmptyHeader withBar />
      <InnerWrapper className="newsDetail" size="lg">
        <PostHeader detailHeader={detailHeader} />
        <Wysiwyg>{detailBody}</Wysiwyg>
        <LikeCounter counter={likeCounter} onClick={onClickCounter} hasLike={hasLike} />
      </InnerWrapper>
      <PostThumbnails thumbnails={thumbnails} />
      <SocialSection />
    </>
  )
}

PostPage.propTypes = {
  detailHeader: PostHeader.propTypes.detailHeader,
  detailBody: PropTypes.node,
  thumbnails: PropTypes.arrayOf(ArticleThumbnail.propTypes.article),
  likeCounter: PropTypes.number,
  onClickCounter: PropTypes.func,
  hasLike: PropTypes.bool
}

export default PostPage
