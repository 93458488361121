import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import ModalContact from '../../ui/components/modals/ModalContact'
import { closeModal, showEphemeralContactSnackbar } from '../../redux/actions'
import { submitContact } from '../../services/user'

import { contactSchema } from '../validation/schemas'

export default props => {
  const dispatch = useDispatch()

  const [showError, setShowError] = useState(false)

  const { email } = useSelector(state => state.user)

  const handlesubmitContact = async (email, newsletter, comment, contact) => {
    setShowError(false)
    const {success} = await submitContact({ email, newsletter, comment, contact })
    if (success) {
      dispatch(closeModal())
      dispatch(showEphemeralContactSnackbar())
    } else {
      setShowError(true)
    }
  }

  const formik = useFormik({
    initialValues: {
      email: email,
      subject: '',
      comment: '',
      privacyPolicy: false,
    },
    validationSchema: Yup.object(contactSchema),
    onSubmit: async values => handlesubmitContact(values.email, values.subject, values.comment, values.privacyPolicy)
  })

  const handleClose = () => dispatch(closeModal())

  const emailField = {
    handleBlur: formik.handleBlur,
    handleChange: formik.handleChange,
    error:
      formik.errors.email && formik.touched.email ? formik.errors.email : '',
    value: formik.values.email,
  }

  const subjectField = {
    handleBlur: formik.handleBlur,
    handleChange: formik.handleChange,
    error:
      formik.errors.subject && formik.touched.subject
        ? formik.errors.subject
        : '',
    value: formik.values.subject,
  }

  const commentField = {
    handleBlur: formik.handleBlur,
    handleChange: formik.handleChange,
    error:
      formik.errors.comment && formik.touched.comment
        ? formik.errors.comment
        : '',
    value: formik.values.comment,
  }

  const privacyCheckField = {
    name: 'privacyPolicy',
    handleBlur: formik.handleBlur,
    handleChange: formik.handleChange,
    error:
      formik.errors.privacyPolicy && formik.touched.privacyPolicy
        ? formik.errors.privacyPolicy
        : '',
    value: formik.values.privacyPolicy,
  }

  return (
    <ModalContact
      onClose={handleClose}
      onSubmit={formik.handleSubmit}
      privacyCheck={privacyCheckField}
      email={emailField}
      subject={subjectField}
      comment={commentField}
      showError={showError}
      {...props}
    />
  )
}
