import React from 'react'
import PropTypes from 'prop-types'

import { errorsType } from '../../../customPropTypes'
import FormSharedItems from './FormSharedItems'
import FormControls from './FormControls'
import FormPolicy from './FormPolicy'
import FormErrors from './FormErrors'
import FormAccess from './FormAccess'
import FormAccessLogged from './FormAccessLogged'

const FormInscription = ({
  isLogged,
  errors,
  formik,
  handleSubmit,
  handleGoToLogin,
  cancelRoute,
}) => {
  return (
    <form className="formBase wrapper" onSubmit={handleSubmit}>
      {isLogged ? (
        <FormAccessLogged formik={formik} />
      ) : (
        <FormAccess formik={formik} handleGoToLogin={handleGoToLogin} />
      )}
      <FormSharedItems formik={formik} />
      <FormPolicy formik={formik} />
      {isLogged ? null : <FormPolicy isRegistration formik={formik} />}
      {errors ? <FormErrors errors={errors} /> : null}
      <FormControls 
        mainActionText="action:inscription" 
        cancelRoute={cancelRoute}
      />
    </form>
  )
}

FormInscription.propTypes = {
  errors: errorsType,
  userName: PropTypes.string,
  isLogged: PropTypes.bool,
  handleSubmit: PropTypes.func,
  handleGoToLogin: PropTypes.func,
  cancelRoute: PropTypes.string,
}

export default FormInscription
