import React from 'react'
import GenericBanner from '../components/banners/GenericBanner'
import Wysiwyg from '../components/wysiwyg/Wysiwyg'

const Conditions = () => {
  return (
    <>
      <GenericBanner title="views:conditions" />
      <Wysiwyg>
        <h3 className="wysiwyg-headline">1) Datos de identificación</h3>
        <p className="wysiwyg-paragraph">
          Usted está visitando la página web www.osl-eu.com titularidad de
          DeAPlaneta S.L (en adelante LA COMPAÑÍA).
        </p>

        <p className="wysiwyg-paragraph">
          Dirección postal: DeAPlaneta S.L., Av. Diagonal, 662-664 08034
          Barcelona <br />
          Dirección email: info@deaplaneta.com <br />
          Telf.: 93 492 88 54 C.I.F.: <br />
          B-62490461 <br />
          Datos Mercantiles: Registro Mercantil de Barcelona, Tomo 33.304, Folio
          91 , Hoja nº B-229.169 inscripción 1ª
        </p>
        <h3 className="wysiwyg-headline">2) Aceptación del Usuario</h3>
        <p className="wysiwyg-paragraph">
          Estas Condiciones de Uso regulan el acceso y utilización de la página
          web www.oslspain.com (en adelante la “Web”) que LA COMPAÑÍA pone a
          disposición de los usuarios de Internet mayores de 14. El acceso a la
          misma implica la aceptación sin reservas de las presentes Condiciones
          de Uso.
        </p>
        <p className="wysiwyg-paragraph">
          LA COMPAÑÍA puede ofrecer a través de la página web, servicios que
          podrán encontrarse sometidos a unas condiciones particulares propias
          sobre las cuales se informará al Usuario en cada caso concreto.
        </p>
        <h3 className="wysiwyg-headline">
          3) Acceso a la página web y Contraseñas
        </h3>
        <p className="wysiwyg-paragraph">
          En general no se exige la previa suscripción o registro como Usuario
          para el acceso y uso de la página web, sin perjuicio de que para la
          utilización de determinados servicios o contenidos de la misma se deba
          realizar dicha suscripción o registro.
        </p>
        <p className="wysiwyg-paragraph">
          Los datos de los Usuarios obtenidos a través de la suscripción o
          registro a la presente página web, están protegidos mediante
          contraseñas elegidas por ellos mismos. El Usuario se compromete a
          mantener su contraseña en secreto y a protegerla de usos no
          autorizados por terceros. El Usuario deberá notificar a LA COMPAÑÍA
          inmediatamente cualquier uso no consentido de su cuenta o cualquier
          violación de la seguridad relacionada con el servicio de la página
          web, de la que haya tenido conocimiento.
        </p>
        <p className="wysiwyg-paragraph">
          LA COMPAÑÍA adopta las medidas técnicas y organizativas necesarias
          para garantizar la protección de los datos de carácter personal y
          evitar su alteración, pérdida, tratamiento y/o acceso no autorizado,
          habida cuenta del estado de la técnica, la naturaleza de los datos
          almacenados y los riesgos a que están expuestos, todo ello, conforme a
          lo establecido por la legislación española de Protección de Datos de
          Carácter Personal.
        </p>
        <p className="wysiwyg-paragraph">
          LA COMPAÑÍA no se hace responsable frente a los Usuarios, por la
          revelación de sus datos personales a terceros que no sea debida a
          causas directamente imputables a LA COMPAÑÍA, ni por el uso que de
          tales datos hagan terceros ajenos a LA COMPAÑÍA.
        </p>
        <h3 className="wysiwyg-headline">4) Uso correcto de la página web</h3>
        <p className="wysiwyg-paragraph">
          El Usuario se compromete a utilizar la página web, los contenidos y
          servicios de conformidad con la Ley, las presentes Condiciones de Uso,
          las buenas costumbres y el orden público. Del mismo modo el Usuario se
          obliga a no utilizar la página web, sus contenidos o los servicios que
          se presten a través de ésta con fines o efectos ilícitos o contrarios
          al contenido de las presentes Condiciones de Uso, lesivos de los
          intereses o derechos de terceros, o que de cualquier forma pueda
          dañar, inutilizar, hacer inaccesibles o deteriorar la página web, sus
          contenidos o sus servicios o impedir un normal disfrute de la misma
          por otros Usuarios.
        </p>
        <p className="wysiwyg-paragraph">
          Asimismo, el Usuario se compromete expresamente a no destruir,
          alterar, inutilizar o, de cualquier otra forma, dañar los datos,
          programas o documentos electrónicos que se encuentren en la Web.
        </p>
        <p className="wysiwyg-paragraph">
          El Usuario se compromete a no obstaculizar el acceso de otros Usuarios
          mediante el consumo masivo de los recursos informáticos a través de
          los cuales LA COMPAÑÍA presta el servicio, así como realizar acciones
          que dañen, interrumpan o generen errores en dichos sistemas o
          servicios.
        </p>
        <p className="wysiwyg-paragraph">
          El Usuario se compromete a no introducir programas, virus, macros,
          applets, controles ActiveX o cualquier otro dispositivo lógico o
          secuencia de caracteres que causen o sean susceptibles de causar
          cualquier tipo de alteración en los sistemas informáticos de LA
          COMPAÑÍA o de terceros.
        </p>
        <h3 className="wysiwyg-headline">5) Publicidad</h3>
        <p className="wysiwyg-paragraph">
          Parte del sitio web puede albergar contenidos publicitarios o estar
          patrocinado. Los anunciantes y patrocinadores son los únicos
          responsables de asegurarse de que el material remitido para su
          inclusión en el sitio web cumple las leyes que en cada caso puedan ser
          de aplicación.
        </p>
        <p className="wysiwyg-paragraph">
          La Compañía no será responsable de cualquier error, inexactitud o
          irregularidad que puedan incluir los contenidos publicitarios o de los
          patrocinadores. En todo caso, para interponer cualquier reclamación
          relacionada con los contenidos publicitarios insertados en este sitio
          web, pueden dirigirse a la siguiente dirección de correo electrónico:
          info@deaplaneta.com
        </p>
        <h3 className="wysiwyg-headline">6) Enlaces de terceros</h3>
        <p className="wysiwyg-paragraph">
          Las presentes Condiciones de Uso se refieren únicamente a la página
          web y contenidos de LA COMPAÑÍA, y no se aplica a los enlaces o a las
          páginas web de terceros accesibles a través de la página web.
        </p>
        <p className="wysiwyg-paragraph">
          Los destinos de dichos enlaces no están bajo el control de LA
          COMPAÑÍA, y LA COMPAÑÍA no es responsable del contenido de ninguna de
          las páginas web de destino de un enlace, ni de ningún enlace incluido
          en una página web a la que se llegue desde la web de LA COMPAÑÍA, ni
          de ningún cambio o actualización de dichas páginas.
        </p>
        <p className="wysiwyg-paragraph">
          Estos enlaces se proporcionan únicamente para informar al Usuario
          sobre la existencia de otras fuentes de información sobre un tema
          concreto, y la inclusión de un enlace no implica la aprobación de la
          página web enlazada por parte de LA COMPAÑÍA.
        </p>
        <h3 className="wysiwyg-headline">
          7) Propiedad intelectual e industrial
        </h3>
        <p className="wysiwyg-paragraph">
          Todos los contenidos de la página Web, salvo que se indique lo
          contrario, son titularidad exclusiva de LA COMPAÑÍA y, con carácter
          enunciativo, que no limitativo, el diseño gráfico, código fuente,
          logos, textos, gráficos, ilustraciones, fotografías, y demás elementos
          que aparecen en la página Web.
        </p>
        <p className="wysiwyg-paragraph">
          Igualmente, todos los nombres comerciales, marcas o signos distintivos
          de cualquier clase contenidos en la página Web están protegidos por la
          Ley.
        </p>
        <p className="wysiwyg-paragraph">
          LA COMPAÑÍA no concede ningún tipo de licencia o autπorización de uso
          personal al Usuario sobre sus derechos de propiedad intelectual e
          industrial o sobre cualquier otro derecho relacionado con su Web y los
          servicios ofrecidos en la misma.
        </p>
        <p className="wysiwyg-paragraph">
          Por ello, el Usuario reconoce que la reproducción, distribución,
          comercialización, transformación, y en general, cualquier otra forma
          de explotación, por cualquier procedimiento, de todo o parte de los
          contenidos de esta página Web constituye una infracción de los
          derechos de propiedad intelectual y/o industrial de LA COMPAÑÍA o del
          titular de los mismos.
        </p>
        <h3 className="wysiwyg-headline">8) Redes sociales</h3>
        <p className="wysiwyg-paragraph">
          El usuario podrá unirse a los Grupos que DeAPlaneta S.L. tiene en
          distintas redes sociales, con la finalidad de estar informado de las
          actividades de la COMPAÑÍA.
        </p>
        <p className="wysiwyg-paragraph">
          El usuario que se haga fan del Grupo DeAPlaneta S.L. acepta las
          condiciones de uso y política de privacidad de la red social
          correspondiente.
        </p>
        <h3 className="wysiwyg-headline">
          9) Protección de datos de carácter personal
        </h3>
        <p className="wysiwyg-paragraph">
          A través de este sitio web se recaban datos de carácter personal de
          los usuarios, por lo que rogamos que el usuario de la misma lea
          atentamente nuestra Política de Privacidad, así como las diferentes
          cláusulas informativas que acompañan a cada uno de los diferentes
          formularios habilitados para la recogida de datos personales.
        </p>
        <p className="wysiwyg-paragraph">
          Asimismo, le informamos que LA COMPAÑÍA utiliza cookies para recoger
          información de los usuarios y registra las direcciones IP de acceso,
          por lo que rogamos, igualmente, que el usuario lea atentamente nuestra
          Política de Cookies.
        </p>
        <p className="wysiwyg-paragraph">
          Se informa al Usuario que, en cualquier caso, los datos de carácter
          personal recabados mediante la presente página web serán tratados en
          cumplimiento de lo previsto en el Reglamento General de Protección de
          Datos de Carácter Personal y en la legislación española aplicable y
          para las finalidades indicadas en las citadas Política de Privacidad y
          Política de Cookies.
        </p>
        <h3 className="wysiwyg-headline">10) Cookies</h3>
        <p className="wysiwyg-paragraph">
          Esta página web utiliza cookies. Los cookies son pequeños ficheros de
          datos que se generan en el ordenador del usuario y que nos permiten
          conocer la siguiente información:
        </p>
        <ul className="wysiwyg-list">
          <li>
            La fecha y la hora de la última vez que el usuario visitó nuestra
            página web.
          </li>
          <li>
            El diseño de contenidos que el usuario escogió en su última visita a
            nuestra página web.
          </li>
          <li>
            Elementos de seguridad que intervienen en el control de acceso a las
            áreas restringidas.
          </li>
        </ul>
        <p className="wysiwyg-paragraph">
          El Usuario tiene la opción de impedir la generación de cookies,
          mediante la selección de la correspondiente opción en su programa
          Navegador.
        </p>
        <p className="wysiwyg-paragraph">
          Si Vd. quiere obtener más información sobre el uso que está página web
          realiza de las cookies, por favor, lea la política de uso específica
          de cookies.
        </p>
        <h3 className="wysiwyg-headline">
          11) Modificación de las Condiciones de Uso
        </h3>
        <p className="wysiwyg-paragraph">
          La Compañía se reserva el derecho de desarrollar o actualizar en
          cualquier momento las Condiciones de Uso de la presente página web por
          lo que el usuario deberá leerlas periódicamente.
        </p>
        <h3 className="wysiwyg-headline">12) Ley aplicable y Jurisdicción</h3>
        <p className="wysiwyg-paragraph">
          La relación entre LA COMPAÑÍA y el Usuario se regirá por la normativa
          española vigente y cualquier controversia se someterá a los Juzgados y
          Tribunales del domicilio del Usuario.
        </p>
        <p className="wysiwyg-paragraph">
          LA COMPAÑÍA perseguirá el incumplimiento de las presentes Condiciones
          de Uso así como cualquier utilización indebida del sitio web
          ejerciendo todas las acciones civiles y penales que le puedan
          corresponder y asistan en derecho.
        </p>
      </Wysiwyg>
    </>
  )
}

export default Conditions
