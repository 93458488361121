import React from 'react'
import PropTypes from 'prop-types'
import defaultAvatar from '../../../assets/images/avatar-mistery-man-big.svg'

const DetailPlayer = ({ avatar, subtitle, title, actions, content }) => {
  return (
    <div className="detailPlayer">
      <div className="detailPlayer-avatar">
        {avatar ? (
          <img src={avatar} alt={subtitle} />
        ) : (
          <img
            className="default-avatar"
            src={defaultAvatar}
            alt="Mistery man"
          />
        )}
      </div>
      <p className="detailPlayer-title">{title}</p>
      {content}
      {actions ? actions : null}
    </div>
  )
}

DetailPlayer.propTypes = {
  avatar: PropTypes.string,
  title: PropTypes.string,
  actions: PropTypes.node,
  content: PropTypes.node,
}

export default DetailPlayer
