import React from 'react'
import PropTypes from 'prop-types'
import OslPropTypes from '../../../common/osl-prop-types'
import useTranslations from '../../../hooks/useTranslations'
import PortraitSquared from '../portraits/PortraitSquared'

const TeamsTga = ({ teams, title }) => {
  const t = useTranslations()
  return (
    <div className="teams-tga">
      <div className="wrapper">
        <h2 className="stepUp4-title">{t(title)}</h2>
        {teams.map(team => (
          <div className="team" key={team.title}>
            <p className="team-title">{team.title}</p>
            {team.members.map(member => (
              <div className="team-member" key={member.name}>
                <PortraitSquared
                  name={member.name}
                  playerImg={member.image}
                  position={member.position}
                  social={member.social}
                />
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  )
}

TeamsTga.propTypes = {
  title: PropTypes.string,
  teams: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      members: PropTypes.arrayOf(
        PropTypes.shape({
          social: OslPropTypes.socialProps,
          name: PropTypes.string,
          playerImg: PropTypes.string,
          position: PropTypes.string,
        }),
      ),
    }),
  ),
}

export default TeamsTga
