import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { ReactComponent as IconLogout } from '../assets/icons/icon-logout.svg'
import PublicNavbarActions from '../ui/components/navigation/PublicNavbarActions'
import UserNavbarActions from '../ui/components/navigation/UserNavbarActions'

import { openModal, logout } from '../redux/actions'
import { MODAL_LOGIN } from './modals/constants'
import useTournamentInfo, {
  STATUS_INSCRIPTIONS_OPEN,
  STATUS_ACTIVE,
} from '../hooks/useTournamentInfo'

import * as routes from './routes'
import { ECOPA2021_SLUG } from './constants'

export default props => {
  const { isLogged, isEnrolled, email } = props

  const { status } = useTournamentInfo(ECOPA2021_SLUG)
  const isInscriptionOpen = status === STATUS_INSCRIPTIONS_OPEN
  const isEcopaActive = status === STATUS_ACTIVE

  const dispatch = useDispatch()
  const history = useHistory()

  if (isLogged) {
    const dropItems = [
      {
        as: 'button',
        name: 'noun:my-profile',
        onClick: () => history.push(routes.USER_PROFILE.path),
        icon: null,
        className: null,
      },
      {
        as: 'button',
        name: 'action:close-session',
        onClick: () => dispatch(logout()),
        icon: IconLogout,
        className: 'button-logout',
      },
    ]
    const enrolledItems = [
      {
        as: 'button',
        name: 'noun:submit-results',
        onClick: () => history.push(routes.ECOPA_SUBMIT_RESULTS.path),
        icon: null,
        className: null,
      },
      ...dropItems,
    ]
    const showEnrolledItems = isEnrolled && isEcopaActive

    return (
      <UserNavbarActions
        name={email}
        dropItems={showEnrolledItems ? enrolledItems : dropItems}
        showInscriptionButton={isInscriptionOpen && !isEnrolled}
      />
    )
  }

  const handleGoToLogin = () => dispatch(openModal(MODAL_LOGIN))
  const handleGoToInscription = () =>
    history.push(routes.ECOPA_INSCRIPTION.path)
  const handleGoToRegister = () => history.push(routes.REGISTER.path)

  return (
    <PublicNavbarActions
      showInscriptionButton={isInscriptionOpen}
      handleGoToLogin={handleGoToLogin}
      handleGoToInscription={handleGoToInscription}
      handleGoToRegister={handleGoToRegister}
    />
  )
}
