import React from 'react'
import PropTypes from 'prop-types'

import { ReactComponent as IconTwitter } from '../../../assets/icons/icon-twitter.svg'
import { ReactComponent as IconWhatsapp } from '../../../assets/icons/icon-whatsapp.svg'
import { ReactComponent as IconFacebook } from '../../../assets/icons/icon-facebook.svg'
import { ReactComponent as IconShare } from '../../../assets/icons/icon-share.svg'
import SocialItem from '../social/SocialItem'
import SocialItemButton from '../social/SocialItemButton'
import SummaryTiles from '../summary-tiles/SummaryTiles'
import useTranslations from '../../../hooks/useTranslations'
import MediaQuery from 'react-responsive'
import { SIZEMD } from '../../common/ui-constants'
import { CMS_URL } from '../../../constants'

const hostUrl = Boolean(process.env.STORYBOOK_RUNNING) ? '' : CMS_URL

const PostHeader = ({ detailHeader, summaryItems }) => {
  const t = useTranslations()
  const {
    title,
    shareWhatsapp,
    shareFacebook,
    shareTwitter,
    share,
    image,
  } = detailHeader
  return (
    <div className="newsDetail-header">
      <h1 className="stepUp4-title">{title}</h1>
      <div className="newsDetail-share">
        <p className="label">{t('action:share')}</p>
        <MediaQuery maxWidth={SIZEMD - 1}>
          <SocialItem icon={IconWhatsapp} href={shareWhatsapp} />
        </MediaQuery>
        <SocialItem icon={IconFacebook} href={shareFacebook} />
        <SocialItem icon={IconTwitter} href={shareTwitter} />
        <SocialItemButton icon={IconShare} onClick={share} />
      </div>
      <figure className="newsDetail-image">
        <img
          src={`${hostUrl}${image.formats.large.url}`}
          srcSet={`${hostUrl}${image.formats.thumbnail.url} 245w,
        ${hostUrl}${image.formats.medium.url} 750w,
        ${hostUrl}${image.formats.large.url} 1000w`}
          sizes="(max-width: 245px) 245px, (max-width: 750px) 750px, (min-width: 1000px) 1000px"
          alt={title}
        />
        <figcaption className="newsDetail-caption">
          {detailHeader.caption}
        </figcaption>
      </figure>
      <SummaryTiles items={summaryItems} />
    </div>
  )
}

PostHeader.propTypes = {
  detailHeader: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    created_at: PropTypes.string,
    author: PropTypes.string,
    share: PropTypes.func,
    shareWhatsapp: PropTypes.string,
    shareFacebook: PropTypes.string,
    shareTwitter: PropTypes.string,
    image: PropTypes.object,
    caption: PropTypes.string,
  }),
  summaryItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
      extra: PropTypes.object,
    }),
  ),
}

export default PostHeader
