import React, { useState } from 'react'

import HeadingBanner from '../../../ui/components/banners/HeadingBanner'
import EmptyHeader from '../../../ui/components/utils/EmptyHeader'
import InnerWrapper from '../../../ui/components/wrappers/InnerWrapper'
import useProfile from '../../../hooks/useProfile'
import ModalEditPassword from './ModalEditPassword'
import EditAccounts from './EditAccounts'
import EditPassword from './EditPassword'
import { AddSocialAccount, EditSocialAccount, AddGamingPlatform, EditGamingPlatform } from './accountsModals'
import PersonalInformation from './PersonalInformation'
import useProfileAccounts from './useProfileAccounts'

export const MODAL_TYPE = {
  SOCIAL: 'socialMedia',
  GAMING: 'gamingPlatforms'
}

export const INNER_MODAL = {
  PASSWORD: 'edit-password',
  ADD_ACCOUNT: 'add-account',
  EDIT_ACCOUNT: 'edit-account'
}

const EditProfilePage = () => {
  const [innerModal, setInnerModal] = useState()
  const [modalType, setModalType] = useState()
  const [editingAccount, setEditingAccount] = useState()
  const { profile } = useProfile()

  const { formik : socialFormik, showError: showSocialError } = 
    useProfileAccounts('social', profile, setInnerModal)
  const { formik : gamingFormik, showError: showGamingError } = 
    useProfileAccounts('gaming', profile, setInnerModal)

return (
    <>
      <EmptyHeader />
      <HeadingBanner isBig title={t => t('action:edit-profile')} />
      {profile && (
        <InnerWrapper size="lg" className="editProfile">
          <PersonalInformation profile={profile} />

            <EditPassword
              onClick={() => {
                setInnerModal(INNER_MODAL.PASSWORD)
              }}
            />
            <EditAccounts
              type={MODAL_TYPE.SOCIAL}
              formik={socialFormik}
              setInnerModal={setInnerModal}
              setModalType={setModalType}
              setEditingAccount={setEditingAccount}
            />
            <EditAccounts
              type={MODAL_TYPE.GAMING}
              formik={gamingFormik}
              setInnerModal={setInnerModal}
              setModalType={setModalType}
              setEditingAccount={setEditingAccount}
            />

        </InnerWrapper>
      )}
      {innerModal === INNER_MODAL.PASSWORD && (
        <ModalEditPassword
          setInnerModal={setInnerModal}
        />
      )}
      {innerModal === INNER_MODAL.ADD_ACCOUNT && modalType === MODAL_TYPE.SOCIAL  && (
        <AddSocialAccount 
          onClose = {() => setInnerModal(null)}
          showError={showSocialError}
          formik={socialFormik}
        />
      )}
      {innerModal === INNER_MODAL.ADD_ACCOUNT && modalType === MODAL_TYPE.GAMING  && (
        <AddGamingPlatform 
          onClose = {() => setInnerModal(null)}
          showError={showGamingError}
          formik={gamingFormik}
        />
      )}
      {innerModal === INNER_MODAL.EDIT_ACCOUNT && modalType === MODAL_TYPE.SOCIAL  && (
        <EditSocialAccount 
          onClose = {() => setInnerModal(null)}
          editingAccount={editingAccount}
          showError={showSocialError}
          formik={socialFormik}
        />
      )}
      {innerModal === INNER_MODAL.EDIT_ACCOUNT && modalType === MODAL_TYPE.GAMING  && (
        <EditGamingPlatform 
          onClose = {() => setInnerModal(null)}
          editingAccount={editingAccount}
          showError={showGamingError}
          formik={gamingFormik}
        />
      )}
    </>
  )
}

export default EditProfilePage