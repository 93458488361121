import React from 'react'

import useTranslations from '../../../hooks/useTranslations'
import { monthOptions } from '../../../common/forms/profile'
import { Select } from '../../../ui/components/inputs/Select'
import EditBirthDate from './EditBirthDate'
import FormikInput from './FormikInput'

const EditPersonalInfo = ({ formik }) => {
  const t = useTranslations()

  return (
    <div className="editProfile-block">
      <h2 className="stepDown1-title">{t('noun:personal-info')}</h2>
      <FormikInput
        formik={formik}
        fieldName="name"
        label="input-label:name"
        autoComplete="username"
      />
      <FormikInput
        formik={formik}
        fieldName="surname"
        label="input-label:surname"
      />
      <EditBirthDate>
        <FormikInput
          formik={formik}
          type="number"
          fieldName="day"
          label="input-label:birth-day"
        />
        <Select
          options={monthOptions.valid}
          handleBlur={formik.handleBlur}
          handleChange={formik.handleChange}
          textLabel="input-label:birth-month"
          name="month"
          value={formik.values['month']}
          textError={
            formik.errors['month'] && formik.touched['month']
              ? formik.errors['month']
              : ''
          }
        />
        <FormikInput
          formik={formik}
          type="number"
          fieldName="year"
          label="input-label:birth-year"
        />
      </EditBirthDate>
      <FormikInput
        formik={formik}
        fieldName="location"
        label="input-label:location"
      />
    </div>
  )
}

export default EditPersonalInfo
