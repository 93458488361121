import {useState} from 'react'
import { useDispatch } from 'react-redux'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { socialAccountsSchema, gamingPlatformsSchema } from '../../validation/schemas'
import { submitUpdateProfile } from '../../../services/user'
import { showEphemeralEditProfileSnackbar } from '../../../redux/actions'
import { SNACKBAR_TEXT_EDIT_SOCIAL_MEDIA, SNACKBAR_TEXT_EDIT_GAMING_PLATFORM } from '../../../hooks/useSnackbar'

const formatProfile = (profile, isSocial) => {
  const formData = new FormData()
  isSocial ? (formData.append('socials', JSON.stringify(profile.socialMedia))) 
  :   (formData.append('platforms', JSON.stringify(profile.gamingPlatforms)))

  return formData
}

export default function useProfileAccounts (type, profile, setInnerModal) {
  const isSocial = type === 'social'

  const dispatch = useDispatch()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [showError, setShowError] = useState(false)

  const submitUpdateProfileData = async formData => {
    const { success } = await submitUpdateProfile(formData)
    if (success) {
      setInnerModal(null)
      dispatch(showEphemeralEditProfileSnackbar(
        isSocial ? SNACKBAR_TEXT_EDIT_SOCIAL_MEDIA : SNACKBAR_TEXT_EDIT_GAMING_PLATFORM
        ))
    } else {
      setShowError(true)
    }
  }

  const handleOnSubmit = async values => {
    setIsSubmitting(true)
    setShowError(false)
    const formData = formatProfile(values, isSocial)
    submitUpdateProfileData(formData)
    setIsSubmitting(false)
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: isSocial ?
    { socialMedia: profile?.socials ?? {} } 
    : { gamingPlatforms: profile?.platforms ?? {} },
    validationSchema: Yup.object(isSocial ? socialAccountsSchema : gamingPlatformsSchema),
    onSubmit: handleOnSubmit,
  })

  return { formik, showError, isSubmitting }
}

