import React from 'react'

import Button from '../../../ui/components/buttons/Button'
import useTranslations from '../../../hooks/useTranslations'

const EditPassword = ({ onClick }) => {
  const t = useTranslations()

  return (
    <div className="editProfile-block">
      <h2 className="stepDown1-title">{t('input-label:password')}</h2>
      <Button
        as="button"
        type="button"
        onClick={onClick}
        text="action:edit-password"
        kind="secondary"
        isLight
      />
    </div>
  )
}

export default EditPassword