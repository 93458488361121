import React from 'react'
import { IntlProvider } from 'react-intl'
import PropTypes from 'prop-types'
import { setupI18n } from '../libs/i18n'

export const ConnectedIntlProvider = ({ children }) => {
  const { messages, locale } = setupI18n()

  return (
    <IntlProvider
      messages={messages}
      locale={locale}
      textComponent={React.Fragment}
    >
      {children}
    </IntlProvider>
  )
}

ConnectedIntlProvider.propTypes = {
  children: PropTypes.node,
}

export default ConnectedIntlProvider
