import { useState, useEffect } from 'react'
import { fetchOutstandingPost } from '../services/cms'

const useOutstandingPost = () => {

  const [post, setPost] = useState(null)

  useEffect(() => {
    async function fetchData() {
      const data = await fetchOutstandingPost()
      setPost(data)
    }
    fetchData()
  }, [])

  return post

}

export default useOutstandingPost