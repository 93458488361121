import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Route, Switch, useLocation, Redirect } from 'react-router-dom'

import ErrorBoundary from './ErrorBoundary'

import '../assets/stylesheets/style.scss'
import { fetchTournamentInscription, remember } from '../redux/actions'
import useTournamentInfo, {
  STATUS_INSCRIPTIONS_OPEN,
} from '../hooks/useTournamentInfo'
import PrivacyEcopa from '../ui/views/PrivacyEcopa'
import Conditions from '../ui/views/Conditions'
import Cookies from '../ui/views/Cookies'
import Error404 from '../ui/views/Error404'
import ErrorInternal from '../ui/views/ErrorInternal'

import PrivateProfilePage from './profiles/PrivateProfilePage'
import PublicProfilePage from './profiles/PublicProfilePage'
import EditProfilePage from './profiles/edit-profile-page'

import MainLayoutRoute from './MainLayoutRoute'
import Privacy from './Privacy'
import EcopaPage from './eCopa/EcopaPage'
import EcopaDivisionPage from './eCopa/EcopaDivisionPage'
import EcopaDivisionBracketPage from './eCopa/EcopaDivisionBracketPage'
import EcopaFinishedBracketPage from './eCopa/EcopaFinishedBracketPage'
import EcopaKnowMore from './eCopa/EcopaKnowMore'
import EcopaInscription from './eCopa/EcopaInscription'
import Registration from './Registration'
import SubmitResultsPage from './SubmitResultsPage'
import GrandPrix from './GrandPrix'
import ScrollToTop from './ScrollToTop'
import News from './News'
import Competition from './Competition'
import Post from './Post'
import Home from './Home'
import TGA from './TGA'
import * as routes from './routes'
import { ECOPA2021_SLUG } from './constants'

import { MODAL_LOGIN, MODAL_PASSWORD_NEW } from './modals/constants'

function App() {
  // const authChecked
  const dispatch = useDispatch()
  const location = useLocation()
  const { email, inscriptions } = useSelector(state => state.user)
  const { authChecked } = useSelector(state => state.flow)

  const isLogged = Boolean(email)
  const isEnrolled = Boolean(inscriptions[ECOPA2021_SLUG])

  useEffect(() => {
    dispatch(remember())
  }, [dispatch])

  useEffect(() => {
    isLogged && dispatch(fetchTournamentInscription(ECOPA2021_SLUG))
  }, [isLogged, dispatch])

  const { status: ecopa2021Status } = useTournamentInfo(ECOPA2021_SLUG)

  const isInscriptionOpen = ecopa2021Status === STATUS_INSCRIPTIONS_OPEN

  return (
    authChecked && (
      <ErrorBoundary key={location.pathname}>
        <ScrollToTop withHash={false} />
        <Switch>
          <MainLayoutRoute
            exact
            path={routes.ROOT.path}
            component={Home}
            email={email}
            isLogged={isLogged}
            isEnrolled={isEnrolled}
            isInscriptionOpen={isInscriptionOpen}
            withPageLayout
          />

          <Route exact path={routes.REGISTER.path}>
            <Registration />
          </Route>

          <MainLayoutRoute
            exact
            path={routes.PRIVACY.path}
            component={Privacy}
            email={email}
            isLogged={isLogged}
            isEnrolled={isEnrolled}
            isInscriptionOpen={isInscriptionOpen}
          />

          <MainLayoutRoute
            exact
            path={routes.CONDITIONS.path}
            component={Conditions}
            email={email}
            isLogged={isLogged}
            isEnrolled={isEnrolled}
            isInscriptionOpen={isInscriptionOpen}
          />

          <MainLayoutRoute
            exact
            path={routes.COOKIES.path}
            component={Cookies}
            email={email}
            isLogged={isLogged}
            isEnrolled={isEnrolled}
            isInscriptionOpen={isInscriptionOpen}
          />

          <Route exact path={routes.ECOPA_INSCRIPTION.path}>
            <EcopaInscription isLogged={isLogged} userName={email} />
          </Route>

          <MainLayoutRoute
            exact
            path={routes.PASSWORD_NEW.path}
            component={Home}
            email={email}
            isLogged={isLogged}
            isEnrolled={isEnrolled}
            isInscriptionOpen={isInscriptionOpen}
            modalKind={MODAL_PASSWORD_NEW}
          />

          <MainLayoutRoute
            exact
            path={routes.USER_PROFILE.path}
            component={PrivateProfilePage}
            isPrivateRoute={true}
            isLight
            email={email}
            isLogged={isLogged}
            isEnrolled={isEnrolled}
            isInscriptionOpen={isInscriptionOpen}
          />

          <MainLayoutRoute
            exact
            path={routes.USER_PROFILE_EDIT.path}
            component={EditProfilePage}
            isPrivateRoute={true}
            email={email}
            isLogged={isLogged}
            isEnrolled={isEnrolled}
            isInscriptionOpen={isInscriptionOpen}
          />

          <Redirect
            exact
            from={routes.PROFILES_SECTION.path}
            to={routes.ROOT.path}
          />
          <MainLayoutRoute
            exact
            path={routes.PROFILES_SUBSECTION.path}
            component={PublicProfilePage}
            isLight
            email={email}
            isLogged={isLogged}
            isEnrolled={isEnrolled}
            isInscriptionOpen={isInscriptionOpen}
          />

          <MainLayoutRoute
            exact
            path={routes.ECOPA_SECTION.path}
            component={EcopaPage}
            email={email}
            isLogged={isLogged}
            isEnrolled={isEnrolled}
            isInscriptionOpen={isInscriptionOpen}
            withPageLayout
          />

          <MainLayoutRoute
            exact
            path={routes.ECOPA_PRIVACY.path}
            component={PrivacyEcopa}
            email={email}
            isLogged={isLogged}
            isEnrolled={isEnrolled}
            isInscriptionOpen={isInscriptionOpen}
          />

          <MainLayoutRoute
            exact
            path={routes.ECOPA_KNOWMORE.path}
            component={EcopaKnowMore}
            email={email}
            isLogged={isLogged}
            isEnrolled={isEnrolled}
            isInscriptionOpen={isInscriptionOpen}
          />

          <MainLayoutRoute
            exact
            path={routes.ECOPA_FINAL_BRACKET.path}
            component={EcopaFinishedBracketPage}
          />

          <Route exact path={routes.ECOPA_SUBMIT_RESULTS.path}>
            {isLogged ? (
              <SubmitResultsPage inscription={inscriptions[ECOPA2021_SLUG]} />
            ) : (
              <Redirect to={routes.LOGIN.path} />
            )}
          </Route>

          <Redirect
            exact
            from={routes.ECOPA_DIVISION_SECTION.path}
            to={routes.ECOPA_SECTION.path}
          />
          <MainLayoutRoute
            exact
            path={routes.ECOPA_DIVISION_SUBSECTION.path}
            component={EcopaDivisionPage}
            email={email}
            isLogged={isLogged}
            isEnrolled={isEnrolled}
            isInscriptionOpen={isInscriptionOpen}
          />

          <MainLayoutRoute
            exact
            path={routes.ECOPA_DIVISION_BRACKET.path}
            component={EcopaDivisionBracketPage}
            email={email}
            isLogged={isLogged}
            isEnrolled={isEnrolled}
            isInscriptionOpen={isInscriptionOpen}
          />

          <MainLayoutRoute
            exact
            path={routes.NEWS_SECTION.path}
            component={News}
            hasOuterWrapper
            email={email}
            isLogged={isLogged}
            isEnrolled={isEnrolled}
            isInscriptionOpen={isInscriptionOpen}
            withPageLayout
          />

          <MainLayoutRoute
            exact
            path={routes.POST.path}
            component={Post}
            email={email}
            isLogged={isLogged}
            isEnrolled={isEnrolled}
            isInscriptionOpen={isInscriptionOpen}
          />

          <MainLayoutRoute
            exact
            path={routes.GT_GRAND_PRIX.path}
            component={GrandPrix}
            email={email}
            isLogged={isLogged}
            isEnrolled={isEnrolled}
            isInscriptionOpen={isInscriptionOpen}
            withPageLayout
          />

          <MainLayoutRoute
            exact
            path={routes.COMPETITION.path}
            component={Competition}
            email={email}
            isLogged={isLogged}
            isEnrolled={isEnrolled}
            isInscriptionOpen={isInscriptionOpen}
            withPageLayout
          />

          <MainLayoutRoute
            exact
            path={routes.TGA.path}
            component={TGA}
            email={email}
            isLogged={isLogged}
            isEnrolled={isEnrolled}
            isInscriptionOpen={isInscriptionOpen}
            withPageLayout
          />

          <MainLayoutRoute
            exact
            path={routes.LOGIN.path}
            component={Home}
            email={email}
            isLogged={isLogged}
            isEnrolled={isEnrolled}
            isInscriptionOpen={isInscriptionOpen}
            modalKind={MODAL_LOGIN}
          />

          <MainLayoutRoute
            exact
            path={routes.INTERNAL_ERROR.path}
            component={ErrorInternal}
            email={email}
            isLogged={isLogged}
            isEnrolled={isEnrolled}
            isInscriptionOpen={isInscriptionOpen}
          />

          <MainLayoutRoute
            exact
            path={routes.NOT_FOUND_ERROR.path}
            component={Error404}
            email={email}
            isLogged={isLogged}
            isEnrolled={isEnrolled}
            isInscriptionOpen={isInscriptionOpen}
          />
          <Redirect to={routes.NOT_FOUND_ERROR.path} />
        </Switch>
      </ErrorBoundary>
    )
  )
}

export default App
