import * as Yup from 'yup'

import {
  platformOptions,
  federationOptions,
  verifiedFifaPlayerOptions,
} from '../../common/forms/inscription'

import {
  matchResultOptions,
  quadrantOptions,
} from '../../common/forms/submit-results'

import {
  monthOptions,
  socialMediaOptions,
  gamingPlatformsOptions,
  requiredHandleServices,
  requiredLinkServices,
} from '../../common/forms/profile'

const file = () =>
  Yup.mixed().test({
    message: 'validation-error:not-a-file',
    name: 'File prototype',
    test: value => {
      if (!value) return true
      return File.prototype.isPrototypeOf(value)
    },
  })

const required = field => field.required('validation-error:field-required')

const checked = field =>
  required(field.oneOf([true], 'validation-error:field-required'))

// User
const email = Yup.string()
  .strict()
  .lowercase('validation-error:email-lowercase')
  .email('validation-error:email-format')
const emailConfirmation = Yup.string()
  .oneOf([Yup.ref('email')], 'validation-error:email-mismatch')
const username = Yup.string().matches(
  '^[0-9a-zñ_]{4,30}$',
  'validation-error:username-format',
)
// Different criteria for passwords in login (passwordOld) to enable the login with passwords created before adding length restriction
const passwordOld = Yup.string()
const password = Yup.string().min(6, 'validation-error:invalid-password')
const passwordConfirmation = Yup.string()
  .oneOf([Yup.ref('password')], 'validation-error:password-mismatch')

// Edit profile
const name = Yup.string()
const surname = Yup.string()
const location = Yup.string()

const day = Yup.number()
  .min(1, 'validation-error:invalid-day')
  .max(31, 'validation-error:invalid-day')
const month = Yup.number().oneOf(
  monthOptions.valid.map(month => month.value),
  'validation-error:invalid-month',
)
const year = Yup.number()
  .min(1900, 'validation-error:invalid-year')
  .max(new Date().getFullYear(), 'validation-error:invalid-year')

const socialMedia = Yup.object()
const gamingPlatforms = Yup.object()

const service = Yup.string().oneOf(
  [
    ...socialMediaOptions.valid.map(service => service.value),
    ...gamingPlatformsOptions.valid.map(service => service.value),
  ],
  'validation-error:not-a-supported-service',
)
const handle = Yup.string()
const link = Yup.string().url('validation-error:bad-formatted-link')

// TODO: be aware there's another avatar validation at validateAndSetAvatar in EditProfilePage.jsx
const avatar = file()
  .test({
    message: 'validation-error:avatar-not-an-image',
    name: 'PNG or JPG mimetype',
    test: value => {
      if (!value) return true
      return value.type === 'image/png' || value.type === 'image/jpeg'
    },
  })
  .test({
    message: 'validation-error:avatar-greater-than-1mb',
    name: 'Size not greater than 2 MB',
    test: value => {
      if (!value) return true
      return value.size <= 2 * 1024 * 1024
    },
  })

// Platform
const platform = Yup.string().oneOf(
  Object.values(platformOptions.valid).map(option => option.value),
  'validation-error:field-required',
)
const tag = Yup.string()

// Professional activity
const federation = Yup.string().oneOf(
  federationOptions.valid,
  'validation-error:field-required',
)
const verifiedFifaPlayer = Yup.string().oneOf(
  Object.values(verifiedFifaPlayerOptions.valid).map(option => option.value),
)
const idGlobalSeries = Yup.string()

// Checkboxes
const newsletterPlaneta = Yup.bool()
const newsletterRFEF = Yup.bool()
const thirdParties = Yup.bool()
const privacyPolicy = Yup.bool()
const oslComercial = Yup.bool()
const oslPlaneta = Yup.bool()
const oslPrivacyPolicy = Yup.bool()

// Contact
const subject = Yup.string().max(
  200,
  'validation-error:subject-length-exceeded',
)
const comment = Yup.string().max(
  2000,
  'validation-error:comment-length-exceeded',
)

// Submit results
const matchResult = Yup.string().oneOf(
  Object.values(matchResultOptions.valid).map(option => option.value),
  'validation-error:field-required',
)
const roundNumber = Yup.number().min(
  1,
  'validation-error:round-greater-than-zero',
)
const quadrant = Yup.string().oneOf(
  Object.values(quadrantOptions.valid).map(option => option.value),
  'validation-error:field-required',
)
const goals = Yup.number().min(0, 'validation-error:goals-positive')
const screenshot = file()
  .test({
    message: 'validation-error:screenshot-not-an-image',
    name: 'PNG or JPG mimetype',
    test: value => {
      if (!value) return true
      return value.type === 'image/png' || value.type === 'image/jpeg'
    },
  })
  .test({
    message: 'validation-error:screenshot-greater-than-1mb',
    name: 'Size not greater than 1 MB',
    test: value => {
      if (!value) return true
      return value.size <= 1024 * 1024
    },
  })

const isATie = (round1player1, round1player2, round2player1, round2player2) => {
  const player1goals = round1player1 + round2player1
  const player2goals = round1player2 + round2player2
  return player1goals === player2goals
}

// Form schemas
export const loginSchema = {
  email: required(email),
  password: required(passwordOld),
}

export const userRegistrationSchema = {
  username: required(username),
  email: required(email),
  emailConfirmation: required(emailConfirmation),
  password: required(password),
  passwordConfirmation: required(passwordConfirmation),
  oslComercial: required(oslComercial),
  oslPlaneta: required(oslPlaneta),
  oslPrivacyPolicy: checked(oslPrivacyPolicy),
}

export const userInscriptionSchema = {
  username: required(username),
  federation: required(federation),
  platform: required(platform),
  tag: required(tag),
  verifiedFifaPlayer: required(verifiedFifaPlayer),
  idGlobalSeries: idGlobalSeries.when('verifiedFifaPlayer', {
    is: verifiedFifaPlayerOptions.valid.yes.value,
    then: schema => required(schema),
  }),
  newsletterPlaneta: required(newsletterPlaneta),
  newsletterRFEF: required(newsletterRFEF),
  thirdParties: required(thirdParties),
  privacyPolicy: checked(privacyPolicy),
}

export const guestInscriptionSchema = {
  ...userInscriptionSchema,
  ...userRegistrationSchema,
}

export const newsleterSubcriptionSchema = {
  email: required(email),
  privacyPolicy: checked(privacyPolicy),
}

export const contactSchema = {
  email: required(email),
  subject: required(subject),
  comment: required(comment),
  privacyPolicy: checked(privacyPolicy),
}

export const validateResultSchema = {
  result: required(matchResult),
  opponentPlatformName: required(tag),
  roundNumber: required(roundNumber),
  quadrant,
}

const requiredWhenTied = schema =>
  schema.when(
    [
      'firstRoundPlayerOneGoals',
      'firstRoundPlayerTwoGoals',
      'secondRoundPlayerOneGoals',
      'secondRoundPlayerTwoGoals',
    ],
    {
      is: isATie,
      then: schema => required(schema),
    },
  )
export const createSubmitResultsSchema = validation => {
  const hasBeenPlayed =
    validation?.result !== matchResultOptions.valid.absentOponent.value

  return {
    firstRoundPlayerOneGoals: hasBeenPlayed ? required(goals) : goals,
    firstRoundPlayerTwoGoals: hasBeenPlayed ? required(goals) : goals,
    firstRoundScreenshot: hasBeenPlayed ? required(screenshot) : screenshot,
    secondRoundPlayerOneGoals: hasBeenPlayed ? required(goals) : goals,
    secondRoundPlayerTwoGoals: hasBeenPlayed ? required(goals) : goals,
    secondRoundScreenshot: hasBeenPlayed ? required(screenshot) : screenshot,
    tieBreakerRoundPlayerOneGoals: requiredWhenTied(goals),
    tieBreakerRoundPlayerTwoGoals: requiredWhenTied(goals),
    tieBreakerRoundScreenshot: requiredWhenTied(screenshot),
  }
}

export const personalInformationSchema = {
  newAvatar: avatar,
  name: required(name),
  surname: surname,
  day: required(day),
  month: required(month),
  year: required(year),
  location: location,
}

export const passwordSchema = {
  newPassword: password,
  confirmPassword: password.when('newPassword', (newPassword, schema) => {
    if (!newPassword) return schema
    return required(
      schema.oneOf([newPassword], 'validation-error:password-mismatch'),
    )
  }),
}

export const socialAccountsSchema = {
  socialMedia: socialMedia,
}

export const gamingPlatformsSchema = {
  gamingPlatforms: gamingPlatforms,
}

export const addAccountSchema = {
  service: required(service),
  handle: handle.when('service', (service, schema) => {
    if (requiredHandleServices.includes(service)) return required(schema)
    return schema
  }),
  link: link.when('service', (service, schema) => {
    if (requiredLinkServices.includes(service)) return required(schema)
    return schema
  }),
}

export const createEditAccountSchema = service => {
  return {
    handle: requiredHandleServices.includes(service)
      ? required(handle)
      : handle,
    link: requiredLinkServices.includes(service) ? required(link) : link,
  }
}
