import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { fetchTournamentWinners } from '../redux/actions'

import { STATUS_FINISHED } from './useTournamentInfo'

export default (tournamentSlug, status) => {
  const dispatch = useDispatch()

  const tournamentWinners = useSelector(state =>
    state.tournaments.winners.find(
      winner => winner.tournamentSlug === tournamentSlug,
    ),
  )

  useEffect(() => {
    if (status === STATUS_FINISHED && !tournamentWinners)
      dispatch(fetchTournamentWinners(tournamentSlug))
  }, [tournamentSlug, status, tournamentWinners, dispatch])

  return tournamentWinners ?? {}
}
