import React from 'react'
import PropTypes from 'prop-types'
import Link from '../links/Link'
import Checkbox from '../inputs/Checkbox'
import OslPropTypes from '../../../common/osl-prop-types'
import useTranslations from '../../../hooks/useTranslations'
import * as routes from '../../../app/routes'

const FormRegistrationPolicy = ({ privacyChecks }) => {
  const t = useTranslations()
  return (
    <div className="formBase-row">
      <div className="formBase-cell">
        <Checkbox
          name="oslComercial"
          textHelper="input-label:optional"
          textLabel="registration:commercial"
          handleChange={privacyChecks.oslComercial.handleChange}
          checked={privacyChecks.oslComercial.value}
          textError={privacyChecks.oslComercial.error}
        />

        <Checkbox
          name="oslPlaneta"
          textHelper="input-label:optional"
          textLabel="registration:planeta"
          handleChange={privacyChecks.oslPlaneta.handleChange}
          checked={privacyChecks.oslPlaneta.value}
          textError={privacyChecks.oslPlaneta.error}
        />

        <p className="helperText has-divider">
          {t('registration:paragraph03')}
        </p>

        <Checkbox
          name="oslPrivacyPolicy"
          textLabel="registration:privacy"
          handleChange={privacyChecks.oslPrivacyPolicy.handleChange}
          checked={privacyChecks.oslPrivacyPolicy.value}
          textError={privacyChecks.oslPrivacyPolicy.error}
        >
          <Link
            isExternal
            kind="primary"
            text="form-policy:check05-link"
            to={routes.PRIVACY.path}
          />
          <Link
            isExternal
            kind="primary"
            text="form-policy:check-conditions-link"
            to={routes.CONDITIONS.path}
          />
        </Checkbox>
      </div>
    </div>
  )
}

FormRegistrationPolicy.propTypes = {
  privacyChecks: PropTypes.shape({
    first: OslPropTypes.inputProps,
    second: OslPropTypes.inputProps,
    third: OslPropTypes.inputProps,
    fourth: OslPropTypes.inputProps,
  }),
}

export default FormRegistrationPolicy
