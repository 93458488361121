import React from 'react'
import PropTypes from 'prop-types'
import Icon from '../icons/Icon'
import useTranslations from '../../../hooks/useTranslations'

const IconListItem = ({ hasLink, to, icon, username }) => {
  const t = useTranslations()
  return (
    <li className="iconList-item">
      {hasLink ? (
        <a
          href={to}
          target="_blank"
          rel="noopener noreferrer"
          className="iconList-content"
        >
          <Icon Component={icon} />
          <p className="iconList-username" title={username}>
            {username}
          </p>
          <p className="iconList-linkHint">{t('action:see-profile')}</p>
        </a>
      ) : (
        <div className="iconList-content">
          <Icon Component={icon} />
          <p className="iconList-username" title={username}>
            {username}
          </p>
        </div>
      )}
    </li>
  )
}

IconListItem.propTypes = {
  hasLink: PropTypes.bool,
  to: PropTypes.string,
  icon: PropTypes.elementType,
  username: PropTypes.string,
}

export default IconListItem
