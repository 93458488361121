import React from 'react'
import thrustmaster from '../../../assets/images/logo-thrustmaster.svg'
import nacon from '../../../assets/images/logo-nacon.png'
import useTranslations from '../../../hooks/useTranslations'

const BrandsGt = () => {
  const t = useTranslations()
  return (
    <div className="brick is-dark">
      <h2 className="stepUp4-title">{t('message:with-the-collaboration')}</h2>
      <div className="brands-gt">
        <img className="brand" src={thrustmaster} alt="Thrustmaster" />
        <img className="brand" src={nacon} alt="Nacon" />
      </div>
    </div>
  )
}

export default BrandsGt
