import React from 'react'
import PropTypes from 'prop-types'

const Wysiwyg = ({ children }) => {
  return (
    <div className="wysiwyg">
      <div className="wysiwyg-wrapper wrapper">
        {children}
      </div>
    </div>
  )
}

Wysiwyg.propTypes = {
  children: PropTypes.node.isRequired
}

export default Wysiwyg
