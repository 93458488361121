import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import MainNavigation from '../components/navigation/MainNavigation'
import Sticky from '../components/utils/Sticky'
import Footer from '../components/footer/Footer'
import OslPropTypes from '../../common/osl-prop-types'

const MainLayout = ({
  isLight,
  navActions,
  navItems,
  children,
  barSlot,
  hasFooter,
  onClickContact,
  modalSlot,
  hasOuterWrapper,
}) => {
  const layoutClasses = cx('mainLayout', {
    isLight: isLight,
    'has-outer-wrapper': hasOuterWrapper,
  })

  return (
    <div className={layoutClasses}>
      <Sticky offsetTop={100}>
        <MainNavigation items={navItems}>{navActions}</MainNavigation>
      </Sticky>
      {barSlot ? <div className="barSlot">{barSlot}</div> : null}
      <div className="mainContent">{children}</div>
      {hasFooter ? <Footer onClickContact={onClickContact} /> : null}
      {modalSlot}
    </div>
  )
}

MainLayout.propTypes = {
  isLight: PropTypes.bool,
  children: PropTypes.node,
  navActions: OslPropTypes.navActions,
  barSlot: PropTypes.node,
  hasFooter: PropTypes.bool,
  onClickContact: PropTypes.func,
  navItems: MainNavigation.propTypes.items,
  modalSlot: PropTypes.node,
  hasOuterWrapper: PropTypes.bool,
}

MainLayout.defaultProps = {
  hasFooter: true,
}

export default MainLayout
