import React, { useEffect, useState } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import * as routes from './routes'

import { openModal } from '../redux/actions'
import { fetchCommonData, fetchCompetitions } from '../services/cms'
import useBreadcrumbs from '../hooks/useBreadcrumbs'
import useSnackbar from '../hooks/useSnackbar'
import useOutstandingPost from '../hooks/useOutstandingPost'
import NavbarActions from './NavbarActions'
import { appNavItems } from './navigation'
import useModal from './modals/useModal'
import { MODAL_NEWSLETTER, MODAL_CONTACT } from './modals/constants'

import PageLayout from '../ui/layout/PageLayout'
import Breadcrumbs from '../ui/components/breadcrumbs/Breadcrumbs'
import MainLayout from '../ui/layout/MainLayout'

export default ({
  component: Component,
  isLogged,
  isEnrolled,
  isInscriptionOpen,
  email,
  isLight,
  hasOuterWrapper,
  modalKind,
  isPrivateRoute = false,
  withPageLayout = false,
  ...rest
}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const breadcrumbs = useBreadcrumbs()
  const snackbar = useSnackbar()
  const modal = useModal()
  const [sponsors, setSponsors] = useState([])
  const [navItems, setNavItems] = useState(appNavItems)
  const withOutstandingPost = withPageLayout && (rest.path !== routes.NEWS_SECTION.path)
  const outstandingPost = withOutstandingPost ? useOutstandingPost() : null

  const navbarActions = (
    <NavbarActions isLogged={isLogged} isEnrolled={isEnrolled} email={email} />
  )
  const barSlot = (
    <>
      <Breadcrumbs items={breadcrumbs} kind={isLight ? 'light' : 'dark'} />
      {snackbar}
    </>
  )

  const handleClickNewsletter = () => dispatch(openModal(MODAL_NEWSLETTER))
  const handleClickContact = () => dispatch(openModal(MODAL_CONTACT))

  useEffect(() => {
    if (modalKind) {
      dispatch(openModal(modalKind))
    }
  }, [dispatch, modalKind])

  useEffect(() => {
    async function fetchData() {
      const data = await fetchCommonData()
      setSponsors(data.sponsors)
    }
    withPageLayout && fetchData()
  }, [withPageLayout])

  useEffect(() => {
    const getCompetitionsSubmenu = async () => {
      const competitions = await fetchCompetitions()
      const competitionsWithSubmenu = appNavItems.map((item) => {
        if(item.to === routes.COMPETITIONS.path) {
          const dropItems = getCompetitionsDropItems(competitions, history)
          return {...item, dropItems}
        } else return item
      })
      return competitionsWithSubmenu
    }

    const getNavItemsWithSubmenus = async () => {
      const itemsWithCompSubmenu = await getCompetitionsSubmenu()
      setNavItems(itemsWithCompSubmenu)
    }

    getNavItemsWithSubmenus()
  }, [history])

  return (
    <Route
      {...rest}
      render={props => (
        <MainLayout
          isLight={isLight}
          hasOuterWrapper={hasOuterWrapper}
          navActions={navbarActions}
          navItems={navItems}
          barSlot={barSlot}
          modalSlot={modal}
          onClickContact={handleClickContact}
        >
          {!isLogged && isPrivateRoute ? (
            <Redirect to={routes.LOGIN.path} />
          ) : (
            withPageLayout ?
            <PageLayout outstandingPost={outstandingPost} sponsors={sponsors}>
              <Component
                isLogged={isLogged}
                isEnrolled={isEnrolled}
                isInscriptionOpen={isInscriptionOpen}
                onClickContact={handleClickContact}
                onClickNewsletter={handleClickNewsletter}
                {...props}
              />
            </PageLayout> :
            <Component
              commonSponsors={sponsors}
              isLogged={isLogged}
              isEnrolled={isEnrolled}
              isInscriptionOpen={isInscriptionOpen}
              onClickContact={handleClickContact}
              onClickNewsletter={handleClickNewsletter}
              {...props}
            />
          )}
        </MainLayout>
      )}
    />
  )
}

const getCompetitionsDropItems = (competitions, history) => {
  const mainCompetDropItems = [{
    as: 'button',
    name: 'Gran Premio GT',
    onClick: () => history.push(routes.GT_GRAND_PRIX.path),
  }]

  const fetchedCompetDropItems = competitions.map( competition => ({
    as: 'button',
    name: competition.title,
    onClick: () => history.push(routes.COMPETITIONS.path+`/${competition.title}`),
  }))
  
  return mainCompetDropItems.concat(fetchedCompetDropItems)
}
