import React from 'react'
import PropTypes from 'prop-types'

const Sponsor = ({ to, sponsor, img }) => {
  return (
    <a href={to} target="_blank" rel="noopener noreferrer" className="sponsor">
      <img className="sponsor-image" src={img} alt={sponsor} />
    </a>
  )
}

Sponsor.propTypes = {
  to: PropTypes.string,
  img: PropTypes.string,
  sponsor: PropTypes.string,
}

export default Sponsor
