import React from 'react'
import PropTypes from 'prop-types'
import CtaNewsletter from '../components/ctas/CtaNewsletter'
import HeadingBanner from '../components/banners/HeadingBanner'
import useTranslations from '../../hooks/useTranslations'
import Button from '../components/buttons/Button'
import TournamentActions from '../components/integrations/TournamentActions'
import { Link } from 'react-router-dom'
import EmptyHeader from '../components/utils/EmptyHeader'

const EcopaActiveBracketP1 = ({
  title,
  subtitle,
  caption = () => null,
  widgetUrl,
  handleClickNewsletter,
  fullBracketButtonTo,
}) => {
  const t = useTranslations()

  return (
    <>
      <EmptyHeader />
      <HeadingBanner
        title={title}
        subtitle={subtitle}
        caption={caption(t)}
        isBig
      />
      <TournamentActions
        url={widgetUrl}
        actions={
          <>
            <Button
              as={Link}
              to={fullBracketButtonTo}
              kind="primary"
              isLight
              text="message:see-full-bracket"
            />
          </>
        }
      />
      <CtaNewsletter
        title="message:cta-newsletter-title"
        handleClick={handleClickNewsletter}
      />
    </>
  )
}

EcopaActiveBracketP1.propTypes = {
  title: PropTypes.func,
  subtitle: HeadingBanner.propTypes.subtitle,
  caption: PropTypes.func,
  widgetUrl: PropTypes.string,
  handleClickNewsletter: PropTypes.func,
  fullBracketButtonTo: PropTypes.string,
}

export default EcopaActiveBracketP1
