import React from 'react'
import PropTypes from 'prop-types'
import SocialItem from '../social/SocialItem'
import { Link } from 'react-router-dom'
import PortraitTiny from './PortraitTiny'
import useTranslations from '../../../hooks/useTranslations'
import getSharedIcon from '../../../common/get-shared-icon'
import OslPropTypes from '../../../common/osl-prop-types'

const PortraitCard = ({
  title,
  playerImg,
  social,
  userName,
  platformName,
  to,
}) => {
  const t = useTranslations()

  return (
    <div className="portraitCard">
      <Link className="portraitCard-link" to={to} />
      <PortraitTiny title={title} playerImg={playerImg} />
      <div className="portraitCard-info">
        <p className="portraitCard-title">{title(t)}</p>
        <p className="portraitCard-platformName">{platformName}</p>
        <p className="portraitCard-userName">{userName}</p>
        <div className="portraitCard-social">
          {social.map(item => (
            <SocialItem
              key={item.sharedIconName}
              icon={getSharedIcon(item.sharedIconName)}
              href={item.href}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

PortraitCard.propTypes = {
  to: PropTypes.string,
  social: OslPropTypes.socialProps,
  title: PropTypes.func,
  playerImg: PropTypes.string,
  userName: PropTypes.string,
  platformName: PropTypes.string,
}

export default PortraitCard
