import React from 'react'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import { useFormik } from 'formik'

import { errorsType } from '../../customPropTypes'

import FormInscription from '../components/forms/FormInscription'
import IntroBanner from '../components/banners/IntroBanner'

const Inscription = ({
  isLogged,
  errors,
  bannerName,
  bannerTitle,
  modalSlot,
  initialValues,
  validationSchema,
  handleSubmit,
  handleGoToLogin,
  cancelRoute,
}) => {
  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object(validationSchema),
    onSubmit: handleSubmit,
  })

  return (
    <>
      <IntroBanner title={bannerTitle} name={bannerName} />
      <FormInscription
        isLogged={isLogged}
        errors={errors}
        formik={formik}
        handleSubmit={formik.handleSubmit}
        handleGoToLogin={handleGoToLogin}
        cancelRoute={cancelRoute}
      />
      {modalSlot}
    </>
  )
}

Inscription.propTypes = {
  isLogged: PropTypes.bool,
  errors: errorsType,
  bannerName: PropTypes.string,
  bannerTitle: PropTypes.string.isRequired,
  modalSlot: PropTypes.node,
  initialValues: PropTypes.object,
  validationSchema: PropTypes.object,
  handleSubmit: PropTypes.func,
  handleGoToLogin: PropTypes.func,
  cancelRoute: PropTypes.string,
}

export default Inscription
