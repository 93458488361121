import React from 'react'
import PropTypes from 'prop-types'
import useTranslations from '../../../hooks/useTranslations'

const Countdown = ({ topLabel, bottomLabel, children }) => {
  const t = useTranslations()

  return (
    <div className="countdown">
      <p className="genericLabel">{t(topLabel)}</p>
      <div className="countdown-content">{children}</div>
      <p className="genericLabel">{t(bottomLabel)}</p>
    </div>
  )
}

Countdown.propTypes = {
  topLabel: PropTypes.string,
  bottomLabel: PropTypes.string,
  children: PropTypes.node,
}

export default Countdown
