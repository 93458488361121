import React from 'react'
import smGamers from '../../../assets/images/gamers-min.png'
import lgGamers from '../../../assets/images/gamers@2x-min.png'

import { playlistEcopa2020 } from '../../../data/ecopa'

import Cta from './Cta'

const CtaLastEcopa = () => {
  return (
    <Cta
      buttonAs="a"
      print="blue"
      title="message:last-ecopa-title"
      buttonKind="primary"
      buttonHref={playlistEcopa2020}
      buttonText="message:last-ecopa-button"
      imgAlt="Torneo eCopa 2020"
      smImg={smGamers}
      lgImg={lgGamers}
      smWidth="799w"
      lgWidth="1598w"
    />
  )
}

export default CtaLastEcopa
