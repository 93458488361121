import React from 'react'
import PropTypes from 'prop-types'
import useTranslations from '../../../hooks/useTranslations'
import { Link } from 'react-router-dom'
import cx from 'classnames'

const Breadcrumbs = ({ items, kind }) => {
  const t = useTranslations()

  return items.length > 1 ? (
    <nav
      className={cx(`breadcrumbs breadcrumbs--${kind}`)}
      aria-label="breadcrumb"
    >
      <div className="wrapper">
        <ol className="breadcrumb">
          {items.map((item, i) => (
            <li className="breadcrumb-item" key={i}>
              {item.notCurrent ? (
                <Link to={item.to}>
                  {item.literal ? item.name : t(item.name)}
                </Link>
              ) : (
                <span>{item.literal ? item.name : t(item.name)}</span>
              )}
            </li>
          ))}
        </ol>
      </div>
    </nav>
  ) : null
}

Breadcrumbs.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      notCurrent: PropTypes.bool,
      to: PropTypes.string,
      name: PropTypes.string,
      literal: PropTypes.bool,
    }),
  ),
  kind: PropTypes.oneOf(['default', 'dark', 'light']).isRequired,
}

Breadcrumbs.defaultProps = {
  kind: 'default',
}

export default Breadcrumbs
