import React from 'react'
import PropTypes from 'prop-types'
import ErrorBanner from '../components/banners/ErrorBanner'
import useTranslations from '../../hooks/useTranslations'
import Button from '../components/buttons/Button'
import { Link } from 'react-router-dom'
import * as routes from '../../app/routes'

const ErrorInternal = ({ notification }) => {
  const t = useTranslations()

  return (
    <ErrorBanner title="views:internal-title" print="orange">
      <h3 className="error-subtext">{t('views:internal-subtext')}</h3>
      <Button
        as={Link}
        to={routes.ROOT.path}
        kind="primary"
        text="action:reload-site"
      />
    </ErrorBanner>
  )
}

ErrorInternal.propTypes = {
  notification: PropTypes.node,
}

export default ErrorInternal
