import React from 'react'
import PropTypes from 'prop-types'
import useTranslations from '../../../hooks/useTranslations'
import ErrorCard from '../cards/ErrorCard'
import OslPropTypes from '../../../common/osl-prop-types'
import FileName from '../inputs/FileName'

const FormResult = ({
  title,
  inputPlayerOne,
  inputPlayerTwo,
  inputScreenshot,
  showError,
  isOptional,
  onDelete,
  file,
  fileError,
}) => {
  const t = useTranslations()
  return (
    <div className="panel formPanel">
      <h3 className="stepUp1-title">
        {t(title)}{' '}
        {isOptional ? (
          <span className="optional-label">{t('input-label:optional')}</span>
        ) : null}
      </h3>
      <div className="inlineInputs-field">
        {inputPlayerOne}
        {inputPlayerTwo}
      </div>

      {file ? (
        <FileName
          name={file.name}
          size={file.size}
          onDelete={onDelete}
          caption="message:screenshot-instructions"
          textError={fileError}
        />
      ) : (
        inputScreenshot
      )}

      {showError && (
        <ErrorCard textTitle="error:validation-failed-title">
          <p className="errorCard-description">
            {t('error:validation-failed-description')}
          </p>
        </ErrorCard>
      )}
    </div>
  )
}

FormResult.propTypes = {
  goalsGamer2: OslPropTypes.inputProps,
  goalsGamer1: OslPropTypes.inputProps,
  showError: PropTypes.bool,
  title: PropTypes.string,
  isDisabled: PropTypes.bool,
  isOptional: PropTypes.bool,
  onDelete: PropTypes.func,
  onChange: PropTypes.func,
  fileError: PropTypes.string,
  file: PropTypes.shape({
    name: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
}

export default FormResult
