import React from 'react'
import PropTypes from 'prop-types'
import ReactSlick from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { ReactComponent as IconChevronRight } from '../../../assets/icons/icon-chevron-right.svg'
import { ReactComponent as IconChevronLeft } from '../../../assets/icons/icon-chevron-left.svg'

const SliderArrowFix = ({ children, className, style, onClick }) =>
  children({ className, style, onClick })

const Slider = ({ children, settings = {} }) => {
  const baseSettings = {
    infinite: false,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 899,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 599,
        settings: {
          slidesToShow: 1,
        },
      },
    ],

    nextArrow: (
      <SliderArrowFix>
        {props => <IconChevronRight {...props} />}
      </SliderArrowFix>
    ),
    prevArrow: (
      <SliderArrowFix>{props => <IconChevronLeft {...props} />}</SliderArrowFix>
    ),
  }

  const finalSettings = { ...baseSettings, ...settings }

  return (
    <ReactSlick {...finalSettings} className="slider">
      {children}
    </ReactSlick>
  )
}

Slider.propTypes = {
  children: PropTypes.node,
  settings: PropTypes.object,
}

export default Slider
