import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { CMS_URL } from '../../../constants'

const hostUrl = Boolean(process.env.STORYBOOK_RUNNING) ? '' : CMS_URL

const ArticleCard = ({ article }) => {
  return (
    <Link className="article articleCard" to={article.to}>
      <div className="articleCard-image">
        <div className="skeleton"></div>
        <img
          src={`${hostUrl}${article.image.formats.medium.url}`}
          srcSet={
            `${hostUrl}${article.image.formats.thumbnail.url} 245w,
            ${hostUrl}${article.image.formats.medium.url} 750w`
          }
          sizes="(max-width: 245px) 245px, (max-width: 750px) 750px"
          alt={article.title}
        />
      </div>
      <div className="articleCard-info">
        <div className="wrapper">
          <h1 className="articleCard-title">{article.title}</h1>
          <p className="articleCard-text">{article.description}</p>
        </div>
      </div>
    </Link>
  )
}

ArticleCard.propTypes = {
  article: PropTypes.shape({
    to: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.shape({
      formats: PropTypes.shape({
        thumbnail: PropTypes.shape({
          url: PropTypes.string
        }),
        medium: PropTypes.shape({
          url: PropTypes.string
        }),
        large: PropTypes.shape({
          url: PropTypes.string
        })
      })
    })
  }),
}

export default ArticleCard
