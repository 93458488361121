import React from 'react'
import Banner from './Banner'
import smRemote from '../../../assets/images/remote-min.png'
import lgRemote from '../../../assets/images/remote@2x-min.png'
import useTranslations from '../../../hooks/useTranslations'

const AboutOsl = () => {
  const t = useTranslations()

  return (
    <Banner
      id="aboutosl"
      className="banner--about"
      print="green"
      alt="remote"
      smImg={smRemote}
      lgImg={lgRemote}
      smWidth="642w"
      lgWidth="1284w"
    >
      <h2 className="stepUp5-title">{t('message:about-title')}</h2>
      <p className="stepUp2-text">{t('message:about-text1')}</p>
      <p className="stepUp2-text">{t('message:about-text2')}</p>
    </Banner>
  )
}

export default AboutOsl
