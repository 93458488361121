import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import useTranslations from '../../../hooks/useTranslations'

const InputFile = ({
  isDisabled,
  textError,
  textLabel,
  name,
  onChange,
  caption,
}) => {
  const classes = cx('inputFile-field', {
    'has-error': textError,
    'is-disabled': isDisabled,
  })

  const t = useTranslations()

  return (
    <>
      <div className={classes}>
        <input
          type="file"
          name={name}
          className="inputFile"
          onChange={onChange}
        />
        <label htmlFor={name} className="inputFile-label">
          {t(textLabel)}
        </label>
      </div>
      {textError ? <span className="errorText">{t(textError)}</span> : null}
      {caption ? <p className="caption">{t(caption)}</p> : null}
    </>
  )
}

InputFile.propTypes = {
  isDisabled: PropTypes.bool,
  textError: PropTypes.string,
  textLabel: PropTypes.string.isRequired,
  name: PropTypes.string,
  onChange: PropTypes.func,
  caption: PropTypes.string,
}

export default InputFile
