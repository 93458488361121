import { showEphemeralClipboardSnackbar } from '../redux/actions'

export const addSocialMediaShares = (data, dispatch) => {
  const encodedUrl = encodeURIComponent(window.location.href)
  data.shareFacebook = `https://www.facebook.com/sharer.php?u=${encodedUrl}`
  data.shareTwitter = `https://twitter.com/intent/tweet?url=${encodedUrl}&text=${data.title}&hashtags=OSL`
  data.shareWhatsapp = `https://api.whatsapp.com/send?text=${encodedUrl}`
  data.share = async () => {
    if (!navigator.clipboard) {
      return
    }
    try {
      await navigator.clipboard.writeText(window.location.href)
      dispatch(showEphemeralClipboardSnackbar())
    } catch (error) {
      console.error('Copy failed', error)
    }
  }
  return data
}

export const loadTwitterScript = () => {
  const script = document.createElement('script')
  script.src = 'https://platform.twitter.com/widgets.js'
  document.body.appendChild(script)
}