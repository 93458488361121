import React from 'react'
import PropTypes from 'prop-types'
import ArticleThumbnail from '../articles/ArticleThumbnail'
import useTranslations from '../../../hooks/useTranslations'

const PostThumbnails = ({ thumbnails }) => {
  const t = useTranslations()
  return (
    <div className="newsDetail-thumbnails">
      <h2 className="newsDetail-label">{t('noun:last-news')}</h2>
      <div className="thumbnails">
        {thumbnails.map(item => (
          <ArticleThumbnail
            isSmall
            key={item.title}
            article={item}
          />
        ))}
      </div>
    </div>
  )
}

PostThumbnails.propTypes = {
  thumbnails: PropTypes.arrayOf(ArticleThumbnail.propTypes.article),
}

export default PostThumbnails
