import React from 'react'

import IconListEdit from '../../../ui/components/lists/IconListEdit'
import { MODAL_TYPE } from './index'

const Accounts = ({
  type = MODAL_TYPE.SOCIAL,
  accounts,
  handleClickNew,
  createHandleClickEdit,
  createHandleClickDelete,
}) => {
  const config = {
    [MODAL_TYPE.SOCIAL]: {
      title: 'noun:social-media',
      buttonText: 'action:add-social-media',
    },
    [MODAL_TYPE.GAMING]: {
      title: 'noun:game-platforms',
      buttonText: 'action:add-new-platform',
    },
  }[type]

  return (
    <IconListEdit
      title={config.title}
      items={Object.entries(accounts || {}).map(([slug, data]) => ({
        sharedIconName: slug,
        name: data.handle,
        onClickDelete: createHandleClickDelete(slug),
        onClickEdit: createHandleClickEdit(slug),
      }))}
      addButton={{
        text: config.buttonText,
        onClick: handleClickNew,
      }}
    />
  )
}

export default Accounts

