export const LOGIN_SUCCESSFUL = 'LOGIN_SUCCESFUL'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'
export const LOGIN_REQUEST = 'LOGIN_REQUEST'

export const LOGOUT_SUCCESSFUL = 'LOGOUT_SUCCESSFUL'

export const GET_TOURNAMENT_INFO_SUCCESSFUL = 'GET_TOURNAMENT_INFO_SUCCESSFUL'
export const GET_TOURNAMENT_INFO_FAILURE = 'GET_TOURNAMENT_INFO_FAILURE'

export const GET_TOURNAMENT_WINNERS_SUCCESSFUL =
  'GET_TOURNAMENT_WINNERS_SUCCESSFUL'

export const TOURNAMENT_TOTAL_REGISTRANTS_SUCCESSFUL =
  'TOURNAMENT_TOTAL_REGISTRANTS_SUCCESSFUL'
export const TOURNAMENT_TOTAL_REGISTRANTS_FAILURE =
  'TOURNAMENT_TOTAL_REGISTRANTS_FAILURE'

export const GET_SUBTOURNAMENTS_LIST_SUCCESSFUL =
  'GET_SUBTOURNAMENTS_LIST_SUCCESSFUL'

export const GET_SUBTOURNAMENT_INFO_SUCCESSFUL =
  'GET_SUBTOURNAMENT_INFO_SUCCESSFUL'

export const CHECK_TOURNAMENT_SUCCESSFUL = 'CHECK_TOURNAMENT_SUCCESSFUL'
export const CHECK_TOURNAMENT_FAILURE = 'CHECK_TOURNAMENT_FAILURE'
export const CHECK_TOURNAMENT_REQUEST = 'CHECK_TOURNAMENT_REQUEST'

export const SNACKBAR_SHOW = 'SNACKBAR_SHOW'
export const SNACKBAR_HIDE = 'SNACKBAR_HIDE'

export const MODAL_OPEN = 'MODAL_OPEN'
export const MODAL_CLOSE = 'MODAL_CLOSE'

export const AUTH_CHECKED = 'AUTH_CHECKED'
