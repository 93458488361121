import image1 from '../assets/images/summary-game.svg'
import image2 from '../assets/images/summary-game-mode.svg'
import image3 from '../assets/images/summary-format.svg'
import image4 from '../assets/images/summary-platform.svg'
import image5 from '../assets/images/summary-game-format.svg'
import image6 from '../assets/images/summary-inscriptions.svg'

export const REGIONS = {
  CANARIAS: 'canarias',
  ANDALUCIA: 'andalucia',
  ASTURIAS: 'asturias',
  ARAGON: 'aragon',
  CANTABRIA: 'cantabria',
  CATALUNIA: 'catalunia',
  LA_RIOJA: 'la-rioja',
  GALICIA: 'galicia',
  MADRID: 'madrid',
  MURCIA: 'murcia',
  NAVARRA: 'navarra',
  PAIS_VASCO: 'pais-vasco',
  COM_VALENCIANA: 'com-valenciana',
  CASTILLA_MANCHA: 'castilla-mancha',
  EXTREMADURA: 'extremadura',
  CASTILLA_LEON: 'castilla-leon',
  BALEARES: 'baleares',
  CEUTA_MELILLA: 'ceuta-melilla',
}

export const SOCIAL = {
  TWITTER: 'twitter',
  TWITCH: 'twitch',
  YOUTUBE: 'youtube',
  INSTAGRAM: 'instagram',
  TIKTOK: 'tiktok',
  DISCORD: 'discord',
  FACEBOOK: 'facebook',
}

export const PLATFORM = {
  STEAM: 'steam',
  PLAYSTATION: 'playstationnetwork',
  XBOX: 'xboxlive',
  ORIGIN: 'origin',
  BATTLENET: 'battlenet',
  EPICGAMES: 'epicgames',
  RIOTGAMES: 'riotgames',
  UPLAY: 'uplay',
}

export const FULL_SERVICE_NAME = {
  twitter: 'Twitter',
  twitch: 'Twitch',
  youtube: 'Youtube',
  instagram: 'Instagram',
  tiktok: 'Tiktok',
  discord: 'Discord',
  facebook: 'Facebook',
  steam: 'Steam',
  playstationnetwork: 'PlayStation Network',
  xboxlive: 'Xbox Live',
  origin: 'Origin',
  riotgames: 'Riot Games',
  epicgames: 'Epic Games',
  battlenet: 'Battle.net',
  uplay: 'UPlay',
}

export const SUMMARY_TILES_COMPETITION = {
  "game": {
    image: image1,
    title: 'summary:game',
    description: '',
  },
  "gameMode": {
    image: image2,
    title: 'summary:game-mode',
    description: '',
  },
  "typeFormat": {
    image: image3,
    title: 'summary:format',
    description: '',
  },
  "platforms": {
    image: image4,
    title: 'summary:platform',
    description: '',
  },
  "matchFormat": {
    image: image5,
    title: 'summary:game-format',
    description: '',
  },
  "registrationDeadline": {
    image: image6,
    title: 'summary:inscriptions',
    description: '',
  },
}

export const SUMMARY_TILES_ECOPA = [
  {
    image: image1,
    title: 'summary:game',
    description: 'FIFA 21',
  },
  {
    image: image2,
    title: 'summary:game-mode',
    description: 'FIFA Ultimate Team',
  },
  {
    image: image3,
    title: 'summary:format',
    description: '1vs1 + Cuadro doble eliminación',
  },
  {
    image: image4,
    title: 'summary:platform',
    description: 'PlayStation 4 & Xbox One',
  },
  {
    image: image5,
    title: 'summary:game-format',
    description: 'Ida y vuelta',
  },
  {
    image: image6,
    title: 'summary:inscriptions',
    description: '',
  },
]


