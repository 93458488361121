import React from 'react'
import PropTypes from 'prop-types'
import SummaryTile from './SummaryTile'

const SummaryTiles = ({ items }) => {
  return (
    <div className="summaryTiles">
      <div className="wrapper">
        {items.map(item => (
          <SummaryTile
            key={item.title}
            image={item.image}
            title={item.title}
            description={item.description}
          />
        ))}
      </div>
    </div>
  )
}

SummaryTiles.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
      extra: PropTypes.object,
    }),
  ),
}

export default SummaryTiles
