import React from 'react'
import PropTypes from 'prop-types'
import useTranslations from '../../../hooks/useTranslations'
import Icon from '../icons/Icon'
import { ReactComponent as IconChevronRight } from '../../../assets/icons/icon-chevron-right.svg'
import { Link } from 'react-router-dom'

const ChartBar = ({ label, literal, percent, to }) => {
  const t = useTranslations()
  return (
    <div className="chartBar">
      <Link to={to}>
        <p className="chartBar-label">{literal ? label : t(label)}</p>
        <div className="chartBar-bar">
          {percent ? (
            <div className="bar">
              <div
                className="progress"
                style={{ transform: `scaleX(${percent / 100})` }}
              ></div>
            </div>
          ) : null}
          <Icon Component={IconChevronRight} />
        </div>
      </Link>
    </div>
  )
}

ChartBar.propTypes = {
  label: PropTypes.string,
  percent: PropTypes.number,
  to: PropTypes.string,
}

export default ChartBar
