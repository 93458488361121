import React from 'react'
import GenericBanner from '../components/banners/GenericBanner'
import Wysiwyg from '../components/wysiwyg/Wysiwyg'
import Button from '../components/buttons/Button'
import CookiesTable from '../components/tables/CookiesTable'

const Cookies = () => {
  return (
    <>
      <GenericBanner title="views:cookies-policy" />
      <Wysiwyg>
        <p className="wysiwyg-paragraph">
          Se advierte al usuario que esta página web utiliza cookies. Las
          cookies son pequeños ficheros de datos que se instalan en el ordenador
          del usuario y que permiten que sea el propio usuario el que almacene
          la información que genera su actividad en la red. Las cookies permiten
          a una página web, entre otras cosas, almacenar y recuperar información
          sobre los hábitos de navegación de un usuario o de su equipo y,
          dependiendo de la información que contengan y de la forma en que
          utilice su equipo, pueden utilizarse para reconocer al usuario.{' '}
        </p>
        <p className="wysiwyg-paragraph">
          Este sitio web puede utilizar las siguientes cookies:
        </p>
        <p className="wysiwyg-paragraph bold underline">
          Según la entidad que las gestione:
        </p>

        <h3 className="wysiwyg-headline">Cookies propias:</h3>
        <p className="wysiwyg-paragraph">
          Son aquellas que se envían al equipo terminal del usuario desde un
          equipo o dominio gestionado por el propio editor y desde el que se
          presta el servicio solicitado por el usuario.
        </p>

        <h3 className="wysiwyg-headline">Cookies de terceros:</h3>
        <p className="wysiwyg-paragraph">
          Son aquellas que se envían al equipo terminal del usuario desde un
          equipo o dominio que no es gestionado por el editor, sino por otra
          entidad que trata los datos obtenidos través de las cookies.
        </p>
        <p className="wysiwyg-paragraph bold underline">Según su finalidad:</p>

        <h3 className="wysiwyg-headline">Cookies técnicas:</h3>
        <p className="wysiwyg-paragraph">
          Son aquellas que permiten al usuario la navegación a través de una
          página web, plataforma o aplicación y la utilización de las diferentes
          opciones o servicios que en ella existan, incluyendo aquellas que el
          editor utiliza para permitir la gestión y operativa de la página web y
          habilitar sus funciones y servicios, como, por ejemplo, controlar el
          tráfico y la comunicación de datos, identificar la sesión, acceder a
          partes de acceso restringido, recordar los elementos que integran un
          pedido, realizar el proceso de compra de un pedido, gestionar el pago,
          controlar el fraude vinculado a la seguridad del servicio, realizar la
          solicitud de inscripción o participación en un evento, contar visitas
          a efectos de la facturación de licencias del software con el que
          funciona el servicio (sitio web, plataforma o aplicación), utilizar
          elementos de seguridad durante la navegación, almacenar contenidos
          para la difusión de vídeos o sonido, habilitar contenidos dinámicos
          (por ejemplo, animación de carga de un texto o imagen) o compartir
          contenidos a través de redes sociales. También pertenecen a esta
          categoría, por su naturaleza técnica, aquellas cookies que permiten la
          gestión, de la forma más eficaz posible, de los espacios publicitarios
          que, como un elemento más de diseño o “maquetación” del servicio
          ofrecido al usuario, el editor haya incluido en una página web,
          aplicación o plataforma en base a criterios como el contenido editado,
          sin que se recopile información de los usuarios con fines distintos,
          como puede ser personalizar ese contenido publicitario u otros
          contenidos.
        </p>

        <h3 className="wysiwyg-headline">
          Cookies de preferencias o personalización:
        </h3>
        <p className="wysiwyg-paragraph">
          Son aquellas que permiten recordar información para que el usuario
          acceda al servicio con determinadas características que pueden
          diferenciar su experiencia de la de otros usuarios, como, por ejemplo,
          el idioma, el número de resultados a mostrar cuando el usuario realiza
          una búsqueda, el aspecto o contenido del servicio en función del tipo
          de navegador a través del cual el usuario accede al servicio o de la
          región desde la que accede al servicio, etc.
        </p>

        <h3 className="wysiwyg-headline">Cookies de análisis o mediación:</h3>
        <p className="wysiwyg-paragraph">
          Son aquellas que permiten al responsable de las mismas el seguimiento
          y análisis del comportamiento de los usuarios del sitio web a los que
          están vinculadas, incluida la cuantificación de los impactos de los
          anuncios. La información recogida mediante este tipo de cookies se
          utiliza en la mediación de la actividad de los sitios web, aplicación
          o plataforma, con el fin de introducir mejoras en función del análisis
          de los datos de uso que hacen los usuarios del servicio.
        </p>
        <p className="wysiwyg-paragraph">
          El sitio web puede utilizar la información de su visita para realizar
          evaluaciones y cálculos estadísticos sobre datos anónimos, así como
          para garantizar la continuidad del servicio o para realizar mejoras en
          sus sitios web. Dicha información no será utilizada para ninguna otra
          finalidad.
        </p>

        <h3 className="wysiwyg-headline">Cookies publicitarias:</h3>
        <p className="wysiwyg-paragraph">
          Las cookies publicitarias permiten gestionar, de la forma más eficaz
          posible, los espacios publicitarios incluidos en nuestra página web,
          en base a criterios como el contenido editado o la frecuencia con la
          que se muestran los anuncios.
        </p>

        <h3 className="wysiwyg-headline">
          Cookies de publicidad comportamental:
        </h3>
        <p className="wysiwyg-paragraph">
          Son aquellas que permiten gestionar de manera eficaz, los espacios
          publicitarios incluidos en esta página web. Estas cookies almacenan
          información de su comportamiento obtenida a través de la observación
          continuada de sus hábitos de navegación, lo que nos permite
          desarrollar un perfil específico para mostrarle publicidad en función
          del mismo.
        </p>
        <p className="wysiwyg-paragraph bold underline">
          Según el plazo de tiempo que permanecen activadas en el equipo
          terminal:
        </p>

        <h3 className="wysiwyg-headline">Cookies de sesión:</h3>
        <p className="wysiwyg-paragraph">
          Son aquellas diseñadas para recabar y almacenar datos mientras el
          usuario accede a una página web. Se suelen emplear para almacenar
          información que solo interesa conservar para la prestación del
          servicio solicitado por el usuario en una sola ocasión (por ejemplo,
          una lista de productos adquiridos) y desaparecen al terminar la
          sesión.
        </p>

        <h3 className="wysiwyg-headline">Cookies persistentes:</h3>
        <p className="wysiwyg-paragraph">
          Son aquellas en las que los datos siguen almacenados en el terminal y
          pueden ser accedidos y tratados durante un periodo definido por el
          responsable de la cookie, y que puede ir de unos minutos a varios
          años.
        </p>

        <h3 className="wysiwyg-headline">
          Cookies utilizadas en este sitio web
        </h3>
        <p className="wysiwyg-paragraph">
          A continuación, se incluye el siguiente cuadro con identificación de
          las cookies utilizadas en el sitio web, distinguiéndose si son cookies
          propias o de terceros (e identificación del tercero contratado o cuyos
          servicios se han decidido utilizar) y su finalidad:
        </p>

        <CookiesTable />

        <h3 className="wysiwyg-headline">Gestión de cookies</h3>
        <p className="wysiwyg-paragraph">
          En cualquier caso, el usuario tiene la posibilidad de co+nfigurar su
          panel para gestionar la instalación de cookies y para impedir su
          instalación en su equipo. No obstante, la desactivación de las cookies
          puede afectar al correcto funcionamiento de determinadas secciones de
          la página web.
        </p>

        <Button
          as="a"
          href="#"
          kind="primary"
          text="cookies:modify-preferences"
          className="optanon-toggle-display cookie-settings-button"
        />

        <p className="wysiwyg-paragraph">
          El hecho de deshabilitar las cookies puede implicar la pérdida de un
          funcionamiento óptimo de la página web quedando inhabilitadas algunas
          de las características o servicios prestados por la misma. Sin
          embargo, incluso habiendo desactivado todas las cookies el navegador
          web recoge determinada información esencial para el funcionamiento
          básico de la página web.
        </p>
        <p className="wysiwyg-paragraph">
          Asimismo, las cookies pueden bloquearse o deshabilitarse con las
          herramientas de configuración de su navegador. El navegador le permite
          rechazar la instalación de todas las cookies, o bien elegir rechazar
          solo alguna de ellas.
        </p>
        <p className="wysiwyg-paragraph">
          Por favor, consulte las instrucciones y manuales de su navegador para
          ampliar esta información:
        </p>

        <ul className="wysiwyg-list">
          <li>
            Si utiliza Microsoft Internet Explorer, en la opción de menú
            Herramientas seleccionando Opciones de Internet y accediendo a
            Privacidad.
          </li>
          <li>
            Si utiliza Firefox para Mac en la opción de menú Preferencias,
            seleccionando Privacidad, accediendo al apartado Mostrar Cookies, y
            para Windows en la opción de menú Herramientas, seleccionando
            Opciones, accediendo a Privacidad y luego a Usar una configuración
            personalizada para el historial.
          </li>
          <li>
            Si utiliza Safari, en la opción de menú Preferencias, seleccionando
            Privacidad.
          </li>
          <li>
            Si utiliza Google Chrome, en la opción de menú Herramientas,
            seleccionando Opciones (Preferencias en Mac), accediendo a Avanzadas
            y luego en la opción Configuración Contenido de la sección
            Privacidad, y finalmente marcando Cookies en el diálogo
            Configuración de contenido.
          </li>
        </ul>

        <p className="wysiwyg-paragraph">
          Por último, le informamos de que tiene la posibilidad de revocar en
          cualquier momento el consentimiento prestado para la utilización de
          cookies, configurando para ello su navegador o el panel de control en
          los términos previstos en el punto anterior.
        </p>
      </Wysiwyg>
    </>
  )
}

export default Cookies
