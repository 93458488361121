import React from 'react'
import { Timeline } from 'react-twitter-widgets'

const TwitterTimeline = () => {
  return (
    <div className="twitterTimeline">
      <Timeline
        dataSource={{
          sourceType: 'profile',
          screenName: 'OSLDeAPlaneta',
        }}
        options={{
          height: '342',
          width: '393',
          chrome: 'noheader, nofooter',
        }}
      />
    </div>
  )
}

export default TwitterTimeline
