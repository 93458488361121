import React , {useState} from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { passwordSchema } from '../../../app/validation/schemas'
import { changePassword } from '../../../services/user'
import { logoutAfterPasswordChanged } from '../../../redux/actions'
import ModalEditPasswordUI from '../../../ui/components/modals/ModalEditPassword'

const formatProfile = (profile) => {
  const formData = new FormData()
  formData.append('newPassword', profile.newPassword)

  return formData
}

const createField = (formik, fieldName) => ({
  name: fieldName,
  value: formik.values[fieldName],
  error:
    formik.errors[fieldName] && formik.touched[fieldName]
      ? formik.errors[fieldName]
      : '',
  handleBlur: formik.handleBlur,
  handleChange: formik.handleChange,
})

const ModalEditPassword = ({ setInnerModal }) => {
  const dispatch = useDispatch()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [showError, setShowError] = useState(false)

  const initialValues = {
    newPassword: '',
    confirmPassword: '',
  }
  const submitUpdateProfileData = async formData => {
    const { success } = await changePassword(formData)
    if (success) {
      dispatch(logoutAfterPasswordChanged())
    } else {
      setShowError(true)
    }
  }

  const handleOnSubmit = async values => {
    setIsSubmitting(true)
    setShowError(false)
    const formData = formatProfile(values)
    submitUpdateProfileData(formData)
    setIsSubmitting(false)
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    Schema: Yup.object(passwordSchema),
    onSubmit: handleOnSubmit,
  })

  const handleClosePasswordModal = () => {
    formik.setFieldValue(
      'newPassword',
      formik.initialValues['newPassword'],
    )
    formik.setFieldTouched('newPassword', false)
    formik.setFieldValue(
      'confirmPassword',
      formik.initialValues['confirmPassword'],
    )
    formik.setFieldTouched('confirmPassword', false)
    setInnerModal(null)
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <ModalEditPasswordUI
        newPassword={createField(formik, 'newPassword')}
        confirmNewPassword={createField(formik, 'confirmPassword')}
        onClose={handleClosePasswordModal}
        showError={showError}
        isBtnDisabled={isSubmitting}
      />
    </form>
  )
}

export default ModalEditPassword