import React from 'react'
import PropTypes from 'prop-types'
import useTranslations from '../../../hooks/useTranslations'

const ErrorCard = ({ errors, textTitle, children }) => {
  const t = useTranslations()

  return (
    <div className="errorCard">
      <p className="errorCard-title">{t(textTitle)}</p>
      {errors ? (
        <ul className="errorCard-list">
          {errors.map(error => (
            <li key={error}>{t(error)}</li>
          ))}
        </ul>
      ) : null}
      {React.Children.count(children) ? children : null}
    </div>
  )
}

ErrorCard.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string),
  textTitle: PropTypes.string.isRequired,
  children: PropTypes.node,
}

export default ErrorCard
