import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import ButtonLink from '../links/ButtonLink'
import { ReactComponent as IconChevronDown } from '../../../assets/icons/icon-chevron-down.svg'
import cx from 'classnames'
import OslPropTypes from '../../../common/osl-prop-types'

const UserDropdown = ({ name, dropItems }) => {
  const [isOpen, setOpen] = useState(false)
  const toggleOpen = useCallback(() => setOpen(!isOpen), [isOpen])

  return (
    <div className={cx('dropdown', { open: isOpen })}>
      <button type="button" className="dropdown-trigger" onClick={toggleOpen}>
        <p className="name">{name}</p>
        <IconChevronDown className="icon icon-chevron" />
      </button>

      <div className="dropdown-list dropdown-list-user">
        {dropItems.map(item => (
          <ButtonLink
            className="dropdown-item"
            as={item.as}
            key={item.name}
            text={item.name}
            onClick={item.onClick}
            icon={item.icon}
          />
        ))}
      </div>
    </div>
  )
}

UserDropdown.propTypes = {
  name: PropTypes.string.isRequired,
  dropItems: OslPropTypes.userDropItems,
}

export default UserDropdown
