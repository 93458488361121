import React from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as IconTwitter } from '../../../assets/icons/icon-twitter.svg'
import { ReactComponent as IconWhatsapp } from '../../../assets/icons/icon-whatsapp.svg'
import { ReactComponent as IconFacebook } from '../../../assets/icons/icon-facebook.svg'
import { ReactComponent as IconShare } from '../../../assets/icons/icon-share.svg'
import SocialItem from '../social/SocialItem'
import SocialItemButton from '../social/SocialItemButton'
import useTranslations from '../../../hooks/useTranslations'
import MediaQuery from 'react-responsive'
import { SIZEMD } from '../../common/ui-constants'
import { CMS_URL } from '../../../constants'

const hostUrl = Boolean(process.env.STORYBOOK_RUNNING) ? '' : CMS_URL

const formatDate = date => {
  const d = new Date(date)
  return d.toLocaleDateString()
}

const PostHeader = ({ detailHeader }) => {
  const t = useTranslations()
  return (
    <div className="newsDetail-header">
      <h1 className="stepUp4-title">{detailHeader.title}</h1>
      {detailHeader.description ? (
        <p className="newsDetail-text">{detailHeader.description}</p>
      ) : null}
      <div className="newsDetail-extraInfo">
        <p className="newsDetail-date">{formatDate(detailHeader.created_at)}</p>
        <p className="newsDetail-author">
          <span>{t('verb:by')}</span>
          {detailHeader.author}
        </p>
      </div>
      <div className="newsDetail-share">
        <p className="label">{t('action:share')}</p>
        <MediaQuery maxWidth={SIZEMD - 1}>
          <SocialItem icon={IconWhatsapp} href={detailHeader.shareWhatsapp} />
        </MediaQuery>
        <SocialItem icon={IconFacebook} href={detailHeader.shareFacebook} />
        <SocialItem icon={IconTwitter} href={detailHeader.shareTwitter} />
        <SocialItemButton icon={IconShare} onClick={detailHeader.share} />
      </div>
      {detailHeader.image ? (
        <figure className="newsDetail-image">
          <img
            src={`${hostUrl}${detailHeader.image.formats.large.url}`}
            srcSet={`${hostUrl}${detailHeader.image.formats.thumbnail.url} 245w,
        ${hostUrl}${detailHeader.image.formats.medium.url} 750w,
        ${hostUrl}${detailHeader.image.formats.large.url} 1000w`}
            sizes="(max-width: 245px) 245px, (max-width: 750px) 750px, (min-width: 1000px) 1000px"
            alt={detailHeader.title}
          />
          <figcaption className="newsDetail-caption">
            {detailHeader.caption}
          </figcaption>
        </figure>
      ) : null}
    </div>
  )
}

PostHeader.propTypes = {
  detailHeader: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    created_at: PropTypes.string,
    author: PropTypes.string,
    share: PropTypes.func,
    shareWhatsapp: PropTypes.string,
    shareFacebook: PropTypes.string,
    shareTwitter: PropTypes.string,
    image: PropTypes.object,
    caption: PropTypes.string,
  }),
}

export default PostHeader
