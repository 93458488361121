import React from 'react'
import PropTypes from 'prop-types'
import PrintYellow from '../../../assets/images/print-yellow.svg'
import PrintBlue from '../../../assets/images/print-blue.svg'
import PrintGreen from '../../../assets/images/print-green.svg'
import PrintPink from '../../../assets/images/print-pink.svg'
import cx from 'classnames'

const Banner = ({
  print,
  smImg,
  lgImg,
  lgWidth,
  smWidth,
  alt,
  children,
  className,
  hasRightImage,
  id,
}) => {
  const selectPrint = propValue => {
    switch (propValue) {
      case 'yellow':
        return PrintYellow
      case 'blue':
        return PrintBlue
      case 'pink':
        return PrintPink
      default:
        return PrintGreen
    }
  }

  const bannerClasses = cx('banner', {
    [className]: className,
    'has-right-image': hasRightImage,
  })

  return (
    <div className={bannerClasses} id={id}>
      {print ? (
        <img className="print" src={selectPrint(print)} alt="Huella OSL" />
      ) : null}
      {smImg ? (
        <div className={cx('banner-image')}>
          <img
            className="image"
            src={smImg}
            srcSet={`${smImg} ${smWidth}, ${lgImg} ${lgWidth}`}
            alt={alt}
          />
        </div>
      ) : null}
      <div className="wrapper">
        <div className="banner-info">{children}</div>
      </div>
    </div>
  )
}

Banner.propTypes = {
  smImg: PropTypes.string.isRequired,
  lgImg: PropTypes.string.isRequired,
  smWidth: PropTypes.string.isRequired,
  lgWidth: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
  id: PropTypes.string,
  children: PropTypes.node,
  print: PropTypes.oneOf(['yellow', 'blue', 'green']),
  hasRightImage: PropTypes.bool,
}

export default Banner
