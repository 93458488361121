import React from 'react'
import PropTypes from 'prop-types'
import PortraitSquared from '../portraits/PortraitSquared'
import OslPropTypes from '../../../common/osl-prop-types'
import useTranslations from '../../../hooks/useTranslations'

const WinnersGt = ({ winners }) => {
  const t = useTranslations()
  return (
    <div className="brick is-dark">
      <div className="wrapper">
        <h2 className="stepUp4-title">{t('message:our-winners')}</h2>
        <div className="winners">
          {winners.map(item => (
            <PortraitSquared
              key={item.name}
              name={item.name}
              playerImg={item.image}
              position={item.position}
              social={item.social}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

WinnersGt.propTypes = {
  winners: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      image: PropTypes.string,
      position: PropTypes.string,
      social: OslPropTypes.socialProps,
    }),
  ),
}

export default WinnersGt
