import React, { useEffect, useState, useCallback } from 'react'
import { setupI18n } from '../libs/i18n'
import { useParams, useHistory } from 'react-router'
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'
import { useDispatch } from 'react-redux'
import { compareDesc } from 'date-fns'

import { fetchCompetitionByTitle } from '../services/cms'
import { addSocialMediaShares, loadTwitterScript } from '../common/social-media'
import useTranslations from '../hooks/useTranslations'
import CompetitionPage from '../ui/views/CompetitionPage'
import { SUMMARY_TILES_COMPETITION } from '../common/constants'
import getFormattedDeadline from '../common/get-formatted-deadline'
import { openModal } from '../redux/actions'
import {  MODAL_LOGIN } from './modals/constants'

const SUMMARY_KEYS = ["game", "gameMode", "typeFormat", "platforms", "matchFormat", "registrationDeadline"]

const Competition = ({ isLogged }) => {
  const { locale } = setupI18n()
  const dispatch = useDispatch()
  const { slug } = useParams()
  const history = useHistory()
  const t = useTranslations()

  const [data, setData] = useState()
  const [summaryItems, setSummaryItems] = useState()
  
  const getSummaryData = useCallback(data => {
    const summaryData = {}
    SUMMARY_KEYS.map(key => summaryData[key]= data[key])

    summaryData.platforms = getPlatformsString(data.platforms, t)
    summaryData.registrationDeadline = getFormattedDeadline(data.registrationDeadline, locale, t)

    return summaryData
  }, [locale, t])

  const formatSummary = summaryData => {
    const summaryObj = SUMMARY_TILES_COMPETITION
    for (const option in summaryObj) {
      summaryObj[option].description = summaryData[option]
    }
    return Object.values(summaryObj)
  }

  const saveFetchedData = useCallback((data) => {    
    const summary = formatSummary(getSummaryData(data))
    setSummaryItems(summary)

    data = addSocialMediaShares(data, dispatch)
    setData(data)
    loadTwitterScript()
  }, [ dispatch, getSummaryData])

  const handleGoToLogin = () => dispatch(openModal(MODAL_LOGIN))

  useEffect(() => {
    async function fetchData() {
      try {
        let data = await fetchCompetitionByTitle(slug)
        if (!data) history.replace('/')
        else {
          saveFetchedData(data)
        }
      } catch (e) {
        //TODO: Redirect and show snackbar
      }
    }
    fetchData()
  }, [slug, history, dispatch, saveFetchedData])

  return data ? (
    <CompetitionPage
      detailHeader={data}
      detailBody={<ReactMarkdown plugins={[gfm]} source={data.content} allowDangerousHtml={true} />}
      sponsor={data.sponsor}
      summaryItems={summaryItems}
      isLogged={isLogged}
      inscriptionLink={data.inscriptionLink}
      openEnrollment={1 === compareDesc(new Date(), new Date(data.registrationDeadline))}
      handleGoToLogin={handleGoToLogin}
      brands={data?.brands ?? []}
    />
  ) : null
}

export default Competition

const getPlatformsString = (platforms) => {
  if (!platforms.length) return ''
  const platformsNames = platforms.map( pl => pl.name)
  return platformsNames.join(`, `)
}

