import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import Snackbar from '../ui/components/snackbars/Snackbar'
import Sticky from '../ui/components/utils/Sticky'

import { ReactComponent as IconLogin } from '../assets/icons/icon-login.svg'
import { ReactComponent as IconSuccess } from '../assets/icons/icon-success-circle.svg'

import { hideSnackbar } from '../redux/actions'

export const SNACKBAR_TEXT_LOGOUT = 'message:session-closed'
export const SNACKBAR_TEXT_REGISTRATION = 'message:registration-feedback'
export const SNACKBAR_TEXT_CONTACT = 'message:contact-feedback'
export const SNACKBAR_TEXT_NEWSLETTER = 'message:newsletter-feedback'
export const SNACKBAR_TEXT_CLIPBOARD = 'message:clipboard'
export const SNACKBAR_TEXT_SUBMIT_RESULTS = 'message:submit-results-feedback'
export const SNACKBAR_TEXT_EDIT_PROFILE = 'message:edit-profile-feedback'
export const SNACKBAR_TEXT_EDIT_PASSWORD = 'message:edit-password-feedback'
export const SNACKBAR_TEXT_EDIT_SOCIAL_MEDIA = 'message:edit-social-media-feedback'
export const SNACKBAR_TEXT_EDIT_GAMING_PLATFORM = 'message:edit-gaming-platform-feedback'

const getIcon = text => {
  if (text === SNACKBAR_TEXT_SUBMIT_RESULTS) return IconSuccess
  return IconLogin
}

const getStatus = text => {
  if (text === SNACKBAR_TEXT_SUBMIT_RESULTS) return 'success'
  return undefined
}

export default () => {
  const dispatch = useDispatch()

  const { showSnackbar, snackbarText } = useSelector(state => state.visual)

  return showSnackbar ? (
    <Sticky>
      <Snackbar
        text={snackbarText}
        status={getStatus(snackbarText)}
        IconComponent={getIcon(snackbarText)}
        hasCloseAction
        handleClose={() => dispatch(hideSnackbar())}
      />
    </Sticky>
  ) : null
}
