import React from 'react'

import * as Yup from 'yup'
import { useFormik } from 'formik'
import { createEditAccountSchema } from '../../../validation/schemas'
import ModalAccount from './ModalAccount'
import { MODAL_TYPE} from '../index'

const EditSocialAccount = ({onClose, editingAccount, showError, formik}) => {
  const type = MODAL_TYPE.SOCIAL

  const updateAccount = ({ handle, link }) => {
    formik.setFieldValue(type, {
      ...formik.values[type],
      [editingAccount]: { handle, link },
    })
    formik.setFieldTouched(type)
    if (!internalFormik.errors.length){
      formik.handleSubmit()
    }
  }

  const configFormik =  { 
    enableReinitialize: true,
    initialValues: {
      handle: formik.values[type][editingAccount]?.handle,
      link: formik.values[type][editingAccount]?.link,
    },
    validationSchema: Yup.object(createEditAccountSchema(editingAccount)),
    onSubmit: updateAccount,
  }
  const internalFormik = useFormik(configFormik)

  return (<ModalAccount
    title='action:edit-social-media'
    showError={showError}
    formik={internalFormik}
    onClose={onClose}
  />) 
}

export default EditSocialAccount