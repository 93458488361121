import React from 'react'

import Input from '../../../ui/components/inputs/Input'

const FormikInput = ({ formik, type = 'text', fieldName, label, ...props }) => (
  <Input
    type={type}
    name={fieldName}
    value={formik.values[fieldName]}
    textLabel={label}
    textError={
      formik.errors[fieldName] && formik.touched[fieldName]
        ? formik.errors[fieldName]
        : ''
    }
    handleBlur={formik.handleBlur}
    handleChange={formik.handleChange}
    {...props}
  />
)

export default FormikInput
