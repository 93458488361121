import {
  LOGIN_SUCCESSFUL,
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGOUT_SUCCESSFUL,
  CHECK_TOURNAMENT_SUCCESSFUL,
  CHECK_TOURNAMENT_FAILURE,
  CHECK_TOURNAMENT_REQUEST,
  GET_TOURNAMENT_INFO_SUCCESSFUL,
  GET_TOURNAMENT_INFO_FAILURE,
  GET_TOURNAMENT_WINNERS_SUCCESSFUL,
  TOURNAMENT_TOTAL_REGISTRANTS_SUCCESSFUL,
  TOURNAMENT_TOTAL_REGISTRANTS_FAILURE,
  GET_SUBTOURNAMENTS_LIST_SUCCESSFUL,
  GET_SUBTOURNAMENT_INFO_SUCCESSFUL,
  SNACKBAR_SHOW,
  SNACKBAR_HIDE,
  MODAL_OPEN,
  MODAL_CLOSE,
  AUTH_CHECKED,
} from './types'

import {
  SNACKBAR_TEXT_LOGOUT,
  SNACKBAR_TEXT_REGISTRATION,
  SNACKBAR_TEXT_CONTACT,
  SNACKBAR_TEXT_NEWSLETTER,
  SNACKBAR_TEXT_CLIPBOARD,
  SNACKBAR_TEXT_SUBMIT_RESULTS,
  SNACKBAR_TEXT_EDIT_PROFILE,
  SNACKBAR_TEXT_EDIT_PASSWORD,
} from '../hooks/useSnackbar'
import * as Auth from '../services/auth'
import * as Tournament from '../services/tournament'

export const loginSuccess = payload => dispatch => {
  dispatch({ type: LOGIN_SUCCESSFUL, payload })
}
export const loginFail = () => dispatch => {
  dispatch({ type: LOGIN_FAILURE })
}

export const remember = () => {
  return async dispatch => {
    dispatch({ type: LOGIN_REQUEST })

    try {
      const response = await Auth.remember()
      dispatch({ type: LOGIN_SUCCESSFUL, payload: response })
    } catch (err) {}

    dispatch({ type: AUTH_CHECKED })
  }
}

export const logoutAfterPasswordChanged = () => {
  return async dispatch => {
    try {
      dispatch({ type: LOGOUT_SUCCESSFUL })
      dispatch(showEphemeralSnackbar(SNACKBAR_TEXT_EDIT_PASSWORD))
    } catch (err) {}
  }
}

export const logout = () => {
  return async dispatch => {
    try {
      await Auth.logout()
      dispatch({ type: LOGOUT_SUCCESSFUL })
      dispatch(showEphemeralLogoutSnackbar())
    } catch (err) {}
  }
}

export const fetchTournamentInscription = tournamentSlug => {
  return async dispatch => {
    dispatch({ type: CHECK_TOURNAMENT_REQUEST, payload: { tournamentSlug } })

    try {
      const data = await Tournament.fetchLoggedUserInscription(tournamentSlug)
      const { inscription } = data

      if (!inscription) {
        dispatch({
          type: CHECK_TOURNAMENT_FAILURE,
          payload: { tournamentSlug },
        })
        return
      }

      dispatch({
        type: CHECK_TOURNAMENT_SUCCESSFUL,
        payload: { tournamentSlug, inscription },
      })
    } catch (err) {
      dispatch({ type: CHECK_TOURNAMENT_FAILURE, payload: { tournamentSlug } })
    }
  }
}

export const fetchTournamentInfo = tournamentSlug => async dispatch => {
  try {
    const { data } = await Tournament.fetchInfo(tournamentSlug)

    dispatch({
      type: GET_TOURNAMENT_INFO_SUCCESSFUL,
      payload: { tournamentSlug, data },
    })
  } catch (err) {
    dispatch({
      type: GET_TOURNAMENT_INFO_FAILURE,
      payload: { tournamentSlug },
    })
  }
}

export const fetchTournamentWinners = tournamentSlug => async dispatch => {
  try {
    const { data } = await Tournament.fetchWinners(tournamentSlug)

    dispatch({
      type: GET_TOURNAMENT_WINNERS_SUCCESSFUL,
      payload: { tournamentSlug, data },
    })
  } catch (err) {}
}

export const fetchTournamentTotalRegistrants = tournamentSlug => async dispatch => {
  try {
    const { total } = await Tournament.fetchTotalRegistrants(tournamentSlug)

    dispatch({
      type: TOURNAMENT_TOTAL_REGISTRANTS_SUCCESSFUL,
      payload: { tournamentSlug, total },
    })
  } catch (err) {
    dispatch({
      type: TOURNAMENT_TOTAL_REGISTRANTS_FAILURE,
      payload: { tournamentSlug },
    })
  }
}

export const fetchSubtournaments = tournamentSlug => async dispatch => {
  try {
    const { data } = await Tournament.fetchSubtournaments(tournamentSlug)

    dispatch({
      type: GET_SUBTOURNAMENTS_LIST_SUCCESSFUL,
      payload: { tournamentSlug, data },
    })
  } catch (err) {}
}

export const fetchSubtournamentInfo = (
  tournamentSlug,
  subtournamentId,
) => async dispatch => {
  try {
    const { data } = await Tournament.fetchSubtournamentInfo(
      tournamentSlug,
      subtournamentId,
    )

    dispatch({
      type: GET_SUBTOURNAMENT_INFO_SUCCESSFUL,
      payload: { tournamentSlug, subtournamentId, data },
    })
  } catch (err) {}
}

const SNACKBAR_TIMEOUT = 10000

export const hideSnackbar = () => dispatch => {
  dispatch({ type: SNACKBAR_HIDE })
}

const showEphemeralSnackbar = text => dispatch => {
  dispatch({ type: SNACKBAR_SHOW, payload: { text } })

  setTimeout(() => {
    dispatch({ type: SNACKBAR_HIDE })
  }, SNACKBAR_TIMEOUT)
}

export const showEphemeralLogoutSnackbar = () =>
  showEphemeralSnackbar(SNACKBAR_TEXT_LOGOUT)

export const showEphemeralRegistrationSnackbar = () =>
  showEphemeralSnackbar(SNACKBAR_TEXT_REGISTRATION)

export const showEphemeralContactSnackbar = () =>
  showEphemeralSnackbar(SNACKBAR_TEXT_CONTACT)

export const showEphemeralNewsletterSnackbar = () =>
  showEphemeralSnackbar(SNACKBAR_TEXT_NEWSLETTER)

export const showEphemeralClipboardSnackbar = () =>
  showEphemeralSnackbar(SNACKBAR_TEXT_CLIPBOARD)

export const showEphemeralSubmitResultsSnackbar = () =>
  showEphemeralSnackbar(SNACKBAR_TEXT_SUBMIT_RESULTS)

export const showEphemeralEditProfileSnackbar = text =>
  showEphemeralSnackbar(text ?? SNACKBAR_TEXT_EDIT_PROFILE)

export const closeModal = () => ({ type: MODAL_CLOSE })

export const openModal = kind => ({
  type: MODAL_OPEN,
  payload: { kind },
})
