import React from 'react'
import useTranslations from '../../../hooks/useTranslations'
import Button from '../buttons/Button'
import InfoCard from '../cards/InfoCard'

const BroadcastsTga = () => {
  const t = useTranslations()
  return (
    <div className="brick">
      <div className="wrapper">
        <h1 className="stepUp4-title">{t('tga:broadcast-title')}</h1>
        <div className="infoCard-group">
          <InfoCard
            isLight
            title="tga:broadcast-weekly-gala"
            date="tga:broadcast-weekly-date"
            description="tga:broadcast-weekly-text"
            actions={
              <Button
                as="a"
                href="https://www.atresplayer.com/directos/neox/"
                text="tga:broadcast-see-gala"
                kind="primary"
                target="_blank"
                rel="noopener noreferrer"
              />
            }
          />
          <InfoCard
            isLight
            title="tga:broadcast-daily-summary"
            date="tga:broadcast-daily-date"
            description="tga:broadcast-daily-text"
            actions={
              <Button
                as="a"
                href="https://www.atresplayer.com/flooxer/programas/top-gamers-academy-resumen-diario/temporada-1/"
                text="tga:broadcast-daily-see-summary"
                kind="primary"
                target="_blank"
                rel="noopener noreferrer"
              />
            }
          />
        </div>
      </div>
    </div>
  )
}

export default BroadcastsTga
