import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

const InnerWrapper = ({ className, children, size }) => {
  return (
    <div
      className={cx(`innerWrapper size-${size}`, {
        [className]: className,
      })}
    >
      {children}
    </div>
  )
}

InnerWrapper.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']),
}

export default InnerWrapper
