import React from 'react'
import PropTypes from 'prop-types'
import { useMediaQuery } from 'react-responsive'
import { SIZEXL } from '../../common/ui-constants'
import { CMS_URL } from '../../../constants'

const hostUrl = Boolean(process.env.STORYBOOK_RUNNING) ? '' : CMS_URL

const SponsorFull = ({ imageSm, imageLg, sponsor , url }) => {
  const isTabletOrMobile = useMediaQuery({
    query: `(max-width: ${SIZEXL - 1}px)`,
  })

  return (
    <div className="sponsorFull">
      {url ? (
      <a href={url} target="_blank" rel="noopener noreferrer" className="sponsor">
        <img src={`${hostUrl}${isTabletOrMobile ? imageSm : imageLg}`} alt={sponsor}/>
      </a>
      ) : (
        <img src={`${hostUrl}${isTabletOrMobile ? imageSm : imageLg}`} alt={sponsor}/>
      )}
    </div>
  )
}

SponsorFull.propTypes = {
  imageSm: PropTypes.string,
  imageLg: PropTypes.string,
  sponsor: PropTypes.string,
}

export default SponsorFull
