import React from 'react'
import CompetitionStep from '../steps/CompetitionStep'
import useTranslations from '../../../hooks/useTranslations'
import { ReactComponent as IconTrophy } from '../../../assets/icons/icon-trophy.svg'

const CompetitionStep4 = () => {
  const t = useTranslations()

  return (
    <CompetitionStep icon={IconTrophy} title="competition:step4-title">
      <p className="step-text">{t('competition:step4-text1')}</p>
    </CompetitionStep>
  )
}

export default CompetitionStep4
