import pathToRegexp from 'path-to-regexp'
import { matchPath } from 'react-router-dom'
import ecopa_regulations from '../assets/docs/regamento-ecopa-rfef-2021.pdf'

// Utils

const makePath = path => ({
  path,
  regexp: pathToRegexp(path),
  linkTo: pathToRegexp.compile(path),
  match: (pathname, options = {}) => matchPath(pathname, { ...options, path }),
})

export const ROOT = makePath('/')

export const REGISTER = makePath('/register')

export const USER_PROFILE = makePath('/my-profile')
export const USER_PROFILE_EDIT = makePath('/my-profile/edit')
export const PROFILES_SECTION = makePath('/profiles')
export const PROFILES_SUBSECTION = makePath('/profiles/:username')

export const ECOPA_SECTION = makePath('/eCopa')
export const ECOPA_PRIVACY = makePath('/eCopa/privacy')
export const ECOPA_KNOWMORE = makePath('/eCopa/knowmore')
export const ECOPA_FINAL_BRACKET = makePath('/eCopa/final-bracket')
export const ECOPA_REGULATIONS_PATH = ecopa_regulations
export const ECOPA_INSCRIPTION = makePath('/eCopa/inscription')
export const ECOPA_SUBMIT_RESULTS = makePath('/eCopa/submit-results')
export const ECOPA_DIVISION_SECTION = makePath('/eCopa/division')
export const ECOPA_DIVISION_SUBSECTION = makePath(
  '/eCopa/division/:subtournamentId',
)
export const ECOPA_DIVISION_BRACKET = makePath(
  '/eCopa/division/:subtournamentId/bracket',
)

export const NEWS_SECTION = makePath('/news')
export const POST = makePath('/news/:slug')

export const COMPETITIONS = makePath('/competitions')
export const COMPETITION = makePath('/competitions/:slug')

export const SHOP_PATH = 'https://oslstore.com/'

export const PASSWORD_NEW = makePath('/password-reset')

export const TGA = makePath('/TGA')

export const GT_GRAND_PRIX = makePath('/competitions/GranPremioGT')

export const PRIVACY = makePath('/privacy')

export const CONDITIONS = makePath('/conditions')

export const COOKIES = makePath('/cookies')

export const LOGIN = makePath('/login')

export const INTERNAL_ERROR = makePath('/internal-error')
export const NOT_FOUND_ERROR = makePath('/not-found-error')
