import React from 'react'
import { useSelector } from 'react-redux'

import * as routes from '../routes'

import {
  MODAL_NEWSLETTER,
  MODAL_CONTACT,
  MODAL_LOGIN,
  MODAL_RECOVER,
  MODAL_RECOVER_SENT,
  MODAL_CONFIRM_INSCRIPTION,
  MODAL_PASSWORD_CHANGED,
  MODAL_PASSWORD_NEW,
} from './constants'
import ModalLogin from './ModalLogin'
import ModalPasswordRecover from './ModalPasswordRecover'
import ModalNewsletter from './ModalNewsletter'
import ModalContact from './ModalContact'
import ModalConfirmInscription from './ModalConfirmInscription'
import ModalRecoverSent from './ModalRecoverSent'
import ModalPasswordChanged from './ModalPasswordChanged'
import ModalPasswordNew from './ModalPasswordNew'

const renderModal = modalKind => {
  if (!modalKind) return null

  if (modalKind === MODAL_NEWSLETTER)
    return <ModalNewsletter privacyPolicyTo={routes.PRIVACY.path} />

  if (modalKind === MODAL_CONTACT)
    return <ModalContact privacyPolicyTo={routes.PRIVACY.path} />

  if (modalKind === MODAL_LOGIN) return <ModalLogin />

  if (modalKind === MODAL_RECOVER) return <ModalPasswordRecover />

  if (modalKind === MODAL_CONFIRM_INSCRIPTION)
    return <ModalConfirmInscription />

  if (modalKind === MODAL_RECOVER_SENT) return <ModalRecoverSent />

  if (modalKind === MODAL_PASSWORD_CHANGED) return <ModalPasswordChanged />

  if (modalKind === MODAL_PASSWORD_NEW) return <ModalPasswordNew />

  console.error('Unrecognized modal kind:', modalKind)
  return null
}

export default () => {
  const { activeModal } = useSelector(state => state.visual)

  return renderModal(activeModal)
}
